// @flow

import { useDispatch, useSelector } from 'react-redux'

import { togglePageDialog, clearDevicesPageDialog, setInclusiveLicenseSubmit, clearClaimingSubmit, completeClaimDevice } from 'actions'

import { ALERT_TYPE, Button, OutlineButton, Alert } from 'components/ReUsable'

import { INCLUSIVE_LICENSE_SCREEN_STEP } from 'appConstants'
import { useEffect, useState } from 'react'
import { LicenseType } from 'types/devices'
import { capitalizeFirstLetter } from 'utils/helpers'
import styled from 'styled-components'

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  min-width: 100%;

  & > button {
    width: 76%;
  }
`

type StepSixStateProps = {
  devices: {
    pageDialog: {
      title: string
      currentStep: number
      currentUserStep: number
      lastStep: number
      requestErrorMessage: string
      inclusiveLicense: LicenseType
    }
    startClaimingForm: {
      serialNumber: string
      defaultName: string
    }
    setDeviceNameForm: {
      name: string
    }
    efoyAddedSuccess: boolean
  }
}

const selectDeviceState = (state: any): StepSixStateProps => ({
  devices: state.devices
})

export function AddEfoyStepSix () {
  const dispatch = useDispatch()
  const { devices } = useSelector(selectDeviceState)
  const { efoyAddedSuccess, startClaimingForm, pageDialog } = devices
  const [success, setSuccess] = useState(efoyAddedSuccess)
  const { currentStep, requestErrorMessage, inclusiveLicense } = pageDialog
  const dynamicText = inclusiveLicense ? `${inclusiveLicense.duration === 'half_year' ? 'half year' : '1-year'} ${capitalizeFirstLetter(inclusiveLicense.tier)}` : ''
  const _togglePageDialog = () => dispatch(togglePageDialog())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())
  const _setInclusiveLicenseSubmit = (payload: {serialNumber: string}) => dispatch(setInclusiveLicenseSubmit(payload))
  const _clearClaimingSubmit = () => dispatch(clearClaimingSubmit())
  const _completeClaimDevice = () => dispatch(completeClaimDevice())

  useEffect(() => {
    if (efoyAddedSuccess) {
      setSuccess(true)
      _clearClaimingSubmit()
    }
  }, [efoyAddedSuccess])

  const handleInclusiveLicenseActivation = () => {
    _setInclusiveLicenseSubmit({ serialNumber: startClaimingForm.serialNumber })
  }

  if (currentStep === INCLUSIVE_LICENSE_SCREEN_STEP) {
    return (
      <>
        {!success && requestErrorMessage ? <Alert message={requestErrorMessage} type={ALERT_TYPE.danger} /> : null}
        {success ? (
          <Alert message="The inclusive license is now active." type={ALERT_TYPE.success} />
          ) : (
          <>
            <p
              className="add-efoy-message"
              data-cy="inclusive-license-text"
              dangerouslySetInnerHTML={{
                __html: `Thank you for choosing this EFOY.<br/>A ${dynamicText} license is included in the purchase price of your EFOY. Would you like to activate this license now?<br/>Attention: The license period starts
          immediately and cannot be canceled or paused!`
              }}
            ></p>
            <StyledButtonContainer>
              <Button data-cy="activate-inclusive-license" type="button" onClick={() => handleInclusiveLicenseActivation()}>
                Activate the license now
              </Button>
            </StyledButtonContainer>
            <p className="add-efoy-message">You can also activate your included license later via the &quot;Apply License&quot; menu. </p>
            <StyledButtonContainer>
              <OutlineButton
                data-cy="activate-inclusive-license-later"
                type="button"
                onClick={() => {
                  _togglePageDialog()
                  _clearDevicesPageDialog()
                  _clearClaimingSubmit()
                  _completeClaimDevice()
                }}
              >
                Do it later
              </OutlineButton>
            </StyledButtonContainer>
          </>
        )}
      </>
    )
  }

  return null
}

export default AddEfoyStepSix
