// @flow

class AlertType {
  value: any
  constructor (type: any) {
    this.value = type
  }
}

const ALERT_TYPE = {
  success: new AlertType('success'),
  danger: new AlertType('danger'),
  warning: new AlertType('warning')
}

type Props = {
  className?: string,
  id?: string,
  type: AlertType,
  message?: string,
  messages?: Array<any>
}

const alertMessage = (messages) => {
  if (messages.length > 0) {
    return (
      messages.map((item, index) => (
        <p style={{ marginBottom: 0, fontSize: '13px' }} key={index}>
          { item }
        </p>
      ))
    )
  } else {
    return (
      <p>{ messages[0] }</p>
    )
  }
}

const Alert = (props: Props) => {
  if (props.type instanceof AlertType) {
    const messages = props.message ? [props.message] : props.messages
    return (
      <div id={props.id ? props.id : undefined} className={`alert alert-${props.type.value} ${props.className}`} role='alert' >
        {alertMessage(messages)}
      </div>
    )
  } else {
    const errorMessage = 'Wrong type given for AlertType!'
    return (<div>{errorMessage}</div>)
  }
}

export { ALERT_TYPE, Alert }
