import * as C from 'appConstants'
import {
  DevicesSearchType,
  CurrentDeviceType,
  DeviceOverviewEndpointType,
  TelemertyPayloadType,
  TelemetryHistoryResponseType,
  TelemetryEventItemType
} from 'types/devices'

import { LicenseType, UpdateLicenseType, LicenseStateType } from 'types/license'
import { TelemetryHistoryType, TelemetryEventType } from 'types/telemetry'

export const getDevice = (payload: {serialNumber: string}) => ({ type: C.GET_DEVICE, payload })

export const getDevices = (payload: DevicesSearchType) => ({ type: C.GET_DEVICES, payload })

export const updateDeviceName = (payload: { serialNumber: string, name: string }) => ({ type: C.UPDATE_DEVICE_NAME, payload })

export const clearDevicesPageDialog = () => ({ type: C.CLEAR_DEVICES_PAGE_DIALOG })

export const createDeviceExport = (payload: { serialNumber: string }) => ({ type: C.CREATE_DEVICE_EXPORT, payload })

export const checkDeviceExport = (payload: { id: string }) => ({ type: C.CHECK_DEVICE_EXPORT, payload })

export const getDeviceExport = (payload: { id: string }) => ({ type: C.GET_DEVICE_EXPORT, payload })

export const populateDeviceExport = (payload: { response: { finished: boolean, exportId: string }}) => ({ type: C.POPULATE_DEVICE_EXPORT, payload })

export const getTelemetryHistory = (payload: TelemetryHistoryType) => ({ type: C.GET_TELEMETRY_HISTORY, payload })

export const getTelemetryEvents = (payload: TelemetryEventType) => ({ type: C.GET_TELEMETRY_EVENTS, payload })

export const clearDevice = () => ({ type: C.CLEAR_DEVICE })

export const populateDevice = (payload: {device: CurrentDeviceType}) => ({ type: C.POPULATE_DEVICE, payload })

export const populateDevices = (payload: DeviceOverviewEndpointType) => ({ type: C.POPULATE_DEVICES, payload })

export const populateTelemetryHistory = (payload: {response: TelemetryHistoryResponseType, payload: TelemertyPayloadType}) => ({ type: C.POPULATE_TELEMETRY_HISTORY, payload })

export const populateTelemetryEvents = (payload: {response: TelemetryEventItemType}) => {
  return { type: C.POPULATE_TELEMETRY_EVENTS, payload }
}

export const chargeFuelCell = (payload: any) => ({ type: C.CHARGE_FUEL_CELL, payload })

export const updateOperatingMode = (payload: any) => ({ type: C.UPDATE_OPERATING_MODE, payload })

export const populateChargeFuelCell = (payload: any) => ({ type: C.POPULATE_CHARGE_FUEL_CELL, payload })

export const populateRequestIsStarted = (payload?: any) => ({ type: C.POPULATE_REQUEST_IS_STARTED, payload })

export const populateRequestIsFinished = (payload?: any) => ({ type: C.POPULATE_REQUEST_IS_FINISHED, payload })

export const resetFuelCell = (payload: any) => ({ type: C.RESET_FUEL_CELL, payload })

export const populateResetFuelCell = (payload: any) => ({ type: C.POPULATE_RESET_FUEL_CELL, payload })

export const resetFuelCellStatus = (payload: {serialNumber: string}) => ({ type: C.RESET_FUEL_CELL_STATUS, payload })

export const populateResetFuelCellStatus = (payload: any) => ({ type: C.POPULATE_RESET_FUEL_CELL_STATUS, payload })

export const createXlsxExport = (payload: {serialNumber: string}) => ({ type: C.CREATE_XLSX_EXPORT, payload })

export const checkXlsxExport = (payload: {serialNumber: string, exportId: string}) => ({ type: C.CHECK_XLSX_EXPORT, payload })

export const clearXLSXExport = () => ({ type: C.CLEAR_XLSX_EXPORT })

export const getXlsxExport = (payload: {serialNumber: string, exportId: string}) => ({ type: C.GET_XLSX_EXPORT, payload })

export const populateXlsxExport = (payload: {response: {exportId: string, checkUrl?: string, deviceSerialNumber?: string, finished?: string | null, downloadUrl?: string| null, filename?: string | null, fileLimitExceeded?: boolean}}) => {
  return { type: C.POPULATE_XLSX_EXPORT, payload }
}

export const resetChargingCycle = (payload: {serialNumber: string}) => ({ type: C.RESET_CHARGING_CYCLE, payload })

export const resetRequest = () => ({ type: C.RESET_REQUEST })

export const resetDeviceExport = () => ({ type: C.RESET_DEVICE_EXPORT })

export const getLicenses = (payload: { serialNumber: string, state: LicenseStateType }) => ({ type: C.GET_LICENSES, payload })

export const populateLicenses = (payload: LicenseType) => ({ type: C.POPULATE_LICENSES, payload })

export const updateDeviceLicense = (payload: UpdateLicenseType) => ({ type: C.UPDATE_DEVICE_LICENSE, payload })

export const deleteDeviceLicense = (payload: { key: string, serialNumber: string, state: LicenseStateType }) => ({ type: C.DELETE_DEVICE_LICENSE, payload })

export const addDeviceLicense = (payload: any) => ({ type: C.ADD_DEVICE_LICENSE, payload })

export const toggleSelectionMode = (payload: any) => ({ type: C.TOGGLE_SELECTION_MODE, payload })

export const updateSelectedDevices = (payload: any) => ({ type: C.UPDATE_SELECTED_DEVICES, payload })

export const batchUpdateSelectedDevices = (payload: any) => ({ type: C.BATCH_UPDATE_SELECTED_DEVICES, payload })

export const updateSelectedDeviceColumns = (payload: { selectedList: string[] }) => ({ type: C.UPDATE_SELECTED_DEVICE_COLUMNS, payload })

export const applyDeviceFilter = (payload: any) => ({ type: C.APPLY_DEVICE_FILTER, payload })

export const updateDeviceSorting = (payload: {sorting: {
  sortKey: string;
  sortDirection: string;
}}) => ({ type: C.UPDATE_DEVICE_SORTING, payload })

export const updateDevicePageNumber = (payload: number) => ({ type: C.UPDATE_DEVICE_PAGE_NUMBER, payload })

export const updateDeviceServiceMode = (payload: { serialNumber: string, serviceMode: boolean }) => ({ type: C.UPDATE_DEVICE_SERVICE_MODE, payload })
