export const GET_DEVICES = 'GET_DEVICES'
export const GET_DEVICE = 'GET_DEVICE'
export const CLEAR_DEVICE = 'CLEAR_DEVICE'
export const UPDATE_DEVICE_NAME = 'UPDATE_DEVICE_NAME'

export const CLEAR_DEVICES_PAGE_DIALOG = 'CLEAR_DEVICES_PAGE_DIALOG'

export const POPULATE_DEVICES = 'POPULATE_DEVICES'
export const POPULATE_DEVICE = 'POPULATE_DEVICE'

export const GET_TELEMETRY_HISTORY = 'GET_TELEMETRY_HISTORY'
export const POPULATE_TELEMETRY_HISTORY = 'POPULATE_TELEMETRY_HISTORY'

export const GET_TELEMETRY_EVENTS = 'GET_TELEMETRY_EVENTS'
export const POPULATE_TELEMETRY_EVENTS = 'POPULATE_TELEMETRY_EVENTS'

export const UPDATE_OPERATING_MODE = 'UPDATE_OPERATING_MODE'
export const CHARGE_FUEL_CELL = 'CHARGE_FUEL_CELL'
export const POPULATE_CHARGE_FUEL_CELL = 'POPULATE_CHARGE_FUEL_CELL'
export const RESET_FUEL_CELL = 'RESET_FUEL_CELL'
export const POPULATE_RESET_FUEL_CELL = 'POPULATE_RESET_FUEL_CELL'
export const RESET_FUEL_CELL_STATUS = 'RESET_FUEL_CELL_STATUS'
export const POPULATE_RESET_FUEL_CELL_STATUS = 'POPULATE_RESET_FUEL_CELL_STATUS'
export const RESET_FUEL_CELL_SUCCESS_MESSAGE = 'Reset was successful.'

export const RESET_COMPLETE = 'RESET_COMPLETE'
export const WAITING_COMPLETE = 'waiting_complete'
export const FAILED = 'Failed'
export const COMPLETE = 'complete'
export const LOADING_SPINNER = 'loading_spinner'

export const POLLING_MS_INTERVAL = 60000
export const RESET_FUEL_CELL_POLLING_MS_INTERVAL = 10000

export const GET_DEVICE_EXPORT = 'GET_DEVICE_EXPORT'
export const POPULATE_DEVICE_EXPORT = 'POPULATE_DEVICE_EXPORT'
export const CREATE_DEVICE_EXPORT = 'CREATE_DEVICE_EXPORT'
export const CHECK_DEVICE_EXPORT = 'CHECK_DEVICE_EXPORT'
export const RESET_DEVICE_EXPORT = 'RESET_DEVICE_EXPORT'

export const PRODUCT_IMAGES = [
  'EFOY_unknown.png',
  'EFOY_80_front.png',
  'EFOY_80.png',
  'EFOY_150_front.png',
  'EFOY_150.png',
  'EFOY_Pro_900_front.png',
  'EFOY_Pro_900.png',
  'EFOY_Pro_1800_front.png',
  'EFOY_Pro_1800.png',
  'EFOY_Pro_2800_front.png',
  'EFOY_Pro_2800.png'
]

export const RESET_CHARGING_CYCLE = 'RESET_CHARGING_CYCLE'

export const CREATE_XLSX_EXPORT = 'CREATE_XLSX_EXPORT'
export const CHECK_XLSX_EXPORT = 'CHECK_XLSX_EXPORT'
export const CLEAR_XLSX_EXPORT = 'CLEAR_XLSX_EXPORT'
export const GET_XLSX_EXPORT = 'GET_XLSX_EXPORT'
export const POPULATE_XLSX_EXPORT = 'POPULATE_XLSX_EXPORT'
export const POPULATE_REQUEST_IS_STARTED = 'POPULATE_REQUEST_IS_STARTED'
export const POPULATE_REQUEST_IS_FINISHED = 'POPULATE_REQUEST_IS_FINISHED'
export const RESET_REQUEST = 'RESET_REQUEST'

export const GET_LICENSES = 'GET_LICENSES'
export const GET_LICENSE = 'GET_LICENSE'
export const POPULATE_LICENSES = 'POPULATE_LICENSES'
export const UPDATE_DEVICE_LICENSE = 'UPDATE_DEVICE_LICENSE'
export const DELETE_DEVICE_LICENSE = 'DELETE_DEVICE_LICENSE'
export const ADD_DEVICE_LICENSE = 'ADD_DEVICE_LICENSE'

export const TOGGLE_SELECTION_MODE = 'TOGGLE_SELECTION_MODE'
export const UPDATE_SELECTED_DEVICES = 'UPDATE_SELECTED_DEVICES'
export const BATCH_UPDATE_SELECTED_DEVICES = 'BATCH_UPDATE_SELECTED_DEVICES'

export const UPDATE_SELECTED_DEVICE_COLUMNS = 'UPDATE_SELECTED_DEVICE_COLUMNS'

export const APPLY_DEVICE_FILTER = 'APPLY_DEVICE_FILTER'
export const OPEN_DEVICE_FILTER = 'OPEN_DEVICE_FILTER'
export const CLOSE_DEVICE_FILTER = 'CLOSE_DEVICE_FILTER'

export const TERMINAL_USER = 'SFC> '

export const UPDATE_DEVICE_SORTING = 'UPDATE_DEVICE_SORTING'
export const UPDATE_DEVICE_PAGE_NUMBER = 'UPDATE_DEVICE_PAGE_NUMBER'

export const UPDATE_DEVICE_SERVICE_MODE = 'UPDATE_DEVICE_SERVICE_MODE'
