// @flow

import styled from 'styled-components'

import {
  Text,
  PermissionCheck,
  Card
} from 'components/ReUsable'
import { DevicePermissions } from './hooks/useAlerts'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { AlertConfiguration } from './alertTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledContainer } from '.'
import SelectElement from 'components/ReUsable/SelectElement'

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

export const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  position: relative;
  border: 1px solid #cfcfcf;
  border-radius: 0.4rem;

  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`

export const StyledButton = styled.button`
  border: none;
  background: none;
  color: var(--color-primary);
`

type Props = {
  selectedUserId: string;
  permissions: DevicePermissions;
  alertConfigs: AlertConfiguration[];
  onAlertConfigSelected: (selectedId: string) => void;
  onAlertRemoved: () => void;
};

const AlertConfigDropdown = ({ selectedUserId, alertConfigs, permissions, onAlertConfigSelected, onAlertRemoved }: Props) => {
  return (
    <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE}
      >
        <StyledContainer>
          <SelectElement
            value={selectedUserId || ''}
            id="alert-user-dropdown"
            className='form-control'
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onAlertConfigSelected(e.target.value)}>
            {alertConfigs.map((config: AlertConfiguration) => {
              return <option key={`config-${config.id}`} value={config.id} label={ config.email }/>
            })}
          </SelectElement>
          <StyledButton onClick={onAlertRemoved}>
            <FontAwesomeIcon
              icon={['fal', 'trash-alt']}
              size='lg'
            />
          </StyledButton>
        </StyledContainer>
    </PermissionCheck>
  )
}

export default AlertConfigDropdown
