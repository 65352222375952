// @flow
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface AlertConfigResponse {
  token: string;
  email: string;
  unsubscribedFromAllDevices?: boolean;
}

interface ErrorMessage {
  message: string;
}

export const useUnsubscribeAlert = function (apiFunc: (payload: any) => Promise<AlertConfigResponse | ErrorMessage >) {
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')
  const email = new URLSearchParams(search).get('email')
  const deviceSerialNumber = new URLSearchParams(search).get('deviceSerialNumber')
  const deviceName = new URLSearchParams(search).get('deviceName')
  const [loading, setLoading] = useState<number>(0)
  const [statusCode, setStatusCode] = useState<number>(0)

  const handleApiCall = async (includeDevice: boolean) => {
    try {
      setLoading(includeDevice ? 1 : 2)
      const response = await apiFunc({ token, email, ...(includeDevice && deviceSerialNumber && { deviceSerialNumber }) })
      if (Object.hasOwn(response, 'message')) { //  Error case
        throw new Error((response as ErrorMessage).message)
      }
      setStatusCode(200)
    } catch (e: any) {
      const statusCode = +(e as ErrorMessage).message
      setStatusCode(statusCode)
    } finally {
      setLoading(0)
    }
  }

  return {
    token,
    email,
    deviceSerialNumber,
    loading,
    statusCode,
    deviceName,
    handleApiCall
  }
}
