// @flow

import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { getDeviceTags, updateDeviceTags } from 'apis/tags'
import { Tag } from '../context/tagContext'

export type DeviceTag = Tag & {selected: boolean}

export type AllDeviceTags = {personal: DeviceTag[], groups: {[key: string]: {name: string, tags: DeviceTag}}}

export type SelectedTagObj = {id: string, selected: boolean}

export type DeviceTagType = {
  personal: Tag[],
  groups: {[key: string]: {
    name: string,
    tags: Tag[]
  }},
  loading: boolean,
  readError: any,
  apiError: string[]
  tagsEmpty: boolean
  allTags: AllDeviceTags
  userSelection: SelectedTagObj[]
  handleUserSelection: (s: SelectedTagObj) => void
  handleTagSave: () => Promise<boolean>
}

function sortTagsByName (tags: DeviceTag[]): DeviceTag[] {
  if (!tags) return []
  return tags.sort((a, b) => a?.name?.localeCompare(b.name))
}

function sortObjectKeys<T extends Record<string, any>> (obj: T): T {
  const sortedKeys = obj ? Object.keys(obj).sort() : []
  const sortedObj = {} as T

  sortedKeys.forEach((key) => {
    sortedObj[key as keyof T] = obj[key as keyof T]
  })

  return sortedObj
}
export function useDeviceTags (serialNumber: string): DeviceTagType {
    const [key, setKey] = useState<string | null>(null)
    useEffect(() => {
      setKey(`deviceTags${Date.now()}`)
    }, [])

  const { data: allTags, error: readError, isLoading: loading } = useSWR(key, (key) => getDeviceTags(key, serialNumber), { revalidateOnMount: true, cacheTime: 0 })
  const [apiError, setApiError] = useState<string[]>([]) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [userSelection, setUserSelection] = useState<SelectedTagObj[]>([])

  const handleUserSelection = (selectedObj: SelectedTagObj) => {
    const itemSelected = userSelection.find((item: SelectedTagObj) => item.id === selectedObj.id)
    if (itemSelected) {
      setUserSelection(prev => prev.map((s: SelectedTagObj) => {
        if (s.id !== selectedObj.id) {
          return s
        } else {
          return { id: s.id, selected: selectedObj.selected }
        }
      }))
    } else {
      setUserSelection([...userSelection, selectedObj])
    }
  }

  const handleTagSave = async (): Promise<boolean> => {
    try {
      const resp = await updateDeviceTags(serialNumber, userSelection)
      if (resp && resp.status && resp.status > 200) {
        throw resp
      }
      return true
    } catch (e: any) {
      return false
    }
  }

  const personalTags = !allTags ? [] : (sortTagsByName(allTags?.personal) as Tag[])
  const groupTags = sortObjectKeys(allTags?.groups)

  const tagsEmpty = Object.keys(groupTags).filter((k: string) => groupTags[k].tags.length > 0).length === 0 && personalTags.length === 0

  return {
    personal: personalTags,
    groups: groupTags,
    loading,
    readError,
    apiError,
    tagsEmpty,
    allTags,
    userSelection,
    handleUserSelection,
    handleTagSave
  }
}
