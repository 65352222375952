import {
  all,
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'

import {
  GET_DEVICES,
  GET_DEVICE,
  UPDATE_DEVICE_NAME,
  GET_DEVICE_EXPORT,
  CHARGE_FUEL_CELL,
  RESET_FUEL_CELL,
  RESET_FUEL_CELL_STATUS,
  GET_TELEMETRY_HISTORY,
  GET_TELEMETRY_EVENTS,
  UPDATE_OPERATING_MODE,
  CHARGE_NOW,
  CHECK_DEVICE_EXPORT,
  CREATE_DEVICE_EXPORT,
  CREATE_XLSX_EXPORT,
  GET_XLSX_EXPORT,
  CHECK_XLSX_EXPORT,
  RESET_CHARGING_CYCLE,
  GET_LICENSES,
  UPDATE_DEVICE_LICENSE,
  DELETE_DEVICE_LICENSE,
  ADD_DEVICE_LICENSE,
  LOADING_SPINNER,
  APPLY_DEVICE_FILTER,
  UPDATE_DEVICE_SERVICE_MODE
} from 'appConstants'

import {
  populateDevices,
  populateDevice,
  populateChargeFuelCell,
  populateResetFuelCell,
  populateResetFuelCellStatus,
  genericError,
  populateTelemetryHistory,
  populateTelemetryEvents,
  populateDeviceExport,
  populateXlsxExport,
  populateRequestIsFinished,
  populateRequestIsStarted,
  populateDeviceOfflineAlert,
  populateLicenses,
  populateError,
  closeDeviceFilter,
  togglePageDialog
} from 'actions'

import {
  getDeviceRequest,
  getDevicesRequest,
  updateDeviceNameRequest,
  updateOperatingModeRequest,
  resetFuelCellRequest,
  resetFuelCellStatusRequest,
  resetChargingCycleRequest,
  telemetryHistoryRequest,
  telemetryEventsRequest,
  createDeviceExportRequest,
  checkDeviceExportRequest,
  getDeviceExportRequest,
  createXlsxExportRequest,
  checkXlsxExportRequest,
  getXlsxExportRequest,
  getDeviceLicenses,
  updateDeviceLicense,
  deleteDeviceLicense,
  addDeviceLicense,
  getSelectedDeviceColumns,
  updateServiceModeRequest
} from 'apis/devices'

import { CurrentDeviceType } from 'types/devices'

import { parseDeviceTelemetryData } from 'helpers/deviceContents'
import { getErrorStatusMessage } from 'helpers/status_message'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function * getDevice ({ type, payload }: {type: string, payload: any}) {
  try {
    const device: CurrentDeviceType = yield call(getDeviceRequest, payload.serialNumber)
    yield put(populateDevice({
      device
    }))

    if (device && device.state && !device.state.content.isConnected) {
      yield put(populateDeviceOfflineAlert())
    }
  } catch (err) {
    yield put(genericError(err))
  }
}

// Selector function to get the uiState from the store
export const getUiState = (state: any) => state.uiState

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getDevices ({ type, payload }: { type: string, payload: any }) {
  // Retrieve uiState from the Redux store
  const uiState = yield select(getUiState)
  const filterModalOpened = uiState?.filterModalOpened

  //  If user is currently opened the popups to filter the values, skip the call to getDevices in Polling.
  if (filterModalOpened) {
    return
  }

  try {
    const filterStr = payload.filter ? JSON.stringify(replacePendingValues(payload.filter)) : undefined
    const [devicesData, selectedColumnsData] = yield all([call(getDevicesRequest, payload.page, payload.searchValue, payload.sorting, filterStr), call(getSelectedDeviceColumns)])
    const devicesContent = yield all(devicesData.content.map(deviceDataItem => parseDeviceTelemetryData(deviceDataItem)))
    yield put(populateDevices(
      {
        content: devicesContent,
        searchValue: payload.searchValue,
        firstPage: devicesData.first,
        lastPage: devicesData.last,
        pageNumber: devicesData.number + 1,
        totalPages: devicesData.totalPages || 1,
        totalItems: devicesData.totalElements,
        sorting: payload.sorting,
        selectedColumns: selectedColumnsData || []
      }
    ))
  } catch (err) {
    yield put(genericError(err))
  }
}

export type CompoundState = {
  active: string[];
  inactive: string[];
}

export type DeviceFilters = {
  name?: string;
  serialNumber?: string;
  firmwareVersion?: string;
  compoundState?: CompoundState;
}

/*
  Those two states are represented in front end with only one value: pending
  'maintenance_circulation_pump',
  'maintenance_dosing_and_feeding_pump',
  The reason is we do not want to show those states to the user at all.
  Before we send the request, we need to check if pending is selected as active or inactive configuration
  If this is the case we replace 'pending' with these two values and send it to the API
  In REDUX in front end we still display and show only one value 'pending'
*/
function replacePendingValues (filter: DeviceFilters) {
  let filtersVal = { ...filter }
  if (filtersVal.compoundState &&
    filtersVal.compoundState.active &&
    filtersVal.compoundState.active.indexOf('pending') > -1
  ) {
    //  Replace pending value with 2 maintenance values.
    filtersVal = {
      ...filtersVal,
      compoundState: {
        ...filtersVal.compoundState,
        active: [
          ...filtersVal.compoundState.active.filter(
            (s: string) => s !== 'pending'
          ),
          'maintenance_circulation_pump',
          'maintenance_dosing_and_feeding_pump'
        ]
      }
    }
  }
  if (filtersVal.compoundState &&
    filtersVal.compoundState.inactive &&
    filtersVal.compoundState.inactive.indexOf('pending') > -1
  ) {
    //  Replace pending value with 2 maintenance values.
    filtersVal = {
      ...filtersVal,
      compoundState: {
        ...filtersVal.compoundState,
        inactive: [
          ...filtersVal.compoundState.inactive.filter(
            (s: string) => s !== 'pending'
          ),
          'maintenance_circulation_pump',
          'maintenance_dosing_and_feeding_pump'
        ]
      }
    }
  }

  return filtersVal
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * chargeFuelCell ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(updateOperatingModeRequest, payload.serialNumber, CHARGE_NOW)
    yield put(populateChargeFuelCell({ response: response, operatingMode: response?.userMode, status: 'Ok\r' }))
    yield getDevice({ type: GET_DEVICE, payload })
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateOperatingMode ({ type, payload }: { type: string, payload: any }) {
  try {
    yield put(populateRequestIsStarted())
    const response = yield call(updateOperatingModeRequest, payload.serialNumber, payload.operatingMode)
    yield put(populateChargeFuelCell({ response: response, operatingMode: response?.userMode, status: 'Ok\r' }))
    yield put(populateRequestIsFinished())
    yield getDevice({ type: GET_DEVICE, payload })
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * resetFuelCell ({ type, payload }: { type: string, payload: any }) {
  try {
    // Adds loading screen to the store, until we get the request done
    yield put(populateResetFuelCell({ response: {}, resetStatus: LOADING_SPINNER, serialNumber: payload.serialNumber }))

    const response = yield call(resetFuelCellRequest, payload.serialNumber)
    yield put(populateResetFuelCell({ response: response, resetStatus: response.state, serialNumber: payload.serialNumber }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * resetFuelCellStatus ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(resetFuelCellStatusRequest, payload.serialNumber)
    yield put(populateResetFuelCellStatus({ response: response, resetStatus: response.state, serialNumber: payload.serialNumber }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * resetChargingCycle ({ type, payload }: { type: string, payload: any }) {
  try {
    call(resetChargingCycleRequest, payload.serialNumber)
    yield call(resetChargingCycleRequest, payload.serialNumber)
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getTelemetryHistory ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(
      telemetryHistoryRequest,
      payload.serialNumber,
      payload.page,
      payload.size,
      payload.timeScale,
      payload.startTimestamp,
      payload.endTimestamp
    )
    yield put(populateTelemetryHistory({ response: response, payload }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getTelemetryEvents ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(
      telemetryEventsRequest,
      payload.serialNumber,
      payload.page,
      payload.size,
      payload.timeScale,
      payload.startTimestamp,
      payload.endTimestamp
    )
    yield put(populateTelemetryEvents({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * createDeviceExport ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(
      createDeviceExportRequest,
      payload.serialNumber
    )
    yield put(populateDeviceExport({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * checkDeviceExport ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(
      checkDeviceExportRequest,
      payload.id
    )
    yield put(populateDeviceExport({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getDeviceExport ({ type, payload }: {type: string, payload: any}) {
  try {
    yield call(
      getDeviceExportRequest,
      payload.id
    )
    yield put(populateDeviceExport(
      { response: { finished: true, exportId: payload.id } }
    ))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * createXlsxExport ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(createXlsxExportRequest, payload.serialNumber)
    yield put(populateXlsxExport({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * checkXlsxExport ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(checkXlsxExportRequest, payload.serialNumber, payload.exportId)
    yield put(populateXlsxExport({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getXlsxExport ({ type, payload }: {type: string, payload: {serialNumber: string, exportId: string}}) {
  try {
    yield call(getXlsxExportRequest, payload.serialNumber, payload.exportId)
    yield put(populateXlsxExport({ response: { exportId: payload.exportId } }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateDeviceName ({ type, payload }: {type: string, payload: any}) {
  try {
    yield call(updateDeviceNameRequest, payload.serialNumber, payload.name)
    yield getDevice({ type: GET_DEVICE, payload })
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getLicenses ({ type, payload }: {type: string, payload: any}) {
  try {
    const licenses = yield call(getDeviceLicenses, payload.serialNumber, payload.state)
    yield put(populateLicenses(licenses))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateAndPopulateDeviceLicense ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(updateDeviceLicense, payload.key, payload.details)
    if (response && response.device) yield getLicenses({ type: GET_LICENSES, payload: { serialNumber: response.device.serialNumber, state: payload.state } })
    else yield put(populateError({ operation: type, code: response.status ? response.status : 404, message: getErrorStatusMessage(response.message) }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * performDeleteDeviceLicense ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(deleteDeviceLicense, payload.key)
    if (response.status && response.status >= 400) put(populateError({ operation: type, code: 400, message: getErrorStatusMessage(400) }))
    else yield getLicenses({ type: GET_LICENSES, payload: { serialNumber: payload.serialNumber, state: payload.state } })
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * performAddLicense ({ type, payload }: {type: string, payload: any}) {
  try {
    const response = yield call(addDeviceLicense, payload.serialNumber, payload.details)
    if (response.status && response.status >= 400) yield put(populateError({ operation: type, code: response.status, message: getErrorStatusMessage(response.status) }))
    else yield getLicenses({ type: GET_LICENSES, payload: { serialNumber: payload.serialNumber, state: payload.state } })
  } catch (err) {
    yield put(genericError(err))
  }
}

export function * handleCloseFilter () {
  yield put(closeDeviceFilter())
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateServiceMode ({ type, payload }: { type: string, payload: { serialNumber: string, serviceMode: boolean } }) {
  try {
    const response = yield call(updateServiceModeRequest, payload.serialNumber, payload.serviceMode)

    if (response && !response.status) {
      yield put(togglePageDialog())
      yield getDevice({ type: GET_DEVICE, payload })
    }
  } catch (err) {
    yield put(genericError(err))
  }
}

export default function * devicesSaga () {
  yield takeLatest(GET_DEVICES, getDevices)
  yield takeLatest(GET_DEVICE, getDevice)
  yield takeLatest(UPDATE_DEVICE_NAME, updateDeviceName)
  yield takeLatest(CHARGE_FUEL_CELL, chargeFuelCell)
  yield takeLatest(UPDATE_OPERATING_MODE, updateOperatingMode)
  yield takeLatest(RESET_FUEL_CELL, resetFuelCell)
  yield takeLatest(RESET_FUEL_CELL_STATUS, resetFuelCellStatus)
  yield takeLatest(GET_TELEMETRY_HISTORY, getTelemetryHistory)
  yield takeLatest(GET_TELEMETRY_EVENTS, getTelemetryEvents)
  yield takeLatest(CREATE_DEVICE_EXPORT, createDeviceExport)
  yield takeLatest(GET_DEVICE_EXPORT, getDeviceExport)
  yield takeLatest(CHECK_DEVICE_EXPORT, checkDeviceExport)
  yield takeLatest(CREATE_XLSX_EXPORT, createXlsxExport)
  yield takeLatest(GET_XLSX_EXPORT, getXlsxExport)
  yield takeLatest(CHECK_XLSX_EXPORT, checkXlsxExport)
  yield takeLatest(RESET_CHARGING_CYCLE, resetChargingCycle)
  yield takeLatest(GET_LICENSES, getLicenses)
  yield takeLatest(UPDATE_DEVICE_LICENSE, updateAndPopulateDeviceLicense)
  yield takeLatest(DELETE_DEVICE_LICENSE, performDeleteDeviceLicense)
  yield takeLatest(ADD_DEVICE_LICENSE, performAddLicense)
  yield takeLatest(APPLY_DEVICE_FILTER, handleCloseFilter)
  yield takeLatest(UPDATE_DEVICE_SERVICE_MODE, updateServiceMode)
}
