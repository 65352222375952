// @flow

import React from 'react'
import { flexRender } from '@tanstack/react-table'

type Props = {
  row: any,
  style: React.CSSProperties
}

const TableRow = ({ row, style }: Props) => (
  <tr style={style}>
    {row.getVisibleCells().map(cell => {
      return <React.Fragment key={cell.id}>
        {flexRender(
          cell.column.columnDef.cell,
          cell.getContext()
        )}
      </React.Fragment >
    })}
  </tr>
)

export default TableRow
