// @flow

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearDevice, getDevice } from 'actions'
import { PageNotReachable, SomethingWentWrong } from 'components/ReUsable'
import { Spinner } from 'react-bootstrap'
import { POLLING_MS_INTERVAL } from 'appConstants'

export function useCurrentDevice (serialNumber: string, recurring?: boolean) {
  const dispatch = useDispatch()
  const [responseCode, setResponseCode] = useState<number>(0)
  const [deviceLoadingOrErrorComponent, setDeviceLoadingOrErrorComponent] = useState<null |JSX.Element>(null)
  const currentDevice = useSelector((state: any) => state.devices.current)
  const alerts = useSelector((state: any) => state?.alert)
  const _getDevice = (payload: { serialNumber: string }) => dispatch(getDevice(payload))
  const _clearDevice = () => dispatch(clearDevice())

  //  Get current Device by loading the component
  useEffect(() => {
      _getDevice({ serialNumber })
      let interval
      if (recurring) {
        interval = setInterval(() => _getDevice({ serialNumber }), POLLING_MS_INTERVAL)
      }
      return () => {
        if (recurring && interval) {
          clearInterval(interval)
        }
        _clearDevice()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber])

  useEffect(() => {
   // Update response code depending on API response 200 OK, > 200 error.
   if (currentDevice) {
    setResponseCode(currentDevice.error && currentDevice.error.status ? currentDevice.error.status : 200)
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDevice?.serialNumber, currentDevice?.error?.status])

  useEffect(() => {
    //  Device not exists yet. Loading
    if (!currentDevice && responseCode === 0) {
      setDeviceLoadingOrErrorComponent(<Spinner animation="border" variant="secondary" />)
    }
    //  Error cases.
    if (responseCode === 403) {
      setDeviceLoadingOrErrorComponent(<PageNotReachable errorMessage={`You have no permission to access device ${ serialNumber }`} />)
  }
    if (responseCode === 404) {
      setDeviceLoadingOrErrorComponent(<PageNotReachable/>)
    }
    if (responseCode === 500) {
      setDeviceLoadingOrErrorComponent(<SomethingWentWrong/>)
    }
    //  Success case.
    if (responseCode === 200) {
      setDeviceLoadingOrErrorComponent(null)
    }
  }, [responseCode])

  return { currentDevice, responseCode, deviceLoadingOrErrorComponent, alerts }
}
