// @flow

import { useDispatch, useSelector } from 'react-redux'

import { startClaimingSubmit } from 'actions'

import { Button } from 'components/ReUsable'

import { RETRY_CONNECTION_SCREEN_STEP } from 'appConstants'

type StepFourStateProps = {
  devices: {
    pageDialog: {
      currentStep: number,
      currentUserStep: number,
      lastStep: number,
      requestErrorMessage: string
    },
    startClaimingForm: {
      serialNumber: string
    }
  },
}

const selectDeviceState = (state: any): StepFourStateProps => ({
  devices: state.devices
})

export function AddEfoyStepFour () {
  const dispatch = useDispatch()
  const { devices } = useSelector(selectDeviceState)
  const { startClaimingForm } = devices

  const {
    currentUserStep, currentStep, lastStep
  } = devices.pageDialog
  const _startClaimingSubmit = (payload: {
    serialNumber: string
}) => dispatch(startClaimingSubmit(payload))

  return currentStep === RETRY_CONNECTION_SCREEN_STEP ? (
    <>
      <p className='add-efoy-message'>
        The connection was unsuccessful, please try again.
        If required you can contact our support or gethelp online.
      </p>
      {/* this section can be a isolated component */}
      <div className='d-flex justify-content-between buttons-section'>
        <Button
          type='button' id='button-connect-retry'
          onClick={ () => _startClaimingSubmit(startClaimingForm) }
        >
          RETRY TO CONNECT
        </Button>
      </div>
      {/* this section can be a isolated component */}
      <p className='modal-step'>Step { currentUserStep } of { lastStep }</p>
    </>
    )
  : null
}

export default AddEfoyStepFour
