import * as C from 'appConstants'

type ContactSupportMessageType = {
  privacy: boolean,
  message: string,
  serialNumber?: string,
  deviceName?: string
}

export const sendContactSupportMessage = (payload: ContactSupportMessageType) => ({ type: C.SEND_CONTACT_SUPPORT_MESSAGE, payload })

export const populateSendContactSupportMessage = (payload: {response: { status: number, type?: string }}) => ({ type: C.POPULATE_SEND_CONTACT_SUPPORT_MESSAGE, payload })
