// @flow

import { Spinner } from 'react-bootstrap'
import { ALERT_TYPE, Alert } from 'components/ReUsable'
import { useTagContext } from '../context/tagContext'
import TagSelectionList from './tagSelectionList'
import { toggleItemInArray } from 'utils/helpers'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledNoTags = styled.p`
  font-family: open-sans-regular;
  color: var(--color-table-text);
  font-weight: bold;
  font-size: 1rem;
}`

const TagSelectorContent = ({ filterValues, onFilterChange }: { filterValues: string[], onFilterChange: (filters: string[]) => void}) => {
  const { personal, loading, groups, readError, tagsEmpty } = useTagContext()
  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  const handleSelection = (id: string) => {
    const newFilters = toggleItemInArray(filterValues, id)
    onFilterChange(newFilters)
  }

  if (tagsEmpty) {
    return <><StyledNoTags>No tags defined</StyledNoTags>
    <StyledNoTags>You can create tags <Link to="/tags"> here</Link>.</StyledNoTags></>
  }

  return (
    <>
      {readError ? (
        <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} />
      ) : (
        <>
          <TagSelectionList tags={personal} selectedTags={filterValues} onSelectTag={(id: string) => handleSelection(id)} />
          {Object.keys(groups).map((k: string) => {
            return <TagSelectionList key={k} tags={groups[k].tags || []} groupName={groups[k].name} groupSlug={k} onSelectTag={(id: string) => handleSelection(id)} selectedTags={filterValues} />
          })}
        </>
      )}
    </>
  )
}

export default TagSelectorContent
