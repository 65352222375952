// @flow

import {
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap'
import styled from 'styled-components'

import { Button, LicenseMissing, PermissionCheck } from 'components/ReUsable'

import {
  AUTO,
  SLEEP,
  DEVICE_DETAILS_PERMISSIONS_LIST,
  CHARGE_NOW,
  DENIED_MISSING_LICENSE
} from 'appConstants'

const ChargeNowButton = styled(Button)`
  margin-left: 10px;
`

type OperatingModeTypes = typeof AUTO | typeof SLEEP | typeof CHARGE_NOW

interface IOperatingModeToggle {
  serialNumber: string,
  permissions: any,
  operatingMode: string,
  updateOperatingMode:({ serialNumber, operatingMode }: { serialNumber: string, operatingMode: OperatingModeTypes }) => void,
  chargeFuelCell: ({ serialNumber }: { serialNumber: string }) => void
}

const OperatingModeToggle = function ({ serialNumber, permissions, operatingMode, updateOperatingMode, chargeFuelCell }: IOperatingModeToggle):JSX.Element {
  const operationModeMissingLicense = permissions[DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE] === DENIED_MISSING_LICENSE
  if (operationModeMissingLicense) {
    return <LicenseMissing mode="inline"/>
  }
  const mappedOperatingMode = operatingMode === 'off' ? SLEEP : AUTO
  return (
    <>
    <PermissionCheck
          value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE]}
          permission={DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE}
        >
          <ToggleButtonGroup
            name='operating-mode-select'
            type='radio'
            value={mappedOperatingMode}
          >
            <ToggleButton
              variant='light'
              id='operating-mode-option-auto'
              value={AUTO}
              onChange={() => {
                updateOperatingMode({ serialNumber, operatingMode: AUTO })
              }}
            >
              AUTO
            </ToggleButton>
            <ToggleButton
              variant='light'
              id='operating-mode-option-sleep'
              value={SLEEP}
              onChange={() => {
                updateOperatingMode({ serialNumber, operatingMode: SLEEP })
              }}
            >
              SLEEP
            </ToggleButton>
          </ToggleButtonGroup>
        </PermissionCheck>
        <PermissionCheck
          value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE]}
          permission={DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE}
        >
          <ChargeNowButton onClick={() => {
            chargeFuelCell({ serialNumber })
          }}>charge now</ChargeNowButton>
        </PermissionCheck>
        </>
  )
}

export default OperatingModeToggle
