export const GET_BATTERY = 'GET_BATTERY'
export const POPULATE_BATTERY = 'POPULATE_BATTERY'
export const LOADING_BATTERY_DATA = 'LOADING_BATTERY_DATA'
export const REQUEST_SAVE_BATTERY_CONFIG = 'REQUEST_SAVE_BATTERY_CONFIG'
export const POPULATE_SAVE_BATTERY_CONFIG = 'POPULATE_SAVE_BATTERY_CONFIG'
export const GET_BATTERY_TYPES = 'GET_BATTERY_TYPES'
export const GET_BATTERY_TYPE_LIMITS = 'GET_BATTERY_TYPE_LIMITS'
export const POPULATE_BATTERY_TYPES = 'POPULATE_BATTERY_TYPES'
export const POPULATE_BATTERY_TYPE_LIMITS = 'POPULATE_BATTERY_TYPE_LIMITS'
export const CLEAR_BATTERY_ALERT_PAGE_DIALOG = 'CLEAR_BATTERY_ALERT_PAGE_DIALOG'
