// @flow

import React, { useState, useEffect, useContext, createContext, Context } from 'react'

import { createAuth0Client } from '@auth0/auth0-spa-js'
import { integrationTestRunning } from 'utils/helpers'
import { PERMISSIONS_ALL } from './mockPermissions'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context: Context<any> = createContext({})
export const useAuth0 = () => {
  return integrationTestRunning(process.env.REACT_APP_ENV_MODE) ? MOCK_USER : useContext(Auth0Context)
}

//  Complete Mock auth user with all possible permissions set.
export const MOCK_USER = {
  user: {
    'http://sfcmonitoring.com/roles': [
      'Fleet_Manager',
      'SUPER_USER',
      'Support_User'
    ],
    given_name: 'Harry',
    family_name: 'Potter',
    nickname: 'harry.potter',
    name: 'harry.potter@demo.sfc.com',
    picture: '',
    updated_at: '2023-03-28T08:39:50.812Z',
    email: 'harry.potter@demo.sfc.com',
    email_verified: true,
    sub: 'auth0|63639aba7fd7fb28bb7bdb24',
    permissions: PERMISSIONS_ALL
  },
  getTokenSilently: () => null,
  isAuthenticated: true
}

type Props = {
  children: React.ReactNode,
  domain: string,
  client_id: string,
  redirect_uri: string,
  onRedirectCallback: (appState: any) => void,
  audience: string | undefined,
}

const once = (fn: any, context?: any) => {
  let result

  return (args?: any) => {
    if (fn) {
      result = fn.apply(context || this, { ...args })
      fn = null
    }

    return result
  }
}

// Avoid creating more than once the object.
export const createAuth0ClientWithParams = once(() => (
  createAuth0Client(
    {
      domain: (window as any).REACT_APP_AUTH0_DOMAIN ? (window as any).REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN,
      clientId: (window as any).REACT_APP_AUTH0_CLIENT_ID ? (window as any).REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: (window as any).REACT_APP_AUTH0_AUDIENCE ? (window as any).REACT_APP_AUTH0_AUDIENCE : process.env.REACT_APP_AUTH0_AUDIENCE
      }
    }
  )
))

export const Auth0Provider = (props: Props) => {
  const {
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
  } = props

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0ClientWithParams()
      setAuth0(auth0FromHook)

      const queryParams = window.location.search
      if (queryParams.includes('code=') && !queryParams.includes('code=success')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }

    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params: any = {}) => {
    setPopupOpen(true)

    try {
      await (auth0Client as any).loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }

    const user = await (auth0Client as any).getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await (auth0Client as any).handleRedirectCallback()
    const user = await (auth0Client as any).getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => (auth0Client as any).getIdTokenClaims(...p),
        loginWithRedirect: (...p) => (auth0Client as any).loginWithRedirect(...p),
        getTokenSilently: (...p) => (auth0Client as any).getTokenSilently(...p),
        getTokenWithPopup: (...p) => (auth0Client as any).getTokenWithPopup(...p),
        logout: (...p) => (auth0Client as any).logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
