// @flow

import { useState } from 'react'
import { Card, CardSection, Col, Dialog, LargeCardSection, LargeCardSectionTitle, Row, UpgradeLicenseButton } from 'components/ReUsable'
import styled from 'styled-components'
import { AnalogPort, IOPort, TemperaturePort, useMultisense } from './hooks/useMultisense'
import { MultiSenseType } from '../Types/DeviceTypes'
import { AlertIcon, FunctionIcon, PortLabel, PortType, ValueLabel } from './micros'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { togglePageDialog } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigurationForm } from './configurationForm'

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: 570px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const StyledCardSection = styled(CardSection)`
  position: relative;
  padding: 7px 8px;
  min-height: 96px;
  max-height: 96px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 768px) {
    min-height: 126px;
    max-height: 126px;
  }
`

const StyledPortHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > button {
    top: -8px;
    right: -8px;
    left: initial;
  }
`

const StyledPortHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`

export const MultiSense = ({ serialNumber, multiSenseType, isConnected }: { serialNumber: string; multiSenseType: MultiSenseType; isConnected: boolean }) => {
  const [selectedPortKey, setSelectedPortKey] = useState<string | null>(null)
  const dispatch = useDispatch()
  const { multiSensePorts, loading, error, screenName, saveMultiSensePortConfig, saveError } = useMultisense(serialNumber, multiSenseType)
  const { isPageDialogOpen, dialogId } = useSelector((state: any) => state.dialog)
  const dialogActive = isPageDialogOpen && dialogId === 'edit-multisense-dialog'

  const handlePortSelection = (portKey: string) => {
    if (multiSensePorts) {
      setSelectedPortKey(portKey)
    }
  }

  if ((!dialogActive && (error.length > 0 || loading)) || multiSensePorts === null) {
    return null
  }

  return (
    <>
      <Row margin="1rem 0">
        <Col alignItems="stretch">
          <Card>
            <Row>
              <Col>
                <LargeCardSection>
                  <StyledCardHeader>
                    <LargeCardSectionTitle data-cy="multisense-header">{screenName}</LargeCardSectionTitle>
                  </StyledCardHeader>
                </LargeCardSection>
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledGrid data-cy="multisense-grid">
                  {Object.keys(multiSensePorts).map((key: string) => {
                    return <MultiSensePort key={key} portKey={key} portSettings={multiSensePorts[key]} isConnected={isConnected} onPortSelected={handlePortSelection} />
                  })}
                </StyledGrid>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Dialog id="edit-multisense-dialog" className="modal-page multisense-modal" title={screenName} show={dialogActive} onClose={() => dispatch(togglePageDialog())}>
        <ConfigurationForm multiSensePorts={multiSensePorts} selectedPortKey={selectedPortKey} screenName={screenName} updateMultiSenseCall={saveMultiSensePortConfig} formError={saveError} />
      </Dialog>
    </>
  )
}

export const MultiSensePort = ({
  portKey,
  portSettings,
  isConnected,
  onPortSelected
}: {
  portKey: string
  portSettings: AnalogPort | TemperaturePort | IOPort | null
  isConnected: boolean
  onPortSelected: (portKey: string) => void
}) => {
  const dispatch = useDispatch()

  const configurationButton = <UpgradeLicenseButton
        dataCy={`edit-multisense-${portKey}`}
        isConnected={isConnected}
        handleClick={() => {
          dispatch(togglePageDialog({ dialogId: 'edit-multisense-dialog' }))
          onPortSelected(portKey)
        }}
        permissionNames={[DEVICE_DETAILS_PERMISSIONS_LIST.MULTI_SENSE_WRITE]}
      />

  if (portSettings === null) {
    return (
      <StyledCardSection data-cy="multisense-item">
        <StyledPortHeaderContainer>
          <StyledPortHeader>
            <PortType name={portKey} />
            <PortLabel enabled={true} label={'Port not configured'} portKey={portKey} />
          </StyledPortHeader>
          {configurationButton}
        </StyledPortHeaderContainer>
      </StyledCardSection>
    )
  }
  const { value, enabled, alert } = portSettings
  return (
    <StyledCardSection data-cy="multisense-item">
      <StyledPortHeaderContainer>
        <StyledPortHeader>
          <PortType name={portKey} />
          <PortLabel enabled={enabled} label={portSettings.function} portKey={portKey} />
        </StyledPortHeader>
        {configurationButton}
      </StyledPortHeaderContainer>
      <FunctionIcon portKey={portKey} portSettings={portSettings as AnalogPort | TemperaturePort | IOPort} />
      <ValueLabel enabled={enabled} functionName={portSettings.function} value={value} unit={(portSettings as AnalogPort | TemperaturePort)?.unit || undefined} />
      <AlertIcon enabled={enabled} alert={alert} />
    </StyledCardSection>
  )
}
