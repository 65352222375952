// @flow
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import { Alert, Text, Col, Button, ALERT_TYPE } from 'components/ReUsable'
import useDeactivateTransport from './Hooks/useDeactivateTransport'

export const StyledButtonBar = styled.div`
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column-reverse;
    & > button {
      display: block;
      min-width: 100%;
    }
  }

  @media (min-width: 991px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 360px;

    & > button {
      padding: 0 1rem;
    }
  }
`

export const StyledText = styled(Text)`
  margin: 24px 0;
`

export const StyledSpinner = styled(Spinner)`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
  margin-right: 0;
`

export const StyledAlert = styled(Alert)`
  margin-top: 16px;
  margin-bottom: 0;
`

export const ERROR_MSG = 'An error occured. Please try again later.'
export const SUCCES_MSG = 'Transport protection is deactivated.'

const DeactivateTransportDialog = function ({
  serialNumber
}: {
  serialNumber: string
}) {
  const { status, handleDeactivation } = useDeactivateTransport(serialNumber)

  return (
    <Col alignItems="center">
      {status.error ? (
        <StyledAlert message={ERROR_MSG} type={ALERT_TYPE.danger} />
      ) : (
        <></>
      )}
      {status.success ? (
        <StyledAlert message={SUCCES_MSG} type={ALERT_TYPE.success} />
      ) : (
        <></>
      )}
      <StyledText textAlign="center">
        The transport protection preserves the fuel cell and its components during shipping and transport.
      </StyledText>
      <StyledButtonBar>
        {!status.success ? (
          <Button
            onClick={handleDeactivation}
            data-cy="confirm-deactivate-transport-dialog"
            disabled={status.saving}
          >
            Confirm
            {status.saving && (
              <StyledSpinner animation="border" variant="secondary" />
            )}
          </Button>
        ) : (
          <></>
        )}
      </StyledButtonBar>
    </Col>
  )
}

export default DeactivateTransportDialog
