// @flow

import styled from 'styled-components'

type Props = {
  values: {
    default: string
  }
}

const StyledImage = styled.img<{isOwner: any}>`
  width: ${(props: any) => (props.isOwner ? '1.5rem' : '1.25rem')};
  height: ${(props: any) => (props.isOwner ? '1.5rem' : '1.25rem')};
  margin-right: 0.5rem;
`

export default function AccessTypeRowCell (props: Props) {
  const defaultValue = props.values.default === 'unrelated' ? '' : props.values.default
  const imgName = defaultValue === 'delegated' ? 'delegate-green' : defaultValue === 'owner' ? 'owner-green' : ''
  return (
    <td className={'text-centered d-none d-lg-table-cell'}>
      {imgName ? <StyledImage alt={defaultValue} src={`/imgs/${imgName}.svg`} isOwner={defaultValue === 'owner'}/> : null}
      <span>{defaultValue}</span>
    </td>
  )
}
