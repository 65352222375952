// @flow

import styled from 'styled-components'

export default styled.div.attrs((props: any) => ({
  className: (props.className ? `container ${props.className}` : 'container') as any
}))`
  display: flex;
  flex-direction: column;
`
