import { call, put, takeLatest } from 'redux-saga/effects'

import * as C from 'appConstants'

import {
  populateUserGroups,
  populateOneUserGroup,
  populateGroupAddSuccess,
  populateResetGroupSuccess,
  populateGroupDeleteSuccess,
  clearErrors,
  updateGroupsPagination,
  populateGroupUpdateSuccess,
  populateGroupLeaveSuccess,
  performUserGroupLoading,
  populateAPIErrors
} from 'actions'

import { createUserGroups, deleteUserGroup, deleteUserGroupMembership, getOneUserGroup, getUserGroups, leaveUserGroup, updateUserGroup, deleteUserGroupInvite } from 'apis/groups'

import { groupErrorHandler } from './helper'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * fetchAndPopulateUserGroups ({ type, payload }: { type: string; payload: { page: number; loading?: boolean } }) {
  try {
    if (payload?.loading) {
      yield put(performUserGroupLoading())
    }

    const response = yield call(getUserGroups, payload?.page)
    if (response) yield put(populateUserGroups(response))
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * createUserGroupsAndPopulate ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    yield call(createUserGroups, payload)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: 1 } })
    yield put(populateGroupAddSuccess())
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * fetchAndPopulateOneUserGroup ({ type, payload }: { type: string; payload: any }) {
  try {
    const response = yield call(getOneUserGroup, payload)
    yield put(populateOneUserGroup(response))
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateAndPopulateUserGroups ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    const response = yield call(updateUserGroup, payload.updatedGroup.data, payload.updatedGroup.slug)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: payload.page } })
    yield call(fetchAndPopulateOneUserGroup, { type: C.GET_ONE_USER_GROUP, payload: response.slug })
    yield put(populateGroupUpdateSuccess())
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * deleteAndPopulateUserGroups ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    yield call(deleteUserGroup, payload)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: 1 } })
    yield put(populateGroupDeleteSuccess())
    yield put(updateGroupsPagination(1))
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * leaveAndPopulateUserGroups ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    yield call(leaveUserGroup, payload)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: 1 } })
    yield put(populateGroupLeaveSuccess())
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * deleteUserGroupMembershipAndPopulateUserGroups ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    yield call(deleteUserGroupMembership, payload.groupUser.slug, payload.groupUser.email)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: payload.page } })
    yield call(fetchAndPopulateOneUserGroup, { type: C.GET_ONE_USER_GROUP, payload: payload.groupUser.slug })
    yield put(populateGroupUpdateSuccess())
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * deleteUserGroupInviteAndPopulateUserGroups ({ type, payload }: { type: string; payload: any }) {
  try {
    yield put(populateResetGroupSuccess())
    yield put(clearErrors())
    yield call(deleteUserGroupInvite, payload.invitedUser.slug, payload.invitedUser.email)
    yield call(fetchAndPopulateUserGroups, { type: C.GET_USER_GROUPS, payload: { page: payload.page } })
    yield call(fetchAndPopulateOneUserGroup, { type: C.GET_ONE_USER_GROUP, payload: payload.invitedUser.slug })
    yield put(populateGroupUpdateSuccess())
  } catch (error: any) {
    yield put(populateAPIErrors(groupErrorHandler({ type, error })))
  }
}

export default function * groupsSaga () {
  yield takeLatest(C.GET_USER_GROUPS, fetchAndPopulateUserGroups)
  yield takeLatest(C.CREATE_USER_GROUPS, createUserGroupsAndPopulate)
  yield takeLatest(C.GET_ONE_USER_GROUP, fetchAndPopulateOneUserGroup)
  yield takeLatest(C.REQUEST_UPDATE_USER_GROUP, updateAndPopulateUserGroups)
  yield takeLatest(C.DELETE_USER_GROUP, deleteAndPopulateUserGroups)
  yield takeLatest(C.LEAVE_USER_GROUP, leaveAndPopulateUserGroups)
  yield takeLatest(C.DELETE_USER_GROUP_MEMEBERSHIP, deleteUserGroupMembershipAndPopulateUserGroups)
  yield takeLatest(C.DELETE_USER_GROUP_INVITE, deleteUserGroupInviteAndPopulateUserGroups)
}
