// @flow
import styled from 'styled-components'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledDeleteButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  background: transparent;
`

const StyledDeleteIcon = styled.img`
  width: 1rem;
  height: auto;
`

export const StyledRemoveMenu = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0 !important;

  & > p {
    margin-bottom: 0;
    font-family: var(--bs-body-font-family);
  }
`

export const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
`

export const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-alert-error);
  cursor: pointer;
  font-size: 18px;
`

function DeleteMenu ({ onDelete, onStartDelete }: { onDelete: () => void, onStartDelete?: () => void }) {
  const [startDelete, setStartDelete] = useState(false)
  return (
    <>
      {startDelete ? (
        <StyledRemoveMenu>
          <p>Delete?</p>&nbsp;&nbsp;
          <StyledPrimaryFontAwesomeIcon data-cy="remove-item" icon={['far', 'circle-check'] as any} onClick={
            () => {
              setStartDelete(false)
              onDelete()
            }
          } />
          &nbsp;&nbsp;
          <StyledAlertFontAwesomeIcon data-cy="cancel-remove-item" icon={['far', 'circle-xmark'] as any} onClick={() => setStartDelete(false)} />
        </StyledRemoveMenu>
      ) : (
        <StyledDeleteButton onClick={() => {
          setStartDelete(true)
          onStartDelete?.()
        }} data-cy="start-remove-item">
          <StyledDeleteIcon alt="Remove" src="/imgs/remove-grey.svg" />
        </StyledDeleteButton>
      )}
    </>
  )
}

export default DeleteMenu
