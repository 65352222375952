// @flow

import styled from 'styled-components'
import { Button } from 'components/ReUsable'

export default styled(Button)`
  &, &:hover {
    ${props => {
      switch (props.color) {
        case 'primary':
        default:
          return `
            color: var(--color-primary);
            border-color: var(--color-primary);
            background: transparent;
          `
      }
    }}
  }

  background: transparent;
  border-radius: var(--dim-border-radius);
  white-space: nowrap;
  min-height: 3rem;
  min-width: 11rem;
  border-style: solid;
`
