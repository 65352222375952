// @flow

import styled from 'styled-components'

type Props = {
  margin?: string
  noWrap?: boolean
  justifyContent?: string
  alignItems?: string
}

export default styled.div`
  margin: ${(props: Props) => props.margin || '0'};
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  flex-direction: ${(props: Props) => (props.noWrap ? 'row' : 'column')};
  align-items: ${(props: Props) => (props.alignItems ? props.alignItems : 'stretch')};

  @media (min-width: 991.98px) {
    flex-direction: row;
  }
`
