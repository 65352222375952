// @flow

import { useEffect, useState } from 'react'

import { getDeviceCluster } from 'apis/devices'

export type ClusterTypes = 'none' | 'controller' | 'client'

export type SuccessContent = {
  content: string
}

export type StatusContent = {
  content :
  {
    state: string,
    isConnected: boolean
  }
}

export type DenyContent = {
  denyReason: string
}

export type DeviceClusterItem = {
  serialNumber: string,
  role: ClusterTypes,
  deviceType: string,
  name: SuccessContent | DenyContent,
  status: StatusContent | DenyContent
} | string | null

const fetchInterval = 60 * 1000

export function useCluster (serialNumber: string, role: string) : {cluster: any[], loading: boolean} {
  const [cluster, setCluster] = useState<DeviceClusterItem[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (role !== 'controller') {
      setLoading(false)
      return
    }
    //  Fetch clusters immediately on mount
    getDeviceClustersCall()
    // Fetch data every X seconds
    const interval = setInterval(() => getDeviceClustersCall(), fetchInterval)
    //  Cleanup.
    return () => clearInterval(interval)
  }, [])

  //  Device Clusters API call.
  const getDeviceClustersCall = async () => {
    try {
      const clusterResponse = await getDeviceCluster(serialNumber)

      if (clusterResponse && clusterResponse.devices) {
        setCluster(clusterResponse.devices)
        return
      } else if (clusterResponse.message) {
        throw new Error(clusterResponse.message)
      }
    } catch (e) {
      setCluster([])
      return false
    } finally {
      setLoading(false)
    }
  }
  return { cluster, loading }
}
