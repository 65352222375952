import * as C from 'appConstants'
import { BatteryType, BatteryTypeLimits } from 'types/battery'

export const getBattery = (payload: { serialNumber: string }) => {
  return { type: C.GET_BATTERY, payload }
}

export const populateBattery = (payload: { response: Partial<BatteryType> }) => ({ type: C.POPULATE_BATTERY, payload })

export const handleLoadingBatteryData = () => ({ type: C.LOADING_BATTERY_DATA })

export const handleRequestSaveBatteryConfig = (payload: { serialNumber: string, batteryFieldsValues: (string|number)[][] }) => ({ type: C.REQUEST_SAVE_BATTERY_CONFIG, payload })

export const populateSaveBatteryConfig = (payload: {response: Partial<BatteryType>}) => ({ type: C.POPULATE_SAVE_BATTERY_CONFIG, payload })

export const getBatteryTypes = (payload: any) => ({ type: C.GET_BATTERY_TYPES, payload })

export const getBatteryTypeLimits = (payload: { serialNumber: string }) => ({ type: C.GET_BATTERY_TYPE_LIMITS, payload })

export const populateBatteryTypes = (payload: { message: string, batteryTypes: { value: string }[] }) => ({ type: C.POPULATE_BATTERY_TYPES, payload })

export const populateBatteryTypeLimits = (payload: {message: string, response: BatteryTypeLimits}) => ({ type: C.POPULATE_BATTERY_TYPE_LIMITS, payload })

export const clearBatteryAlertPageDialog = () => ({ type: C.CLEAR_BATTERY_ALERT_PAGE_DIALOG })
