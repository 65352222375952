// @flow

import { useEffect } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'

import { Button } from 'components/ReUsable'

import AlertConfigMessages from 'components/ReUsable/Alert/alertConfigMessages'

import {
  createXlsxExport,
  checkXlsxExport,
  getXlsxExport,
  clearXLSXExport
} from 'actions'

const CenterContentDiv = styled.div`
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextSection = styled.p`
  color: #878682;
  font-family: open-sans-regular;
  font-size: 14px;
  font-weight: normal;
  padding: 20px 0 20px 0;
  text-align: center;
`

type Props = {
  serialNumber: string,
  xlsxReport: {
    exportId: string,
    fileLimitExceeded: false,
    status: string | null,
    expiresAt: null
  },
  pageDialog: {
    requestErrorMessage: string
  },
  createXlsxExport: (obj: {serialNumber: string}) => void,
  checkXlsxExport: (obj: {serialNumber: string, exportId: string}) => void,
  getXlsxExport: (obj: {serialNumber: string, exportId: string}) => void,
  clearXLSXExport: () => void
}

export function XlsxExportDownload (props: Props) {
  const {
    serialNumber,
    createXlsxExport,
    checkXlsxExport,
    getXlsxExport,
    clearXLSXExport,
    xlsxReport,
    pageDialog
  } = props

  useEffect(() => {
    createXlsxExport({ serialNumber })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const pool = setInterval(() => {
      if (xlsxReport.exportId && (xlsxReport.status === null || xlsxReport.status === 'processing')) {
        return checkXlsxExport({ serialNumber, exportId: xlsxReport.exportId })
      }
    }, 5000)

    return () => clearInterval(pool)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xlsxReport.exportId, xlsxReport.status === 'done'])

  useEffect(() => {
    return () => { clearXLSXExport() }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (xlsxReport.status === null || xlsxReport.status === 'processing') {
    return (
      <CenterContentDiv>
        <AlertConfigMessages pageDialog={ pageDialog }/>
        <TextSection>
          Generating XLSX Report
        </TextSection>
        <Spinner animation='border' variant='secondary' />
      </CenterContentDiv>
    )
  }

  return (
    <CenterContentDiv>
      <TextSection>
        XLSX report is ready
      </TextSection>
      {
        xlsxReport.fileLimitExceeded &&
        <TextSection>
          Unfortunately the report is not complete, data limit exceeded. <br />
          Data range was too large.
        </TextSection>
      }
      <Button onClick={ () => getXlsxExport({ serialNumber, exportId: xlsxReport.exportId }) }>
        XLSX Report
      </Button>
    </CenterContentDiv>
  )
}

const mapState = state => (
  {
    serialNumber: state.devices.current.serialNumber,
    xlsxReport: state.devices.xlsxReport
  }
)

const mapDispatch = {
  createXlsxExport,
  checkXlsxExport,
  getXlsxExport,
  clearXLSXExport
}

export default connect(
  mapState,
  mapDispatch
)(XlsxExportDownload)
