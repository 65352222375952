import { Row } from '@tanstack/react-table'

const HIDDEN_COLOR = 'rgba(101, 179, 48, .2)'
const HIDDEN_COLOR_ALTERNATING = 'rgba(101, 179, 48, .4)'

export const getRowStyles = (row: Row<any>): React.CSSProperties => {
  if (row.original.hidden) {
    return {
      backgroundColor: row.index % 2 === 0 ? HIDDEN_COLOR : HIDDEN_COLOR_ALTERNATING
    }
  } else {
    return {}
  }
}
