import { takeLatest, put, call } from 'redux-saga/effects'
import { CONFIRM_NOTIFICATION, GET_NOTIFICATIONS } from 'appConstants'
import { populateNotifications, saveConfirmNotification } from 'actions'
import { confirmNotificationAPI, getNotificationsAPI } from 'apis/notifications'
import { INotification } from 'reducers/notificationReducer'

export function * fetchNotificationsSaga () {
  try {
    const response = yield call(getNotificationsAPI)
    yield put(populateNotifications(response as INotification[]))
  } catch (error) {
    yield put(populateNotifications([]))
  }
}

export function * confirmNotificationsSaga ({ payload }: { type: string, payload: { uuid: string } }) {
  try {
    yield call(confirmNotificationAPI, payload.uuid)
    yield put(saveConfirmNotification({ uuid: payload.uuid }))
  } catch (error) {
    yield put(populateNotifications([]))
  }
}

export default function * notificationsSaga () {
  yield takeLatest(GET_NOTIFICATIONS, fetchNotificationsSaga)
  yield takeLatest(CONFIRM_NOTIFICATION, confirmNotificationsSaga)
}
