// @flow

import { Spinner } from 'react-bootstrap'

import {
  Container,
  PermissionCheck,
  HeaderBar
} from 'components/ReUsable'
import { useCurrentDevice } from './hooks/useCurrentDevice'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { useAddDelegation } from './hooks/useAddDelegation'
import DelegationFormUI from './components/DelegationFormUI'
import RoleInfoPanel from './components/RoleInfoPanel'

const AddDelegation = ({ match }: { match: any }) => {
  const serialNumber = match.params.serialNumber
  const { error, success, formik, lastInsertedName, backLink } =
  useAddDelegation(serialNumber)
  const { currentDevice, deviceLoadingOrErrorComponent } = useCurrentDevice(serialNumber)
  const permissions = currentDevice ? currentDevice.permissions : null

  if (deviceLoadingOrErrorComponent) {
    return deviceLoadingOrErrorComponent
  }

  if (!permissions) {
    return <Spinner animation="border" variant="secondary" />
  }

  return (
    <main role="main">
      <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DELEGATION_WRITE]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.DELEGATION_WRITE}
      >
        <Container data-cy="page-add-delegation">
          <HeaderBar
            headerText="Add user/group to EFOY"
            backlinkText="Back to Manage Access"
            backlinkUrl={`/devices/${serialNumber}/manageroles`}
            serialNumber={serialNumber}
            device={currentDevice}
          />
          <br/>
          <DelegationFormUI
            {...{
              error,
              success,
              formik,
              lastInsertedName,
              backLink
            }}
          />
          <RoleInfoPanel/>
        </Container>
      </PermissionCheck>
    </main>
  )
}

export default AddDelegation
