import DatePicker from 'react-datepicker'

type DatePickerProps = {
  id?: string
  name?: string
  selected?: Date
  selectsRange?: boolean
  startDate?: Date
  endDate?: Date | null | undefined
  dateFormat?: string
  onChange: (dates: any) => void
  customInput?: any
  monthsShown?: number
  maxDate?: Date | null | undefined
  showMonthDropdown?: boolean
  showYearDropdown?: boolean
  withPortal?: boolean
  popperPlacement?: any
  fixedHeight?: boolean
  peekNextMonth?: boolean
  excludeDateIntervals?: {
    start: Date
    end: Date
  }[]
  todayButton?: string
  children?: any
  customInputClassName?: string
  placeholderText?: string
  disabled?: boolean
  allowSameDay?: boolean
  timeInputLabel?: string
  minDate?: Date
  showTimeInput?: boolean
  className?: string
  showTimeSelect?: boolean
  timeIntervals?: number
  timeFormat?: string
  showPreviousMonths?: boolean
  minTime?: Date
  maxTime?: Date
  dataCy?: string
}

export const DatePickerController = (props: DatePickerProps) => {
  return (
    <div className="date-picker-container-col" data-cy={props.dataCy}>
      <DatePicker
        id={props.id}
        name={props.name}
        selected={props.selected}
        selectsRange={props.selectsRange || false}
        startDate={props.startDate}
        endDate={props.endDate || undefined}
        dateFormat={props.dateFormat || 'dd.MM.yyyy'}
        onChange={props.onChange}
        customInput={props.customInput || <input className={`form-control ${props.customInputClassName || ''}`} onChange={props.onChange} type="text" />}
        monthsShown={props.monthsShown || 1}
        maxDate={props.maxDate}
        showMonthDropdown={props.showMonthDropdown || false}
        showYearDropdown={props.showYearDropdown || false}
        withPortal={props.withPortal || false}
        popperPlacement={props.popperPlacement || 'bottom'}
        fixedHeight={props.fixedHeight || false}
        peekNextMonth={props.peekNextMonth || false}
        excludeDateIntervals={props.excludeDateIntervals}
        todayButton={props.todayButton}
        placeholderText={props.placeholderText || 'Select Date'}
        disabled={props.disabled || false}
        allowSameDay={props.allowSameDay || false}
        timeInputLabel={props.timeInputLabel}
        minDate={props.minDate}
        showTimeInput={props.showTimeInput}
        className={props.className}
        showTimeSelect={props.showTimeSelect || false}
        timeIntervals={props.timeIntervals || 30}
        timeFormat={props.timeFormat || 'p'}
        showPreviousMonths={props.showPreviousMonths || false}
        minTime={props.minTime}
        maxTime={props.maxTime}
      >
        {props.children}
      </DatePicker>
    </div>
  )
}

export default DatePickerController
