// @flow
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ALERT_TYPE, Alert } from 'components/ReUsable'
import { SectionTitle, NextButton, PreviousButton } from 'components/ReUsable/Dialog'
import { allPortsList, AnalogPort, TemperaturePort, IOPort, AnalogPortForm, TemperaturePortForm, Ports, IOPortForm } from './hooks/useMultisense'
import { portScreenName } from './micros'
import { AnalogOrTemperatureFormPage } from './micros/analogOrTemperatureFormPage'
import { IOFormPage } from './micros/ioFormPage'

const StyledFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 310px;
`

const StyledFormContent = styled.div`
  flex-grow: 1;
`

const StyledIconButton = styled.button`
  background: transparent;
  border: none;
  margin-bottom: 4.2rem;
`

const StyledAlert = styled(Alert)`
  margin-bottom: 0;
`

type ConfigurationFormType = {
  multiSensePorts: Ports | null
  selectedPortKey: string | null
  screenName: string
  updateMultiSenseCall: (portKey: string, values: AnalogPortForm | TemperaturePortForm | IOPortForm) => Promise<boolean>
  formError: string
}

export function ConfigurationForm ({ multiSensePorts, selectedPortKey, updateMultiSenseCall, formError }: ConfigurationFormType) {
  const [activePortKey, setActivePortKey] = useState<string>(selectedPortKey || allPortsList[0])
  const [activePortConfig, setActivePortConfig] = useState<AnalogPort | TemperaturePort | IOPort | null>(null)
  const [saveError, setSaveError] = useState<string>(formError)
  const [saveSuccess, setSaveSuccess] = useState<string>('')
  const { isPageDialogOpen, dialogId } = useSelector((state: any) => state.dialog)

  useEffect(() => {
    if (multiSensePorts && activePortKey) {
      setSaveSuccess('')
      setActivePortConfig(multiSensePorts[activePortKey])
    }
  }, [activePortKey])

  useEffect(() => {
    if (formError) {
      setSaveError(formError)
    }
  }, [formError])

  if (!isPageDialogOpen || dialogId !== 'edit-multisense-dialog') {
    return null
  }

  if (!multiSensePorts || !activePortKey) {
    return null
  }

  const currentIndex = allPortsList.indexOf(activePortKey)

  const handleUpdate = async (portKey: string, values: AnalogPortForm | TemperaturePortForm | IOPortForm) => {
    const updateCompleted = await updateMultiSenseCall(portKey, values)

    if (updateCompleted) {
      setSaveError('')
      setSaveSuccess('Data saved successfully.')
    }
  }

  const isIOPort = activePortKey.indexOf('io') === 0
  return (
    <>
      {saveError ? <StyledAlert message={saveError} type={ALERT_TYPE.danger} /> : saveSuccess ? <StyledAlert message={saveSuccess} type={ALERT_TYPE.success} /> : null}
      <SectionTitle data-cy="multisense-form-title">{portScreenName(activePortKey)}</SectionTitle>
      <StyledFormContainer>
        <StyledIconButton
          data-cy="form-button-prev"
          disabled={currentIndex === 0}
          onClick={() => {
            setActivePortKey(allPortsList[currentIndex - 1])
            setSaveError('')
          }}
        >
          <PreviousButton icon={['fas', 'chevron-left']} disabled={currentIndex === 0} />
        </StyledIconButton>
        <StyledFormContent>{isIOPort ? <IOFormPage portKey={activePortKey} portConfig={activePortConfig as IOPort | null} onSubmit={handleUpdate}/> : <AnalogOrTemperatureFormPage portKey={activePortKey} portConfig={activePortConfig} onSubmit={handleUpdate} />}</StyledFormContent>
        <StyledIconButton
          data-cy="form-button-next"
          disabled={currentIndex === Object.keys(multiSensePorts).length - 1}
          onClick={() => {
            setActivePortKey(allPortsList[currentIndex + 1])
            setSaveError('')
          }}
        >
          <NextButton disabled={currentIndex === Object.keys(multiSensePorts).length - 1} icon={['fas', 'chevron-right']} />
        </StyledIconButton>
      </StyledFormContainer>
    </>
  )
}
