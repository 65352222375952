// @flow

import styled from 'styled-components'

import { roundedNumber, isLicenseExpired } from 'utils/helpers'
import { DEVICE_DETAILS_PERMISSIONS_LIST, DENIED_MISSING_LICENSE, UNSUPPORTED_LICENSE_ERROR } from 'appConstants'
import { NEW_API_ACTIVE } from 'featureToggles'
import { StyledColumn, StyledFontAwesomeIcon, StyledImage } from './styleDefinitions'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'

type Props = {
  values: {
    default: number,
    permissions: any,
    inServiceMode: boolean
  }
}

const ColoredText = styled.span`
  color: ${(props: any) => {
    if (props.fuelLevel > 15) {
      return 'var(--color-alert-success);'
    } else if (props.fuelLevel < 16 && props.fuelLevel > 5) {
      return 'var(--color-alert-warning);'
    } else {
      return 'var(--color-alert-error);'
    }
  }}
`

export default function FuelLevelRowCell (props: Props) {
  const fuelLevel = props.values.default
  const { permissions, inServiceMode } = props.values

  if (isLicenseExpired(permissions) && !NEW_API_ACTIVE) {
    return null
  }

  if (isLicenseExpired(permissions) && NEW_API_ACTIVE) {
    return <StyledColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title="License expired" /></StyledColumn>
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && !NEW_API_ACTIVE) {
    return <td colSpan={4}>{UNSUPPORTED_LICENSE_ERROR}</td>
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && NEW_API_ACTIVE) {
    return <StyledColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title={UNSUPPORTED_LICENSE_ERROR} /></StyledColumn>
  }

  if (inServiceMode && !NEW_API_ACTIVE) { return null }

  if (inServiceMode && NEW_API_ACTIVE) {
    return <StyledColumn>
      <StyledFontAwesomeIcon icon={faScrewdriverWrench as any} title="In Service"/>
    </StyledColumn>
  }

  return (
    <td className={ 'text-centered' }>
      <ColoredText {...{ fuelLevel }}>{ fuelLevel && `${roundedNumber(fuelLevel)}%` }</ColoredText>
    </td>
  )
}
