// @flow

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

// TODO: move to a folder of only types
import { Button, ButtonBar } from 'components/ReUsable'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSelectionMode } from 'actions'

const StyledButton = styled(Button)`
  min-height: 30px;
`

const StyledIcons = styled.img`
  width: 16px;
  height: 16px;
`

export default function DelegationButtons () {
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices.map((device: any) => device.serialNumber))
  const selectionActive = useSelector((state: any) => state.devices.selectionActive)

  return (
    <ButtonBar relative={true} desktopOnly={true}>
      <StyledButton
        data-cy="button-batch-selection"
        className="no-text-transform"
        onClick={() => {
          dispatch(toggleSelectionMode({ selectionActive: !selectionActive }))
        }}
      >
        <StyledIcons alt="Select Efoys" src={'/imgs/list-radio.svg'} />&nbsp;&nbsp;
        <span>SELECT EFOYs</span>
      </StyledButton>
      {selectionActive && (
        <>
          <StyledButton
            data-cy="button-batch-add"
            className="no-text-transform"
            onClick={() => {
              history.push('/devices/delegations/add', { from: 'home' })
            }}
            disabled={selectedDevices.length === 0}
          >
            ADD USER/GROUP TO EFOYs
          </StyledButton>
          <StyledButton
            data-cy="button-batch-remove"
            className="no-text-transform"
            onClick={() => {
              history.push('/devices/delegations/remove', { from: 'home' })
            }}
            disabled={selectedDevices.length === 0}
          >
            DELETE USER/GROUP FROM EFOYs
          </StyledButton>
          <StyledButton
            data-cy="button-batch-license"
            className="no-text-transform"
            onClick={() => {
              history.push('devices/licenses/', { from: 'home' })
            }}
            disabled={selectedDevices.length === 0}
          >
            APPLY LICENSES
          </StyledButton>
        </>
      )}
    </ButtonBar>
  )
}
