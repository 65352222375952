import { useEffect, useState } from 'react'
import { useFormik, FormikErrors } from 'formik'

import { useNotificationContext } from './context/NotificationContext'
import { StyledSubTitle } from 'components/ReUsable/HeaderBar'
import {
  Row,
  Input,
  Button,
  DatePicker
} from 'components/ReUsable'
import Label from 'components/ReUsable/Label'
import styled from 'styled-components'
import { NotificationFormType } from 'apis/notifications'
import { NotificationGridItem, StyledNoticeGrid } from './NotificationGrid'
import { AdminNotificationType } from './hooks/useAdminNotifications'
import { isValidUrl } from 'utils/helpers'
import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import { SimpleButton } from 'components/ReUsable/SimpleButton'
import HeaderBackButton from 'components/ReUsable/HeaderBackButton'

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  min-width: 100%;

  @media (min-width: 991.98px) {
    & > div:first-child {
      flex: 1;
    }

    & > div:nth-child(2) {
      flex: 4;
    }
  }
`

const StyledLabelContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
`

const StyledInputContainer = styled.div`
  flex: 3;
`

const StyledButtonRow = styled(Row)`
  margin-top: 16px;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const StyledTextArea = styled.textarea`
  font-family: open-sans-regular;
  font-size: 14px;
  color: #000;
`

function customValidation (values: NotificationFormType) {
  const errors: Partial<NotificationFormType> = {}
  if (!values.title.trim()) {
    errors.title = 'Required'
  }
  if (!values.body.trim()) {
    errors.body = 'Required'
  }
  if (!values.button.trim()) {
    errors.button = 'Required'
  }
  if (!values.publishDate.trim()) {
    errors.publishDate = 'Required'
  }
  //  Validate inserted url
  if (values.link?.url?.trim() && !isValidUrl(values.link?.url?.trim())) {
    errors.link = errors.link
      ? { ...errors.link, url: 'Please insert a valid URL' }
      : { label: '', url: 'Please insert a valid URL' }
  }
  //  If there is link label inserted, make also url required.
  if (values.link?.label?.trim() && !values.link?.url?.trim()) {
    errors.link = errors.link
      ? { ...errors.link, url: 'Please insert an URL' }
      : { label: '', url: 'Please insert an URL' }
  }
  //  If there is link url inserted, make also label required.
  if (!values.link?.label?.trim() && values.link?.url?.trim()) {
    errors.link = errors.link
      ? {
          ...errors.link,
          label: 'Please insert both a link title and a valid URL'
        }
      : { url: '', label: 'Please insert a link title' }
  }

  return errors
}

const now = new Date()
const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1))
const oneHourLater = new Date(new Date().getTime() + 60 * 60 * 1000)

export default function NotificationForm () {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const {
    getNotification,
    selectedNotification,
    setMode,
    mode,
    editNotification,
    addNotification
  } = useNotificationContext()
  const [notification, setNotification] = useState<NotificationFormType | null>(
    null
  )
  const [initialValues, setInitialValues] = useState<NotificationFormType>({
    id: selectedNotification || '',
    title: '',
    body: '',
    button: '',
    link: {
      label: '',
      url: ''
    },
    publishDate: ''
  })
  const formik = useFormik<NotificationFormType>({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validate: (values: NotificationFormType) => {
      return customValidation(values)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: (values: NotificationFormType) => {
      let formValues = { ...values }
      //  Remove link from object if not inserted.
      if (!formValues.link?.label.trim() && !formValues.link?.url.trim()) {
        formValues = { ...formValues, link: null }
      }
      selectedNotification
        ? editNotification(selectedNotification, formValues)
        : addNotification(formValues)
    }
  })

  const loadNotification = async () => {
    const data = await getNotification(selectedNotification as string)
    if (data) {
      setNotification(data)
    }
  }

  useEffect(() => {
    if (selectedNotification) {
      loadNotification()
    }
  }, [])

  useEffect(() => {
    if (notification) {
      setInitialValues(notification as NotificationFormType)
    }
  }, [notification])

  const formikErrors:FormikErrors<NotificationFormType> = formik.errors
  return (
    <>
      <form id='notice-form' onSubmit={formik.handleSubmit}>
        <HeaderBackButton
          backlinkText="Back to notice list"
          handleButtonClick={() => setMode('LIST')}
        />
        <br/>
        <StyledSubTitle>
          {mode === 'ADD'
            ? 'Add a new notice'
            : `Update notice [${selectedNotification}]`}
        </StyledSubTitle>
        <NotificationFormRow
          errorText={formikErrors.title}
          touched={formik.touched.title}
          onChange={formik.handleChange}
          value={formik.values.title}
          id='title'
          label='Title'
        />
        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='body'>Text (HTML allowed)*</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <StyledTextArea
              rows={8}
              name='body'
              id='notice-form-text'
              className={`form-control ${
                formikErrors.body && formik.touched.body ? 'input-errored' : ''
              }`}
              onChange={formik.handleChange}
              value={formik.values.body}
            />
            {formikErrors.body && formik.touched.body ? (
              <div className='text-danger'>{formikErrors.body}</div>
            ) : null}
          </StyledInputContainer>
        </StyledRow>
        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='link.label'>Link title</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <Input
              id='notice-form-link-title'
              className={`form-control ${
                // eslint-disable-next-line
                (formikErrors.link as any)?.label &&
                (formik.touched.link as any)?.label
                  ? 'input-errored'
                  : ''
              }`}
              name='link.label'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.link?.label}
            />
            {(formikErrors.link as any)?.label &&
            (formik.touched.link as any)?.label ? (
              <div className='text-danger'>
                {(formikErrors.link as any)?.label}
              </div>
            ) : null}
          </StyledInputContainer>
        </StyledRow>
        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='link.url'>Link URL</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <Input
              id='notice-form-link-url'
              className={`form-control ${
                // eslint-disable-next-line
                (formikErrors.link as any)?.url &&
                (formik.touched.link as any)?.url
                  ? 'input-errored'
                  : ''
              }`}
              name='link.url'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.link?.url}
            />
            {(formikErrors.link as any)?.url &&
            (formik.touched.link as any)?.url ? (
              <div className='text-danger'>
                {(formikErrors.link as any)?.url}
              </div>
            ) : null}
          </StyledInputContainer>
        </StyledRow>
        <NotificationFormRow
          errorText={formikErrors.button}
          touched={formik.touched.button}
          onChange={formik.handleChange}
          value={formik.values.button}
          id='button'
          label='Button title'
        />
        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='publishDate'>Publish at*</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <DatePicker
              name='publishDate'
              selected={
                formik.values.publishDate
                  ? new Date(formik.values.publishDate)
                  : undefined
              }
              onChange={(date: Date | null) =>
                formik.setFieldValue(
                  'publishDate',
                  date ? date.toISOString() : ''
                )
              }
              timeInputLabel='Time:'
              maxDate={oneYearLater}
              minDate={oneHourLater}
              dateFormat='dd.MM.yyyy HH:mm'
              showTimeSelect={true}
              timeIntervals={60}
              timeFormat='HH:mm'
              className='form-control'
              id='notice-publish-date'
              placeholderText='Publish at'
              data-cy='token-expires-at'
              withPortal={!matches}
            />
            {formikErrors.publishDate && formik.touched.publishDate ? (
              <div className='text-danger'>{formikErrors.publishDate}</div>
            ) : null}
          </StyledInputContainer>
        </StyledRow>
        <Row>* Required</Row>
        <StyledButtonRow>
          <SimpleButton
            id="notice-cancel-button"
            type='button'
            onClick={() => setMode('LIST')}
          >
            Cancel
          </SimpleButton>
          <Button id="notice-submit-button" type="submit">{mode === 'ADD' ? 'Add' : 'Update'}</Button>
        </StyledButtonRow>
      </form>
      <br/>
      <StyledSubTitle>Preview</StyledSubTitle>
      <StyledNoticeGrid data-cy="notice-preview-grid">
        <NotificationGridItem
          notification={formik.values as AdminNotificationType}
        />
      </StyledNoticeGrid>
    </>
  )
}

function NotificationFormRow ({
  id,
  label,
  value,
  errorText,
  touched,
  onChange
}: {
  id: string
  label: string
  value: string
  errorText: string | undefined
  touched: boolean | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
  return (
    <StyledRow>
      <StyledLabelContainer>
        <Label htmlFor={id}>{label}*</Label>
      </StyledLabelContainer>
      <StyledInputContainer>
        <Input
          id={`notice-form-${id}`}
          className={`form-control ${
            errorText && touched ? 'input-errored' : ''
          }`}
          name={id}
          type='text'
          onChange={onChange}
          value={value}
        />
        {errorText && touched ? (
          <div className='text-danger'>{errorText}</div>
        ) : null}
      </StyledInputContainer>
    </StyledRow>
  )
}
