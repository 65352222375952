import { Container, PageTitle, Input, Button, Gap, PageNotReachable } from 'components/ReUsable'
import { useState } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { getDeviceLicense } from 'apis/devices'
import { productImage } from 'utils/helpers'
import moment from 'moment'
import { SOMETHING_WENT_WRONG } from 'appConstants'
import GenerateLicense from './generateLicense'
import useCheckAuth0ScopePermissions from '../hooks/useCheckAuth0ScopePermissions'
import { LICENSE_WRITE } from '../constants'

type Device = {
    deviceType: string;
    name: string;
    serialNumber: string;
}

type LicenseDetails = {
    device: Device;
    duration: string;
    end: string;
    key: string;
    start: string;
    state: string;
    tier: string;
}

const ContentDiv = styled.div`
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color: #ffffff;
    width: 100%;
    border-radius: 3px 3px 0px 0px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
`

const StyledLicenseForm = styled.div`
    display: block;
    @media (min-width: 992px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & > input {
        height: 42px;
    }
`

const StyledDeviceLicense = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    margin-top: 2rem;
`

const StyledDeviceImage = styled.img`
    width: 85px;
    height: 56px;
`

const StyledDeviceLicenseDetails = styled.div`
    & > div {
        & > span {
        font-size: 16px;
        color: gray;
        }

        & > span + span {
        color: black;
        }
        & > a {
        font-size: 16px;
        }
    }
`

const StyledLicenseProperties = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 2rem;
`

const StyledLicensePropertyCard = styled.div`
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    justify-content: space-around;
    & span {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        :nth-child(1) {
        font-size: 14px;
        color: gray;
        }
        :nth-child(2) {
        font-size: 18px;
        color: black;
        }
    }
`

const StyledErrorSpan = styled.span`
    color: red;
`

export const License = () => {
  const { allow: allowWrite } = useCheckAuth0ScopePermissions({ rights: [LICENSE_WRITE] })
  const [licenseKey, setLicenseKey] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [licenseDetails, setLicenseDetails] = useState<LicenseDetails>()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKey(e.target.value.trim())
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    if (licenseKey) {
      setLicenseDetails(undefined)
      setShowSpinner(true)
      setShowError(false)
      setErrorMessage('')
      const licenseDetails = await getDeviceLicense(licenseKey)
      if (licenseDetails?.status >= 400) {
        setShowError(true)
        setShowSpinner(false)
        setErrorMessage(
          `${SOMETHING_WENT_WRONG} Reason: ${licenseDetails?.detail}.`
        )
        return
      }
      setLicenseDetails({
        ...licenseDetails,
        start: licenseDetails.start
          ? moment(licenseDetails.start).format('DD MMMM yyyy HH:mm')
          : '-',
        end: licenseDetails.end
          ? moment(licenseDetails.end).format('DD MMMM yyyy HH:mm')
          : '-'
      })
      setShowSpinner(false)
      return
    }
    setShowSpinner(false)
    setShowError(true)
    setErrorMessage('License Key cannot be empty.')
  }

  //  Page only visible for users with user read rights.
  if (!allowWrite) return <PageNotReachable/>

  return (
    <Container>
      <ContentDiv>
        <PageTitle data-cy="page-header-license">License</PageTitle>
        <StyledLicenseForm>
          <Input
            value={licenseKey}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="input"
            className="form-control"
            placeholder="Enter License Key"
            data-cy="input-add-license"
          />
          <Gap />
          <Button
            onClick={() => handleSubmit()}
            type="submit"
            name="submit"
            data-cy="button-add-license"
          >
            Submit
          </Button>
        </StyledLicenseForm>
        {showError ? <StyledErrorSpan data-cy="add-license-error">{errorMessage}</StyledErrorSpan> : null}
        {showSpinner ? (
          <Spinner animation="border" variant="secondary" />
        ) : null}
        {licenseDetails && licenseDetails.device ? (
          <StyledDeviceLicense>
            <StyledDeviceImage data-cy="device-img"
              src={productImage(licenseDetails.device.deviceType, '_front')}
            />
            <StyledDeviceLicenseDetails data-cy="device-details">
              <div>
                <span>Device Name:</span>{' '}
                <span>{licenseDetails.device.name}</span>
              </div>
              <div>
                <span>Serial Number:</span>{' '}
                <a
                  href={`/devices/${licenseDetails.device.serialNumber}`}
                  target="_blank"
                  rel="noreferrer"
                  data-cy="device-detail-link"
                >
                  {licenseDetails.device.serialNumber}
                </a>
              </div>
            </StyledDeviceLicenseDetails>
          </StyledDeviceLicense>
        ) : null}

        {licenseDetails ? (
          <StyledLicenseProperties data-cy="device-props-cards">
            {Object.keys(licenseDetails).map((property: string, key: number) =>
              property === 'device' ? null : (
                <StyledLicensePropertyCard key={key}>
                  <span>{property}</span>
                  <span>{licenseDetails[property]}</span>
                </StyledLicensePropertyCard>
              )
            )}
          </StyledLicenseProperties>
        ) : null}
        <GenerateLicense />
      </ContentDiv>
    </Container>
  )
}

export default License
