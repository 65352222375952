// @flow

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import * as C from 'appConstants'
import {
  togglePageDialog,
  clearDevicesPageDialog,
  setDeviceNameSubmit,
  clearClaimingSubmit
} from 'actions'

import {
  Button,
  Input,
  Form
} from 'components/ReUsable'

import { DEVICE_NAME_SCREEN_STEP } from 'appConstants'

type StepFiveStateProps = {
  devices: {
    pageDialog: {
      title: string,
      currentStep: number,
      currentUserStep: number,
      lastStep: number,
      requestErrorMessage: string
    },
    startClaimingForm: {
      serialNumber: string,
      defaultName: string
    },
    setDeviceNameForm: {
      name: string
    },
    efoyAddedSuccess: boolean
  }
}

const selectDeviceState = (state: any): StepFiveStateProps => ({
  devices: state.devices
})

export function AddEfoyStepFive () {
  const dispatch = useDispatch()
  const _togglePageDialog = () => dispatch(togglePageDialog())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())
  const _clearClaimingSubmit = () => dispatch(clearClaimingSubmit())
  const _setDeviceNameSubmit = (payload: {serialNumber: string, name: string}) => dispatch(setDeviceNameSubmit(payload))
  const { devices } = useSelector(selectDeviceState)
  const {
    startClaimingForm, setDeviceNameForm, efoyAddedSuccess, pageDialog
  } = devices

  const {
    currentUserStep, currentStep, lastStep,
    requestErrorMessage
  } = pageDialog

  const formik = useFormik(
    {
      initialValues: setDeviceNameForm,
      onSubmit: (values) => {
        const updatedValues = Object.assign(startClaimingForm, values)
        _setDeviceNameSubmit(updatedValues)
      }
    }
  )

  useEffect(() => {
    //  Efoy successfully added on current step.
    if (efoyAddedSuccess && currentStep === C.DEVICE_NAME_SCREEN_STEP) {
      _togglePageDialog()
      _clearDevicesPageDialog()
      _clearClaimingSubmit()
    }
  }, [efoyAddedSuccess])

  useEffect(() => {
    formik.setFieldValue('name', startClaimingForm.defaultName)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startClaimingForm.defaultName])

  return currentStep === DEVICE_NAME_SCREEN_STEP ? (
    <>
      <p className='add-efoy-message'>
        The fuel cell has been successfully paired and is now added to your overview.
        Please choose a name now or later and confirm.
      </p>
      <Form id='add-efoy-confirm-form' handleSubmit={ formik.handleSubmit }>
        <Input
          type='text' id='name' name='name' placeholder='EFOY 85 - 12345'
          className={ `form-control confirm-efoy ${requestErrorMessage ? 'input-errored' : ''}` }
          onChange={ formik.handleChange } value={ formik.values.name }
        />
        <p className='field-error'>{ requestErrorMessage }</p>
        {/* this section can be a isolated component */}
        <div className='d-flex justify-content-between buttons-section'>
          <Button
            type='submit' id='button-confirm-efoy'
            disabled={ !formik.values.name }
          >
            Save
          </Button>
        </div>
        {/* this section can be a isolated component */}
        <p className='modal-step'>Step { currentUserStep } of { lastStep }</p>
      </Form>
    </>
  ) : null
}

export default AddEfoyStepFive
