// @flow

import { connect } from 'react-redux'

import InfoBar from '../InfoBar'
import NavBar from '../NavBar'
import { useOnlineStatus } from '../AvailabilityCheck/useOnlineStatus'
import ApplicationStatusBar from '../ApplicationStatusBar'

type Props = {
  dialog: {
    isDialogOpen: boolean
  }
}

export function Header (props: Props) {
  const { isDialogOpen } = props.dialog
  const { unavailable } = useOnlineStatus()

  return (
    <header id='efoy-header' className={ `bg-light ${isDialogOpen ? 'fixed' : ''}` }>
      <ApplicationStatusBar />
      <InfoBar hideLogout={unavailable} />
      <NavBar hideContent={unavailable} />
    </header>
  )
}

const mapState = state => (
  {
    dialog: state.dialog
  }
)

const mapDispatch = {}

export default connect(
  mapState,
  mapDispatch
)(Header)
