// @flow

import { useState } from 'react'

import { getDeviceDelegations } from 'apis/devices'

import { useCurrentDevice } from './useCurrentDevice'

export const useManageDelegations = (serialNumber: string) => {
  const { currentDevice, deviceLoadingOrErrorComponent } = useCurrentDevice(serialNumber)
  const [delegations, setDelegations] = useState<any>([])
  const [loading, setLoading] = useState(true)
  //  Device Delegation API call.
  const getDeviceDelegationsCall = async () => {
    try {
      const delegationsArr = await getDeviceDelegations(serialNumber)
      if (delegationsArr.message) {
        throw new Error(delegationsArr.message)
      }
      setDelegations(delegationsArr)
      return true
    } catch (e) {
      setDelegations([])
      return false
    } finally {
      setLoading(false)
    }
  }
  return { getDeviceDelegationsCall, delegations, currentDevice, loading, deviceLoadingOrErrorComponent }
}
