// @flow

import styled from 'styled-components'

import {
  FuelCartridge
} from 'components/ReUsable'

const Overview = styled.div`
  margin: 0 -0.2rem;
  max-width: 11rem;
`

const SmallCartridge = styled.div`
  display: inline-block;
  padding: 0.2rem;
  position: relative;
`

type Props = {
  fuelCellCartridges: {
    remainingTotalFuelPercent: string,
    connectedCartridges: Array<any>
  }
}

export default function FuelCartridgesOverviewSection (props: Props) {
  const {
    fuelCellCartridges
  } = props

  return (
    <Overview>
      {fuelCellCartridges.connectedCartridges.map((item: any, index: number) => {
        return <SmallCartridge key={index}>
          <FuelCartridge fuelCartridge={item}></FuelCartridge>
        </SmallCartridge>
      })}

    </Overview>
  )
}
