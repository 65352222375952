import { ChangeEvent, FC, InputHTMLAttributes } from 'react'
interface NumberInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string
  onChange: (value: string) => void
  decimalLimit?: number; // Decimal limit is 2 by default. Provide a number if change needed. decimalLimit 0 allows only integers.
}

// Function to escape special characters
const escapeSpecialChars = (str: string) => {
  return str.replace(/[&<>"'=`/]/g, function (s) {
    const entityMap: Record<string, string> = {
      '&': '&amp',
      '<': '&lt',
      '>': '&gt',
      '"': '&quot',
      "'": '&#39',
      '/': '&#x2F',
      '`': '&#x60',
      '=': '&#x3D'
    }
    return entityMap[s]
  })
}

// Function to sanitize number input
const sanitizeNumber = (str: string) => {
  return str.replace(/[^\d.-]/g, '')
}

const NumberInput: FC<NumberInputProps> = ({ value, onChange, decimalLimit, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value

     // Escape special characters
     inputValue = escapeSpecialChars(inputValue)

     // Sanitize input
     inputValue = sanitizeNumber(inputValue)

    // Allow empty string
    if (inputValue === '') {
      onChange(inputValue)
      return
    }

    let isValid: boolean
    if (decimalLimit === 0) {
      // Only allow integers, no dots
      isValid = /^-?\d+$/.test(inputValue) || /^-?$/.test(inputValue)
    } else if (typeof decimalLimit === 'number') {
      // Allow up to 'decimalLimit' decimal places if provided
      isValid = new RegExp(`^-?\\d+(\\.\\d{0,${decimalLimit}})?$`).test(inputValue) || /^-?\d*\.$/.test(inputValue) || /^-?$/.test(inputValue)
    } else {
      // If decimalLimit is not provided, allow up to 2 decimal places
      isValid = /^-?\d+(\.\d{0,2})?$/.test(inputValue) || /^-?\d*\.$/.test(inputValue) || /^-?$/.test(inputValue)
    }

    if (isValid) {
      onChange(inputValue)
    }
  }

  return (
    <input
      type="text"
      value={value || ''}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default NumberInput
