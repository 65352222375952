import {
  POPULATE_NOTIFICATIONS,
  SAVE_CONFIRM_NOTIFICATION
} from 'appConstants'

export interface INotification
  {
    id: string,
    title: string,
    body: string,
    button: string,
    link: {
      label: string,
      url: string
    } | null
  }

const initialState = { list: [] }

function populateNotificationsData (state: any, payload: INotification[]) {
  return {
    ...state,
    list: [...payload]
  }
}

function confirmNotification (state: any, payload: {uuid: string}) {
  const { uuid } = payload
  const notificationList = state.list
  return {
    ...state,
    list: notificationList.filter((n: INotification) => n.id !== uuid)
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case POPULATE_NOTIFICATIONS:
      return populateNotificationsData(state, payload)
    case SAVE_CONFIRM_NOTIFICATION:
      return confirmNotification(state, payload)
    default:
      return state
  }
}
