// @flow

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { Button, Container, HeaderBar, SelectedEfoy, Card, ButtonBar, Alert, ALERT_TYPE } from 'components/ReUsable'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import { submitLicenseKey } from 'apis/devices'
import { checkLicenses, newError, checkDuplicates } from './utils'

type LicenseKeyGroup = {
  name?: string
  serial: string
  license: string
  tier?: string
  status: string
  error?: string
}

const HEADER = 'Apply Licenses'
const BACK_LINK_TEXT = 'Back to EFOY fuel cells'
const TITLE = 'Licenses Keys'
const AFTER_APPLY_TITLE = 'Results for apply license keys'
const PLACE_HOLDER = `P100-1234-1234-1234
P100-1234-1234-1234
P100-1234-1234-1234
P100-1234-1234-1234
P100-1234-1234-1234
P100-1234-1234-1234
P100-1234-1234-1234`

const CardTitle = styled.div`
  color: #4b4b4b;
  font-size: 18px;
  line-height: 22px;
`

const BatchTextArea = styled.textarea<{ errors: boolean }>`
  font-family: open-sans-regular;
  width: 100%;
  height: 20rem;
  margin: ${(props) => (props.errors ? '17px 0 17px 0' : '17px 0 60px 0')};
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  resize: none;
`

export const StyledCard = styled(Card)`
  position: relative;
  border: 1px solid #cfcfcf;
  border-radius: 0.4rem;
  flex-direction: column;
  flex: 0.5 1 1px;
  media (max-width: 991.97px) {
    flex: 0.5 1 1px;
  }
`

const HintDisplay = styled.div`
  font-family: open-sans-regular;
  font-size: 1rem;
  margin-left: 1rem;
  color: var(--color-text);
  margin-top: 10px;
`

const StyledBackButton = styled.button`
  margin-top: 1rem;
  background: none;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border: none;
  text-decoration: underline;
  @media (max-width: 991.97px) {
    margin-top: 0;
  }
  padding-right: 0px;

  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
`

export const StyledCancelButton = styled.button`
  background: none;
  position: absolute;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border: none;
  text-decoration: underline;
  padding: 0;
  @media (max-width: 991.97px) {
    top: 1rem;
    right: 1rem;
  }

  @media (min-width: 991.98px) {
    bottom: 1.5rem;
    left: 1rem;
  }

  &:hover,
  &:focus,
  &:active {
    filter: brightness(0.8);
  }
`
const StyledAlert = styled(Alert)`
  margin: 0rem 0 4rem 0;
  media (max-width: 991.97px) {
    margin: 1rem 0 1rem 0;
  }
`

const DivSuccessfulResults = styled.div<{ status: boolean }>`
  font-weight: normal;
  font-family: open-sans-regular;
  span {
    font-weight: bold;
    color: ${(props) => (props.status ? '#4B4B4B' : '#FA5862')};
  }
  margin: 20px 0px;
`

const ResultArea = styled.div<{ group: LicenseKeyGroup }>`
  margin: 5px 0px;
  font-family: open-sans-regular;
  font-size: 1rem;
  .pending {
    display: ${(props) => (props.group.status === 'pending' ? 'inline-block' : 'none')};
  }
  .success {
    display: ${(props) => (props.group.status === 'success' ? 'inline-block' : 'none')};
  }
  .failed {
    display: ${(props) => (props.group.status === 'failed' ? 'inline-block' : 'none')};
  }
`

const RmainingKeys = styled.div`
  margin: 20px 0px;
  font-family: open-sans-regular;
  font-size: 1rem;
  font-weight: normal;
  color: #4b4b4b;
  letter-spacing: 0.4px;
`

const NotSuccessfullDevices = styled.div`
  margin: 20px 0px;
  font-family: open-sans-regular;
  font-size: 1rem;
  font-weight: normal;
  color: #4b4b4b;
  letter-spacing: 0.4px;
`

const ManageLicenses = () => {
  const history = useHistory()
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices)
  const selectionActive = useSelector((state: any) => state.devices.selectionActive)
  const [applyActive, setApplyActive] = useState<boolean>(false)
  const [devicesKeyGroup, setDevicesKeyGroup] = useState<LicenseKeyGroup[]>([])
  const [remainingKeys, setRemainingKeys] = useState<string[]>([])
  const [remainingDevices, setRemainingDevices] = useState<string[]>([])

  const submitLicenseForDevice = (device: any) => {
    return submitLicenseKey(device.serial, device.license)
      .then((res) => {
        // if res has tier, then it is a successful application
        if (Object.prototype.hasOwnProperty.call(res, 'tier')) {
          setDevicesKeyGroup((prev) => {
            const indexOfDevice = prev.findIndex((d) => d.serial === device.serial)
            const restGroups = prev.filter((d) => d.serial !== device.serial)
            const newGroup = { serial: device.serial, name: device.name, license: device.license, tier: res.tier, status: 'success' }
            restGroups.splice(indexOfDevice, 0, newGroup)
            return restGroups
          })
        } else {
          setRemainingDevices((prev) => [...prev, device.serial])
          setRemainingKeys((prev) => [...prev, device.license])
          setDevicesKeyGroup((prev) => {
            const indexOfDevice = prev.findIndex((d) => d.serial === device.serial)
            const restGroups = prev.filter((d) => d.serial !== device.serial)
            const newGroup = { serial: device.serial, name: device.name, license: device.license, error: newError(res.title), status: 'failed' }
            // return at the same position
            restGroups.splice(indexOfDevice, 0, newGroup)
            return restGroups
          })
        }
      })
      .catch(() => {
        setRemainingDevices((prev) => [...prev, device.serial])
        setRemainingKeys((prev) => [...prev, device.license])
      })
  }

  const Formik = useFormik({
    initialValues: {
      licenses: ''
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.licenses) {
        errors.licenses = 'The License Keys are required to proceed.'
      }
      if (!checkLicenses(values.licenses)) {
        errors.licenses = 'Invalid license format detected. Please check the license keys.'
      }
      if (
        checkDuplicates(
          values.licenses
            .split(/\n|,|;/)
            .map((license) => license.trim())
            .filter((license) => license !== '')
        )
      ) {
        errors.licenses = 'Duplicate license keys detected. Please check the license keys.'
      }

      return errors
    },
    onSubmit: (values) => {
      setApplyActive(true)
      setRemainingDevices([])
      setRemainingKeys([])
      // trim licenses and seperate them
      const licenses = values.licenses
        .split(/\n|,|;/)
        .map((license) => license.trim())
        .filter((license) => license !== '')
        .map((license) => license.replace(/,|;| /g, ''))

      const devicesKeyGroup = selectedDevices.map((device, index) => {
        return { serial: device.serialNumber, name: device.name, license: licenses[index], status: 'pending' }
      })

      // push extra licenses to remaining keys
      if (licenses.length > selectedDevices.length) {
        for (let i = selectedDevices.length; i < licenses.length; i++) {
          setRemainingKeys((prev) => [...prev, licenses[i]])
        }
      }
      setDevicesKeyGroup(devicesKeyGroup)
      // Start submitting the licenses
      const promises = devicesKeyGroup.map((device, index) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            submitLicenseForDevice(device).then(() => resolve(null))
          }, (index + 1) * 100)
        })
      })

      Promise.all(promises)
    }
  })

  useEffect(() => {
    if (selectedDevices.length === 0) {
      history.push('/')
    }
  }, [history, selectedDevices.length, devicesKeyGroup.length])

  return (
    <main role="main">
      <Container data-cy="page-batch-delegation">
        <HeaderBar headerText={HEADER} backlinkText={BACK_LINK_TEXT} backlinkUrl={'/'} resetReferrer={false} />
        <SelectedEfoy count={selectedDevices.length} margin="0 0 10px 0" />
        {selectionActive && (
          <StyledCard>
            {!applyActive ? (
              <form onSubmit={Formik.handleSubmit}>
                <CardTitle>{TITLE}</CardTitle>
                <BatchTextArea
                  errors={!!Formik?.errors?.licenses}
                  placeholder={PLACE_HOLDER}
                  id="licenses"
                  data-cy="textarea-batch-licenses"
                  onChange={Formik.handleChange}
                  onBlur={Formik.handleBlur}
                  value={Formik.values.licenses}
                />
                {Formik.errors?.licenses && <StyledAlert message={Formik.errors.licenses} type={ALERT_TYPE.danger} />}
                <ButtonBar>
                  <Button type="submit" id="button-apply" data-cy="button-apply" disabled={!!Formik.errors.licenses || !Formik.values.licenses.length}>
                    Apply
                  </Button>
                </ButtonBar>
                <StyledCancelButton
                  id="button-cancel"
                  onClick={() =>
                    history.push({
                      pathname: '/',
                      state: { from: history?.location?.pathname }
                    })
                  }
                >
                  Cancel
                </StyledCancelButton>
              </form>
            ) : (
              <>
                <CardTitle>{AFTER_APPLY_TITLE}</CardTitle>
                <DivSuccessfulResults status={devicesKeyGroup.filter((ele) => ele.status === 'success').length > 0}>
                  <span>{devicesKeyGroup.filter((ele) => ele.status === 'success').length}</span> of {selectedDevices.length} Successful
                </DivSuccessfulResults>
                {devicesKeyGroup &&
                  devicesKeyGroup.map((group) => (
                    <ResultArea key={group.serial} group={group}>
                      <div className="pending">
                        {group.serial} ({group.name}) trying to apply {group.license || <span style={{ color: '#FA5862' }}> - no License added -</span>}{' '}
                        <Spinner animation="border" variant="secondary" size="sm" />
                      </div>
                      <div className="success">
                        {group.serial} ({group.name}) applied the key: {group.license} - <span style={{ color: '#65B32F' }}>success</span>
                      </div>
                      <div className="failed">
                        {group.serial} ({group.name}) <span style={{ color: '#FA5862' }}>failed</span> to apply key - {group.license || <span style={{ color: '#FA5862' }}>no License applied</span>} -
                        ({group.error})
                      </div>
                    </ResultArea>
                  ))}
                <RmainingKeys>
                  {remainingKeys?.length > 0 && (
                    <>
                      <b>Remaining keys:</b>
                      <br />
                      <div>
                        {remainingKeys.map((key, index) => (
                          <div key={index}>{key}</div>
                        ))}
                      </div>
                    </>
                  )}
                </RmainingKeys>
                <NotSuccessfullDevices>
                  {!!remainingDevices?.length && (
                    <>
                      <b>Not successful devices:</b>
                      <br />
                      <div>
                        {remainingDevices.map((device, index) => (
                          <div key={index}>{device}</div>
                        ))}
                      </div>
                    </>
                  )}
                </NotSuccessfullDevices>
                <StyledBackButton
                  id="button-back"
                  onClick={() =>
                    history.push({
                      pathname: '/',
                      state: { from: history?.location?.pathname }
                    })
                  }
                >
                  Back to fuel cell
                </StyledBackButton>
              </>
            )}
          </StyledCard>
        )}
        {!applyActive && (
          <HintDisplay>
            <strong>Hint:</strong> You can add multiple licenses at once by entering them line by line or separated by a comma / semicolon.
          </HintDisplay>
        )}
      </Container>
    </main>
  )
}

export default ManageLicenses
