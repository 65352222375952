import { call, put, takeLatest } from 'redux-saga/effects'

import * as C from 'appConstants'
import { genericError, populateStartClaiming, populateVerifyPassword, connectToDeviceSubmit, populateSetDeviceName, togglePageDialog, populateInclusiveLicense } from 'actions'
import { createClaimRequest, updateClaimRequest, deleteClaimRequest } from 'apis/claims'
import { push } from 'connected-react-router'
import { getDeviceLicenses, setInclusiveLicenseRequest, updateDeviceNameRequest } from 'apis/devices'
import { LicenseType } from 'types/devices'
import { INCLUSIVE_LICENSE } from 'components/Application/Device/Dialog/ActivateLicenseKey/activateInclusiveLicense'

//  eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * createClaim ({ type, payload }: { type: string; payload: any }) {
  try {
    //  Adds loading screen to the store, until we get the request done
    yield put(connectToDeviceSubmit())

    const data = yield call(createClaimRequest, payload.serialNumber)
    yield put(populateStartClaiming({ response: data, serialNumber: payload.serialNumber }))
  } catch (err) {
    yield put(genericError(err))
  }
}

//  eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateClaim ({ type, payload }: { type: string; payload: { serialNumber: string; password: string } }) {
  try {
    const data = yield call(updateClaimRequest, payload.serialNumber, payload.password)

    yield put(populateVerifyPassword({ response: data, password: payload.password }))
  } catch (err) {
    yield put(genericError(err))
  }
}

//  eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateDeviceName ({ type, payload }: { type: string; payload: any }) {
  try {
    yield call(updateDeviceNameRequest, payload.serialNumber, payload.name)
    //  Check if there is an inclusive license available for the new claimed device.
    const inclusiveLicenseResp = yield call(getDeviceLicenses, payload.serialNumber, { inclusive: true })
    if (inclusiveLicenseResp?.status > 200) {
      //  Error.
      yield put(populateSetDeviceName({ inclusiveLicense: null }))
    } else {
      //  Check if there is a inclusive license in the response.
      const inclusiveLicense = inclusiveLicenseResp?.content && inclusiveLicenseResp?.content.length > 0 ? inclusiveLicenseResp?.content.filter((license: LicenseType) => license.state === INCLUSIVE_LICENSE)[0] : null
      yield put(populateSetDeviceName({ inclusiveLicense }))
    }
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * activateInclusiveLicense ({ type, payload }: { type: string; payload: { serialNumber: string } }) {
  try {
    const data = yield call(setInclusiveLicenseRequest, payload.serialNumber)
    yield put(populateInclusiveLicense(data))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function * deleteClaim ({ type, payload }: { type: string; payload: any }) {
  try {
    const response = yield call(deleteClaimRequest, payload.serialNumber)

    if (response.status === 204) {
      yield put(togglePageDialog())
      yield put(push('/'))
    }
  } catch (err) {
    yield put(genericError(err))
  }
}

export default function * claimDevicesSaga () {
  yield takeLatest(C.START_CLAIMING_SUBMIT, createClaim)
  yield takeLatest(C.VERIFY_PASSWORD_SUBMIT, updateClaim)
  yield takeLatest(C.SET_DEVICE_NAME_SUBMIT, updateDeviceName)
  yield takeLatest(C.SET_INCLUSIVE_LICENSE_SUBMIT, activateInclusiveLicense)
  yield takeLatest(C.DELETE_CLAIM, deleteClaim)
}
