import { integrationTestRunning } from 'utils/helpers'

interface MatomoEvent {
  event: string
  modalId: string
}

export const trackModalOpen = (modalId: string): void => {
  if (integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return
  }
  const winObj = window as any
  if (!winObj._mtm) {
    winObj._mtm = []
  }

  const matomoEvent: MatomoEvent = {
    event: 'modalOpened',
    modalId: modalId
  }

  winObj._mtm.push(matomoEvent)
}
