// @flow

type Props = {
  className?: string,
  id?: string,
  handleSubmit: (event :any) => void,
  children: React.ReactNode,
}

export default function Form (props: Props) {
  return (
    <form
      className={ `${props.className ? props.className : ''}` }
      id={ `${props.id ? props.id : ''}` }
      onSubmit={ props.handleSubmit }
    >
      { props.children }
    </form>
  )
}
