// @flow

import { connect, useDispatch } from 'react-redux'

import { useFormik } from 'formik'
import { Form, Button, Input, Row, Col, Gap } from 'components/ReUsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import {
  applyDeviceFilter,
  getDevices,
  togglePageDialog
} from 'actions'

type Props = {
  devices: {
    totalItems: number,
    search: {
      searchValue: string
    }
  },
  sorting: {
    sortKey: string,
    sortDirection: string
  } | any,
  getDevices?: (obj: any) => void,
  togglePageDialog: (obj: any) => void,
  formInitialValues: any
  handleSearchFormSubmit?: (values: any) => void
}

const DeviceCountRow = styled.div`
  padding: 0.5rem 0.7rem;
  font-family: open-sans-bold;
  color: var(--color-text-muted);
  font-size: 11px;
`

export function SearchForm (props: Props) {
  const dispatch = useDispatch()
  const formik = useFormik(
    {
      initialValues: props.devices.search,
      onSubmit: (values) => {
        dispatch(applyDeviceFilter(null))
        const updatedValues = Object.assign({ sorting: { ...props.sorting } }, values)
        if (props.getDevices) {
          props.getDevices(updatedValues)
        }
      }
    }
  )

  return (
    <Form handleSubmit={ formik.handleSubmit } id={ 'search-form' }>
      <Row>
        <Col>
          <div className='input-group search-field-content'>
            <span className='input-group-append'>
              <span className='input-group-text border-right-0'>
                <FontAwesomeIcon icon={['fal', 'search']} size='lg' />
              </span>
            </span>
            <Input
              id={ 'searchValue' }
              type={ 'text' }
              name={ 'searchValue' }
              className={ 'form-control border-left-0 search-input' }
              placeholder={ 'Search for Serial / Name' }
              onChange={ formik.handleChange }
              value={ formik.values.searchValue }
            />
          </div>
        </Col>
        <Gap></Gap>
        <Col>
          <Button
            type='button' id={ 'button-search-form' } className='btn-add-device'
            onClick={ () => props.togglePageDialog({ dialogId: 'device-claiming-dialog' }) }
          >
            <span className='btn-add-device-content'><FontAwesomeIcon icon={['fal', 'plus']} size='sm' /> ADD EFOY</span>
          </Button>
        </Col>
      </Row>
      <DeviceCountRow>
        Total devices: { props.devices.totalItems }
      </DeviceCountRow>
    </Form>
  )
}

const mapState = (state: any): any => (
  {
    devices: state.devices,
    dialog: state.dialog
  }
)

const mapDispatch = {
  getDevices,
  togglePageDialog
}

export default connect(
  mapState,
  mapDispatch
)(SearchForm)
