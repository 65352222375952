import * as C from 'appConstants'

const initialState = {
  type: '',
  types: [],
  beginOfChargeVoltage: {},
  capacity: {},
  cutOffCurrent: {},
  cutOffTime: {},
  deepDischargeProtectionVoltage: {},
  endOfChargeVoltage: {},
  maximumChargeTime: {},
  maximumDepthOfDischarge: {},
  minimumStateOfCharge: {},
  maximumStateOfCharge: {},
  minimumChargeTime: {},
  switchOnDelay: {},
  batteryForm: {
    type: '' // other fields will be dynamically added using getBatteryFormValues() func
  },
  pageDialog: {
    requestErrorMessage: '',
    batteryConfigSavedSuccess: false,
    loadingRequest: false
  },
  limits: {}
}

// TODO: refactor to be used also in batteryVoltagesSection component
const batteryFieldIsWritable = (fieldSetting: any, supportedWriteSettings: any) => supportedWriteSettings.includes(fieldSetting)

function getBatteryFormValues (properties: any) {
  // Mapping here because, props can change from battery type to another battery type
  const batteryFormValues: any = {}

  for (const property in properties) {
    const item = properties[property]

    if (
      item &&
      Object.prototype.hasOwnProperty.call(item, 'setting') &&
      batteryFieldIsWritable(item.setting, properties.supportedWriteSettings)
    ) {
      batteryFormValues[property] = item.current || item.default
    }
  }

  batteryFormValues.type = properties.type

  return batteryFormValues
}

function populateBattery (state: any, payload: any) {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        requestErrorMessage: C.SOMETHING_WENT_WRONG
      }
    }
  }

  return {
    ...state,
    ...payload.response,
    batteryForm: {
      ...getBatteryFormValues(payload.response)
    }
    }
  }

function loadingBatteryData (state: any) {
  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      loadingRequest: true
    }
  }
}

function populateSaveBatteryConfig (state: any, payload: any) {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        loadingRequest: false,
        requestErrorMessage: C.SOMETHING_WENT_WRONG,
        batteryConfigSavedSuccess: false
      }
    }
  }

  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      loadingRequest: false,
      batteryConfigSavedSuccess: true
    },
    type: payload.response.type
  }
}

function populateBatteryTypes (state: any, payload: any) {
  if (payload.status >= 400) {
    return {
      ...state,
      pageDialog: {
        loadingRequest: false,
        requestErrorMessage: C.SOMETHING_WENT_WRONG,
        batteryConfigSavedSuccess: false
      }
    }
  }

  return {
    ...state,
    types: payload.batteryTypes,
    pageDialog: {
      ...state.pageDialog,
      loadingRequest: false
    }
  }
}

function populateBatteryTypeLimits (state: any, payload: any) {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        loadingRequest: false,
        requestErrorMessage: C.SOMETHING_WENT_WRONG,
        batteryConfigSavedSuccess: false
      }
    }
  }

  return {
    ...state,
    limits: payload.response,
    pageDialog: {
      ...state.pageDialog,
      loadingRequest: false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function clearBatteryAlertPageDialog (state: any, payload?: any) {
  return {
    ...state,
    limits: { ...initialState.limits },
    batteryForm: { ...initialState.batteryForm },
    pageDialog: { ...initialState.pageDialog }
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_BATTERY:
      return populateBattery(state, payload)
    case C.LOADING_BATTERY_DATA:
      return loadingBatteryData(state)
    case C.POPULATE_SAVE_BATTERY_CONFIG:
      return populateSaveBatteryConfig(state, payload)
    case C.POPULATE_BATTERY_TYPES:
      return populateBatteryTypes(state, payload)
      case C.POPULATE_BATTERY_TYPE_LIMITS:
      return populateBatteryTypeLimits(state, payload)
    case C.CLEAR_BATTERY_ALERT_PAGE_DIALOG:
      return clearBatteryAlertPageDialog(state, payload)
    default:
      return state
  }
}
