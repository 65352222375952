//  @flow

import { Spinner } from 'react-bootstrap'

import { useAuth0 } from 'utils/react_auth0_spa'

import ErrorMessage from '../ErrorMessage'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import TrackingConsent from './TrackingConsent'

type Props = {
  dialog: {
    isDialogOpen: boolean
  },
  isMobile: boolean
};

// eslint-disable-next-line
export default (Component: Function) => (props: Props) => {
  const { loading, user } = useAuth0()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  if (user && user.email_verified === false) {
    return <ErrorMessage />
  }

  if (loading) {
    return <Spinner animation='border' variant='secondary' />
  }

  return <><Component { ...{ ...props, isMobile: !matches }} /> <TrackingConsent/> </>
}
