
import styled from 'styled-components'
import { PageTitle, Container, Row, Col, Button, Gap } from '..'

const StyledPage = styled(Row)`
    flex-direction: column;
`
export default function AppUnavailable ({ pageTitle }: { pageTitle?: string }) {
    function handleRefresh () {
      window.location.reload()
    }
    return (
        <Container>
            <StyledPage>
                <Gap width='5rem' ></Gap>
                <Col alignItems='center'>
                    <PageTitle style={{ textAlign: 'center' }} data-cy="app-not-available-title">{pageTitle || 'Application not available.'}</PageTitle>
                </Col>
                <Gap width='3rem' className='d-none d-lg-table-cell'></Gap>
                <Col alignItems='center'>
                    <Button
                        type='button'
                        data-cy="app-not-available-button"
                        onClick={handleRefresh}>
                        <span>Try again</span>
                    </Button>
                </Col>
            </StyledPage>
        </Container>
    )
}
