// @flow

import { isDifferenceMoreThanXMinutes } from 'utils/timeCalculations'
import { ALERT_TYPE, Alert } from '.'

type Props = {
    pageDialog: {
        requestErrorMessage: string,
        deviceOfflineErrorMessage?: string
    },
    isConnected: boolean,
    isPageDialogOpen: boolean,
    deviceLastUpdated?: string
}

const lastUpdatedMessage = 'The displayed information of the EFOY might not be up to date. The timestamp of the latest EFOY information differs significantly from the browser time.'

const inServiceModeMessage = 'Your EFOY is currently being repaired. Please contact support if you did not expect this.'

export function DevicePageMainAlerts (props: Props) {
    const {
        requestErrorMessage, deviceOfflineErrorMessage
    } = props.pageDialog

    const { isConnected, isPageDialogOpen, deviceLastUpdated } = props

    //  If there is an active modal dialog, do not show this message.
    if (isPageDialogOpen) {
      return null
    }

    //  Device is not connected and there is an offline error message.
    if (deviceOfflineErrorMessage && !isConnected) return (<Alert message={deviceOfflineErrorMessage} type={ALERT_TYPE.danger} />)

    //  Device is connected and last updated time is more then 60 minutes (absolute).
    if (isConnected && deviceLastUpdated && isDifferenceMoreThanXMinutes(deviceLastUpdated, 60)) return (<Alert message={lastUpdatedMessage} type={ALERT_TYPE.warning} />)

    //  Device is connected and there is no error message.
    if (isConnected || requestErrorMessage.length === 0) return null

    return (<Alert message={requestErrorMessage} type={ALERT_TYPE.danger} />)
}

export function DevicePageServiceModeAlert ({ inServiceMode }: { inServiceMode: boolean }) {
    if (!inServiceMode) {
        return null
    }

    return (<Alert message={inServiceModeMessage} type={ALERT_TYPE.warning} />)
}
