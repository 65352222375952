import { call, put, takeLatest } from 'redux-saga/effects'

import * as C from 'appConstants'

import {
  sendContactSupportMessageRequest
} from 'apis/contact'

import {
  genericError,
  populateSendContactSupportMessage
} from 'actions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * sendContactSupportMessage ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(sendContactSupportMessageRequest, payload)
    yield put(populateSendContactSupportMessage({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

export default function * contactSaga () {
  yield takeLatest(C.SEND_CONTACT_SUPPORT_MESSAGE, sendContactSupportMessage)
}
