// @flow

import { useCallback, useState } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { SubTitle, Row, Col, Gap, CheckBox } from 'components/ReUsable'
import { isLicenseExpired, roleLabel } from 'utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { updateSelectedDevices } from 'actions'
import { Tag } from '../Tags/context/tagContext'
import DeviceTags from '../Tags/deviceTags'
import { useMediaQuery } from '@mui/material'

type Props = {
  values: {
    id: number
    name: string
    img_url: string
    type: string
    serviceLevel: string
    serialNumber: string
    permissions: any
    license: any
    clusterRole: 'controller' | 'client' | 'none',
    tags: Tag[]
  }
}

const StyledLicenseText = styled.span`
  display: inline-block;
  max-width: 8rem;
  &:first-letter {
    text-transform: capitalize;
  }
  @media (min-width: 991.97px) {
    max-width: 10rem;
  }
`

const StyledAdditionalText = styled.span`
  font-size: 12px;
  @media (min-width: 991.97px) {
    font-size: 14px;
  }
`

const StyledTypeText = styled.span`
  display: inline-block;
  max-width: 8rem;
  @media (min-width: 991.97px) {
    max-width: 10rem;
  }
`

const StyledCheckBox = styled(CheckBox)`
  display: none;
  position: relative;
  top: 4px;
  @media (min-width: 991.97px) {
    display: flex;
  }
`

const StyledClusterImg = styled.img`
  transform: scale(0.8);
  position: absolute;
  right: ${(props) => (props.role === 'controller' ? '0' : '8px')};
  bottom: ${(props) => (props.role === 'controller' ? '2px' : '8px')};
  @media (min-width: 991.97px) {
    transform: scale(1);
    right: ${(props) => (props.role === 'controller' ? '7px' : '14px')};
    bottom: ${(props) => (props.role === 'controller' ? '4px' : '14px')};
  }
`

const StyledNavLink = styled(NavLink)`
  flex-grow: 1;
`

export default function DeviceRowCell (props: Props) {
  const { values } = props
  const isDesktop = useMediaQuery('(min-width:992px)')
  const selectionActive = useSelector((state: any) => state.devices.selectionActive)
  const selectedColumns = useSelector((state: any) => state.devices.selectedColumns)
  let selectedDevices = useSelector((state: any) => state.devices.selectedDevices.map((d) => d.serialNumber))
  const [selected, setSelected] = useState(selectedDevices && selectedDevices.indexOf(values.serialNumber) > -1)
  const dispatch = useDispatch()
  const onSelect = (newValue: boolean) => {
    setSelected(newValue)
    dispatch(updateSelectedDevices({ serialNumber: values.serialNumber, name: values.name }))
    // Add
    if (newValue) {
      selectedDevices = [
        ...selectedDevices,
        {
          serialNumber: values.serialNumber,
          name: values.name
        }
      ]
    } else if (selectedDevices) {
      selectedDevices = selectedDevices.filter((d) => d.serialNumber !== values.serialNumber)
    }
  }

  const license = values.license
  const licenseExpired = isLicenseExpired(values.permissions)
  const handleClick = useCallback(
    (e) => {
      if (licenseExpired) e.preventDefault()
    },
    [licenseExpired]
  )

  const licenseColumnVisible = isDesktop ? selectedColumns.includes('licenseType') : false
  const licenseExpireColumnVisible = isDesktop ? selectedColumns.includes('licenseExpire') : false

  return (
    <td className="d-flex justify-flex-start" style={{ position: 'relative' }}>
      <>
        {selectionActive && (
          <>
            <StyledCheckBox
              data-cy="select-device-checkbox"
              checked={selected}
              onChange={() => {
                onSelect(!selected)
              }}
            />
            <Gap />
          </>
        )}

        <StyledNavLink data-cy="device-detail-link" className={`${licenseExpired ? 'license-expired no-style-link' : 'no-style-link'}`} to={`/devices/${values.serialNumber}`} onClick={handleClick}>
          <Row>
            <Col base="1px" grow="0" justifyContent="center" alignItems="center" className="d-none d-lg-table-cell">
              <img src={values.img_url} alt="Product" />
            </Col>
            <Gap width="1rem" className="d-none d-lg-table-cell"></Gap>
            <Col justifyContent="space-around">
              <SubTitle className={`${licenseExpired ? 'license-expired' : ''}`}>{values.name}</SubTitle>
              <StyledTypeText>{values.type}</StyledTypeText>
              {!licenseColumnVisible ? <StyledLicenseText>{license ? `${license.tier} license` : 'no license'}</StyledLicenseText> : null}
              <StyledAdditionalText>{!licenseExpireColumnVisible && license ? `(expires: ${license.end ? moment(license.end).format('DD.MM.yyyy') : 'never'}) ` : null}
                <DeviceTags tags={values.tags} compact={true}/>
              </StyledAdditionalText>
            </Col>
          </Row>
          <>
            {values.clusterRole && values.clusterRole !== 'none' ? (
              <StyledClusterImg
                data-cy="devicelist-cluster-icon"
                title={roleLabel(values.clusterRole)}
                src={`/imgs/Role_${values.clusterRole.toLowerCase()}.svg`}
                alt={roleLabel(values.clusterRole)}
                role={values.clusterRole}
              />
            ) : null}
          </>
        </StyledNavLink>
      </>
    </td>
  )
}
