// @flow

import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'components/ReUsable'

type Props = {
  label: string,
  iconName: string,
  onClick: () => void,
  optionList?: boolean,
  isOpen?: boolean,
  className?:string,
  disabled?: boolean
};

const StyledButton = styled(Button)<{optionList: any, isOpen?: boolean}>`
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2rem;
  min-width: 12rem;

  @media (max-width: 991.97px) {
    min-width: 100%;
  }

  @media (min-width: 991.98px) {
    padding: 0 1rem;
  }

  ${(props: any) => {
    if (props.isOpen) {
      return `margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;`
    }
  }}

  ${(props: any) => {
    if (props.optionList) {
      return `&:focus, &:hover {
        opacity: .9;
      }
      margin-bottom: 0;`
    }
  }}
  &:focus {
    box-shadow: none;
  }

  &:enabled:hover {
    transform: scale(1);
    opacity: 0.9;
  }
`
const StyledText = styled.span``

const StyledImg = styled.img`
  width: 1rem;
  height: auto;
`

export default function ButtonWithIcon (props: Props) {
  const { onClick, label, iconName, optionList, isOpen, ...rest } = props

  return (
    <StyledButton
      onClick={onClick}
      optionList={optionList}
      isOpen={isOpen}
      {...rest}
    >
      <StyledText style={{ flexGrow: 1 }}>{label}</StyledText>
      {optionList ? (
        <FontAwesomeIcon
          style={
            isOpen
              ? {
                  transform: 'rotate(-180deg)',
                  transition: 'all .25s ease-in-out'
                }
              : {
                  transform: 'rotate(0deg)',
                  transition: 'all .25s ease-in-out'
                }
          }
          icon={['fas', 'chevron-down']}
        />
      ) : (
        <StyledImg alt={label} src={`/imgs/${iconName}.svg`} />
      )}
    </StyledButton>
  )
}
