// @flow

import { INotification } from 'reducers/notificationReducer'
import {
  backendEndpoint,
  executeRequest
} from '.'

export type NotificationFormType = INotification & { publishDate: string }

const notificationsEndpoint = `${backendEndpoint || ''}/account/notices`
const adminNotificationsEndpoint = `${backendEndpoint || ''}/notices`

export const confirmNotificationAPI = (uuid: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'PUT',
      body: JSON.stringify({
        confirm: true
      })
  }
  return executeRequest(`${notificationsEndpoint}/${uuid}`, undefined, undefined, options)
}

export const getNotificationsAPI = () => {
  const options = {
      'Content-Type': 'application/json',
      method: 'GET'
  }
  return executeRequest(`${notificationsEndpoint}`, undefined, undefined, options)
}

export const getAdminNotificationsAPI = () => {
  const options = {
      'Content-Type': 'application/json',
      method: 'GET'
  }
  return executeRequest(`${adminNotificationsEndpoint}`, undefined, undefined, options)
}

export const getOneAdminNotificationAPI = (uuid: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'GET'
  }
  return executeRequest(`${adminNotificationsEndpoint}/${uuid}`, undefined, undefined, options)
}

export const deleteAdminNotificationAPI = (uuid: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'DELETE'
  }
  return executeRequest(`${adminNotificationsEndpoint}/${uuid}`, undefined, undefined, options)
}

export const updateAdminNotificationAPI = (uuid: string, notification: NotificationFormType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...notificationToEdit } = notification
  const options = {
      'Content-Type': 'application/json',
      method: 'PUT',
      body: JSON.stringify(notificationToEdit)
  }
  return executeRequest(`${adminNotificationsEndpoint}/${uuid}`, undefined, undefined, options)
}

export const insertAdminNotificationAPI = (notification: NotificationFormType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...notificationToAdd } = notification
  const options = {
      'Content-Type': 'application/json',
      method: 'POST',
      body: JSON.stringify(notificationToAdd)
  }
  return executeRequest(`${adminNotificationsEndpoint}`, undefined, undefined, options)
}
