// @flow

import { useState, useEffect } from 'react'
import { format, formatDistance } from 'date-fns'

import { getApplicationStatusRequest, StatusSummary, IncidentSummary, MaintenanceStatus } from 'apis/applicationStatus'

/* const exampleResponse = {
  page: {
    name: 'Instatus',
    url: 'https://instat.us',
    status: 'HASISSUES'
  },
  activeIncidents: [
    {
      name: 'We are facing an issue with our API',
      started: 'Sat Jun 11 2022 18:55:50 GMT+0000 (Coordinated Universal Time)',
      status: 'INVESTIGATING',
      impact: 'MAJOROUTAGE',
      url: 'https://testing-widget.instatus.com/incident/cl4a8n3070721qtmzvts760c4'
    }
  ],
  activeMaintenances: [
    {
      name: 'Database maintenance',
      start: 'Sat Jun 11 2022 18:55:54 GMT+0000 (Coordinated Universal Time)',
      status: 'NOTSTARTEDYET',
      duration: '90',
      url: 'https://testing-widget.instatus.com/maintenance/cl4a8nlv50918qtmzdlw60ivu'
    }
  ]
} */

const fetchInterval = 60 * 1000 * 10

type StatusBarHookType = {
  loading: boolean;
  error: string;
  banner: StatusBannerType
}

export type StatusBannerType = { type: 'NONE' | 'INACTIVE' | 'WARNING' | 'DANGER', text: string, url: string }

const ISSUE_TEXT = 'We are currently monitoring a problem with our service. More information&nbsp;<u>here</u>.'
const MAINTENANCE_TEXT = 'We are performing maintenance, the performance of the service may be affected. More information&nbsp;<u>here</u>.'

const dateFormat = 'dd.MM.yyyy HH:mm'

export function useStatusBar () : StatusBarHookType {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [applicationStatus, setApplicationStatus] = useState<null | StatusSummary>(null)
  const [banner, setBanner] = useState<StatusBannerType>({ type: 'NONE', text: '', url: '' })
  useEffect(() => {
    //  Fetch status
    getApplicationStatusCall()
    //  Fetch data every X seconds
    const interval = setInterval(() => getApplicationStatusCall(), fetchInterval)
    //  Cleanup.
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!applicationStatus) {
      setBanner({ type: 'NONE', text: '', url: '' })
      return
    }

    //  Take active incidents and filter out resolved ones.
    const activeIncidents:IncidentSummary[] = applicationStatus?.activeIncidents
    ? (applicationStatus?.activeIncidents as IncidentSummary[]).filter((incident: IncidentSummary) => incident.status !== 'RESOLVED')
    : []
    const activeMaintenances: MaintenanceStatus[] = applicationStatus?.activeMaintenances
    ? (applicationStatus?.activeMaintenances as MaintenanceStatus[]).filter((maintenance: MaintenanceStatus) => maintenance.status !== 'COMPLETED')
    : []
    const applicationStatusValue = applicationStatus?.page.status

    //  Both elements are empty
    if (activeIncidents.length === 0 && activeMaintenances.length === 0) {
      if (applicationStatusValue === 'UP') {
        setBanner({ type: 'NONE', text: '', url: '' })
      } else if (applicationStatusValue === 'HASISSUES') {
        setBanner({
          type: 'DANGER',
          text: ISSUE_TEXT,
          url: applicationStatus?.page.url
        })
      } else if (applicationStatusValue === 'ONEUNDERMAINTENANCE') {
        setBanner({
          type: 'WARNING',
          text: MAINTENANCE_TEXT,
          url: applicationStatus?.page.url
        })
      }
    } else if (activeIncidents.length > 0) {
      setBanner({
        type: 'DANGER',
        text: `${activeIncidents[0].name}. More information&nbsp;<u>here</u>.`,
        url: activeIncidents[0].url
      })
    } else if (activeMaintenances.length > 0) {
      const currentMaintenance = activeMaintenances[0]
      const formattedDuration = formatDistance(0, +currentMaintenance.duration * 60000)
      if (currentMaintenance.status === 'NOTSTARTEDYET') {
        setBanner({
          type: 'INACTIVE',
          text: `${currentMaintenance.name} will start at ${format(new Date(currentMaintenance.start), dateFormat)} for ${formattedDuration}. More information&nbsp;<u>here</u>.`,
          url: currentMaintenance.url
        })
      } else if (currentMaintenance.status === 'INPROGRESS') {
        setBanner({
          type: 'WARNING',
          text: `${currentMaintenance.name} is running since ${format(new Date(currentMaintenance.start), dateFormat)} for ${formattedDuration}. More information&nbsp;<u>here</u>.`,
          url: currentMaintenance.url
        })
      }
    }
  }, [applicationStatus])

  //  Multisense API call.
  const getApplicationStatusCall = async () => {
    try {
      const statusResponse = await getApplicationStatusRequest()
      setApplicationStatus(statusResponse as StatusSummary)
    } catch (e) {
      setApplicationStatus(null)
      setError('Something went wrong')
      return false
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, banner }
}
