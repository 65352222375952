// @flow

import { connect } from 'react-redux'

import { useAuth0 } from 'utils/react_auth0_spa'
import { integrationTestRunning } from 'utils/helpers'

import { toggleDialog } from '../../../actions'
import CollapseNavBar from './collapseNavBar'
import CollapseNavBarModal from './collapseNavBarModal'

type Props = {
  dialog: {
    isDialogOpen: boolean
  },
  toggleDialog: () => void,
  hideContent?: boolean
}

export function NavBar (props: Props) {
  const { isDialogOpen } = props.dialog
  const { hideContent } = props
  const { loading, isAuthenticated } = useAuth0()
  const userIsLogged = (isAuthenticated && !loading) || integrationTestRunning(process.env.REACT_APP_ENV_MODE)

  const toggleDialog = () => {
    props.toggleDialog()
    window.scrollTo(0, 0)
  }

  return (
    <>
      <nav className={ `navbar navbar-expand-lg ${isDialogOpen ? 'nav-blur-color' : ''}` }>
        <a className='navbar-brand' href='/'>
          <img
            className={ `logo-image ${isDialogOpen ? 'hide' : 'show'}` }
            height='55' width='115' src='/imgs/logo.svg' alt='Home'
          />
        </a>
        {
          userIsLogged &&
          <button
            className={ `navbar-toggler ${!isDialogOpen ? 'collapsed' : ''}` }
            type='button' onClick={ () => toggleDialog() }
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        }
        {
          !hideContent ? <CollapseNavBar toggleDialog={toggleDialog} /> : <></>
        }
      </nav>
      <CollapseNavBarModal { ...props } />
    </>
  )
}

const mapState = state => (
  {
    dialog: state.dialog
  }
)

const mapDispatch = { toggleDialog }

export default connect(
  mapState,
  mapDispatch
)(NavBar)
