// @flow

import React from 'react'
import { flexRender } from '@tanstack/react-table'

type Props = {
  headerGroup: any
}

const TableHeading = ({ headerGroup }: Props) => (
  <tr>
    {
      headerGroup.headers.map(header => (
        <React.Fragment key={header.id}>
          {flexRender(
            header.column.columnDef.header,
            { ...header.getContext(), id: header.id }
          )}
        </React.Fragment>
      ))
    }
  </tr>
)

export default TableHeading
