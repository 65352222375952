
import { PageTitle, Container, Row, Col, Gap } from 'components/ReUsable'

export default function SomethingWentWrong () {
    return (
        <Container>
            <Row>
                <Gap width='5rem' ></Gap>
                <Col alignItems='center'>
                    <PageTitle data-cy="error-went-wrong" style={{ textAlign: 'center' }}>Something went wrong. Please try refreshing the page.</PageTitle>
                </Col>
            </Row>
        </Container>
    )
}
