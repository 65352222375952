// @flow

import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { SubTitle, Dialog, Text, Col, Button, OutlineButton } from 'components/ReUsable'
import { Delegation } from './ManageDelegationList'
import DelegationSelector from './DelegationSelector'
import { OWNER } from 'appConstants'
import { useAddDelegation } from '../hooks/useAddDelegation'
import { togglePageDialog } from 'actions'
import { StyledButtonPanel } from 'components/Application/Device'

export const StyledSubTitle = styled(SubTitle)`
  font-size: 14px;
  margin-bottom: 0;
  color: #4b4b4b;

  @media (min-width: 991px) {
    font-size: 17px;
  }
`

export const StyledItemRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  background: rgba(228, 228, 228, 0.5);
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  color: #fff;
  margin: 6px 0;
  @media (min-width: 991px) {
    height: 64px;
  }
`

const StyledItemCol = styled.div<{ noPadding?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: ${(props: any) => (!props.noPadding ? '0 4px 0 20px' : '0')};
  @media (max-width: 991px) {
    padding-left: ${(props: any) => (!props.noPadding ? '7px' : '0')};
  }

  & > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:last-child) {
    border-right: 1px solid #cfcfcf;
  }

  &:first-child {
    width: 43%;
    @media (min-width: 991px) {
      width: 26.5%;
    }
  }
  &:nth-child(2) {
    display: none;
    @media (min-width: 991px) {
      display: flex;
      width: 25.5%;
    }
  }
  &:nth-child(3) {
    width: 43%;
    @media (min-width: 991px) {
      display: flex;
      width: 36.5%;
    }
  }
  &:nth-child(4) {
    width: 14%;
    @media (min-width: 991px) {
      display: flex;
      width: 11.5%;
    }
  }
`

const StyledImgCol = styled(StyledItemCol)`
  justify-content: center;
  padding: 0;
  position: relative;
  top: 2px;
`

export const StyledDecoupleBtn = styled.button`
  border: none;
  background: transparent;
  disabled: ${(props: any) => props.disabled};
`

const StyledDecoupleImg = styled.img`
  width: 48px;
  height: 48px;
`

const StyledMobileIdentity = styled.span`
  @media (min-width: 991px) {
    display: none;
  }
`

const StyledSpinner = styled(Spinner)`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
  margin-right: 0;
`

export const ManageDelegationItem = function ({
  delegation,
  index,
  onUpdateList
}: {
  delegation: Delegation;
  index: number;
  onUpdateList: () => Promise<boolean>;
}) {
  const { isPageDialogOpen, dialogId } = useSelector(
    (state) => (state as any)?.dialog
  )
  const dispatch = useDispatch()
  const { serialNumber } = useParams<{ serialNumber: string }>()
  const { makeDelegationAPICall } = useAddDelegation(serialNumber)
  const [saving, setSaving] = useState<boolean>(false)
  const [decoupling, setDecoupling] = useState<boolean>(false)

  const handleConfirmDecouple = async () => {
    if (!delegation) return
    const { identity, role } = delegation
    try {
      setDecoupling(true)
      const removeSuccess = await makeDelegationAPICall(
        identity,
        role,
        'DELETE'
      )
      const getDelegationsSuccess = await onUpdateList()

      if (removeSuccess && getDelegationsSuccess) {
        setSaving(false)
      }
    } catch {
      setSaving(false)
    } finally {
      setDecoupling(false)
    }
    dispatch(togglePageDialog())
  }

  const handleCancelDecouple = () => {
    setSaving(false)
    dispatch(togglePageDialog())
  }

  if (delegation) {
    const { identity, type, role } = delegation
    let identityName = identity
    if (type === 'GROUP') {
      identityName = `Group ID: ${identityName}`
    }

    return (
      <>
        <StyledItemRow
          key={`delegation-row-${index}`}
          data-cy={`manage-delegation-item${index + 1}`}
        >
          <StyledItemCol>
            <StyledSubTitle title={delegation.displayName}>
              {delegation.displayName}
              <StyledMobileIdentity>
                <br />
                {identity}
              </StyledMobileIdentity>
            </StyledSubTitle>
          </StyledItemCol>
          <StyledItemCol>
            <StyledSubTitle title={identityName}>{identityName}</StyledSubTitle>
          </StyledItemCol>
          <StyledItemCol noPadding={true}>
            <DelegationSelector delegation={delegation} />
          </StyledItemCol>
          <StyledImgCol>
            <StyledDecoupleBtn
              disabled={role === OWNER}
              onClick={async () => {
                if (role !== OWNER) {
                  dispatch(togglePageDialog({ dialogId: 'decouple-delegation-item' }))
                  setSaving(true)
                }
              }}
            >
              <StyledDecoupleImg
                  src={`/imgs/chain${role === OWNER ? '-deactivated' : ''}.svg`}
                  alt='Decouple'
                />
            </StyledDecoupleBtn>
          </StyledImgCol>
        </StyledItemRow>
        {saving && (
          <Dialog
            id='decouple-delegation-item'
            className='modal-page'
            title={'Decouple user/group form EFOY'}
            show={isPageDialogOpen && dialogId === 'decouple-delegation-item'}
            onClose={handleCancelDecouple}
          >
            <Col alignItems='center'>
              <Text textAlign='center'>{`Are you sure you want to decouple the ${type === 'GROUP' ? 'group ID' : 'user'} ${identity} from the EFOY?`}</Text>
              <StyledButtonPanel>
                <OutlineButton
                  type='button'
                  id='cancel'
                  color='primary'
                  onClick={handleCancelDecouple}
                >
                  CANCEL
                </OutlineButton>
                  <Button
                  data-cy="confirm-decouple-button"
                    onClick={handleConfirmDecouple}
                  >
                    Confirm {decoupling && (
                    <StyledSpinner animation='border' variant='secondary' />
                  )}
                </Button>
              </StyledButtonPanel>
            </Col>
          </Dialog>
        )}
      </>
    )
  }

  return <></>
}

export default ManageDelegationItem
