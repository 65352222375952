// @flow
import { deleteAlertConfigsRequest } from 'apis/alerts'
import { Button, Container, PageTitle, Row, Col, Alert, ALERT_TYPE } from 'components/ReUsable'
import styled from 'styled-components'
import { useUnsubscribeAlert } from './hooks/useUnsubscribeAlert'
import PageNotReachable from 'components/ReUsable/PageNotReachable'

const StyledText = styled.span`
  color: var(--color-additional-value);
  font-size: 16px;
  font-family: open-sans-bold;
  font-weight: bold;
  line-height: 21px;
  padding-bottom: 6px;
`

export const UnsubscribePageContainer = function ({ children }: { children: React.ReactNode }) {
  return (
    <main role="main">
      <Container data-cy="page-unsubscribe">
      <PageTitle>Unsubscribe alerts</PageTitle>
      <br/>
      { children }
      </Container>
    </main>
  )
}

export const ERROR_404_TEXT = 'You already unsubscribed alerts for this EFOY.'
export const ERROR_400_TEXT = 'Unsubscribe link is not valid.'
export const ERROR_TEXT = 'An error occured. Please try again later.'
export const SUCCESS_TEXT = 'Unsubscribed successfully.'

export const UnsubscribeAlertConfig = function () {
  const {
    token,
    email,
    deviceSerialNumber,
    loading,
    statusCode,
    deviceName,
    handleApiCall
  } = useUnsubscribeAlert(deleteAlertConfigsRequest)

  //  Missing query parameter.
  if (!token || !email) return <PageNotReachable />

  //  Response success.
  if (statusCode === 200) {
    return (
      <UnsubscribePageContainer>
        <Alert data-cy="unsubscribe-success" messages={[SUCCESS_TEXT]} type={ALERT_TYPE.success} />
      </UnsubscribePageContainer>
    )
  }

  //  Response error.
  if (statusCode > 200) {
    const errText = statusCode === 404 ? ERROR_404_TEXT : statusCode === 400 ? ERROR_400_TEXT : ERROR_TEXT
    return (
      <UnsubscribePageContainer>
        <Alert messages={[errText]} type={ALERT_TYPE.danger} />
      </UnsubscribePageContainer>
    )
  }

  return (
    <UnsubscribePageContainer>
      {deviceSerialNumber &&
        <>
          <Row>
            <Col justifyContent='center'>
              <StyledText data-cy="unsubscribe-device-header">
                Unsubscribe alerts for EFOY: { deviceName }
              </StyledText>
              <Button data-cy="unsubscribe-device-button" loading={ loading === 1 ? true : undefined } onClick={ () => handleApiCall(true) }>
                unsubscribe
              </Button>
            </Col>
          </Row>
          <br/>
        </>
      }
      <Row>
        <Col justifyContent='center'>
          <StyledText data-cy="unsubscribe-all-header">
            Unsubscribe all EFOY alerts:
          </StyledText>
          <Button data-cy="unsubscribe-all-button" loading={ loading === 1 ? true : undefined } onClick={ () => handleApiCall(false) }>
            unsubscribe all
          </Button>
        </Col>
      </Row>
    </UnsubscribePageContainer>
  )
}

export default UnsubscribeAlertConfig
