import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications, confirmNotification } from 'actions/notifications'
import { INotification } from 'reducers/notificationReducer'
import { CONSENT_DIALOG_ID } from 'appConstants'
import { integrationTestRunning } from 'utils/helpers'

interface IUseNotification {
  activeNotification: INotification | null,
  handleConfirmNotification: (uuid: string) => void
}

export default function useNotifications ():IUseNotification {
  const dispatch = useDispatch()
  const notifications:INotification[] = useSelector((state:any) => state.notifications.list)
  const { dialogId } = useSelector(
    (state: any) => state.dialog
  )
  const handleConfirmNotification = (uuid: string) => {
    dispatch(confirmNotification({ uuid }))
  }

  useEffect(() => {
    dispatch(getNotifications())
  }, [dispatch])

  return {
    activeNotification: notifications && notifications.length > 0 && (dialogId !== CONSENT_DIALOG_ID || integrationTestRunning(process.env.REACT_APP_ENV_MODE)) ? notifications[0] : null,
    handleConfirmNotification
  }
}
