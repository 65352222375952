// @flow

import NavBarList from './navBarList'

type Props = {toggleDialog: () => void}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CollapseNavBar (props: Props) {
  return (
    <div className='collapse navbar-collapse'>
      <div className='navbar-nav flex-row ml-md-auto d-md-flex'>
        <NavBarList
          className={ 'navbar-nav mr-auto mt-2 mt-lg-0' } LinkClassName={ 'nav-link' }
          toggleDialog={ () => undefined }
        />
      </div>
    </div>
  )
}
