// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const contactEndpoint = `${backendEndpoint || ''}/contact`

export const sendContactSupportMessageRequest = (payload: any) => {
  const options = {
    method: 'POST',
    body: payload.message,
    'Content-Type': 'application/json'
  }

  if (payload.deviceName && payload.serialNumber) {
    return executeRequest(`${contactEndpoint}?deviceName=${payload.deviceName}&serialNumber=${payload.serialNumber}`, undefined, undefined, options)
  }

  return executeRequest(`${contactEndpoint}`, undefined, undefined, options)
}
