import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects'

import {
  GET_BATTERY,
  REQUEST_SAVE_BATTERY_CONFIG,
  GET_BATTERY_TYPES,
  GET_BATTERY_TYPE_LIMITS
} from 'appConstants'

import {
  populateBattery,
  populateSaveBatteryConfig,
  handleLoadingBatteryData,
  populateBatteryTypes,
  genericError,
  populateBatteryTypeLimits
} from 'actions'

import {
  getBatteryRequest,
  saveBatteryConfigRequest,
  getBatteryLimitsRequest
} from 'apis/batteries'
import { BatteryTypeLimits } from 'types/battery'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getBattery ({ type, payload }: { type: string, payload: any }) {
  try {
    // Adds loading screen to the store, until we get the request done
    yield put(handleLoadingBatteryData())

    //  API call to get battery data.
    const response = yield call(getBatteryRequest, payload.serialNumber)

    //  Save into redux.
    yield put(populateBattery({ response: response }))
    return response
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * saveBatteryConfig ({ type, payload }: { type: string, payload: any }) {
  try {
    // Adds loading screen to the store, until we get the request done
    yield put(handleLoadingBatteryData())

    //  API call to save changes. Response returns saved values.
    const batteryValues = yield call(saveBatteryConfigRequest, payload)

    //  Receive all limit properties and min, max, default values for each battery type.
    const batteryLimitsResponse = yield call(getBatteryLimitsRequest, payload.serialNumber)
    //  Final values can be used in the battery settings form.
    const finalObj = fillInLimitsObjectWithCurrentBatteryValues(batteryLimitsResponse, batteryValues)
    //  Save limits into redux.
    yield put(populateBatteryTypeLimits({ message: batteryLimitsResponse.message, response: finalObj }))
    //  Save into redux
    yield put(populateSaveBatteryConfig({ response: batteryValues }))
  } catch (err) {
    yield put(genericError(err))
  }
}

export const batteryTypes = [
  {
    value: 'No Battery'
  },
  {
    value: 'Lead Acid 12V'
  },
  {
    value: 'Lead Acid 24V'
  },
  {
    value: 'LiFePO4 12V'
  },
  {
    value: 'LiFePO4 24V'
  },
  {
    value: 'EFOY Battery'
  }
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getBatteryTypes () {
  try {
    yield put(populateBatteryTypes({ message: '', batteryTypes: batteryTypes }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getBatteryTypeLimits ({ type, payload }: { type: string, payload: { serialNumber: string } }) {
  try {
    const batteryValues = yield call(getBattery, { type: GET_BATTERY, payload: { serialNumber: payload.serialNumber } })
    //  Receive all limit properties and min, max, default values for each battery type.
    const batteryLimitsResponse = yield call(getBatteryLimitsRequest, payload.serialNumber)
    //  Final values can be used in the battery settings form.
    const finalObj = fillInLimitsObjectWithCurrentBatteryValues(batteryLimitsResponse, batteryValues)
    //  Save limits into redux.
    yield put(populateBatteryTypeLimits({ message: batteryLimitsResponse.message, response: finalObj }))
    //  Save into redux
    yield put(populateBattery({ response: batteryValues }))
  } catch (err) {
    yield put(genericError(err))
  }
}

function fillInLimitsObjectWithCurrentBatteryValues (batteryLimitsResponse: any, batteryValues: any): BatteryTypeLimits {
  //  Find matching battery type from limits object.
  let fillInBatteryValues = { ...batteryLimitsResponse[batteryValues.type] }

  //  Add all current values coming from battery of the device into the limits obj.
  //  Limits object now will have the current values from device battery.
  if (fillInBatteryValues) {
    Object.keys(fillInBatteryValues).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(batteryValues, key)) {
        fillInBatteryValues = { ...fillInBatteryValues, [key]: { ...fillInBatteryValues[key], current: batteryValues[key] } }
      }
    })
  }

  //  Final values can be used in the battery settings form.
  const finalObj = { ...batteryLimitsResponse, [batteryValues.type]: fillInBatteryValues }
  return finalObj
}

export default function * batteriesSaga () {
  yield takeLatest(GET_BATTERY, getBattery)
  yield takeLatest(REQUEST_SAVE_BATTERY_CONFIG, saveBatteryConfig)
  yield takeLatest(GET_BATTERY_TYPES, getBatteryTypes)
  yield takeLatest(GET_BATTERY_TYPE_LIMITS, getBatteryTypeLimits)
}
