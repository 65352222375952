// @flow
import { useState } from 'react'

import { unlockTransportProtectionLock } from 'apis/devices'

interface IResponseStatus {
  success: boolean;
  error: boolean;
  saving: boolean;
}

const useDeactivateTransport = function (
  serialNumber: string
) {
  const [status, setStatus] = useState<IResponseStatus>({ success: false, error: false, saving: false })

  const handleDeactivation = async () => {
    try {
      setStatus((prevStatus: IResponseStatus) => ({ ...prevStatus, saving: true }))
      const response = await unlockTransportProtectionLock(serialNumber)
      if (response.ok) {
        setTimeout(() => {
          setStatus((prevStatus: IResponseStatus) => ({ ...prevStatus, error: false, saving: false, success: true }))
        }, 5000)
      }
    } catch (error) {
      setStatus((prevStatus: IResponseStatus) => ({ ...prevStatus, error: true, saving: false, success: false }))
    }
  }

  return { status, handleDeactivation }
}

export default useDeactivateTransport
