export const getPropertyTitle = (itemName) => {
  switch (itemName) {
    case 'capacity':
      return 'Battery capacity'
    case 'cutOffCurrent':
      return 'Cut-off current'
    case 'cutOffTime':
      return 'Cut-off time'
    case 'maximumChargeTime':
      return 'Max. charge time'
    case 'minimumChargeTime':
      return 'Min. charge time'
    case 'switchOnDelay':
      return 'Switch-on delay'
    case 'stateOfCharge':
      return 'SOC'
    case 'deepDischargeProtectionSoc':
    case 'deepDischargeProtectionSOC':
    case 'deepDischargeProtectionVoltage':
      return 'Battery protection'
    case 'maximumDepthOfDischarge':
    case 'minimumStateOfCharge':
      return 'Switch-on SOC'
    case 'maximumStateOfCharge':
      return 'Switch-off SOC'
    case 'beginOfChargeVoltage':
      return 'Switch-on voltage'
    case 'endOfChargeVoltage':
      return 'Switch-off voltage'
   /*  case 'minimumStateOfCharge':
      return 'Minimum charge state' */
    default:
      return itemName
  }
}
