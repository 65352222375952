// @flow
import styled from 'styled-components'

type Props = {
  width?: string,
}

export default styled.div`
  flex: 0 0 ${(props: Props) => props.width || 'var(--dim-colum-gap)'};
`
