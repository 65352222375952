// @flow

import { useState, useEffect } from 'react'
import { useCurrentDevice } from 'components/Application/ManageDelegations/hooks/useCurrentDevice'
import { DENIED, DENIED_MISSING_LICENSE, DEVICE_DETAILS_PERMISSIONS_LIST, GRANTED } from 'appConstants'
import { getUsersWithAlertConfigurationRequest, getAlertConfigurationByUserRequest, AlertUser, deleteUsersAlertsProfileRequest, updateAlertConfigurationRequest, AlertAPIConfig } from 'apis/alertsv2'
import { ErrorResponse } from 'apis'
import { AlertConfiguration, AlertsObjectConfig } from '../alertTypes'

type PermissionStatus = typeof GRANTED | typeof DENIED | typeof DENIED_MISSING_LICENSE;

export type DevicePermissions = {
  [K in keyof typeof DEVICE_DETAILS_PERMISSIONS_LIST]: PermissionStatus;
};

type UseAlertType = {
  loading: boolean,
  currentDevice: any,
  error: boolean,
  deviceLoadingOrErrorComponent: JSX.Element | null
  permissions: DevicePermissions | null
  userType: UserType,
  alertUsersList: AlertUser[],
  getUsersList: () => void,
  selectedConfiguration: AlertConfiguration | null,
  handleNewConfigInsert: (newEmail: string, configs: AlertConfiguration[]) => void,
  selectedUserId: string,
  setSelectedUserId: (userId: string) => void,
  removeUser: () => void,
  handleUpdateConfig: (config: AlertsObjectConfig) => void,
  successMessage: string,
  formIsDirty: boolean,
  setFormIsDirty: (val: boolean) => void
}

type UserType = 'ADMIN' | 'REGULAR' | ''

export function useAlerts (serialNumber: string): UseAlertType {
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [selectedConfiguration, setSelectedConfiguration] = useState<AlertConfiguration | null>(null)
  const [userType, setUserType] = useState<UserType>('')
  const { currentDevice, deviceLoadingOrErrorComponent, responseCode } = useCurrentDevice(serialNumber)
  const [alertUsersList, setAlertUsersList] = useState<AlertUser[]>([])
  const [permissions, setPermissions] = useState<DevicePermissions | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  useEffect(() => {
    if (!currentDevice) {
      return
    }

    const devicePermissions: DevicePermissions | null = currentDevice.permissions ? currentDevice.permissions : null
    if (devicePermissions) {
      const currentUserType : UserType = devicePermissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE] === GRANTED
      ? devicePermissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_ADMIN] === GRANTED
        ? 'ADMIN'
        : 'REGULAR'
      : ''
      setUserType(currentUserType)
    }
    setPermissions(devicePermissions)
  }, [currentDevice])

  useEffect(() => {
    if (userType === '') {
      return
    }
    //  Get list of users for admin.
    if (userType === 'ADMIN') {
      getUsersList()
    } else {
      setSelectedUserId('self')
    }
  }, [userType])

  useEffect(() => {
    getConfiguration()
  }, [selectedUserId])

  const handleNewConfigInsert = (newEmail: string, configs: AlertConfiguration[]) => {
    if (configs.length === 0) {
      return
    }
    const matchedConfig = configs.filter((conf: AlertConfiguration) => conf.email === newEmail)[0]
    setAlertUsersList(configs)
    if (matchedConfig) {
      setSelectedUserId(configs.filter((conf: AlertConfiguration) => conf.email === newEmail)[0].id)
    }
    setError(false)
    setFormIsDirty(false)
    setSuccessMessage('New alert configuration has been added.')
  }

  const handleUpdateConfig = async (alertsConfig: AlertsObjectConfig) => {
    try {
      const alertUserResponse = await updateAlertConfigurationRequest(serialNumber, selectedUserId, (alertsConfig as unknown) as AlertAPIConfig)
      if (alertUserResponse.status >= 400) {
        throw new Error()
      }
      setError(false)
      setSuccessMessage('Alert configuration has been updated.')
      setFormIsDirty(false)
      return true
    } catch (e) {
      setAlertUsersList([])
      setError(true)
      return false
    } finally {
      setLoading(false)
    }
  }

  //  Only relevant for admin users.
  //  Gets the list of the users and selects the first item from the list.
  const getUsersList = async () => {
    try {
      setLoading(true)
      const usersList:any = await getUsersWithAlertConfigurationRequest(serialNumber)
      if (usersList?.status > 200 || usersList?.message) {
        setAlertUsersList([])
        throw new Error()
      }
      setAlertUsersList(usersList)

      if (usersList.length > 0) {
        setSelectedUserId(usersList[0].id)
      } else {
        setSelectedUserId('')
      }
      return true
    } catch (e) {
      setAlertUsersList([])
      setError(true)
      return false
    } finally {
      setLoading(false)
    }
  }

  const removeUser = async () => {
    if (!selectedUserId) {
      return
    }
    try {
      const removeUserResponse:any = await deleteUsersAlertsProfileRequest(serialNumber, selectedUserId)
      if (removeUserResponse?.status > 204 || removeUserResponse?.message) {
        setAlertUsersList([])
        throw new Error()
      }
      getUsersList()
      setError(false)
      setSuccessMessage('Alert configuration has been removed.')
      setFormIsDirty(false)
      return true
    } catch (e) {
      setAlertUsersList([])
      setError(true)
      return false
    }
  }

  const getConfiguration = async () => {
    if (!selectedUserId) {
      return
    }
    try {
      const configuration: AlertConfiguration | ErrorResponse = await getAlertConfigurationByUserRequest(serialNumber, selectedUserId)
      if (('status' in configuration && configuration?.status > 200) || ('message' in configuration && configuration?.message)) {
        setAlertUsersList([])
        throw new Error()
      }

      const currentConfig = configuration as AlertConfiguration

      //  MOCK CURRENTCONFIG
      /* const mockData = configuration as AlertConfiguration
      const currentConfig = { ...mockData, alerts: { ...mockData?.alerts, fmActivePort: { enabled: false } } } as AlertConfiguration
       */
      setSelectedConfiguration(currentConfig)
      if (userType === 'REGULAR') {
        setAlertUsersList([{ id: currentConfig.id, email: currentConfig.email }])
      }
      return true
    } catch (e) {
      setSelectedConfiguration(null)
      setAlertUsersList([])
      setError(true)
      return false
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (+responseCode >= 200) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCode])

  return {
    loading,
    currentDevice,
    error,
    deviceLoadingOrErrorComponent,
    permissions,
    userType,
    alertUsersList,
    getUsersList,
    selectedUserId,
    setSelectedUserId,
    handleNewConfigInsert,
    removeUser,
    selectedConfiguration,
    handleUpdateConfig,
    successMessage,
    formIsDirty,
    setFormIsDirty
  }
}
