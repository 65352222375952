// @flow

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuth0 } from 'utils/react_auth0_spa'
import { integrationTestRunning } from 'utils/helpers'

import NavBarList from './navBarList'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'

type Props = {
  dialog: {
    isDialogOpen: boolean
  },
  toggleDialog: () => void
}

export default function CollapseNavBarModal (props: Props) {
  const { loading, isAuthenticated, logout } = useAuth0()
  const { isDialogOpen } = props.dialog

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up(991))

  if ((!isAuthenticated || loading) && !integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return null
  }

  if (matches) {
    return null
  }

  return (
    <div
      className={ `modal-link-menu fade ${isDialogOpen ? 'show show-modal' : 'hide-modal'}` }
      id='navbarTogglerDemo01' tabIndex={-1} role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'
    >
      <div className='modal-dialog-menu' role='document'>
        <div className='modal-content-menu'>
          <div className='modal-body-menu'>
            <div className='navbar-nav navbar-nav-modal'>
              <NavBarList
                className={ 'navbar-nav' } LinkClassName={ 'nav-link nav-link-modal' }
                toggleDialog={ () => props.toggleDialog() }
              />
              <p>
              <a className='' href="#logout" onClick={ () => logout() }><FontAwesomeIcon icon={['fas', 'sign-out']} />&nbsp;SIGN OUT</a>
            </p>
            </div>
          </div>
         </div>
      </div>
    </div>
  )
}
