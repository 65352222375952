import styled from 'styled-components'

export const SimpleButton = styled.button`
  background: none;
  border: none;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #979797;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid #4b4b4b;
    filter: brightness(0.8);
  }
`
