import { SERVER_NOT_AVAILABLE_ERROR } from 'appConstants'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

interface IUserOnlineStatus {
  isOnline: boolean,
  isServerAvailable: boolean,
  unavailable: boolean
}

export function useOnlineStatus (): IUserOnlineStatus {
  const [isOnline, setIsOnline] = useState<boolean>(true)
  const isServerAvailable = useSelector((state: any) => !state?.errors[SERVER_NOT_AVAILABLE_ERROR])

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return { isOnline, isServerAvailable, unavailable: !isOnline || !isServerAvailable }
}
