import * as C from 'appConstants'

const alertPageDialogInitialState = {
  pageDialog: {
    step: 1,
    requestErrorMessage: '',
    alertConfigSavedSuccess: false,
    alertProfilesConfigSavedSuccess: false,
    loadingRequest: false,
    contentLoaded: false,
    deviceOfflineErrorMessage: ''
  }
}

const initialState = {
  alertEmailsForm: {
    emails: []
  },
  alertsProfiles: [],
  ...alertPageDialogInitialState
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function populateDeviceOfflineAlert (state: any, payload?: any) {
  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      deviceOfflineErrorMessage: C.DEVICE_OFFLINE_ERROR_MESSAGE
    }
  }
}

export default function (state:any = initialState, action: any) {
  const { type, payload } = action

  switch (type) {
    case C.DEVICE_OFFLINE_ERROR:
      return populateDeviceOfflineAlert(state, payload)
    default:
      return state
  }
}
