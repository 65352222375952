// @flow

import moment from 'moment'

type Props = {
  date: string
}

export default function detailClaimDateIndicator (props: Props) {
  if (!props.date) {
    return null
  }

  return (
    <>
      Added: { moment(props.date).format('DD.MM.yyyy') }
    </>
  )
}
