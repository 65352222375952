// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const claimsEndpoint = `${backendEndpoint || ''}/claims`

export const createClaimRequest = (serialNumber: string) => executeRequest(
  `${claimsEndpoint}/${serialNumber}`, undefined, undefined, { method: 'POST' }
)

export const updateClaimRequest = (serialNumber: string, password: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ password: password })
  }

  return executeRequest(`${claimsEndpoint}/${serialNumber}`, undefined, undefined, options)
}

export const deleteClaimRequest = (serialNumber: number) => executeRequest(`${claimsEndpoint}/${serialNumber}`, undefined, null, { method: 'DELETE' })
