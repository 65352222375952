// @flow

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Input, Dialog, Text, Gap, OutlineButton } from 'components/ReUsable'
import { useFormik } from 'formik'
import { emailRegex, customDebounce } from 'utils/helpers'
import { performIdentityCheck } from 'apis/groups'
import SelectElement from 'components/ReUsable/SelectElement'
import { StyledButtonPanel } from '../Device'

const GroupDetailsSection = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  & > div {
    @media (max-width: 991px) {
      flex-direction: row;
    }
    :first-child {
      margin-top: 1em;
    }
    :not(:first-child) {
      & > div {
        :first-child {
          flex: 20%;
          flex-grow: 0.5;
          justify-content: center;
        }
      }
    }
  }
`
const GroupManagementDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 4em;
  @media (max-width: 991px) {
    gap: 1em;
    flex-direction: column;
  }
`
const GroupManagementContainer = styled.div`
  flex-direction: column;
  flex: 1;
`
const GroupUserDetailsSection = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  & .error,.warn {
    margin-top: -22px;
    font-size: 12px;
    color: rgb(251, 99, 109);
    font-family: open-sans-regular;
    letter-spacing: 0.14px;
  }
  & .warn {
    color: var(--color-alert-warning);
  }
  & div.m-add-user {
        border-bottom: none;
        gap: 0;
        div:first-child {
            flex-basis: 0;
        }
        :has(.remove) {
            border-bottom: 1px solid lightgray;
        }
    }
  }
  & >div {
    gap: 1rem;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    @media (max-width: 991px) {
        border-bottom: 1px solid lightgray;
        padding-bottom: 2em;
        :last-child {
            border-bottom: none;
        }
    }
    :first-child {
        margin-top: 1em;
    }
    & >div {
        :first-child {
            flex-basis: 40%;
            overflow: hidden;
        }
        :nth-child(2) {
            flex-basis: 40%;
            & select:focus {
                box-shadow: none;
            }
        }
        :nth-child(3) {
            flex-basis: 20%;
            align-items: flex-end;
            & button:focus {
                box-shadow: none;
            }
        }
        & >span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
  }
`
const StyledRemoveButton = styled(Button)`
  min-width: 1rem;
  min-height: 2.5rem;
  background-color: #ffff;
  border: 0.5px solid #cfcfcf;
  &:hover {
    border: 0.5px solid var(--color-alert-error);
    background-color: var(--color-alert-error);
  }
`
const ErrorSpan = styled.span`
  font-size: 12px;
  color: rgb(251, 99, 109);
  font-family: open-sans-regular;
  letter-spacing: 0.14px;
  @media (max-width: 991px) {
    margin-top: 0 !important;
  }
`
const StyledSaveButton = styled(Col)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & span {
    font-size: 16px;
    color: var(--color-primary);
    font-family: open-sans-regular;
  }
  & .error {
    color: var(--color-alert-error);
  }
`
const StyledMRow = styled(Row)`
  flex-direction: row;
  & div:first-child {
    flex: 10%;
  }
  & .remove {
    flex-direction: row;
  }
`
const StyledAddUser = styled(Row)`
  @media (max-width: 991px) {
    align-items: flex-start !important;
  }
`
const StyledCancelAddNewGroupCol = styled(Col)`
  align-self: flex-end;
  text-decoration: underline;
  & span {
    cursor: pointer;
  }
`
const StyledGroupActionRow = styled(Row)`
  @media (max-width: 991px) {
    flex-direction: row;
  }
`

const StyledRequiredFieldIndicator = styled(Text)`
  position: absolute;
  bottom: -8px;
  left: 0;
  margin-bottom: 0;
  font-size: 13px;
`

const StyledHighColumn = styled(Col)`
  position: relative;
  min-height: 48px;

  @media (min-width: 991.97px) {
    min-height: 60px;
  }
`

const StyledInviteCol = styled(Col)`
  color: #979797;
`

const StyledDeleteIcon = styled.img`
  width: 1rem;
  height: auto;
`

const StyledDeleteButton = styled.button<{ visibility: boolean }>`
  display: ${(props: { visibility: boolean }) => (props.visibility ? 'block' : 'none')};
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: var(--color-alert-error);

    & > img {
      filter: brightness(0) invert(1);
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
`

const StyledRemoveMenu = styled.div<{ visibility: boolean }>`
  display: ${(props: { visibility: boolean }) => (props.visibility ? 'flex' : 'none')};
  align-items: center;
  min-height: 2.5rem;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0 !important;

  & > p {
    margin-bottom: 0;
    font-family: var(--bs-body-font-family);
  }
`

const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
  margin: 0 0.5rem;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`

const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-alert-error);
  cursor: pointer;
  font-size: 18px;
  margin-right: 0.5rem;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`

type Props = {
  group: any
  deleteUserGroupMembership: ({ slug, email }: { slug: string; email: string }) => void
  updateUserGroup: ({ data, slug }: { data: any; slug: string }) => void
  createUserGroups: (group: any) => void
  mobileView: boolean
  currentUser: any
  cancelAddGroup: () => void
  groupErrors: any
  updateSuccess: any
  togglePageDialog: () => void
  deleteUserGroupInvite: ({ slug, email }: { slug: string; email: string }) => void
}

const GroupManagementEditDetails = (props: Props) => {
  const { group, deleteUserGroupMembership, updateUserGroup, createUserGroups, mobileView, currentUser, cancelAddGroup, groupErrors, updateSuccess, deleteUserGroupInvite } = props
  const [addUser, setAddUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [enableSaveButton, setEnableSaveButton] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusText, setStatusText] = useState({ error: '', success: '' })

  const initialFieldValues = {
    name: group.name,
    company: group.company,
    slug: group.slug,
    address: group.address,
    memberships: group.memberships,
    newUsers: [],
    invitations: group.invitations,
    groupMemberToRemove: ''
  }
  const formik = useFormik({
    initialValues: initialFieldValues
  } as any)

  useEffect(() => {
    setLoading(false)
    setAddUser(false)
    formik.resetForm({ values: initialFieldValues })
    setStatusText({ error: '', success: '' })
  }, [group])

  useEffect(() => {
    setStatusText({ error: groupErrors.message, success: '' })
    setLoading(false)
  }, [groupErrors])

  useEffect(() => {
    if (updateSuccess) {
      setStatusText({ error: '', success: 'Changes saved successfully.' })
      setLoading(false)
    }
  }, [updateSuccess])

  useEffect(() => {
    const { name, company, address, slug } = formik.values

    if (name === '' || slug === '' || (slug && slug.length < 5)) {
      setEnableSaveButton(false)
      return
    }
    if (
      group.name === name &&
      group.company === company &&
      group.address === address &&
      group.slug === slug &&
      group.memberships &&
      group.memberships.every((membership: any, i: number) => membership.membershipType === (membership[i] && membership[i].membershipType))
    ) {
      setEnableSaveButton(false)
      if (formik.values.newUsers.length === 0 || formik.values.newUsers.every((newUser) => newUser.email.length === 0)) {
        return
      }
    } else {
      setEnableSaveButton(true)
    }

    if (formik.values.newUsers.length > 0 && formik.values.newUsers.some((newUser) => newUser.email.length > 0 && newUser.error.length > 0)) {
      setEnableSaveButton(false)
      return
    } else {
      setEnableSaveButton(true)
    }

    if (!formik.dirty) setEnableSaveButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, JSON.stringify(formik.values.newUsers), group])

  // when the user is invited but not yet accepted the invitation
  const handleDeleteInvitedMember = (slug, email) => {
    deleteUserGroupInvite({ slug: slug, email: email })
    formik?.values?.invitations &&
      formik.setFieldValue(
        'invitations',
        formik.values.invitations.filter((inv) => inv.email !== email)
      )
  }

  // when the user is already a member of the group
  const revokeMembershipAndDelete = (slug, email) => {
    deleteUserGroupMembership({ slug: slug, email: email })
    formik?.values?.invitations &&
      formik.setFieldValue(
        'invitations',
        formik.values.invitations.filter((inv) => inv.email !== email)
      )
  }

  const handleSave = () => {
    const newOrUpdatedGroupFields = {
      name: formik.values.name,
      company: formik.values.company,
      address: formik.values.address,
      slug: formik.values.slug,
      memberships: formik.values.memberships
        .map((membership) => ({ email: membership.user.email, membershipType: membership.membershipType }))
        .concat(formik.values.newUsers.map((user) => (user.email !== '' ? { email: user.email, membershipType: user.membershipType } : null)).filter((value) => value !== null))
    }

    setLoading(true)

    if (group.isNew) {
      createUserGroups(newOrUpdatedGroupFields)
      return
    }
    updateUserGroup({
      data: newOrUpdatedGroupFields,
      slug: group.slug
    })
  }

  const handleAddUser = (value: any) => {
    setAddUser(value)
    formik.values.newUsers.push({ email: '', membershipType: 'user', error: '', warn: '' })
    formik.setFieldValue('newUsers', formik.values.newUsers)
  }

  const updateFormikArrayValues = (value, field, property, index) => {
    const updatedMember = formik.values[field].map((member) => ({ ...member }))
    updatedMember[index][property] = value
    return updatedMember
  }

  //  Make async call without await. Async operation breaks the validation and does not store cursor position.
  //  Bugfix: 8479
  const asyncIdentityCheck = (email: string, validateNewUserEmail: any, index: number) => {
    performIdentityCheck(email).then((emailExists: boolean) => {
      const newVal = validateNewUserEmail
      newVal[index].warn = emailExists ? '' : 'The user is currently not registered, an invitation will be sent.'
      newVal[index].error = ''
      formik.setFieldError('newUsers', newVal)
    })
  }

  const debouncedIdentityCheck = customDebounce(asyncIdentityCheck, 600) // 600ms debounce time

  const handleNewUserEmailChange = (email, field, property, index) => {
    const validateNewUserEmail = updateFormikArrayValues(email, field, property, index)
    if (email === '') {
      validateNewUserEmail[index].error = ''
      validateNewUserEmail[index].warn = ''
      formik.setFieldValue('newUsers', validateNewUserEmail)
    } else if (!emailRegex.test(email)) {
      validateNewUserEmail[index].error = 'Invalid email'
      validateNewUserEmail[index].warn = ''
      formik.setFieldValue('newUsers', validateNewUserEmail)
    } else {
      formik.setFieldValue('newUsers', validateNewUserEmail)
      debouncedIdentityCheck(email, validateNewUserEmail, index)
    }
  }

  const handleRemoveNewUser = (index) => {
    formik.values.newUsers.splice(index, 1)
    formik.setFieldValue('newUsers', formik.values.newUsers)
  }

  const handleCancelAddGroup = () => {
    cancelAddGroup()
  }

  return (
    <>
      <GroupManagementContainer>
        <Col>
          <GroupManagementDetailsContainer>
            <GroupDetailsSection className="group-details">
              <Row>
                <StyledHighColumn>
                  <h4>Group Management</h4>
                  <StyledRequiredFieldIndicator>All fields marked with * are required.</StyledRequiredFieldIndicator>
                </StyledHighColumn>
              </Row>
              <Row alignItems="baseline">
                <Col>Group ID*</Col>
                <Col>
                  <Input
                    type="text"
                    name="group-slug"
                    value={formik.values.slug}
                    className={`form-control ${formik.getFieldMeta('slug').touched && formik.values.slug.length < 5 ? 'input-errored' : ''}`}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      if (/^[ a-zA-Z0-9-]*$/.test(e.currentTarget.value)) {
                        formik.setFieldValue('slug', e.currentTarget.value.toLowerCase().trim())
                      }
                      formik.getFieldHelpers('slug').setTouched(true)
                    }}
                  />
                  {formik.getFieldMeta('slug').touched && formik.values.slug.length < 5 ? (
                    <ErrorSpan className="error">Cannot be less than 5 characters (lowercase letters, numbers or dashes).</ErrorSpan>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>Description*</Col>
                <Col>
                  <Input
                    type="text"
                    name="group-name"
                    value={formik.values.name}
                    className={`form-control ${formik.values.name === '' && formik.getFieldMeta('name').touched ? 'input-errored' : ''}`}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      formik.setFieldValue('name', e.currentTarget.value)
                      formik.getFieldHelpers('name').setTouched(true)
                    }}
                  />
                  {formik.values.name === '' && formik.getFieldMeta('name').touched ? <ErrorSpan className="error">Description cannot be empty</ErrorSpan> : null}
                </Col>
              </Row>
              <Row>
                <Col>Company</Col>
                <Col>
                  <Input
                    type="text"
                    name="group-company"
                    value={formik.values.company}
                    className="form-control"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => formik.setFieldValue('company', e.currentTarget.value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col>Address</Col>
                <Col>
                  <Input
                    type="text"
                    name="address"
                    value={formik.values.address}
                    className="form-control"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => formik.setFieldValue('address', e.currentTarget.value)}
                  />
                </Col>
              </Row>
            </GroupDetailsSection>
            <GroupUserDetailsSection>
              {mobileView ? null : (
                <Row>
                  <StyledHighColumn alignItems="left">
                    <h4>User</h4>
                  </StyledHighColumn>
                  <StyledHighColumn alignItems="left">
                    <h4>Role</h4>
                  </StyledHighColumn>
                  <StyledHighColumn alignItems="center">
                    <h4>Remove</h4>
                  </StyledHighColumn>
                </Row>
              )}
              {formik.values.memberships &&
                formik.values.memberships.map((membership: any, i: number) =>
                  mobileView ? (
                    <Row key={i}>
                      <StyledMRow>
                        <Col>
                          <span>User</span>
                        </Col>
                        <Col>
                          <span>
                            {membership.user.firstName} {membership.user.lastName}
                          </span>
                          <span>{membership.user.email}</span>
                        </Col>
                      </StyledMRow>
                      <StyledMRow>
                        <Col>
                          <span>Role</span>
                        </Col>
                        <Col alignItems="left">
                          <SelectElement
                            disabled={currentUser.sub === membership.user.auth0Id}
                            name="userRole"
                            value={membership.membershipType}
                            onChange={(e) => formik.setFieldValue('memberships', updateFormikArrayValues(e.target.value, 'memberships', 'membershipType', i))}
                          >
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                          </SelectElement>
                        </Col>
                      </StyledMRow>
                      {currentUser.sub === membership.user.auth0Id ? null : (
                        <StyledMRow>
                          <Col>
                            <span>Remove</span>
                          </Col>
                          <Col alignItems="right" className="remove">
                            <StyledRemoveButton onClick={() => revokeMembershipAndDelete(group.slug, membership.user.email)}>
                              <img src={'/imgs/Remove_grey.svg'} alt="Remove user" />
                            </StyledRemoveButton>
                          </Col>
                        </StyledMRow>
                      )}
                    </Row>
                  ) : (
                    <Row key={i}>
                      <Col alignItems="left">
                        <span>
                          {membership.user.firstName} {membership.user.lastName}
                        </span>
                        <span>{membership.user.email}</span>
                      </Col>
                      <Col alignItems="left">
                        <SelectElement
                          disabled={currentUser.sub === membership.user.auth0Id}
                          name="userRole"
                          value={membership.membershipType}
                          onChange={(e) => formik.setFieldValue('memberships', updateFormikArrayValues(e.target.value, 'memberships', 'membershipType', i))}
                        >
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </SelectElement>
                      </Col>
                      <Col alignItems="right">
                        {currentUser.sub === membership.user.auth0Id ? null : (
                          <StyledRemoveButton onClick={() => revokeMembershipAndDelete(group.slug, membership.user.email)}>
                            <img src={'/imgs/Remove_grey.svg'} alt="Remove user" />
                          </StyledRemoveButton>
                        )}
                      </Col>
                    </Row>
                  )
                )}
              {formik?.values?.invitations?.length > 0
                ? formik?.values?.invitations.map((invitation: any, i: number) =>
                    mobileView ? (
                      <Row key={i}>
                        <StyledMRow>
                          <Col>
                            <span>User</span>
                          </Col>
                          <StyledInviteCol alignItems="left">
                            <span>{invitation.email}</span>
                            <span>{`Invitation sent at: ${moment(new Date(invitation.createdAt)).format('DD.MM.yyyy')}`}</span>
                          </StyledInviteCol>
                        </StyledMRow>
                        <StyledMRow>
                          <Col>
                            <span>Role</span>
                          </Col>
                          <Col alignItems="left">
                            <SelectElement disabled={true} name="userRole" value={invitation.membershipType}>
                              <option value="admin">Admin</option>
                              <option value="user">User</option>
                            </SelectElement>
                          </Col>
                        </StyledMRow>
                        <StyledMRow>
                          <Col>
                            <span>Remove</span>
                          </Col>
                          <Col alignItems="right" className="remove">
                            <StyledRemoveButton onClick={() => handleDeleteInvitedMember(group.slug, invitation.email)}>
                              <img src={'/imgs/Remove_grey.svg'} alt="Remove user" />
                            </StyledRemoveButton>
                          </Col>
                        </StyledMRow>
                      </Row>
                    ) : (
                      <Row key={i}>
                        <StyledInviteCol alignItems="left">
                          <span>{invitation.email}</span>
                          <span>{`Invitation sent at: ${moment(new Date(invitation.createdAt)).format('DD.MM.yyyy')}`}</span>
                        </StyledInviteCol>
                        <Col alignItems="left">
                          <SelectElement disabled={true} name="userRole" value={invitation.membershipType}>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                          </SelectElement>
                        </Col>
                        <Col alignItems="right">
                          <StyledDeleteButton visibility={formik.values.groupMemberToRemove !== invitation.email} onClick={() => formik.setFieldValue('groupMemberToRemove', invitation.email)}>
                            <StyledDeleteIcon alt="Remove group user" src="/imgs/remove-grey.svg" />
                          </StyledDeleteButton>
                          <StyledRemoveMenu visibility={formik.values.groupMemberToRemove === invitation.email}>
                            <p>Delete?</p>
                            <StyledPrimaryFontAwesomeIcon icon={['far', 'circle-check'] as any} onClick={() => handleDeleteInvitedMember(group.slug, invitation.email)} />
                            <StyledAlertFontAwesomeIcon
                              icon={['far', 'circle-xmark'] as any}
                              onClick={() => {
                                formik.setFieldValue('groupMemberToRemove', '')
                              }}
                            />
                          </StyledRemoveMenu>
                        </Col>
                      </Row>
                    )
                  )
                : null}
              {addUser ? (
                <>
                  {formik.values.newUsers.map((newUser: any, i: number) =>
                    mobileView ? (
                      <React.Fragment key={`newusers-${i}`}>
                        <StyledMRow className="m-add-user pb-0">
                          <Col>
                            <span>User</span>
                          </Col>
                          <Col>
                            <Input
                              type="text"
                              placeholder="User Email"
                              value={newUser.email}
                              className={`form-control ${newUser.error ? 'input-errored' : ''}`}
                              onChange={(e) => handleNewUserEmailChange(e.target.value, 'newUsers', 'email', i)}
                              autoFocus={true}
                            />
                            <ErrorSpan className={newUser.error ? 'error' : 'warn'}>{newUser.error ? newUser.error : newUser.warn}</ErrorSpan>
                          </Col>
                          {/* <Col className='error'>{newUser.error}</Col> */}
                        </StyledMRow>
                        <StyledMRow className="m-add-user pb-0">
                          <Col>
                            <span>Role</span>
                          </Col>
                          <Col>
                            <SelectElement
                              name="newUserMemberType"
                              value={newUser.membershipType}
                              onChange={(e) => formik.setFieldValue('newUsers', updateFormikArrayValues(e.target.value, 'newUsers', 'membershipType', i))}
                            >
                              <option value="user">User</option>
                              <option value="admin">Admin</option>
                            </SelectElement>
                          </Col>
                        </StyledMRow>
                        <StyledMRow className="m-add-user">
                          <Col>
                            <span>Remove</span>
                          </Col>
                          <Col className="remove">
                            <StyledRemoveButton onClick={() => handleRemoveNewUser(i)}>
                              <img src={'/imgs/Remove_grey.svg'} alt="Remove user" />
                            </StyledRemoveButton>
                          </Col>
                        </StyledMRow>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={i}>
                        <Row className="add-user-row">
                          <Col alignItems="left">
                            <Input
                              type="text"
                              placeholder="User Email"
                              value={newUser.email}
                              className={`form-control ${newUser.error ? 'input-errored' : ''}`}
                              onChange={(e: any) => handleNewUserEmailChange(e.target.value, 'newUsers', 'email', i)}
                              autoFocus={true}
                            />
                          </Col>
                          <Col alignItems="left">
                            <SelectElement
                              name="newUserMemberType"
                              value={newUser.membershipType}
                              onChange={(e) => formik.setFieldValue('newUsers', updateFormikArrayValues(e.target.value, 'newUsers', 'membershipType', i))}
                            >
                              <option value="user">User</option>
                              <option value="admin">Admin</option>
                            </SelectElement>
                          </Col>
                          <Col alignItems="right">
                            <StyledRemoveButton onClick={() => handleRemoveNewUser(i)}>
                              <img src={'/imgs/Remove_grey.svg'} alt="Remove user" />
                            </StyledRemoveButton>
                          </Col>
                        </Row>

                        <Row className={newUser.error ? 'error' : 'warn'}>
                          <Col>{newUser.error ? newUser.error : newUser.warn}</Col>
                        </Row>
                      </React.Fragment>
                    )
                  )}
                </>
              ) : null}
              <StyledAddUser>
                <Col alignItems="flex-start">
                  {
                    <span style={{ color: 'green', cursor: 'pointer' }} onClick={() => handleAddUser(true)}>
                      <FontAwesomeIcon icon={['far', 'circle-plus'] as any} />
                      &nbsp;&nbsp;&nbsp;Add User
                    </span>
                  }
                </Col>
              </StyledAddUser>
            </GroupUserDetailsSection>
          </GroupManagementDetailsContainer>
        </Col>
        <Col>
          <StyledGroupActionRow>
            {group.isNew ? (
              <StyledCancelAddNewGroupCol>
                <span onClick={() => handleCancelAddGroup()}>Cancel</span>
              </StyledCancelAddNewGroupCol>
            ) : null}

            <StyledSaveButton>
              {statusText.error ? <span className="error">{statusText.error}</span> : statusText.success ? <span className="success">{statusText.success}</span> : null}

              <Gap></Gap>
              <Button loading={loading} disabled={loading || !enableSaveButton} type="submit" onClick={handleSave}>
                Save
              </Button>
            </StyledSaveButton>
          </StyledGroupActionRow>
        </Col>
      </GroupManagementContainer>
    </>
  )
}

export default GroupManagementEditDetails
