// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const cartridgesEndpoint = `${backendEndpoint || ''}/ports`

export const getCartridgesRequest = (serialNumber: string) => executeRequest(`${cartridgesEndpoint}/${serialNumber}`)

export const updateCartridgeRequest = (payload: any) => {
  const portNumber = payload.cartridge.portNumber

  // unwanted fields in the body request
  delete payload.cartridge.absoluteFuelLevel
  delete payload.cartridge.timestamp
  delete payload.cartridge.portNumber

  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(payload.cartridge)
  }

  return executeRequest(`${cartridgesEndpoint}/${payload.deviceSerialNumber}/${portNumber}`, undefined, undefined, options)
}
