// @flow

import styled from 'styled-components'
const ButtonBar = styled.div<{ relative?: boolean; desktopOnly?: boolean; block?: boolean }>`
  display: flex;
  gap: 1rem;

  @media (min-width: 991.98px) {
    position: ${(props: any) => (props.relative ? 'relative' : props.block ? 'block' : 'absolute')};
    bottom: ${(props: any) => (props.relative ? '0' : '1rem')};
    right: ${(props: any) => (props.relative ? '0' : '1rem')};
  }

  @media (max-width: 991.97px) {
    display: ${(props: any) => (props.desktopOnly ? 'none' : 'flex')};
    margin-top: 4rem;
    & > button {
      min-width: calc(50% - 0.5rem);
    }
  }
`

export default ButtonBar
