import {
  createContext,
  useContext,
  ReactNode
} from 'react'
import useAdminNotifications, {
  APIError,
  AdminNotificationType,
  PageMode
} from '../hooks/useAdminNotifications'
import { NotificationFormType } from 'apis/notifications'

type NotificationContextType = {
  notifications: AdminNotificationType[] | null
  addNotification: (notification: NotificationFormType) => void
  editNotification: (id: string, notification: NotificationFormType) => void
  getNotification: (id: string) => Promise<AdminNotificationType | undefined>
  deleteNotification: (id: string) => void
  mode: PageMode
  setMode: React.Dispatch<React.SetStateAction<PageMode>>
  selectedNotification: string | null
  setSelectedNotification: React.Dispatch<React.SetStateAction<string | null>>
  error: APIError | null
  allowWrite: boolean
  allowRead: boolean
}

// Create a context with an undefined default value.
const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

// Create a custom hook to use the NotificationContext
export function useNotificationContext () {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotificationContext must be used within a NotificationContextProvider'
    )
  }
  return context
}

// Create a NotificationContext provider with children
interface NotificationContextProviderProps {
  children: ReactNode
}

export const NotificationContextProvider = ({
  children
}: NotificationContextProviderProps) => {
  const adminNotificationProps = useAdminNotifications()

  return (
    <NotificationContext.Provider
      value={adminNotificationProps}
    >
      {children}
    </NotificationContext.Provider>
  )
}
