// @flow

import { Row, Col } from 'components/ReUsable'
import styled from 'styled-components'

const HeaderRow = styled(Row)`
  font-family: digital-serial;
  font-weight: bold;
  height: 38px;
  background: #bebdbd;
  border: 1px solid #bebdbd;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 6px;
  @media (max-width: 991px) {
    display: none;
  }
`

const HeaderCol = styled(Col)`
  padding: 0 4px 0 20px;
  justify-content: center;
  @media (max-width: 991px) {
    padding-left:7px;
  }
  &:not(:last-child) {
    border-right: 1px solid var(--color-background);
  }
`

export const ManageDelegationHeader = function () {
  return (
    <HeaderRow data-cy="manage-delegation-header">
      <HeaderCol base="26.5%">
        Name
      </HeaderCol>
      <HeaderCol base="25.5%">
        Email Address
      </HeaderCol>
      <HeaderCol base="36.5%">
        Role
      </HeaderCol>
      <HeaderCol base="11.5%">
        Decoupling
      </HeaderCol>
    </HeaderRow>
  )
}

export default ManageDelegationHeader
