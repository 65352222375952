// @flow

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  sorting: any,
  sortKey?: string
}

export default function SortingArrow (props: Props) {
  const { sortKey, sorting } = props

  if (!sorting) {
    return null
  }

  if (sortKey !== sorting.sortKey) {
    return <FontAwesomeIcon icon={['fal', 'sort']} />
  }

  return sorting.sortDirection === 'desc' ? <FontAwesomeIcon icon={['fas', 'sort-down']} /> : <FontAwesomeIcon icon={['fas', 'sort-up']} />
}
