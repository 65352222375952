import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import styled from 'styled-components'

import CommonHeadingCell from 'components/Application/CommonTable/commonHeadingCell'

const StyledLink = styled.a`
  color: #000;
  text-decoration: underline;
`

export const defineColumns = function () {
    const columnHelper = createColumnHelper()

    return [
      columnHelper.accessor((row: any) => row.timestamp, {
        id: 'timestamp',
        header: (info: any) => <CommonHeadingCell
            value='Date'
            id={info.id}
        />,
        cell: (info: any) => <td style={{ width: '15%' }}>{moment(info.renderValue()).format('YYYY-MM-DD HH:mm')}</td>
    }),
    columnHelper.accessor((row: any) => row.createdBy, {
      id: 'createdBy',
      header: (info: any) => <CommonHeadingCell
          value='User'
          id={info.id}
      />,
      cell: (info: any) => <td style={{ width: '15%' }}>{info.renderValue()}</td>
    }),
      columnHelper.accessor((row: any) => row.eventType, {
        id: 'eventType',
        header: (info: any) => <CommonHeadingCell
            value='Event type'
            id={info.id}
        />,
        cell: (info: any) => <td style={{ width: '15%' }}>{info.renderValue()}</td>
    }),
      columnHelper.accessor((row: any) => row.description, {
        id: 'description',
        header: (info: any) => <CommonHeadingCell
            value='Description'
            id={info.id}
        />,
        cell: (info: any) => <td style={{ width: '30%' }}>{info.renderValue()}</td>
    }),
      columnHelper.accessor((row: any) => row, {
        id: 'device',
        header: (info: any) => <CommonHeadingCell
            value='Device/Group'
            id={info.id}
        />,
        cell: (info: any) => {
          const { device, groupSlug } = info.row.original
          const renderElem = device ? <StyledLink data-cy="device-detail-link" href={`/devices/${device}`} target="_blank">{device}</StyledLink> : <>{groupSlug as unknown}</>
          return <td style={{ width: '25%' }}>{renderElem}</td>
        }
    })
    ]
}
