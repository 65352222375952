import { SERVER_NOT_AVAILABLE_MESSAGE, USER_OFFLINE_MESSAGE } from 'appConstants'
import { AppUnavailable } from 'components/ReUsable'
import { useOnlineStatus } from './useOnlineStatus'

function AvailabilityCheck ({ children }: { children: React.ReactNode }) {
  const { isOnline, isServerAvailable } = useOnlineStatus()

  if (!isOnline || !isServerAvailable) {
    return <AppUnavailable pageTitle={!isOnline ? USER_OFFLINE_MESSAGE : SERVER_NOT_AVAILABLE_MESSAGE}/>
  }

  return <>{children}</>
}

export default AvailabilityCheck
