// @flow
import SelectElement from 'components/ReUsable/SelectElement'
import { useSelector } from 'react-redux'
import { isNotNullAndHasKeys } from 'utils/helpers'

const typeNames = {
  no_battery: 'No Battery',
  lead_acid_12v: 'Lead Acid 12V',
  lead_acid_24v: 'Lead Acid 24V',
  lifepo4_12v: 'LiFePO4 12V',
  lifepo4_24v: 'LiFePO4 24V',
  efoy_battery: 'EFOY Battery'
}

export const mapTypesToNames = (key:string) => {
  return typeNames[key]
}
interface IBatteryTypeSectionProps {
  formik: any,
  isDeviceInChargingState: boolean,
  onFormEdited: () => void
}

const BatteryTypesItems = ({ types }: { types: string[] }) => {
  return (
    <>
      {types.map((item: string, index: number) => (
        <option key={ index } value={ item }>
          { mapTypesToNames(item) }
        </option>
      ))}
    </>
  )
}

export default function BatteryTypeSection ({ formik, isDeviceInChargingState, onFormEdited } : IBatteryTypeSectionProps) {
  const limitsObj = useSelector((state: any) => state?.battery?.limits)
  const errorResult = <p>Not able to retrieve battery type data.</p>

  //  We receive 403 from limits backend.
  //  Display only Type dropdown with disabled.
  if (isDeviceInChargingState) {
    return (
      <SelectElement
        data-cy="battery-type-selector"
        value={formik.values.type}
        disabled={isDeviceInChargingState}>
          <BatteryTypesItems types={[formik.values.type]}/>
        </SelectElement>
    )
  }

  if (isNotNullAndHasKeys(limitsObj)) {
    const types = Object.keys(limitsObj)
    const handleChange = (event: any) => {
      formik.resetForm()
      formik.setFieldValue('type', event.target.value)
      onFormEdited()
    }

    if (formik.values.type && types.length > 0) {
      return (
        <SelectElement
          value={formik.values.type}
          onChange={ handleChange }
          disabled={isDeviceInChargingState}
          width="150px"
          data-cy="battery-type-selector">
            <BatteryTypesItems types={types}/>
          </SelectElement>
      )
    }
  }
  return errorResult
}
