import styled from 'styled-components'

export default styled.div`
margin: 0 auto;
padding: 2rem;
display: flex;
flex-direction: column;
align-items: left;
background-color: #ffffff;
width: 100%;
border-radius: 3px 3px 0px 0px;
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
`
