//  @flow

import { useEffect } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import {
  useLocation
} from 'react-router-dom'

import Form from 'components/ReUsable/Form'
import FormErrors from 'components/ReUsable/FormErrors'
import { ALERT_TYPE, Alert } from 'components/ReUsable/Alert'

import {
  PageTitle,
  PageSubTitle,
  Text,
  Button
} from 'components/ReUsable'

import {
  sendContactSupportMessage
} from 'actions'

const TextHighlighted = styled.p`
  font-size: 12px;
  line-height: 22px;
  a {
    color: #212529;
    text-decoration: underline;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

type Props = {
  contact: {
    sent: boolean,
    error: string
  },
  sendContactSupportMessage: (payload: any) => void
}

const renderDeviceDetails = (label: string, value: string) => {
  return <div className="row">
    <div className="col-3 col-sm-3 mb-3">
      <Text>{label}</Text>
    </div>
    <div className="col-3 col-sm-3 mb-3">
      <Text>{value}</Text>
    </div>
  </div>
}

export function Contact (props: Props) {
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const deviceName = query.get('deviceName')
  const serialNumber = query.get('serialNumber')

  useEffect(() => {
    if (props.contact.sent) {
      formik.setFieldValue('message', '')
      formik.setFieldValue('privacy', false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact.sent])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmitWithValidation = (values: any, { setErrors, _ }: {setErrors: any, _: any}) => {
    const errors = { message: '', privacy: '' }

    if (!values.message) {
      errors.message = 'Message text required'
    }

    if (!values.privacy) {
      errors.privacy = 'Privacy policy required'
    }

    if (errors.message.length > 0 || errors.privacy.length > 0) {
      setErrors(errors)
      return
    }

    if (deviceName && serialNumber) {
      return props.sendContactSupportMessage({ ...values, deviceName: deviceName, serialNumber: serialNumber })
    }

    return props.sendContactSupportMessage(values)
  }

  const formik = useFormik(
    {
      initialValues: {
        message: '',
        privacy: false
      },
      onSubmit: onSubmitWithValidation as any
    }
  )

  return (
    <div>
      <div className="container mt-0 mt-sm-5 p-3 p-sm-0">
        <div className="bg-white px-3 pt-3 px-sm-5 pt-sm-5">
          <div className="row">
            <div className="col mb-3">
              <PageTitle>Support</PageTitle>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3">
              <PageSubTitle>Your direct contact</PageSubTitle>
              <PageSubTitle className="text-primary">+49 89 / 673 592-0</PageSubTitle>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Text>
                SFC Energy AG<br />
                Eugen-Sänger-Ring 7<br />
                85649 Brunnthal-Nord<br />
                +49 89 / 673 592-0
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3 p-sm-5 bg-white pb-sm-0 pb-0">
        <div className="row">
          <div className="col-6 mb-3">
            <PageSubTitle>Contact</PageSubTitle>
          </div>
        </div>
        {
          deviceName ? renderDeviceDetails('Device Name', deviceName) : null
        }
        {
          serialNumber ? renderDeviceDetails('Serial Number', serialNumber) : null
        }
        <Form handleSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col mb-3">
              <FormErrors
                errors={formik.errors}
                errorMessage={props.contact.error}
              />
              {props.contact.sent &&
                <Alert messages={['Message has been sent successfully']} type={ALERT_TYPE.success} />
              }
            </div>
          </div>
          <div className="row">
            <div className="col mb-3">
              <textarea name="message" className="form-control form-textarea w-100" placeholder="Your message...*" rows={10} onChange={formik.handleChange} value={formik.values.message} />
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-9">
              <TextHighlighted className="ml-0 ml-sm-3">
                Privacy policy *<br />
                <div style={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}>
                <input name="privacy" type="checkbox" onChange={formik.handleChange} value={formik.values.privacy as any} /> <label htmlFor="privacy">Yes, I have read the <a href="/gdpr" target="_blank">data protection declaration</a> and agree that the data provided by me may be collected and stored electronically. My data will be used strictly for the purpose of processing and responding to my request. With the submission of this form, I agree to the processing.</label>
                </div>
              </TextHighlighted>
            </div>
          </div>
          <div className="row">
            <div className="col mb-3">
              <StyledButtonContainer>
                <Button type='submit' className='btn-add-device mb-3'>
                  Send Form
                </Button>
              </StyledButtonContainer>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapState = (state: any) => (
  {
    contact: state.contact
  }
)

const mapDispatch = {
  sendContactSupportMessage
}

export default connect(
  mapState,
  mapDispatch
)(Contact)
