// @flow

import { BooleanConfig, LowerConfig } from 'components/Application/Alerts/alertTypes'
import {
  backendEndpointV2,
  executeRequest
} from '.'

const alertsEndpoint = (serialNumber: string) => `${backendEndpointV2 || ''}/devices/${serialNumber}/alerts`
const alertsByUserEndpoint = (serialNumber: string, userId: string) => `${backendEndpointV2 || ''}/devices/${serialNumber}/alerts/${userId}`

export type AlertUser = { id: string, email: string }

export type AlertConfigurationResponse = {
  id: string,
  email: string,
  alerts: AlertAPIConfig
}

export type AlertAPIConfig = {
  fuelLevel: LowerConfig,
  batteryVoltage: LowerConfig
  batterySOC: LowerConfig,
  connection: BooleanConfig,
  error: BooleanConfig,
  warning: BooleanConfig,
  firmwareVersion: BooleanConfig,
  fmActivePort: BooleanConfig,
  multiSense: MultiSenseAlert
}

export type MultiSenseAlert = {
  a1: MultiSensePortAlert,
  a2: MultiSensePortAlert,
  t1: MultiSensePortAlert,
  t2: MultiSensePortAlert,
  io1?: MultiSenseIOPortAlert,
  io2?: MultiSenseIOPortAlert,
  io3?: MultiSenseIOPortAlert,
  io4?: MultiSenseIOPortAlert
}

export type MultiSenseBoundary = {threshold: number, clear: number, enabled: boolean}

export type MultiSensePortAlert = {
  function: string,
  unit: string,
  lower: MultiSenseBoundary,
  upper: MultiSenseBoundary
}

export type MultiSenseIOPortAlert = {
  function: string,
  mode: string,
  enabled: boolean
}

//  List users with alert configuration on device.
export const getUsersWithAlertConfigurationRequest = (serialNumber: string) => executeRequest(alertsEndpoint(serialNumber))
//  export const getUsersWithAlertConfigurationRequest = (serialNumber: string) => Promise.resolve([{ id: '123123', email: 'test@test.me' }])

//  Creates new alert configuration for a user with the provided email address.
export const createAlertConfigurationRequest = (serialNumber: string, payload: {email: string}) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'POST',
    body: JSON.stringify(payload)
  }

  return executeRequest(alertsEndpoint(serialNumber), undefined, undefined, options)
}

//  Load the alert configuration of the specified user.
//  In order to receive current users configuration, provide 'self' as userId
export const getAlertConfigurationByUserRequest = (serialNumber: string, userId: string) => executeRequest(alertsByUserEndpoint(serialNumber, userId))

//  Update the alert configuration of the specified user.
//  In order to update current users configuration, provide 'self' as userId
export const updateAlertConfigurationRequest = (serialNumber: string, userId: string, alertsConfig: AlertAPIConfig) => {
  const alertsConfigNumbered = removeProps(convertStringNumbersInObject(alertsConfig), ['function', 'unit', 'direction'])

  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(alertsConfigNumbered)
  }

  return executeRequest(alertsByUserEndpoint(serialNumber, userId), undefined, undefined, options)
}

//  Delete any users alert configuration
//  In order to reset current users configuration, provide 'self' as userId
export const deleteUsersAlertsProfileRequest = (serialNumber: string, userId: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE'
  }

  return executeRequest(`${alertsByUserEndpoint(serialNumber, userId)}`, undefined, undefined, options)
}

function convertStringNumbersInObject (obj: any) {
  const newObj = { ...obj }

  for (const key in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      const value = newObj[key]
      // Check if the value is an object and not null, then recurse
      if (typeof value === 'object' && value !== null) {
        newObj[key] = convertStringNumbersInObject(value)
      } else {
        // Check for specific properties to convert
        if ((key === 'threshold' || key === 'clear') && typeof value === 'string') {
          newObj[key] = Number(value)
        }
      }
    }
  }
  return newObj
}

function removeProps (obj: any, propsToRemove: string[]) {
  const newObj = { ...obj }
  // Iterate through each property in the object
  for (const key in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      const value = newObj[key]
      // Check if the value is an object and not null, then recurse
      if (typeof value === 'object' && value !== null) {
        newObj[key] = removeProps(value, propsToRemove)
      }
      // If the key is one of the props to remove, delete it
      if (propsToRemove.includes(key)) {
        delete newObj[key]
      }
    }
  }
  return newObj
}
