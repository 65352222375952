// @flow

import styled from 'styled-components'

import { Col } from '../index'
import { isDeviceOffline } from 'helpers/devices'

type Props = {
  small?: boolean,
  isConnected: boolean,
  status: string
}

export const colorSelect = (isConnected: boolean, status: string) => {
  if (isDeviceOffline(isConnected)) {
    return 'var(--color-alert-inactive)'
  }

  switch (status) {
    case 'off':
    case 'standby':
    case 'in_operation':
    case 'frost_protection':
    case 'deep_discharge_protection'://  Battery Protection
    case 'transport_lock_procedure':
    case 'reset':
    case 'factory_defaults':
    case 'start_phase':
    case 'shut_down':
    case 'unknown':
    case 'pending':
    case 'can_update':
    case 'maintenance_circulation_pump':
    case 'maintenance_dosing_and_feeding_pump':
      return 'var(--color-primary)'
    case 'warning':
      return 'var(--color-alert-warning)'
    case 'error':
    case 'emergency_frost_protection'://  Error & Frost protection
    case 'transport_lock':
      return 'var(--color-alert-error)'
    case 'status_ideal':
      return 'var(--color-primary)'
    case 'attention_required':
      return 'var(--color-alert-warning)'
    case 'action_required':
      return 'var(--color-alert-error)'
    default:
      return 'var(--color-alert-inactive)'
  }
}

const IndicatorBox = styled.div`
  --dim: ${(props: Props) => props.small ? '0.9rem' : '1.2rem'};
  width: var(--dim);
  height: var(--dim);
  background: ${(props: Props) => colorSelect(props.isConnected, props.status)};
  border-radius: var(--dim-border-radius);
`

export default function statusIndicator (props: Props) {
  const { isConnected, small, status } = props

  return (
    <Col grow='0' base='1px' justifyContent='center'>
      <IndicatorBox status={status} isConnected={isConnected} small={small} data-cy="state-indicator"/>
    </Col>
  )
}
