// @flow

import { NavLink } from 'react-router-dom'

type Props = {
  LinkClassName: string,
  url: string,
  linkText: string,
  toggleDialog: () => void
  exact?: boolean
  isActive?: (match: any, location: any) => boolean
  dataCY?: string
}

export default function CustomNavLink (props: Props) {
  return (
    <li className='nav-item'>
      <NavLink isActive={props.isActive}
        exact={ props.exact } className={ props.LinkClassName }
        activeClassName='active' to={ props.url } onClick={ () => props.toggleDialog() }
        data-cy={props.dataCY}
      >
        { props.linkText }
      </NavLink>
    </li>
  )
}
