import {
  GENERIC_ERROR,
  SERVER_NOT_AVAILABLE_ERROR,
  TOGGLE_DIALOG,
  TOGGLE_PAGE_DIALOG,
  POPULATE_ERROR,
  CLEAR_ERRORS
} from 'appConstants'
import { isServerNotAvailable } from 'helpers/errorDetection'
import { trackModalOpen } from 'matomo'

export const genericError = (payload: unknown) => {
  //  API is not available
  if (isServerNotAvailable(payload)) {
    return (
      {
        type: SERVER_NOT_AVAILABLE_ERROR,
        payload: { error: payload }
      }
    )
  }
  return (
    {
      type: GENERIC_ERROR,
      payload: { error: payload }
    }
  )
}

export const toggleDialog = () => (
  {
    type: TOGGLE_DIALOG
  }
)

export const togglePageDialog = (payload?: {dialogId?: string, dialogTitle?: string, dialogContent?: string, dialogContentValue?: string}) => {
  if (payload?.dialogId) {
    trackModalOpen(payload?.dialogId)
  }
  return (
    {
      type: TOGGLE_PAGE_DIALOG,
      payload: { ...payload }
    }
  )
}

export const populateError = (payload: { operation: string, code: number, message: string }) => (
  {
    type: POPULATE_ERROR,
    payload
  }
)

export const clearErrors = () => (
  {
    type: CLEAR_ERRORS
  }
)
