// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const batteriesEndpoint = `${backendEndpoint || ''}/batteries`

export const getBatteryRequest = (serialNumber: string) => executeRequest(`${batteriesEndpoint}/${serialNumber}`)

export const saveBatteryConfigRequest = (payload: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(payload.batteryFieldsValues)
  }

  return executeRequest(`${batteriesEndpoint}/${payload.serialNumber}`, undefined, undefined, options)
}

export const getBatteryTypeRequest = (serialNumber: string) => executeRequest(`${batteriesEndpoint}/${serialNumber}/types`)

export const getBatteryLimitsRequest = (serialNumber: string) => executeRequest(`${batteriesEndpoint}/${serialNumber}/limits`)
