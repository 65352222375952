import { validateLicenseKey } from 'components/Application/Device/Dialog/ActivateLicenseKey/activateLicenseKeyDialog'

// Validate the license key
const isLicenseKeyValid = (key: string) => validateLicenseKey(key) === 1
// check for duplicates
export const checkDuplicates = (arr: string[]) => new Set(arr).size !== arr.length

export const checkLicenses = (licenses: string) => {
  // Split the licenses by new line or by commas or semi colons
  const licenseArray = licenses
    .split(/\n|,|;/)
    .map((license) => license.trim())
    .filter((license) => license !== '')
  // clean commas and spaces and semi colons from the Array
  const cleanArray = licenseArray.map((license) => license.replace(/,|;| /g, ''))
  const licenseRegex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/
  // example license P100-1234-1234-1234
  let valid = true
  cleanArray.forEach((license) => {
    if (!licenseRegex.test(license) || !isLicenseKeyValid(license)) {
      valid = false
    }
  })
  return valid
}

// based on error show new ErrorMessage
export const newError = (error: string) => {
  switch (error) {
    case 'Invalid request body':
      return 'Empty license key - please enter a valid license key'
    case 'License not found':
      return 'License not found'
    case 'License conflict':
      return 'Device has already a followup license'
    default:
      return 'Something went wrong, try again later'
  }
}
