export const REQUEST_GET_USER_ACCOUNT = 'REQUEST_GET_USER_ACCOUNT'
export const POPULATE_GET_USER_ACCOUNT = 'POPULATE_GET_USER_ACCOUNT'

export const REQUEST_UPDATE_USER_ACCOUNT = 'REQUEST_UPDATE_USER_ACCOUNT'
export const POPULATE_UPDATE_USER_ACCOUNT = 'POPULATE_UPDATE_USER_ACCOUNT'

export const REQUEST_DELETE_USER_ACCOUNT = 'REQUEST_DELETE_USER_ACCOUNT'
export const POPULATE_DELETE_USER_ACCOUNT = 'POPULATE_DELETE_USER_ACCOUNT'

export const REQUEST_UPDATE_USER_ACCOUNT_PASSWORD = 'REQUEST_UPDATE_USER_ACCOUNT_PASSWORD'
export const POPULATE_UPDATE_USER_ACCOUNT_PASSWORD = 'POPULATE_UPDATE_USER_ACCOUNT_PASSWORD'
