export const OFF = 'Off'
export const STANDBY = 'Standby'
export const CHARGING = 'Charging'
export const SHUTDOWN = 'Shutdown'
export const FROST_PROTECTION = 'Frost protection'
export const BATTERY_PROTECTION = 'Battery protection'
export const TRANSPORT_LOCK_PROCEDURE = 'Transport lock procedure'
export const TRANSPORT_LOCK = 'Transport lock'
export const RESET = 'Reset'
export const FACTORY_DEFAULTS = 'Factory defaults'
export const ERROR = 'Error'
export const ERROR_AND_FROST_PROTECTION = 'Error & Frost protection'
export const PENDING = 'Pending'
export const UPDATE_EFOY_ACCESSORIES = 'Update EFOY accessories'
export const WARNING = 'Warning'
export const START_PHASE = 'Start phase'
export const UNKNOWN = 'Unknown'

export const STATUS_IDEAL = 'Status ideal'
export const ATTENTION_REQUIRED = 'Attention required'
export const ACTION_REQUIRED = 'Action required'
export const STATUS_INACTIVE = 'Inactive'
