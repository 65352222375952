// @flow

import { Container, HeaderBar } from 'components/ReUsable'
import TagContent from './tagContent'
import { TagContextProvider } from './context/tagContext'

const Tags = () => {
  return (
    <main role="main">
        <Container data-cy="page-tags">
          <HeaderBar
            headerText="Device Tags"
            backlinkText="Back to Dashboard"
            backlinkUrl={'/'} />
            <TagContextProvider>
              <TagContent/>
            </TagContextProvider>
        </Container>
    </main>
  )
}

export default Tags
