// @flow

import { useState, useEffect } from 'react'
import { getAccessTokensRequest, deleteAccessTokenRequest, createAccessTokenRequest } from 'apis/accessToken'

import { ErrorResponse } from 'declarations/@types/alltypes'

export type AccessTokenType = {
  uuid: string,
  name: string,
  createdAt: string,
  expiresAt: string,
  token: string
}

export function useAccessToken () {
  const [tokens, setTokens] = useState<AccessTokenType[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<ErrorResponse | null>(null)
  //  Access tokens GET API call.
  const getAccessTokens = async () => {
    const tokensResponse: any = await getAccessTokensRequest()
    if (tokensResponse?.status) {
      handleErrorCase(tokensResponse)
    }
    setLoading(false)
    setSaving(false)
    setTokens(tokensResponse?.content)
    return true
  }

  //  Access tokens POST API call.
  const createToken = async (name: string, expiresAt: string) => {
    setSaving(true)
    const tokensResponse: any = await createAccessTokenRequest({ name, expiresAt })
    if (tokensResponse?.status >= 400) {
      handleErrorCase(tokensResponse)
    }
    await getAccessTokens()
    return tokensResponse?.token
  }

  //  Access tokens DELETE API call.
  const deleteToken = async (uuid: string) => {
    setSaving(true)
    const tokensResponse: any = await deleteAccessTokenRequest(uuid)
    if (tokensResponse?.status >= 400) {
      handleErrorCase(tokensResponse)
    }
    await getAccessTokens()
    return true
  }

  const handleErrorCase = (responseObj: any) => {
    try {
      setError(responseObj as ErrorResponse)
      throw new Error()
    } catch (e) {
      setTokens(null)
      return false
    } finally {
      setLoading(false)
      setSaving(false)
    }
  }

  useEffect(() => {
    getAccessTokens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* useEffect(() => {
    if (+responseCode >= 400) {
      setError("ERROR")
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCode]) */

  return {
    error,
    tokens,
    loading,
    saving,
    createToken,
    deleteToken
  }
}
