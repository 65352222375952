// @flow

import styled from 'styled-components'
import {
  Col,
  CardSectionTitle,
  CardSection,
  DataRow,
  DataRowTitle,
  DataRowValue,
  DataRowAdditionalValue,
  ShowDataValue,
  ShowDataValueRounded,
  ShowErrorLink,
  ShowTimeStamp,
  PermissionCheck,
  UpgradeLicenseButton,
  Row,
  Button,
  IconButton
} from 'components/ReUsable'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { getProperty } from 'utils/helpers'
import { DeviceProps } from '../Types/DeviceTypes'
import { secondsToHours } from 'date-fns'
import { AnimatedContainer } from 'components/ReUsable/Card'

type Props = {
  firmwareVersion: {
    major: string
    minor: string
    patch: string
  }
  fuelCellData: {
    activeError: string
    powerOutput: string
    voltageBattery: string
    voltageEfoy: string
    chargingCurrent: string
    efoyTemperature: string
    error: string
    lastError: string
    activeWarning: string
    lastWarning: string
    stackOperationTime: number
    operatingMode: string
  }
  batteryData: {
    batteryType: string
    averageChargingTimeManual: string
    averageChargingTimeAutomatic: string
    switchOnVoltage: string
    cutOffCurrent: string
    batteryCapacity: string
    switchOnDelay: string
    deepDischargeProtection: string
    cutOffTime: string
    minChargeTime: string
    maxStateOfCharge: string
    maxDepthOfDischarge: string
    switchOffVoltage: string
    voltageBattery: string
    chargingCurrent: string
  }
  latestTelemetry: {
    id: 4
    lastModifiedDate: string
    createdDate: string
    eventId: number
    timestamp: string
    outputVoltage: number
    stackVoltage: number
    peripheralVoltage: number
    outputCurrent: number
    stackCurrent: number
    peripheralCurrent: number
    stackTemperature: number
    heatExchangerTemperature: number
    methanolTemperature: number
    humidity: number
    dosingStrokeVolume: number
    fillLevel: number
    cartridgeFillLevel: number
    systemOperationTime: number
    stackOperationTime: number
    batteryVoltage: number
    ambientTemperature: number
    ambientPressure: number
    outputPower: number
  }
  togglePageDialog: () => void
  toggleTerminalPageDialog: () => void
  device: DeviceProps
  isConnected: boolean
  permissions: any
  isExpanded: boolean
}

const StyledFuelCellTitle = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTransportLockImg = styled.img`
  width: 90%;
  height: auto;
  margin-top: 1rem;
`

const efoyVoltageVisibleFor = [
  'in_operation',
  'shut_down',
  'frost_protection',
  'deep_discharge_protection',
  'transport_lock_procedure',
  'emergency_frost_protection'
]

export default function FuelCellSection (props: Props) {
  const { device, fuelCellData, batteryData, firmwareVersion, toggleTerminalPageDialog, togglePageDialog, isConnected, permissions, isExpanded } = props
  const { activeError, activeWarning, lastError, lastWarning } = fuelCellData || {}
  const deviceState = getProperty(device?.state) || {}
  const isTransportMode = Object.keys(deviceState).length > 0 && deviceState.state === 'transport_lock'
  const isDeviceVoltageVisible = Object.keys(deviceState).length > 0 && efoyVoltageVisibleFor.indexOf(deviceState.state) > -1
  return (
    <Col>
      <CardSectionTitle>
        <StyledFuelCellTitle>
          Fuel Cell
          <span>
            <PermissionCheck permission={DEVICE_DETAILS_PERMISSIONS_LIST.DIRECT_COMMAND} value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DIRECT_COMMAND]}>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => (isConnected ? toggleTerminalPageDialog() : null)} // eslint-disable-line @typescript-eslint/no-unused-vars
                src="monitor"
                disabled={!isConnected}
                alt="Monitor"
                dataCy="fuel-cell-monitor"
              />
            </PermissionCheck>
            <UpgradeLicenseButton
              dataCy={'edit-fuel-cell-section'}
              isConnected={isConnected as boolean}
              handleClick={() => togglePageDialog()}
              permissionNames={isTransportMode ? [DEVICE_DETAILS_PERMISSIONS_LIST.TLP_UNLOCK] : [DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE, DEVICE_DETAILS_PERMISSIONS_LIST.RESET]}
            />
          </span>
        </StyledFuelCellTitle>
      </CardSectionTitle>
      <CardSection>
        {isTransportMode ? (
          <DataRow>
            <DataRowTitle data-cy="transport-lock-row">Transport protection</DataRowTitle>
            <DataRowValue>
              <Row alignItems="center" data-cy="transport-lock-value">
                <Col alignItems="center">
                  <StyledTransportLockImg src={'/imgs/transport-lock.png'} alt="Transport-lock" />
                </Col>
                <Col>
                  <Button onClick={togglePageDialog}>Deactivate</Button>
                </Col>
              </Row>
            </DataRowValue>
          </DataRow>
        ) : null}
        <DataRow>
          <DataRowTitle>Operating mode</DataRowTitle>
          <DataRowValue>
            <ShowDataValue data={{ ...fuelCellData, operatingMode: fuelCellData?.operatingMode === 'off' ? 'SLEEP' : 'AUTO' }} property={'operatingMode'} />
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowTitle>Power output</DataRowTitle>
          <DataRowValue>
            <ShowDataValue showZero={true} data={fuelCellData} property={'powerOutput'} suffix={'W'} decimals={2} />
          </DataRowValue>
        </DataRow>
        <AnimatedContainer {...{ expanded: isExpanded }} data-cy="fuel-cell-animated-container">
          <DataRow>
            <DataRowTitle>Voltage at the battery</DataRowTitle>
            <DataRowValue>
              <ShowDataValueRounded data={batteryData} property={'voltageBattery'} places={1} suffix={'V'} decimals={1} />
            </DataRowValue>
          </DataRow>
          {isDeviceVoltageVisible ? (
            <DataRow>
              <DataRowTitle>Voltage at the EFOY</DataRowTitle>
              <DataRowValue>
                <ShowDataValueRounded data={fuelCellData} property={'voltageEfoy'} places={1} suffix={'V'} decimals={1} />
              </DataRowValue>
            </DataRow>
          ) : null}
          <DataRow>
            <DataRowTitle>Charging current</DataRowTitle>
            <DataRowValue>
              <ShowDataValueRounded data={batteryData} property={'chargingCurrent'} places={1} suffix={'A'} showZero />
            </DataRowValue>
          </DataRow>
          <DataRow>
            <DataRowTitle>EFOY temperature</DataRowTitle>
            <DataRowValue>
              <ShowDataValueRounded data={fuelCellData} property={'efoyTemperature'} places={1} suffix={'°C'} />
            </DataRowValue>
          </DataRow>
          <DataRow>
            <DataRowTitle>Operating hours</DataRowTitle>
            <DataRowValue>
              <ShowDataValueRounded data={{ ...fuelCellData, stackOperationTime: secondsToHours(fuelCellData?.stackOperationTime) }} property={'stackOperationTime'} suffix={'h'} />
            </DataRowValue>
          </DataRow>
          <DataRow data-cy="firmware-version">
            <DataRowTitle>Firmware version</DataRowTitle>
            <DataRowValue>
              {firmwareVersion ? <DataRowValue>{`${firmwareVersion.major}.${firmwareVersion.minor}.${firmwareVersion.patch}`}</DataRowValue> : <ShowDataValue data={undefined} />}
            </DataRowValue>
          </DataRow>
        </AnimatedContainer>
        <DataRow>
          <DataRowTitle>Active error</DataRowTitle>
          <DataRowValue>
            <ShowErrorLink data={device} errorCode={ShowDataValue({ data: activeError, properties: ['major', 'minor'], padStart: 3 })} />
          </DataRowValue>
          <DataRowAdditionalValue>
            <ShowTimeStamp data={activeError} property={'time'} />
          </DataRowAdditionalValue>
        </DataRow>
        <DataRow>
          <DataRowTitle>Last error</DataRowTitle>
          <DataRowValue>
            <ShowErrorLink data={device} errorCode={ShowDataValue({ data: lastError, properties: ['major', 'minor'], padStart: 3 })} />
          </DataRowValue>
          <DataRowAdditionalValue>
            <ShowTimeStamp data={lastError} property={'time'} />
          </DataRowAdditionalValue>
        </DataRow>
        <DataRow>
          <DataRowTitle>Active warning</DataRowTitle>
          <DataRowValue>
            <ShowErrorLink data={device} errorCode={ShowDataValue({ data: activeWarning, properties: ['major', 'minor'], padStart: 3 })} />
          </DataRowValue>
          <DataRowAdditionalValue>
            <ShowTimeStamp data={activeWarning} property={'time'} />
          </DataRowAdditionalValue>
        </DataRow>
        <DataRow>
          <DataRowTitle>Last warning</DataRowTitle>
          <DataRowValue>
            <ShowErrorLink data={device} errorCode={ShowDataValue({ data: lastWarning, properties: ['major', 'minor'], padStart: 3 })} />
          </DataRowValue>
          <DataRowAdditionalValue>
            <ShowTimeStamp data={lastWarning} property={'time'} />
          </DataRowAdditionalValue>
        </DataRow>
      </CardSection>
    </Col>
  )
}
