// @flow
import * as React from 'react'

type Props = {
  children: React.ReactNode,
  className?: string,
  id?: string,
  htmlFor?: string,
  for?: string

}

export default function Label (props: Props) {
  return (
    <label
      className={ `${props.className ? props.className : ''}` }
      id={ `${props.id ? props.id : ''}` }
      htmlFor={ props.for }
    >
      { props.children }
    </label>
  )
}
