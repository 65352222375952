import { useEffect, useState } from 'react'
import { useFormik, FormikErrors } from 'formik'
import styled from 'styled-components'
import moment from 'moment'

import {
  PageTitle,
  Row,
  Input,
  Button,
  Label,
  SelectElement
} from 'components/ReUsable'
import { generateNewLicenses, GenerateLicenseFormType } from 'apis/licenses'
import { checkPermissionFromAuth0, downloadCSVFromResponse } from 'utils/helpers'
import { SOMETHING_WENT_WRONG } from 'appConstants'
import { useAuth0 } from 'utils/react_auth0_spa'

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  min-width: 100%;

  @media (min-width: 991.98px) {
    & > div:first-child {
      flex: 1;
    }

    & > div:nth-child(2) {
      flex: 4;
    }
  }
`

const StyledLabelContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
`

const StyledInputContainer = styled.div`
  flex: 3;
`

const StyledButtonRow = styled(Row)`
  margin-top: 16px;
  min-width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`

const StyledErrorSpan = styled.span`
  color: red;
  font-size: 1rem;
  margin-right: 2rem;
`

const StyledContainer = styled.div`
  margin-top: 3rem;
`

function customValidation (values: GenerateLicenseFormType) {
  const errors: Partial<GenerateLicenseFormType> = {}
  if (!values.amount) {
    errors.amount = 'Required'
  } else if (+values.amount < 1 || +values.amount > 1000) {
    errors.amount = 'Please insert a value between 1 and 1000'
  }
  return errors
}

const initialValues:GenerateLicenseFormType = {
  amount: '1',
  tier: 'platinum',
  duration: 'one_year'
}

export default function GenerateLicense () {
  const { getTokenSilently } = useAuth0()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [hasPermissions, setHasPermissions] = useState<boolean>(false)

  const checkAuth0Permissions = async function () {
    const token = await getTokenSilently()
    const createLicense = await checkPermissionFromAuth0('licenses', /create/, token)
    setHasPermissions(createLicense)
  }
  useEffect(() => {
    checkAuth0Permissions()
}, [])

  const formik = useFormik<GenerateLicenseFormType>({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validate: (values: GenerateLicenseFormType) => {
      return customValidation(values)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: async (values: GenerateLicenseFormType) => {
      setErrorMessage('')
      const formValues = { ...values }
      const fileName = `licenses_${formValues.tier}_${formValues.duration}_${moment(new Date()).format('YYYY-MM-DDTHH-mm-ss')}`
      try {
        const response = await generateNewLicenses(formValues)
        if (!(response instanceof Blob)) {
          throw new Error()
        }
        await downloadCSVFromResponse(response, fileName)
      } catch (error) {
        setErrorMessage(SOMETHING_WENT_WRONG)
      }
    }
  })

  const formikErrors:FormikErrors<GenerateLicenseFormType> = formik.errors

  if (!hasPermissions) {
    return null
  }

  return (
    <StyledContainer>
      <PageTitle data-cy="page-header-generate-license">
        Generate License
      </PageTitle>
      <form id='generate-license-form' onSubmit={formik.handleSubmit}>
        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor="generate-form-amount">Amount*</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <Input
              id="generate-form-amount"
              className={`form-control ${
                formikErrors.amount && formik.touched.amount ? 'input-errored' : ''
              }`}
              name="amount"
              onChange={formik.handleChange}
              value={formik.values.amount}
              type="number"
            />
            {formikErrors.amount && formik.touched.amount ? (
              <div className='text-danger' data-cy="form-error-amount">{formikErrors.amount}</div>
            ) : null}
          </StyledInputContainer>
        </StyledRow>

        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='tier'>Tier*</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <SelectElement id="tier" width="100%" value={formik.values.tier} onChange={formik.handleChange}>
              <option value="platinum">platinum</option>
              <option value="basic">basic</option>
            </SelectElement>
          </StyledInputContainer>
        </StyledRow>

        <StyledRow>
          <StyledLabelContainer>
            <Label htmlFor='duration'>Duration*</Label>
          </StyledLabelContainer>
          <StyledInputContainer>
            <SelectElement id="duration" value={formik.values.duration} width="100%" onChange={formik.handleChange}>
              <option value="half_year">half_year</option>
              <option value="one_year">one_year</option>
            </SelectElement>
          </StyledInputContainer>
        </StyledRow>
        <Row>* Required</Row>
        <StyledButtonRow>
          { errorMessage.length > 0 ? <StyledErrorSpan>{errorMessage}</StyledErrorSpan> : null }
          <Button id="generate-submit-button" type="submit">Generate</Button>
        </StyledButtonRow>
      </form>
    </StyledContainer>
  )
}
