import { forwardRef, ForwardedRef } from 'react'

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const Input = forwardRef(({ name, className, id, type, accept, value, placeholder, onChange, ...rest }: InputProps, ref:ForwardedRef<HTMLInputElement>) => {
  return (
    <input
      name={ name }
      className={ `${className || ''}` }
      id={ `${id || ''}` }
      type={ type }
      accept={ accept }
      value={ value || '' }
      placeholder={ placeholder }
      onChange={ onChange }
      ref={ref}
      {...rest}
    />
  )
})

Input.displayName = 'Input'

export default Input
