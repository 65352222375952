// @flow

import {
    backendEndpoint,
    executeGroupApiRequest,
    executeRequest
} from '.'

const groupsEndpoint = `${backendEndpoint || ''}/groups`

export const deleteUserGroup = (slug: string) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'DELETE'
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${slug}`, undefined, undefined, options)
}

export const getUserGroups = (page: number) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'GET'
    }

    return executeGroupApiRequest(`${groupsEndpoint}?page=${page || 1}&size=25`, undefined, undefined, options)
}

export const createUserGroups = (payload) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'POST',
        body: JSON.stringify(payload)
    }

    return executeGroupApiRequest(groupsEndpoint, undefined, undefined, options)
}

export const getOneUserGroup = (groupSlug: string) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'GET'
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${groupSlug}`, undefined, undefined, options)
}

export const updateUserGroup = (payload, groupSlug) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'PUT',
        body: JSON.stringify(payload)
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${groupSlug}`, undefined, undefined, options)
}

export const leaveUserGroup = (groupSlug) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'PUT'
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${groupSlug}/leave-group`, undefined, undefined, options)
}

export const deleteUserGroupMembership = (groupSlug, email) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'DELETE'
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${groupSlug}/memberships/${email}`, undefined, undefined, options)
}

export const deleteUserGroupInvite = (groupSlug, email) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'DELETE'
    }

    return executeGroupApiRequest(`${groupsEndpoint}/${groupSlug}/invitations/${email}`, undefined, undefined, options)
}

export const performIdentityCheck = (payload: string) => {
    const options = {
        'Content-Type': 'application/json',
        method: 'GET'
    }

    return executeRequest(`${backendEndpoint}/identity-exists/${payload?.toLowerCase()}`, undefined, undefined, options)
}
