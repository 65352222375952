// @flow

import styled from 'styled-components'

const ContentText = styled.div`
  color: var(--color-text);
  font-size: 14px;
  font-family: open-sans-bold;
  font-weight: bold;
  line-height: 19px;
  margin-top: 8px;
`

const ContentTextSpan = styled.span`
  font-family: open-sans-regular;
  font-weight: regular;
`

type Props = {
    owner: {
      email: string,
      company: string,
      claimDate: string
    }
}

export default function OwnerDetails (props: Props) {
  if (!props.owner || Object.keys(props.owner).length === 0) {
    return null
  }

  const { email, company } = props.owner

  if (!email && !company) {
    return null
  }

  return (
    <ContentText data-cy="page-device-details-owner">
      { company && company.length ? company : null}
      { company && company.length && email && email.length ? <br/> : null }
      { email && email.length
        ? <><ContentTextSpan>{ email }</ContentTextSpan></>
        : null
      }
    </ContentText>
  )
}
