
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PageTitle, Container, Row, Col, Button, Gap } from '..'

const StyledPageNotReachable = styled(Row)`
    flex-direction: column;
`

export default function PageNotReachable ({ errorMessage }: { errorMessage?: string }) {
    const history = useHistory()
    return (
        <Container>
            <StyledPageNotReachable>
                <Gap width='5rem' ></Gap>
                <Col alignItems='center'>
                    <PageTitle style={{ textAlign: 'center' }} data-cy="page-not-found-title">
                        { errorMessage || '404 Page Not Found.' }
                    </PageTitle>
                </Col>
                <Gap width='3rem' className='d-none d-lg-table-cell'></Gap>
                <Col alignItems='center'>
                    <Button
                        type='button' onClick={() => history.push('/')}
                    >
                        <span>Go to EFOY fuel cells</span>
                    </Button>
                </Col>
            </StyledPageNotReachable>
        </Container>
    )
}
