// @flow

import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { addDays, startOfDay } from 'date-fns'
import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'

import {
  Container,
  HeaderBar,
  PaginateIt,
  CommonResponsiveTable,
  Text,
  Col,
  Row,
  PermissionCheck,
  Button,
  DatePicker
} from 'components/ReUsable'
import { getRowStyles } from './rowStyles'
import { useAudit } from './hooks/useAudit'
import { defineColumns } from './defineColumns'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

const StyledDatePickerRow = styled(Row)`
  width: 32rem;
  margin-bottom: 2rem;
  max-width: 80vw;
  @media (max-width: 992px) {
    margin-top: 2rem;
  }
  
`

const StyledShowAllButton = styled(Button) <{ show?: boolean }>`
  min-height: 31.5px;
  margin: 0;
  margin-left: 20px;
  min-width: 8rem;
  
  @media (max-width: 992px) {
    margin-left: 0;
    margin-top: 1rem;
    max-width: 4rem;
  }

  visibility: ${(props: any) => props.show ? 'visible' : 'hidden'}
`

const StyledTableContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  width: 100%;
`

const maxEndDate = startOfDay(addDays(new Date(), 1))

type Props = {
  match: any;
};

const AuditLogs = ({ match }: Props) => {
  const serialNumber = match.params.serialNumber
  const {
    getDeviceAuditsCall,
    audits,
    loading,
    currentDevice,
    currentPage,
    error,
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    deviceLoadingOrErrorComponent
  } = useAudit(serialNumber)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const table = useReactTable({
    data: audits?.content || [],
    columns: defineColumns(),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      getRowStyles
    }
  })

  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  if (deviceLoadingOrErrorComponent) {
    return deviceLoadingOrErrorComponent
  }

  const permissions = currentDevice ? currentDevice.permissions : {}

  const handleRangeDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  return (
    <main role="main">
      <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.AUDIT_READ]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.AUDIT_READ}
      >
        <Container data-cy="page-audit-logs">
          <HeaderBar
            headerText="Audit Log"
            backlinkText="Back to Fuel Cell"
            backlinkUrl={`/devices/${serialNumber}`}
            serialNumber={serialNumber}
            device={currentDevice}
          />
          <StyledDatePickerRow>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxEndDate}
              onChange={handleRangeDateChange}
              monthsShown={!matches ? 1 : 2}
              fixedHeight={true}
              showMonthDropdown={true}
              showYearDropdown={true}
              withPortal={!matches}
            />
            <Col style={{ alignItems: 'center', justifyContent: 'center', display: startDate || endDate ? 'flex' : 'none' }}>
              <Row>
                <StyledShowAllButton
                  onClick={() => {
                    setStartDate(undefined)
                    setEndDate(undefined)
                  }}
                  data-cy="audit-showall-button"
                  show={startDate || endDate}
                >
                  Show all
                </StyledShowAllButton>
              </Row>
            </Col>
          </StyledDatePickerRow>
          {audits && audits?.content?.length ? (
            <PaginateIt
              page={currentPage}
              totalPages={audits.totalPages}
              firstPage={audits.first}
              lastPage={audits.last}
              jumpToPage={(payload) => getDeviceAuditsCall(payload)}
              showLegend={false}
            >
              <StyledTableContainer data-cy="audit-table-container">
                <CommonResponsiveTable
                  theadLineClass={'table-dark-grey'}
                  table={table}
                />
              </StyledTableContainer>
            </PaginateIt>
          ) : (
            <StyledEmptyText data-cy="empty-audit-logs">
              {error
                ? 'An error occured. Please try again later.'
                : 'No audit entries yet.'}
            </StyledEmptyText>
          )}
        </Container>
      </PermissionCheck>
    </main>
  )
}

export default AuditLogs
