// @flow

import styled from 'styled-components'
import moment from 'moment'
import ClaimState from './detailClaimDateIndicator'

type LicenseProps = {
    tier: string;
    end?: Date;
}

type Props = {
    device: {
        deviceType: string,
        serviceLevel: string,
        serialNumber: string,
        license: LicenseProps,
        inclusiveLicenseAvailable: boolean
    },
    deviceOwner: {
        claimDate: string
    },
    isLicenseAddGranted: boolean;
    togglePageDialog: (obj: any) => void;
}

const StyledDeviceTypeText = styled.span`
    text-transform: capitalize;
    font-size: 12px;
`
const StyledLicenseText = styled.span`
    font-size: 12px;
    display: inline-block;
    &:first-letter{
        text-transform: capitalize;
    }
    &:hover {
        text-decoration: ${(props: {showLink: boolean}) => props.showLink ? 'underline' : 'none'};
        cursor: ${(props: {showLink: boolean}) => props.showLink ? 'pointer' : 'none'};
    }
`

const StyledInfoText = styled.span`
    font-size: 12px;
`

const StyledInfoTextSerialNumber = styled.span`
    display: inline-block;
    font-size: 12px;
    margin-bottom: 8px;
`

const GeneralInfo = ({ device, deviceOwner, isLicenseAddGranted, togglePageDialog }: Props) => {
    const license = device.license as LicenseProps
    const { inclusiveLicenseAvailable } = device
    return <>
        <div>
        <StyledDeviceTypeText>{`${device.deviceType} - `}</StyledDeviceTypeText>
        <StyledLicenseText
            showLink={isLicenseAddGranted}
            onClick={() =>
                isLicenseAddGranted ? togglePageDialog({ dialogId: 'add-license-dialog' }) : null
            }
        >
            {license ? `Active ${(license).tier} license (expires: ${(license).end ? moment((license).end).format('DD.MM.yyyy') : 'never'})` : 'no license'}
            {inclusiveLicenseAvailable ? ' (inclusive one available)' : ''}
        </StyledLicenseText>
        </div>
        <StyledInfoTextSerialNumber>Serial Number: {device.serialNumber}</StyledInfoTextSerialNumber>
        <StyledInfoText><ClaimState date={deviceOwner.claimDate} /></StyledInfoText>
    </>
}

export default GeneralInfo
