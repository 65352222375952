// @flow

import { useState, useEffect, useCallback } from 'react'
import useSWR from 'swr'

import { getAudits } from 'apis/audits'
import useCheckAuth0ScopePermissions from '../../hooks/useCheckAuth0ScopePermissions'
import { USERS_READ } from '../../constants'

type AuditType = {
  content: {
    eventType: string;
    description: string;
    timestamp: string;
    device: string;
    createdBy: string;
    groupSlug: string | null;
    hidden?: boolean;
  }[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { empty: boolean; sorted: boolean; unsorted: boolean };
    unpaged: false;
  };
  size: number;
  sort: { empty: boolean; sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
};

export function useAdminAudit () {
  const { allow: allowRead } = useCheckAuth0ScopePermissions({ rights: [USERS_READ] })
  const [eventType, setEventType] = useState<string>('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [customError, setCustomError] = useState<string>('')
  const { data: audits, error, isLoading: loading } = useSWR({ eventType, pageNumber, startDate, endDate }, getAudits, {
    shouldRetryOnError: false,
    keepPreviousData: true
  })

  const handleRangeDateChange = useCallback((dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }, [])

  const handleEventTypeChange = useCallback((eventTypeVal: string) => {
    setEventType(eventTypeVal)
  }, [])

  const handlePageNumberChange = useCallback((nmbr: number) => {
    setPageNumber(nmbr)
  }, [])

  useEffect(() => {
    if (error) {
      setCustomError(error?.data?.detail ? error?.data?.detail : 'An error occured. Please try again later.')
    } else {
      setCustomError('')
    }
  }, [error])

  useEffect(() => {
    setPageNumber(1)
  }, [startDate, endDate, eventType])

  return {
    audits: audits as AuditType,
    loading,
    pageNumber,
    handlePageNumberChange,
    customError,
    startDate,
    endDate,
    eventType,
    allowRead,
    handleRangeDateChange,
    handleEventTypeChange
  }
}
