
export const DELETE_CLAIM = 'DELETE_CLAIM'
export const START_CLAIMING_SUBMIT = 'START_CLAIMING_SUBMIT'
export const POPULATE_START_CLAIMING = 'POPULATE_START_CLAIMING'
export const CLEAR_CLAIM = 'CLEAR_CLAIM'

export const VERIFY_PASSWORD_SUBMIT = 'VERIFY_PASSWORD_SUBMIT'
export const POPULATE_VERIFY_PASSWORD = 'POPULATE_VERIFY_PASSWORD'

export const CONNECT_TO_DEVICE_SUBMIT = 'CONNECT_TO_DEVICE_SUBMIT'
export const RECONNECT_TO_DEVICE_SUBMIT = 'RECONNECT_TO_DEVICE_SUBMIT'

export const SET_DEVICE_NAME_SUBMIT = 'SET_DEVICE_NAME_SUBMIT'
export const SET_INCLUSIVE_LICENSE_SUBMIT = 'SET_INCLUSIVE_LICENSE_SUBMIT'
export const POPULATE_SET_DEVICE_NAME = 'POPULATE_SET_DEVICE_NAME'
export const POPULATE_SET_INCLUSIVE_LICENSE = 'POPULATE_SET_INCLUSIVE_LICENSE'
export const COMPLETE_CLAIM_DEVICE = 'COMPLETE_CLAIM_DEVICE'

export const SERIAL_NUMBER_SCREEN_STEP = 1
export const PASSWORD_SCREEN_STEP = 2
export const TRY_CONNECTION_SCREEN_STEP = 3
export const RETRY_CONNECTION_SCREEN_STEP = 4
export const DEVICE_NAME_SCREEN_STEP = 5
export const INCLUSIVE_LICENSE_SCREEN_STEP = 6

export const CURRENT_USER_STEP_ONE = 1
export const CURRENT_USER_STEP_TWO = 2
export const CURRENT_USER_STEP_THREE = 3
