import * as C from 'appConstants'
import { ErrorReturnType } from 'sagas/helper'
import { UserGroupType, UserGroupItemType } from 'types/userGroup'

export const fetchUserGroups = (payload: { page: number, loading?: boolean }) => ({ type: C.GET_USER_GROUPS, payload })
export const populateUserGroups = (payload: UserGroupType) => ({ type: C.POPULATE_USER_GROUPS, payload })

export const createUserGroups = (payload: UserGroupItemType) => ({ type: C.CREATE_USER_GROUPS, payload })

export const fetchOneUserGroup = (payload: boolean | string) => ({ type: C.GET_ONE_USER_GROUP, payload })
export const populateOneUserGroup = (payload: UserGroupItemType) => ({ type: C.POPULATE_ONE_USER_GROUP, payload })
export const clearOneUserGroup = () => ({ type: C.CLEAR_ONE_USER_GROUP })

export const updateUserGroup = (payload: { page: number, updatedGroup: UserGroupItemType }) => ({ type: C.REQUEST_UPDATE_USER_GROUP, payload })

export const deleteUserGroup = (payload: string) => ({ type: C.DELETE_USER_GROUP, payload })

export const leaveUserGroup = (payload: string) => ({ type: C.LEAVE_USER_GROUP, payload })

export const deleteUserGroupMembership = (payload: {groupUser: { slug: string, email: string }, page: number}) => ({ type: C.DELETE_USER_GROUP_MEMEBERSHIP, payload })

export const addNewGroup = (payload: any) => ({ type: C.ADD_NEW_GROUP, payload })

export const cancelAddGroup = () => ({ type: C.CANCEL_ADD_GROUP })

export const populateGroupAddSuccess = () => ({ type: C.POPULATE_GROUP_ADD_SUCCESS })
export const populateGroupDeleteSuccess = () => ({ type: C.POPULATE_GROUP_DELETE_SUCCESS })
export const populateGroupUpdateSuccess = () => ({ type: C.POPULATE_GROUP_UPDATE_SUCCESS })
export const populateGroupLeaveSuccess = () => ({ type: C.POPULATE_GROUP_LEAVE_SUCCESS })
export const populateResetGroupSuccess = () => ({ type: C.RESET_GROUP_SUCCESS })

export const populateGroupCreateError = (payload: any) => ({ type: C.POPULATE_GROUP_CREATE_ERROR, payload })
export const populateGroupUserDeleteError = (payload: any) => ({ type: C.POPULATE_GROUP_USER_DELETE_ERROR, payload })
export const populateOneGroupError = (payload: any) => ({ type: C.POPULATE_ONE_GROUP_ERROR, payload })
export const populateGroupUpdateError = (payload: any) => ({ type: C.POPULATE_GROUP_UPDATE_ERROR, payload })

export const updateGroupsPagination = (payload: any) => ({ type: C.POPULATE_UPDATE_PAGINATION, payload })

export const performUserGroupLoading = () => ({ type: C.PERFORM_USER_GROUP_LOADING })

export const deleteUserGroupInvite = (payload: any) => ({ type: C.DELETE_USER_GROUP_INVITE, payload })
export const populateAPIErrors = (payload: ErrorReturnType) => ({ type: C.POPULATE_API_ERRORS, payload })
