// @flow

import { ALERT_TYPE, Alert } from 'components/ReUsable'

type RequestErrorsProps = {
  deleteUserAccountAction?: {
    deletionSuccess?: boolean,
    deletionErrorMessage?: string
  }
}

// TODO: this can be a re-usable component
export default function RequestErrors (props: RequestErrorsProps) {
  const deletionSuccess = props.deleteUserAccountAction?.deletionSuccess
  const deletionErrorMessage = props.deleteUserAccountAction?.deletionErrorMessage

  if (deletionSuccess) {
    setInterval(() => (window.location.reload()), 2000)

    return (
      <Alert message={'User was deleted successfully.'} type={ALERT_TYPE.success} />
    )
  }

  if (!deletionErrorMessage) {
    return null
  }

  return (
    <Alert message={deletionErrorMessage} type={ALERT_TYPE.danger} />
  )
}
