import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects'

import {
  GET_CARTRIDGES,
  UPDATE_CARTRIDGE
} from 'appConstants'

import {
  populateBattery,
  populateUpdateCartridge,
  genericError
} from 'actions'

import {
  getCartridgesRequest,
  updateCartridgeRequest
} from 'apis/cartridges'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * getCartridges ({ type, payload }: { type: string, payload: any }) {
  try {
    const response = yield call(getCartridgesRequest, payload.serialNumber)
    yield put(populateBattery({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function * updateCartridge ({ type, payload }: { type: string, payload: any }) {
  try {
    delete payload.cartridge.portActive
    const response = yield call(updateCartridgeRequest, payload)
    yield put(populateUpdateCartridge({ response: response }))
  } catch (err) {
    yield put(genericError(err))
  }
}

export default function * batteriesSaga () {
  yield takeLatest(GET_CARTRIDGES, getCartridges)
  yield takeLatest(UPDATE_CARTRIDGE, updateCartridge)
}
