// @flow

import { Spinner } from 'react-bootstrap'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import {
  Container,
  HeaderBar,
  Text,
  PermissionCheck,
  ALERT_TYPE,
  Card,
  Row,
  Col,
  PageNotReachable
} from 'components/ReUsable'
import { useAlerts } from './hooks/useAlerts'
import { DENIED, DENIED_MISSING_LICENSE, DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { StyledAlert } from '../Device/Dialog/deactivateTransportDialog'
import NewAlertUser from './newAlertUser'
import AlertConfigDropdown from './alertconfigDropdown'
import AlertConfigForm from './alertconfigForm'
import { StyledSubTitle } from 'components/ReUsable/HeaderBar'

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

export const StyledCard = styled(Card)`
  height: auto;
  margin-top: 1.5rem;
  position: relative;
  border: 1px solid #cfcfcf;
  border-radius: 0.4rem;
  flex: none;

  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: .75rem;
  align-items: center;
`

export const StyledButton = styled.button`
  border: none;
  background: none;
  color: var(--color-primary);
`

const EmailProfile = styled.div`
  display: block;
  height: 21px;
  color: var(--color-additional-value);
  font-size: 18px;
  font-family: digital-serial;
  font-weight: bold;
  line-height: 21px;
  padding-bottom: 10px;
`

const StyledAlertMessage = styled(StyledAlert)`
  margin-bottom: 1rem
`

type Props = {
  match: {params: {serialNumber: string}}
};

const Alerts = ({ match }: Props) => {
  const serialNumber = match.params.serialNumber
  const pageMainElement = useRef(null)
  const {
    loading,
    currentDevice,
    deviceLoadingOrErrorComponent,
    permissions,
    userType,
    alertUsersList,
    error,
    selectedUserId,
    handleNewConfigInsert,
    setSelectedUserId,
    selectedConfiguration,
    removeUser,
    handleUpdateConfig,
    successMessage,
    formIsDirty,
    setFormIsDirty
  } = useAlerts(serialNumber)

  useEffect(() => {
    if (successMessage.length > 0) {
      window.scrollTo(0, 0)
    }
  }, [successMessage.length])

  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  if (deviceLoadingOrErrorComponent || !permissions) {
    return deviceLoadingOrErrorComponent
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE] === DENIED || permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_ADMIN] === DENIED_MISSING_LICENSE) {
    return <PageNotReachable/>
  }

  return (
    <main role="main" ref={pageMainElement}>
      <PermissionCheck
        value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE]}
        permission={DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE}
      >
        <Container data-cy="page-device-alerts">
          <HeaderBar
            headerText="Alerts"
            backlinkText="Back to Fuel Cell"
            backlinkUrl={`/devices/${serialNumber}`}
            serialNumber={serialNumber}
            device={currentDevice}
          />
          <StyledCard>
            { error && <StyledAlertMessage type={ALERT_TYPE.danger} message='An error occured. Please try again later.'/> }
            { !error && successMessage && !formIsDirty && <StyledAlertMessage type={ALERT_TYPE.success} message={successMessage}/> }
            { !error && userType === 'ADMIN'
            ? <>
                <NewAlertUser serialNumber={serialNumber} permissions={permissions} onAlertConfigAdded={handleNewConfigInsert}/>
                { alertUsersList.length > 0
                  ? <>
                      <Row>
                        <Col base="60%">
                          <StyledSubTitle>Alert configs</StyledSubTitle>
                        </Col>
                      </Row>
                      <AlertConfigDropdown
                        selectedUserId={selectedUserId}
                        alertConfigs={alertUsersList}
                        permissions={permissions}
                        onAlertConfigSelected={(id:string) => setSelectedUserId(id)}
                        onAlertRemoved={removeUser}/>
                      </>
                : <></>
                }
              </>
            : <>
                <StyledSubTitle>Alert config</StyledSubTitle>
                <EmailProfile data-cy="email-profile">
                  {alertUsersList && alertUsersList.length > 0 ? alertUsersList[0].email : ''}
                </EmailProfile>
              </>
            }
            { !error && selectedUserId && selectedConfiguration && alertUsersList.length > 0 ? <AlertConfigForm handleSubmit={handleUpdateConfig} setFormIsDirty={setFormIsDirty} formIsDirty={formIsDirty} permissions={permissions} selectedUserId={selectedUserId} selectedConfiguration={selectedConfiguration} userType={userType}/> : null}
          </StyledCard>
        </Container>
      </PermissionCheck>
    </main>
  )
}

export default Alerts
