// @flow

import styled from 'styled-components'

const LevelSensorValue = styled.div`
  font-family: digital-serial;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: ${(props: any) => props.isError ? 'var(--color-alert-error)' : 'var(--color-table-text)'};
`

export default function LevelSensor (props: { isFuelLevelBelowSensor: boolean }) {
  const isFuelLevelBelowSensor = props.isFuelLevelBelowSensor
  const message = isFuelLevelBelowSensor ? 'Fuel level below sensor' : 'Fuel level above sensor or no sensor connected'

  return <LevelSensorValue {...{ isError: isFuelLevelBelowSensor }}>{message}</LevelSensorValue>
}
