// @flow

import {
  backendEndpoint,
  getResponseBody
} from '.'

const unsubscribeEndpoint = () => `${backendEndpoint || ''}/unsubscribe/alert-configs`

interface AlertConfigParams {
  token: string;
  email: string;
  device?: string;
}

export const deleteAlertConfigsRequest = (payload: AlertConfigParams) => {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify(payload)
  }

  return fetch(unsubscribeEndpoint(), options)
    .then(response => {
      if (response.ok) {
        return getResponseBody(options, response)
      }
      throw new Error(response.status.toString())
    })
    .then(data => data)
    .catch(error => error)
}
