// @flow
import styled from 'styled-components'

import { Col, CheckBox } from 'components/ReUsable'
import { useDispatch, useSelector } from 'react-redux'
import { applyDeviceFilter, batchUpdateSelectedDevices, openDeviceFilter } from 'actions'
import { DeviceProps } from '../Device/Types/DeviceTypes'
import FilterPopOver from './filterPopOver'
import { FilterPropName } from '../Home/defineColumns'
import FilterTagsPopOver from './filterTagsPopOver'
import SortCell from './sortCell'

const StyledCheckBox = styled(CheckBox)<{ border: string }>`
  display: none;
  margin-right: 14px;
  margin-top: 2px;
  @media (min-width: 991.97px) {
    display: flex;
  }
`

export const StyledTableRow = styled.th`
  padding: 0.3rem !important;
  font-size: 12px;

  @media (min-width: 991.97px) {
    padding: 0.75rem !important;
    padding-right: 0.5rem !important;
  }
`

const StyledDeviceCellHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledCol = styled(Col)`
  flex: 0 0 16px;
  min-height: 16px;
  margin-left: 5px;
  @media (min-width: 991.97px) {
    margin-left: 0;
  }
`

const StyledDeviceInfoHeader = styled.div`
  display: flex;
  align-items: center;
`
const StyledDeviceSortingCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`

type Props = {
  value: string | number
  sortable: boolean
  sortKey: string
  sorting: any
  sortByColumn: (arg0: any) => void
  id: string
  devices: DeviceProps[]
  hideable?: boolean
  filterable?: boolean
  filterPropName: FilterPropName
}

const areAllElementsSelected = (arr: any, target: any) => target.every((v: any) => arr.find((d: any) => d.serialNumber === v.serialNumber) !== undefined)

const CommonHeadingDeviceCell = (props: Props) => {
  const { value, hideable, sortable, sortKey, sorting, sortByColumn, id, devices, filterable, filterPropName } = props
  const dispatch = useDispatch()
  const selectionMode = useSelector((state: any) => state.devices.selectionActive)
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices)
  const currentFilterValue = useSelector((state: any) => (state.devices?.filter ? state.devices?.filter[filterPropName] : ''))
  const filterActive = currentFilterValue && currentFilterValue.length > 0
  const currentTagsFilterValue = useSelector((state: any) => (state.devices?.filter ? state.devices?.filter.tags : []))
  const filterTagsActive = currentTagsFilterValue && currentTagsFilterValue.length > 0

  const serialNumbersOnPage = devices.map((d: any) => {
    return { serialNumber: d.serialNumber.default, name: d.device.name }
  })
  const allElementsSelected = areAllElementsSelected(selectedDevices, serialNumbersOnPage)

  let className = ' vertical-centered'
  if (sortable) {
    className = className.concat(' sortable')
  }

  if (hideable) {
    className = className.concat(' d-none d-lg-table-cell')
  }

  return (
    <StyledTableRow className={className} key={id}>
      <StyledDeviceCellHeaderContainer>
        <StyledDeviceInfoHeader>
          {selectionMode && (
            <StyledCheckBox
              border="#fff"
              checked={allElementsSelected}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onChange={(e: any) => {
                dispatch(
                  batchUpdateSelectedDevices({
                    serialNumbers: serialNumbersOnPage,
                    mode: allElementsSelected ? 'REMOVE' : 'ADD'
                  })
                )
              }}
              data-cy="select-all-device-checkbox"
            />
          )}
          <span
            onClick={() => {
              if (sortable) {
                sortByColumn({
                  sortKey,
                  sortDirection: (sorting as any).sortDirection === 'asc' ? 'desc' : 'asc'
                })
              }
            }}
          >
            {value}
          </span>
        </StyledDeviceInfoHeader>
        <StyledDeviceSortingCell>
          {filterable ? (
            <>
              <FilterTagsPopOver
                id={'filter-tags'}
                filterActive={filterTagsActive}
                currentFilterValue={currentTagsFilterValue}
                onFilterSave={(f: string[]) => dispatch(applyDeviceFilter({ name: 'tags', value: f.length > 0 ? f : null }))}
                onFilterOpen={() => dispatch(openDeviceFilter())}
              />
              <FilterPopOver
                id={filterPropName || 'filter-popover'}
                filterActive={filterActive}
                currentFilterValue={currentFilterValue}
                onFilterSave={(f: string) => dispatch(applyDeviceFilter({ name: filterPropName, value: f.length > 0 ? f : null }))}
                onFilterOpen={() => dispatch(openDeviceFilter())}
              />
            </>
          ) : (
            <></>
          )}
          <SortCell
            onSort={() => {
              if (sortable && sortByColumn) {
                sortByColumn({ sortKey, sortDirection: sorting.sortDirection === 'asc' ? 'desc' : 'asc' })
              }
            }}
            sortKey={sortKey}
            sorting={sorting}
          />
        </StyledDeviceSortingCell>
      </StyledDeviceCellHeaderContainer>
    </StyledTableRow>
  )
}

export default CommonHeadingDeviceCell
