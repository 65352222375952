export const GET_USER_GROUPS = 'GET_USER_GROUPS'
export const POPULATE_USER_GROUPS = 'POPULATE_USER_GROUPS'

export const CREATE_USER_GROUPS = 'CREATE_USER_GROUPS'

export const GET_ONE_USER_GROUP = 'GET_ONE_USER_GROUP'
export const POPULATE_ONE_USER_GROUP = 'POPULATE_ONE_USER_GROUP'

export const REQUEST_UPDATE_USER_GROUP = 'REQUEST_UPDATE_USER_GROUP'

export const DELETE_USER_GROUP = 'DELETE_USER_GROUP'

export const LEAVE_USER_GROUP = 'LEAVE_USER_GROUP'

export const DELETE_USER_GROUP_MEMEBERSHIP = 'DELETE_USER_GROUP_MEMEBERSHIP'

export const CLEAR_ONE_USER_GROUP = 'CLEAR_ONE_USER_GROUP'

export const ADD_NEW_GROUP = 'ADD_NEW_GROUP'

export const CANCEL_ADD_GROUP = 'CANCEL_ADD_GROUP'

export const POPULATE_ONE_GROUP_ERROR = 'POPULATE_ONE_GROUP_ERROR'
export const POPULATE_GROUP_CREATE_ERROR = 'POPULATE_GROUP_CREATE_ERROR'
export const POPULATE_GROUP_UPDATE_ERROR = 'POPULATE_GROUP_UPDATE_ERROR'
export const POPULATE_GROUP_USER_DELETE_ERROR = 'POPULATE_GROUP_USER_DELETE_ERROR'

export const POPULATE_GROUP_ADD_SUCCESS = 'POPULATE_GROUP_ADD_SUCCESS'
export const POPULATE_GROUP_DELETE_SUCCESS = 'POPULATE_GROUP_DELETE_SUCCESS'
export const POPULATE_GROUP_UPDATE_SUCCESS = 'POPULATE_GROUP_UPDATE_SUCCESS'
export const POPULATE_GROUP_LEAVE_SUCCESS = 'POPULATE_GROUP_LEAVE_SUCCESS'
export const RESET_GROUP_SUCCESS = 'RESET_GROUP_SUCCESS'

export const POPULATE_UPDATE_PAGINATION = 'POPULATE_UPDATE_PAGINATION'

export const PERFORM_USER_GROUP_LOADING = 'PERFORM_USER_GROUP_LOADING'

export const DELETE_USER_GROUP_INVITE = 'DELETE_USER_GROUP_INVITE'

export const POPULATE_API_ERRORS = 'POPULATE_API_ERRORS'
