import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  font-size: 24px;
`

export const StyledImage = styled.img`
  width: 30px;
  height: 30px;
`

export const StyledColumn = styled.td`
  text-align: center;
  vertical-align: middle;
`

export const StyledDesktopOnlyColumn = styled.td`
  text-align: center;
  vertical-align: middle;
  @media (max-width: 992px) {
    display: none;
  }
`
