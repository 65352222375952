import {
  getAdminNotificationsAPI,
  getOneAdminNotificationAPI,
  deleteAdminNotificationAPI,
  insertAdminNotificationAPI,
  updateAdminNotificationAPI,
  NotificationFormType
} from 'apis/notifications'
import { useEffect, useState } from 'react'
import { NOTICE_READ, NOTICE_WRITE } from '../../constants'
import useCheckAuth0ScopePermissions from '../../hooks/useCheckAuth0ScopePermissions'
import { SOMETHING_WENT_WRONG } from 'appConstants'

export type AdminNotificationType = NotificationFormType & {
  id: string
  userConfirmations: number
}

export type PageMode = 'EDIT' | 'ADD' | 'LIST'
export type APIError = { message: string, operation: PageMode | 'GETONE' | 'DELETE' } | null

export default function useAdminNotifications () {
  const { allow: allowRead } = useCheckAuth0ScopePermissions({ rights: [NOTICE_READ] })
  const { allow: allowWrite } = useCheckAuth0ScopePermissions({ rights: [NOTICE_WRITE] })
  const [mode, setMode] = useState<PageMode>('LIST')
  const [selectedNotification, setSelectedNotification] = useState<
    string | null
  >(null)
  const [notifications, setNotifications] = useState<
    AdminNotificationType[] | null
  >(null)
  const [error, setError] = useState<APIError>(null)

  //  BEGIN CRUD operations with error handling.
  const loadNotifications = async () => {
    try {
      setMode('LIST')
      const data = await getAdminNotificationsAPI()
      if (data?.status >= 400) {
        throw new Error()
      }
      setNotifications(data)
    } catch {
      setError({ message: `${SOMETHING_WENT_WRONG} Please try again later.`, operation: 'LIST' })
      setNotifications(null)
    }
  }

  const getNotification = async (id: string) => {
    try {
      const notification = await getOneAdminNotificationAPI(id)
      if (notification?.status >= 400) {
        throw new Error()
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { userConfirmations, ...notificationWithoutConfirmationInfo } = notification
      return notificationWithoutConfirmationInfo
    } catch {
      setMode('LIST')
      setError({ message: `${SOMETHING_WENT_WRONG} Selected notice can not be loaded.`, operation: 'GETONE' })
    }
  }

  const deleteNotification = async (id: string) => {
    try {
      const data = await deleteAdminNotificationAPI(id)
      if (data?.status >= 400) {
        throw new Error()
      }
      loadNotifications()
      return data
    } catch {
      setError({ message: `${SOMETHING_WENT_WRONG} Selected notice with the id ${id} can not be removed.`, operation: 'DELETE' })
    }
  }

  const addNotification = async (notificationToAdd: NotificationFormType) => {
    try {
      const notification = await insertAdminNotificationAPI(notificationToAdd)
      if (notification?.status >= 400) {
        throw new Error()
      }
      loadNotifications()
      return notification
    } catch {
      setError({ message: `${SOMETHING_WENT_WRONG} Notice is not added.`, operation: 'ADD' })
    }
  }

  const editNotification = async (
    id: string,
    notificationToEdit: NotificationFormType
  ) => {
    try {
      const notification = await updateAdminNotificationAPI(
        id,
        notificationToEdit
      )
      if (notification?.status >= 400) {
        throw new Error()
      }
      await loadNotifications()
      return notification
    } catch {
      setError({ message: `${SOMETHING_WENT_WRONG} Selected notice with the id ${id} can not be edited.`, operation: 'EDIT' })
    }
  }
  //  END CRUD operations with error handling.

  //  Load notifications if read is allowed.
  useEffect(() => {
    if (allowRead) {
      loadNotifications()
    }
  }, [allowRead])

  //  Clean up errors and selected notification by switching to LIST view.
  useEffect(() => {
    setError(null)
    if (mode === 'LIST') {
      setSelectedNotification(null)
    }
  }, [mode])

  return {
    notifications,
    getNotification,
    deleteNotification,
    addNotification,
    editNotification,
    mode,
    setMode,
    selectedNotification,
    setSelectedNotification,
    error,
    allowWrite,
    allowRead
  }
}
