import { parse, isValid, format, parseISO } from 'date-fns'

import * as C from 'appConstants'
import { DEVICE_DETAILS_PERMISSIONS_LIST, DENIED_MISSING_LICENSE, DENIED } from 'appConstants'
import { LICENSE_WRITE, NOTICE_READ } from 'components/Application/Admin/constants'
import { PERMISSIONS_ALL, PERMISSIONS_MOCKS } from './mockPermissions'

export function integrationTestRunning (environment) {
  return ['integration_test'].includes(environment)
}

export function getPublicUrl () {
  return process.env.PUBLIC_URL || ''
}

export const productImage = (deviceType, type = '') => {
  const productImage = C.PRODUCT_IMAGES.find((img) => img === `${deviceType.replace(/ /gi, '_').replace(/_JP$/gi, '')}${type}.png`)
  return `/imgs/products/${productImage || C.PRODUCT_IMAGES[0]}`
}

export function getNumberWithDecimals (nmbr: number, decimals: any) {
  if (Number.isInteger(nmbr)) {
    return nmbr.toString()
  }
  return nmbr.toFixed(decimals)
}

export function roundedNumber (nmbr: number, decimals?: number) {
  if (Number.isInteger(nmbr)) {
    return nmbr
  }

  if (decimals) {
    return getNumberWithDecimals(nmbr, decimals)
  }

  return Math.round(nmbr)
}

/**
 * @function
 * @param {Object} object The object to read.
 * @param {String} read defaults to 'content' and tells which property to read from 'object' param
 **/
export function getProperty (object, read = 'content') {
  return typeof object === 'object' && object !== null && !Array.isArray(object) ? object[read] : undefined
}

export function capFuelPercentageValue (value) {
  if (value > 100) return 100
  if (value < 0) return 0
  return value
}

export function isLicenseExpired (permissions) {
  if (
    (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ] === DENIED || permissions[DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ] === DENIED_MISSING_LICENSE) &&
    permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE
  ) {
    return true
  }
  return false
}

// eslint-disable-next-line
export const emailRegex = /^[\w-]+([\.\+-][\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/

export function isNotNullAndHasKeys (obj: any) {
  return typeof obj === 'object' && obj !== null && obj !== undefined && Object.keys(obj).length > 0
}

export async function copyToClipboard (text: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    return false
  }
}
export const getPropertiesWithSameValue = (obj) => {
  const groupedObject = Object.entries(obj).reduce((acc, [key, value]: any) => {
    if (!acc[value]) {
      acc[value] = []
    }
    acc[value].push(key)
    return acc
  }, {})

  for (const prop in groupedObject) {
    if (groupedObject[prop].length > 1) {
      return groupedObject[prop]
    }
  }
}

// blend two hex colors together by an amount. Ref: https://stackoverflow.com/questions/6367010/average-2-hex-colors-together-in-javascript
export const blendColors = (colorA, colorB, amount = 1.0) => {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16))
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16))
  const r = Math.round(rA + (rB - rA) * amount)
    .toString(16)
    .padStart(2, '0')
  const g = Math.round(gA + (gB - gA) * amount)
    .toString(16)
    .padStart(2, '0')
  const b = Math.round(bA + (bB - bA) * amount)
    .toString(16)
    .padStart(2, '0')
  return '#' + r + g + b
}

export function uppercaseFirstLetter (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function roleLabel (role: string): string {
  return `Role: ${uppercaseFirstLetter(role)}`
}

//  Checks if all elements from array1 are also elements of array2
export function isSubset (array1: string[], array2: string[]) {
  const set = new Set(array2)
  return array1.every((val) => set.has(val))
}

//  Checks if at least one element from array1 is inside array2
export function containsElement (array1: string[], array2: string[]) {
  const set = new Set(array2)
  return array1.some((val) => set.has(val))
}

// Decoding JWT token Ref: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt (token: string) {
  //  If integrations tests are running, we provide different user permissions
  //  in order to cover different scenarios.
  if (integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    //  Mock users permission value is set in Cypress as window variable e.g. 'PERMISSIONS_NO_LICENSE_CREATE'
    const mockUserPermissions = (window as any).USE_AUTH_MOCK_PERMISSIONS || ''
    let defaultMock = {
      email: 'demo@sfc.com',
      iss: 'https://dev-pachunil.auth0.com/',
      sub: 'auth0|63c5534a2dde5c9e9aee31e1',
      aud: ['http://localhost:8080/', 'https://dev-pachunil.auth0.com/userinfo'],
      iat: 1699862659,
      exp: 1699949059,
      azp: '5t2HDTmcZK4EGpncqPOCLpAjyJhgQPBo',
      scope: 'openid profile email',
      permissions: PERMISSIONS_ALL
    }
    //  in Cypress: e.g. window.USE_AUTH_MOCK_PERMISSIONS = 'PERMISSIONS_NO_LICENSE_CREATE'
    if (mockUserPermissions !== '') {
      defaultMock = { ...defaultMock, permissions: [...PERMISSIONS_MOCKS[mockUserPermissions]] }
    }
    return defaultMock
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// Decoding JWT token Ref: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export async function checkPermissionFromAuth0 (type: string, operation: RegExp, token: string): Promise<boolean> {
  if (!type) throw new Error('No type or operation provided.')
  if (!integrationTestRunning(process.env.REACT_APP_ENV_MODE) && !token) throw new Error('No token provided.')
  const payload = parseJwt(token)

  const filteredPermissions = payload.permissions.filter((permission: string) => permission.includes(type))
  return operation.test(filteredPermissions.join())
}

export async function checkScopePermissionsFromAuth0 (types: string[], token: string): Promise<boolean> {
  if (!types || types.length === 0) throw new Error('No type or operation provided.')
  if (!integrationTestRunning(process.env.REACT_APP_ENV_MODE) && !token) throw new Error('No token provided.')
  const payload = parseJwt(token)
  const activePermissions = payload.permissions.filter((permission: string) => types.indexOf(permission) > -1)
  return activePermissions.length > 0
}

//  In order to reach admin pages a user should at least one of these rights in his/her profile.
export const ADMIN_RIGHTS_ARRAY = [LICENSE_WRITE, NOTICE_READ]

export async function getFirstMatchedAdministrationPermissionFromAuth0 (token: string): Promise<string> {
  if (!integrationTestRunning(process.env.REACT_APP_ENV_MODE) && !token) throw new Error('No token provided.')

  const payload = parseJwt(token)
  const firstMatchingPermission = payload.permissions.find((permission: string) => ADMIN_RIGHTS_ARRAY.includes(permission))
  return firstMatchingPermission
}

export function isValidUrl (urlStr: string): boolean {
  const expression = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  const urlRegex = new RegExp(expression)
  return urlRegex.test(urlStr)
}

export async function downloadCSVFromResponse (response: any, filename: string): Promise<void> {
  if (response) {
    const blob: Blob = response
    // Create a temporary download link
    const tempLink: HTMLAnchorElement = document.createElement('a')
    tempLink.href = window.URL.createObjectURL(blob)
    tempLink.setAttribute('download', filename)

    // Append to the DOM, trigger click, and then remove it
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  } else {
    throw new Error('Response is invalid.')
  }
}

export function capitalizeFirstLetter (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function randomHexColorCode () {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}

export function toggleItemInArray (items: string[], item: string): string[] {
  const index = items.indexOf(item)

  const updatedItems = [...items]

  if (index === -1) {
    updatedItems.push(item)
  } else {
    updatedItems.splice(index, 1)
  }

  return updatedItems
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export function getDateStringOrInputString (input: string) {
  const dateFormat = 'yyyy-MM-dd'
  if (input.length < 10) {
    return input
  }
  try {
    const dateSegment = input.substring(0, 10)
    const parsedDate = parse(dateSegment, dateFormat, new Date())
    if (isValid(parsedDate) && dateSegment === format(parsedDate, dateFormat)) {
      const dateObj = parseISO(input)
      // Convert the Date object to a string in a standard format
      return format(dateObj, 'yyyy-MM-dd HH:mm:ss')
    } else {
      return input
    }
  } catch {
    return input
  }
}

// Utility function to debounce
export const customDebounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

// Utility function for biding dispatch to action creators
export const bindDispatch =
  (dispatch) =>
  (actionCreator) =>
  (...args) =>
    dispatch(actionCreator(...args))
