import { togglePageDialog } from 'actions'
import { CONSENT_STORAGE_PROP, CONSENT_HEADER_TEXT, CONSENT_CONTENT_TEXT, CONSENT_DIALOG_ID } from 'appConstants'
import { Button, Dialog, OutlineButton } from 'components/ReUsable'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { integrationTestRunning } from 'utils/helpers'

const StyledContent = styled.div`
  padding: 10px;
`

const StyledText = styled.p`
  font-family: 'open-sans-regular';
  font-size: 16px;
`
// Skip

const TrackingConsent = () => {
  const dispatch = useDispatch()
  const _togglePageDialog = useCallback((payload?: any) => dispatch(togglePageDialog(payload)), [dispatch])
  const [consentVal, setConsentVal] = useState<string | null>('rejected')
  // consentVal is used to store the new val of consent selection
  // while the consentStorage is used to store the previous val of consent selection
  const consentStorage = localStorage.getItem(CONSENT_STORAGE_PROP)

  useEffect(() => {
    //  Store the information that request consent dialog is opened into redux store
    //  Other dialogs will use this information to toggle their visibility.
    if (!consentStorage) {
      _togglePageDialog({ dialogId: CONSENT_DIALOG_ID })
    }
    setConsentVal(consentStorage)
  }, [_togglePageDialog])

  useEffect(() => {
    if (consentVal === undefined) {
      localStorage.removeItem(CONSENT_STORAGE_PROP)
    } else {
      localStorage.setItem(CONSENT_STORAGE_PROP, consentVal ?? '')
    }

    return () => {
      localStorage.removeItem(CONSENT_STORAGE_PROP)
    }
  }, [consentVal])

  const handleConsentSelection = useCallback(
    (val: string) => {
      _togglePageDialog()
      setConsentVal(val)
    },
    [_togglePageDialog, setConsentVal]
  )

  if (integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return null
  }

  return (
    <Dialog id="consent-dialog" className="modal-page" title={CONSENT_HEADER_TEXT} show={!consentVal} hideCloseButton={true}>
      <StyledContent>
        <StyledText>{CONSENT_CONTENT_TEXT}</StyledText>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Button onClick={() => handleConsentSelection('agreed')}>AGREE</Button>
          <OutlineButton onClick={() => handleConsentSelection('rejected')}>REJECT</OutlineButton>
        </div>
      </StyledContent>
    </Dialog>
  )
}

export default TrackingConsent
