// @flow

import {
  backendEndpoint,
  auth0Domain,
  auth0ClientId,
  executeRequest
} from '.'

const accountEndpoint = `${backendEndpoint || ''}/account`

export const deleteUserAccountRequest = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE'
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

export const getUserAccountRequest = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

export const updateUserAccountRequest = (payload: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(payload)
  }

  return executeRequest(accountEndpoint, undefined, undefined, options)
}

export const updateUserAccountPasswordRequest = (payload: any) => {
  return fetch(`https://${auth0Domain}/dbconnections/change_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      client_id: auth0ClientId,
      email: payload.email,
      connection: 'Username-Password-Authentication'
    })
  })
}
