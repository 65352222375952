// @flow

import styled from 'styled-components'

type Props = {
  textAlign?: string,
}

export default styled.p`
  color: var(--color-text);
  font-family: open-sans-regular;
  font-size: 14px;
  line-height: 18px;
  text-align: ${(props: Props) => props.textAlign ? props.textAlign : 'initial'};
`
