// @flow

import { useSelector } from 'react-redux'

function ErrorMessage () {
  const isDialogOpen = useSelector((state: any) => state.dialog.isDialogOpen)

  return (
    <main role='main' className={ `${isDialogOpen ? 'blur-element' : ''}` }>
      <div className='error-message'>
        You have registered and no content is available, please go back
        to your Mail inbox and confirm your account by click on the attached link.
      </div>
    </main>
  )
}

export default ErrorMessage
