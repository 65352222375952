import { Button, Container, Row, Col, PageTitle, Alert, ALERT_TYPE, PageNotReachable } from 'components/ReUsable'
import styled from 'styled-components'
import NotificationGrid from './NotificationGrid'
import NotificationForm from './NotificationForm'
import { useNotificationContext } from './context/NotificationContext'
import { StyledAdminCard } from 'components/ReUsable/Card'

const StyledButtonContaier = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 991.98px) {
    justify-content: flex-end;
    margin-top: 1rem;
  }
`

export default function AdminNotification () {
  const { mode, setMode, error, allowWrite, allowRead } = useNotificationContext()
  //  Page only visible for users with notice read rights.
  if (!allowRead) return <PageNotReachable/>
  return (
    <Container data-cy="page-admin-notification">
      <Row margin="1rem 0">
        <Col>
          <PageTitle>Notices</PageTitle>
        </Col>
      </Row>
      {error ? (
          <Alert message={error.message} type={ALERT_TYPE.danger} />
        ) : null
      }
      <Row>
        <StyledAdminCard>
          { mode === 'LIST'
            ? <>
                <NotificationGrid />
                { allowWrite
                  ? <StyledButtonContaier>
                      <Button onClick={() => setMode('ADD')} data-cy="add-notice-btn">
                        Add new notice
                      </Button>
                    </StyledButtonContaier>
                  : null}
              </>
            : null
          }
          { mode === 'ADD' || mode === 'EDIT'
            ? <NotificationForm />
            : null
          }
        </StyledAdminCard>
      </Row>
    </Container>
  )
}
