// @flow

import moment from 'moment'

type Props = {
  data?: any,
  property?: string,
}

export default function ShowTimeStamp (props: Props) {
  const { data, property } = props

  if (data && property && data[property]) {
    return <>{`${moment(data[property]).format('DD.MM.YY HH:mm')} h`}</>
  }

  return null
}
