// @flow

import { DEVICE_DETAILS_PERMISSIONS_LIST, DENIED_MISSING_LICENSE, UNSUPPORTED_LICENSE_ERROR } from 'appConstants'
import { NEW_API_ACTIVE } from 'featureToggles'
import { StyledDesktopOnlyColumn, StyledFontAwesomeIcon, StyledImage } from './styleDefinitions'
import { isLicenseExpired } from 'utils/helpers'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'

type Props = {
  values: {
    inServiceMode: boolean
    default: number,
    permissions: any
  }
}

export default function FirmwareVersionRowCell (props: Props) {
  const { values } = props
  const { permissions, inServiceMode } = values

  if (isLicenseExpired(permissions) && !NEW_API_ACTIVE) {
    return null
  }

  if (isLicenseExpired(permissions) && NEW_API_ACTIVE) {
    return <StyledDesktopOnlyColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title="License expired" /></StyledDesktopOnlyColumn>
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && !NEW_API_ACTIVE) { return null }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && NEW_API_ACTIVE) { return <StyledDesktopOnlyColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title={UNSUPPORTED_LICENSE_ERROR} /></StyledDesktopOnlyColumn> }

  if (inServiceMode && !NEW_API_ACTIVE) {
    return null
  }

  if (inServiceMode && NEW_API_ACTIVE) {
    return <StyledDesktopOnlyColumn>
      <StyledFontAwesomeIcon icon={faScrewdriverWrench as any} title="In Service"/>
    </StyledDesktopOnlyColumn>
  }

  return (
    <td className={ 'text-centered d-none d-lg-table-cell' }>
      <span>{ values.default }</span>
    </td>
  )
}
