// @flow

import styled from 'styled-components'

import { Text } from 'components/ReUsable'
import { ManageDelegationHeader } from './ManageDelegationHeader'
import ManageDelegationItem from './ManageDelegationItem'

export type Delegation = {
  displayName: string
  identity: string
  role: string
  type: 'USER' | 'GROUP'
}

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

export const ManageDelegationList = function ({ delegations, onUpdateList }: { delegations: Delegation[]; onUpdateList: () => Promise<boolean> }) {
  return (
    <>
      <ManageDelegationHeader />
      {delegations?.length ? (
        delegations.map((d: Delegation, index: number) => {
          return <ManageDelegationItem key={`manageDelegationItem-${index}`} delegation={d} index={index} onUpdateList={onUpdateList} />
        })
      ) : (
        <StyledEmptyText data-cy="empty-manage-delegations">You can add new roles using the ADD button.</StyledEmptyText>
      )}
    </>
  )
}

export default ManageDelegationList
