// @flow

import * as Steps from './steps'

export default function DeviceClaimingContent () {
  return (
    <div className='add-efoy-dialog'>
      <Steps.One />
      <Steps.Two />
      <Steps.Three />
      <Steps.Four />
      <Steps.Five />
      <Steps.Six />
    </div>
  )
}
