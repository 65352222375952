// @flow

import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { LicenseType } from 'types/devices'
import { capitalizeFirstLetter } from 'utils/helpers'

const StyledText = styled.div`
  font-size: 14px;
  button,
  button:hover,
  button:active {
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`

export const INCLUSIVE_LICENSE = 'inclusive'

const ActivateInclusiveLicense = ({ onLicenseAdd, inclusiveLicense }: { onLicenseAdd: (license: string) => void, inclusiveLicense: LicenseType }) => {
  const { duration, tier } = inclusiveLicense
  const dynamicText = `${duration === 'half_year' ? 'half year' : '1-year'} ${capitalizeFirstLetter(tier)}`
  return (
    <>
      <StyledText className="alert alert-success" role="alert">
        {`A ${dynamicText} license is included in the purchase price of your EFOY. Would you like to activate this license now?`}
        <br />
        Attention: The license period starts immediately and cannot be canceled or paused!
        <Button type="button" onClick={() => onLicenseAdd(INCLUSIVE_LICENSE)}>
          Apply included license now
        </Button>
      </StyledText>
    </>
  )
}

export default ActivateInclusiveLicense
