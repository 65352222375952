import useNotifications from './hooks/useNotifications'
import {
  Dialog
} from 'components/ReUsable'
import NotificationContent from './notificationContent'

export default function NotificationModal () {
  const { activeNotification, handleConfirmNotification } = useNotifications()
  if (!activeNotification) return null

  const { title } = activeNotification

  return (
    <Dialog
    id='notification-dialog'
    className='modal-page'
    title={title}
    show={activeNotification !== null}
    hideCloseButton={true}
  >
    <NotificationContent activeNotification={ activeNotification } handleConfirmNotification={handleConfirmNotification}/>
  </Dialog>
  )
}
