import { ErrorResponse, backendEndpoint, executeRequest } from 'apis'

export type TextInformationResponse = {
  loadedAt: string
  data: string
}

const informationEndpoint = `${backendEndpoint || ''}/devices/`

export function fetchTextInformation (serialNumber: string, commandName: string): Promise<TextInformationResponse | ErrorResponse> {
  return executeRequest(`${informationEndpoint}${serialNumber}/advanced-info/${commandName}`)
}

export type AttributeInformationResponse = {
  loadedAt: string
  data: { [key: string]: string | EnabledResponse }
}

export type EnabledResponse = {
  enabled: boolean;
  port: number;

}
export type ListInformationResponse = {
  loadedAt: string
  header: string[],
  data: string[][]
}

export function fetchAttributeInformation (serialNumber: string, commandName: string): Promise<AttributeInformationResponse | ErrorResponse> {
  return executeRequest(`${informationEndpoint}${serialNumber}/advanced-info/${commandName}`)
}

export function fetchListInformation (serialNumber: string, commandName: string): Promise<ListInformationResponse | ErrorResponse> {
  return executeRequest(`${informationEndpoint}${serialNumber}/advanced-info/${commandName}`)
}
