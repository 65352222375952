import { createContext, useContext, ReactNode } from 'react'
import { useTags } from '../hooks/useTags'

export type Tag = {id: string, name: string, color: string}
export type TagPost = {name: string, color: string, group: string | null, id?: string}

export type TagContextType = {
  personal: Tag[],
  groups: {[key: string]: {
    name: string,
    tags: Tag[]
  }},
  loading: boolean,
  handleTagAddEdit: (tag: TagPost | Tag, groupSlug: string | undefined) => Promise<boolean>,
  handleTagDelete: (id: string, groupSlug: string | undefined) => Promise<boolean>,
  isUniqueInGroup: (tag: TagPost) => boolean,
  readError: any,
  apiError: string[]
  tagsEmpty: boolean
}

export const PERSONAL_TAG_PLACEHOLDER = '##personal##'

const TagContext = createContext<TagContextType | undefined>(undefined)

export function useTagContext () {
  const context = useContext(TagContext)
  if (!context) {
    throw new Error('useTagContext must be used within a TagProvider')
  }
  return context
}

// Create a NotificationContext provider with children
interface TagContextProviderProps {
  children: ReactNode
}

export const TagContextProvider = ({
  children
}: TagContextProviderProps) => {
  const tagProps = useTags()

  return (
    <TagContext.Provider value={tagProps}>
      {children}
    </TagContext.Provider>
  )
}
