// @flow

import { useAuth0 } from 'utils/react_auth0_spa'
import { integrationTestRunning } from 'utils/helpers'

function InfoBar ({ hideLogout } : { hideLogout?: boolean }) {
  const { loading, isAuthenticated, logout } = useAuth0()

  if ((!isAuthenticated || loading) && !integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return null
  }

  return (
    <div className='info-bar container-fluid'>
      {!hideLogout ? <div className='info-nav'>
        <div className='col-12 justify-content-end d-flex align-self-center'>
          <ul className='list-inline info-list'>
            <li className='list-inline-item'>
              <a className='info-links' href='#logout' onClick={ () => logout() }>SIGN OUT</a>
            </li>
          </ul>
        </div>
      </div> : <></>}
    </div>
  )
}

export default InfoBar
