// @flow

import styled from 'styled-components'

import { Row, Alert, ALERT_TYPE } from 'components/ReUsable'

export const StyledAlert = styled(Alert)`
  @media (max-width: 991.97px) {
    margin-top: 2rem;
  }
`
export const ERROR_MESSAGE = 'Something went wrong. Please check your input.'
export const SUCCESS_MESSAGE = 'Email / Group ID ##name## has been saved.'

const AddDelegationAlert = function ({
  error,
  success,
  name
}: {
  error: string | number,
  success: boolean,
  name?: string,
}) {
  if (+error > 200) {
    return (
      <Row>
        <StyledAlert message={ERROR_MESSAGE} type={ALERT_TYPE.danger} />
      </Row>
    )
  }
  if (typeof error === 'string' && error.length > 0) {
    return (
      <Row>
        <StyledAlert message={error} type={ALERT_TYPE.danger} />
      </Row>
    )
  }

  if (success) {
    return (
      <Row>
        <StyledAlert
          message={SUCCESS_MESSAGE.replace('##name##', name || '')}
          type={ALERT_TYPE.success}
        />
      </Row>
    )
  }

  return <></>
}

export default AddDelegationAlert
