// @flow

import { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

import { Container, PermissionCheck, HeaderBar, Button } from 'components/ReUsable'
import { useManageDelegations } from './hooks/useManageDelegations'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import ManageDelegationList from './components/ManageDelegationList'
import { Spinner } from 'react-bootstrap'
import RoleInfoPanel from './components/RoleInfoPanel'

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-top: 0.6rem;

  & > button {
    width: 9.15rem;
  }
`

export const ManageDelegations = function () {
  const { serialNumber } = useParams<{ serialNumber: string }>()
  const history = useHistory()
  const { getDeviceDelegationsCall, delegations, currentDevice, loading, deviceLoadingOrErrorComponent } = useManageDelegations(serialNumber)
  //  Load all delegations
  useEffect(() => {
    getDeviceDelegationsCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  if (deviceLoadingOrErrorComponent) {
    return deviceLoadingOrErrorComponent
  }

  const permissions = currentDevice ? currentDevice.permissions : {}

  return (
    <main role="main">
      <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DELEGATION_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.DELEGATION_WRITE}>
        <Container data-cy="page-manage-roles">
          <HeaderBar headerText="Manage Access" backlinkText="Back to Fuel Cell" backlinkUrl={`/devices/${serialNumber}`} serialNumber={serialNumber} device={currentDevice} />

          <ManageDelegationList delegations={delegations} onUpdateList={getDeviceDelegationsCall} />
          <StyledButtonContainer>
            <Button
              data-cy="add-delegation-btn"
              onClick={() => {
                history.push(`/devices/${serialNumber}/addrole`)
              }}
            >
              Add
            </Button>
          </StyledButtonContainer>
          <RoleInfoPanel />
        </Container>
      </PermissionCheck>
    </main>
  )
}

export default ManageDelegations
