import { UNSUPPORTED_LICENSE_ERROR } from 'appConstants'

import styled from 'styled-components'

import { Card, Text, Button } from '..'

type LicenseMissingMode = 'inline'
interface IMissingLicense {
    mode?: LicenseMissingMode
}

const StyledContainer = styled.div<IMissingLicense>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: var(--color-background);
    padding: ${props => props.mode === 'inline' ? '1.4rem' : '2.4rem'};
    margin: .25rem;
    @media (min-width: 991px) {
        min-height: ${props => props.mode === 'inline' ? '12rem' : '16rem'};
    }
    p {
        font-family: DigitalSerialBold;
        font-size: 14px;
    }
`

const StyledButton = styled(Button)`
    padding: 0 2.5rem;
`
export const PRO_LINK = 'https://www.efoy-pro.com/en/service/cloud-license/'

export default function LicenseMissing ({ mode }: IMissingLicense) {
    const content = (
     <StyledContainer mode={ mode } data-cy='license-missing-container'>
            <Text textAlign='center'>{UNSUPPORTED_LICENSE_ERROR}</Text>
            <StyledButton className='mb-3' onClick={() => {
                window.open(PRO_LINK)
            }}>Get your license</StyledButton>
        </StyledContainer>
    )
    return mode === 'inline' ? content : <Card>{ content }</Card>
}
