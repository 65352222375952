// @flow

import { useState } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'

import {
  submitBatchDeviceDelegation
} from 'apis/devices'

import {
  CARTRIDGE_MONITORING,
  LICENSE_MISSING,
  FORBIDDEN,
  DEVICE_NOT_FOUND
} from 'appConstants'
import { performIdentityCheck } from 'apis/groups'
import { IDENTITY_NOT_EXISTS } from './useAddDelegation'

export type DelegationItemResponse = {
  deviceSerialNumber: string,
  deviceName: string | null;
  error: typeof LICENSE_MISSING | typeof FORBIDDEN | typeof DEVICE_NOT_FOUND | null,
  success: boolean
}

export type DelegationResponse = {
  identity: string,
  role: string,
  delegations: DelegationItemResponse[]
}

export function useBatchEditDelegation (httpMode: 'POST' | 'DELETE') {
  const [success, setSuccess] = useState(false)
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices.map((device: any) => device.serialNumber))
  const [error, setError] = useState('')
  const [delegationResponse, setDelegationResponse] = useState<DelegationResponse | null>(null)
  const backLink = '/'
  const formik = useFormik({
    initialValues: {
      name: '',
      role: httpMode === 'DELETE' ? undefined : CARTRIDGE_MONITORING,
      submitBtnId: ''
    },
    onSubmit: async (values) => {
      await makeAPICall(
        values.name?.toLowerCase().trim(),
        values.role
      )
    },
    validate: (values) => {
      const errors: { name?: string } = {}
      if (!values.name) {
        errors.name = 'Required!'
      }

      return errors
    },
    validateOnChange: false,
    validateOnBlur: false
  })
   //  Remove or Add batch delegation for multiple devices
  const makeAPICall = async (identity: string, role?: string) => {
    try {
      setSuccess(false)
      setError('')
      //  Check if user already exists.
      const identityExists = await performIdentityCheck(identity)
      if (!identityExists) {
        setError(IDENTITY_NOT_EXISTS)
        return false
      }

      const delegationResponse = await submitBatchDeviceDelegation(identity, selectedDevices, httpMode, role)
      if (delegationResponse.message) {
        throw delegationResponse.message
      } else {
        setDelegationResponse(delegationResponse)
        setSuccess(true)
        return true
      }
    } catch (e: any) {
      setError(e)
      return false
    }
  }

  return { error, success, formik, backLink, delegationResponse }
}
