// @flow

import { Col, CardSectionTitle, CardSection, DataRow, DataRowTitle, DataRowValue, ShowDataValue, ShowErrorLink } from 'components/ReUsable'

// importing types
import type { FuelManagerInfo, DeviceProps } from '../Types/DeviceTypes'

type Props = {
  readonly fuelManagerInfo: FuelManagerInfo
  readonly device: DeviceProps // eslint-disable-line react/no-unused-prop-types
}

const FuelManagerSection = ({ fuelManagerInfo }: Props) => {
  const renderDataRow = (title: string, property: string, errorLink?: boolean) => (
    <DataRow key={property}>
      <DataRowTitle>{title}</DataRowTitle>
      <DataRowValue>
        {errorLink ? (
          <ShowErrorLink
            data={{ deviceType: 'efoy-fuel-manager' }}
            errorCode={ShowDataValue({
              data: fuelManagerInfo,
              properties: [property],
              padStart: 3
            })}
          />
        ) : (
          <ShowDataValue data={fuelManagerInfo} property={property} />
        )}
      </DataRowValue>
    </DataRow>
  )

  return (
    <Col>
      <CardSectionTitle>Fuel Manager</CardSectionTitle>
      <CardSection>
        {renderDataRow('Serial number', 'serialNumber')}
        {renderDataRow('Firmware version', 'firmwareVersion')}
        {fuelManagerInfo.errorMajor !== undefined && fuelManagerInfo.errorMinor !== undefined && renderDataRow('Active error', 'errorMajor', true)}
      </CardSection>
    </Col>
  )
}

export default FuelManagerSection
