import * as C from 'appConstants'
import { ErrorResponseBody } from 'declarations/@types/alltypes'

enum OPERATIONS {
    GROUP = 'group'
}

export type ErrorReturnType = {
    message: string;
    operation: OPERATIONS;
}

const COMMON_GROUP_ERRORS = {
    401: C.UNAUTHORIZED_ERROR,
    500: C.SOMETHING_WENT_WRONG
}

const ERRORS_LIST = {
    GET_USER_GROUPS: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_VALIDATION_ERROR,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION
    },
    CREATE_USER_GROUPS: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_HAS_ATLEAST_ONE_ADMIN,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        409: C.USER_GROUP_ALREADY_EXISTS
    },
    GET_ONE_USER_GROUP: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_VALIDATION_ERROR,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        404: C.USER_GROUP_NOT_FOUND
    },
    REQUEST_UPDATE_USER_GROUP: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_HAS_ATLEAST_ONE_ADMIN,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        404: C.USER_GROUP_NOT_FOUND,
        409: C.USER_GROUP_ALREADY_EXISTS
    },
    DELETE_USER_GROUP: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_VALIDATION_ERROR,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        404: C.USER_GROUP_NOT_FOUND
    },
    DELETE_USER_GROUP_INVITE: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_VALIDATION_ERROR,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        404: C.USER_GROUP_NOT_FOUND
    },
    LEAVE_USER_GROUP: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_HAS_ATLEAST_ONE_ADMIN,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION
    },
    DELETE_USER_GROUP_MEMEBERSHIP: {
        ...COMMON_GROUP_ERRORS,
        400: C.GROUP_HAS_ATLEAST_ONE_ADMIN,
        403: C.INSUFFICIENT_USER_GROUP_PERMISSION,
        404: C.USER_GROUP_NOT_FOUND
    }

}

const getStatusMessage = (status: number, messageCodes: any, operation: OPERATIONS): ErrorReturnType => ({
    message: messageCodes[status],
    operation: operation
})

export const groupErrorHandler = (errorBody: ErrorResponseBody): ErrorReturnType => {
    const status = errorBody.error.status
    switch (errorBody.type) {
        case C.GET_USER_GROUPS:
        case C.REQUEST_UPDATE_USER_GROUP:
        case C.CREATE_USER_GROUPS:
        case C.GET_ONE_USER_GROUP:
        case C.DELETE_USER_GROUP:
        case C.DELETE_USER_GROUP_INVITE:
        case C.LEAVE_USER_GROUP:
        case C.DELETE_USER_GROUP_MEMEBERSHIP:
            return getStatusMessage(status, ERRORS_LIST[errorBody.type], OPERATIONS.GROUP)
        default: return {
            message: C.SOMETHING_WENT_WRONG,
            operation: OPERATIONS.GROUP
        }
    }
}
