// @flow

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default styled(FontAwesomeIcon)`
  float: right;
  cursor: pointer;
  color: var(--color-primary);
`
