// @flow

import { useHistory } from 'react-router-dom'

import { Row, Col, PageTitle, Text, SubTitle } from 'components/ReUsable'
import styled from 'styled-components'
import HeaderBackButton from '../HeaderBackButton'

export const StyledSubTitle = styled(SubTitle)`
  font-size: 1.25rem;
  margin-bottom: 0.9rem;
  color: #4b4b4b;
`

export const StyledText = styled(Text)`
  font-family: open-sans-regular;
  font-size: 0.9rem;
  color: #4b4b4b;
`

const StyledInfoCol = styled(Col)`
  padding: 0;
  & > h3,
  & > p {
    margin-bottom: 0;
  }
`

const StyledOwnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center & > p {
    margin-bottom: 0;
  }

  & > p {
    margin-bottom: 0;
  }
`

const StyledImg = styled.img<{isOwner: any}>`
  width: ${(props: any) => (props.isOwner ? '1.5rem' : '1.25rem')};
  height: ${(props: any) => (props.isOwner ? '1.5rem' : '1.25rem')};
  margin-right: 0.5rem;
`

export const StyledBackLinkContainer = styled.div`
  cursor: pointer;

  &:hover,
  &:focus {
    filter: brightness(0.8);
  }
`

export const OwnershipValues = {
  OWNER: 'owner',
  DELEGATED: 'delegated',
  NOT_LINKED_TO_DEVICE: 'not_linked_to_device'
}

export const StyledDeviceImage = styled.img`
  max-width: 10rem;
  height: auto;
`

export const StyledPageTitle = styled(PageTitle)`
  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
`

type HeaderBarProps = {
  backlinkText: string,
  backlinkUrl: string,
  headerText: string,
  device?: any,
  serialNumber?: string,
  resetReferrer?: boolean
};

export const OwnerShipView = function ({
  ownershipStatus
}: {
  ownershipStatus: string,
}) {
  if (
    !ownershipStatus ||
    ownershipStatus === OwnershipValues.NOT_LINKED_TO_DEVICE
  ) {
    return (
      <StyledOwnerContainer>
        <StyledText></StyledText>
      </StyledOwnerContainer>
    )
  }
  const isOwner = ownershipStatus === OwnershipValues.OWNER
  const imgName = isOwner ? 'owner-green' : 'delegate-green'
  const viewText = isOwner ? 'Owner' : 'Delegated'
  return (
    <StyledOwnerContainer>
      <StyledImg
        alt={viewText}
        src={`/imgs/${imgName}.svg`}
        isOwner={isOwner}
        data-cy="owner-icon"
      />
      <StyledText data-cy="owner-text">{viewText}</StyledText>
    </StyledOwnerContainer>
  )
}

export const HeaderBar = function ({
  backlinkText,
  backlinkUrl,
  headerText,
  device,
  serialNumber,
  resetReferrer
}: HeaderBarProps) {
  const history = useHistory()
  const ownershipStatus = device?.ownershipStatus?.toString() || ''

  return (
    <Row margin="1rem 0 0 0" style={{ minHeight: '92px' }}>
      <Col base="60%" justifyContent="space-around" data-cy="header-part">
        <HeaderBackButton backlinkText={backlinkText} handleButtonClick={() => {
            history.push({
              pathname: backlinkUrl,
              state: { from: resetReferrer ? '' : history?.location?.pathname } //  Put referrer into the state. (Used on Home.tsx)
            })
        }}/>
        <StyledPageTitle data-cy="">{headerText}</StyledPageTitle>
      </Col>
      {serialNumber && device && (
        <Col base="40%" alignAlways={true} alignItems="flex-start">
          <Row noWrap={true}>
            <Col base="34%" justifyContent="center">
              <StyledDeviceImage src={device.img_url} alt="Product" />
            </Col>
            <StyledInfoCol base="66%" justifyContent="space-around">
              <StyledSubTitle data-cy="device-name">
                {device.name}
              </StyledSubTitle>
              <StyledText data-cy="serial-number">
                Serial number: {serialNumber}
              </StyledText>
              <OwnerShipView ownershipStatus={ownershipStatus} />
            </StyledInfoCol>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default HeaderBar
