import * as C from 'appConstants'
import { UserGroupType } from 'types/userGroup'

const initialState = {
  totalPages: 0,
  totalElements: 0,
  size: 0,
  content: [],
  number: 0,
  sort: {
    empty: true,
    unsorted: true,
    sorted: true
  },
  first: true,
  last: true,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    sort: {
      empty: true,
      unsorted: true,
      sorted: true
    },
    paged: true,
    unpaged: true,
    pageNumber: 0,
    pageSize: 0
  },
  empty: true,
  current: null,
  currentPage: 1,
  loading: false
}

const performUserGroupLoading = (state) => {
  return ({
    ...state,
    loading: true
  })
}

const populateUserGroups = (state, payload: UserGroupType) => {
  payload.content?.sort((a, b) => {
    const groupA = a?.slug?.toUpperCase()
    const groupB = b?.slug?.toUpperCase()
    if (groupA === '') return 1
    if (groupB === '') return -1
    if (groupA < groupB) return -1
    if (groupA > groupB) return 1
    return 0
  })

  return ({
    ...state,
    ...payload,
    loading: false
  })
}

const populateOneUserGroup = (state, payload) => {
  payload.memberships.sort((a, b) => {
    const groupAMemberType = a?.membershipType?.toUpperCase()
    const groupBMemberType = b?.membershipType?.toUpperCase()
    const groupAUser = a?.user?.fullName?.toUpperCase()
    const groupBUser = b?.user?.fullName?.toUpperCase()
    if (groupAMemberType < groupBMemberType) return -1
    if (groupAMemberType > groupBMemberType) return 1

    if (!groupAUser || !groupBUser) {
      return 0
    }

    if (groupAUser < groupBUser) return -1
    if (groupAUser > groupBMemberType) return 1
    return 0
  })
  return ({
  ...state,
  current: { ...payload }
})
}

const clearOneUserGroup = (state) => ({ // eslint-disable-line @typescript-eslint/no-unused-vars
  ...state,
  current: null
})

const addNewGroup = (state, payload) => {
  const newGroup = {
    address: '',
    company: '',
    name: '',
    slug: '',
    permissions: {
      'group:leave': 'granted',
      'group:delete': 'granted',
      'group:modify': 'granted'
    },
    memberships: [
      {
        membershipType: 'admin',
        user: {
          email: payload.email,
          auth0Id: payload.sub
        }
      }
    ],
    isNew: true
  }

  const groupsWithNewForm = JSON.parse(JSON.stringify(state))
  groupsWithNewForm.current = newGroup
  groupsWithNewForm.content.push(newGroup)
  return populateUserGroups(state, groupsWithNewForm)
}

const cancelAddGroup = (state) => {
  state.content.splice(state.content.length - 1, 1)
  return {
    ...state,
    current: null
  }
}

const populateUpdateGroupPagination = (state, payload) => {
  return {
    ...state,
    currentPage: payload
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_USER_GROUPS:
      return populateUserGroups(state, payload)
    case C.POPULATE_ONE_USER_GROUP:
      return populateOneUserGroup(state, payload)
    case C.CLEAR_ONE_USER_GROUP:
      return clearOneUserGroup(state)
    case C.ADD_NEW_GROUP:
      return addNewGroup(state, payload)
    case C.CANCEL_ADD_GROUP:
      return cancelAddGroup(state)
    case C.POPULATE_UPDATE_PAGINATION:
      return populateUpdateGroupPagination(state, payload)
    case C.PERFORM_USER_GROUP_LOADING:
        return performUserGroupLoading(state)
    default:
      return state
  }
}
