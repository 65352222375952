// @flow

import { ALERT_TYPE, Alert } from 'components/ReUsable'

type FormikErrorsProps = {
  errors: any
}

type Props = {
  errors: any,
  errorMessage: string
}

// Finally a re-usable component for formik errors
const FormikErrors = (props: FormikErrorsProps) => {
  const messages: any[] = []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(props.errors).forEach(([_key, value]) => {
    messages.push(value)
  })

  return (
    <Alert messages={ messages } type={ ALERT_TYPE.danger } />
  )
}

export default function FormErrors (props: Props) {
  const { errors, errorMessage } = props

  if (Object.entries(errors).length > 0) {
    return <FormikErrors errors={ errors } />
  }

  if (errorMessage.length > 0) {
    return <Alert message={ errorMessage } type={ ALERT_TYPE.danger } />
  }

  return null
}
