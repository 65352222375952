// @flow
import styled from 'styled-components'

import SortingArrow from './sortingArrow'
import { Col } from 'components/ReUsable'
import { NEW_API_ACTIVE } from 'featureToggles'

const StyledCol = styled(Col)`
  flex: 0 0 16px;
  min-height: 16px;
  margin-left: 5px;
  @media (min-width: 991.97px) {
    margin-left: 0;
  }
`

type Props = {
  sorting: any
  onSort: (e: React.MouseEvent<HTMLDivElement>) => void
  sortKey?: string
}

const SortCell = (props: Props) => {
  const { sortKey, sorting, onSort } = props
  if (!NEW_API_ACTIVE && sortKey !== 'serialNumber' && sortKey !== 'name') {
    return null
  }
  return (
        <StyledCol
          onClick={onSort}
          base="1px"
          justifyContent="center"
          alignItems="center"
          data-cy={`sortBy-${sortKey}`}
        >
          <SortingArrow sortKey={sortKey} sorting={sorting} />
        </StyledCol>

  )
}

export default SortCell
