// @flow

import { useEffect } from 'react'

import { Container, HeaderBar, SelectedEfoy } from 'components/ReUsable'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useBatchEditDelegation } from './hooks/useBatchEditDelegation'
import DelegationFormUI from './components/DelegationFormUI'
import DelegationResultUI from './components/DelegationResultUI'
import RoleInfoPanel from './components/RoleInfoPanel'

const ADD = 'ADD'
const REMOVE = 'REMOVE'
const ADD_HEADER = 'Add User/Group to EFOYs'
const DELETE_HEADER = 'Remove User/Group from EFOYs'
const BACK_LINK_TEXT = 'Back to EFOY fuel cells'

function getHTTPVerb (mode: typeof ADD | typeof REMOVE) {
  return mode === REMOVE ? 'DELETE' : 'POST'
}

const BatchDelegation = ({ match }: { match: any }) => {
  const history = useHistory()
  const selectedDevices = useSelector((state: any) => state.devices.selectedDevices.map((device: any) => device.serialNumber))
  const selectionActive = useSelector((state: any) => state.devices.selectionActive)
  const mode =
    match.params.mode && match.params.mode.toUpperCase() === ADD ? ADD : REMOVE
  const headerText = mode === ADD ? ADD_HEADER : DELETE_HEADER
  const { error, success, formik, backLink, delegationResponse } =
    useBatchEditDelegation(getHTTPVerb(mode))

  useEffect(() => {
    if (selectedDevices.length === 0) {
      history.push('/')
    }
  }, [history, selectedDevices.length])

  return (
    <main role="main">
      <Container data-cy="page-batch-delegation">
        <HeaderBar
          headerText={headerText}
          backlinkText={BACK_LINK_TEXT}
          backlinkUrl={'/'}
          resetReferrer={!!delegationResponse}
        />
        {selectionActive && (
          <>
            <SelectedEfoy count={selectedDevices.length} margin="0 0 10px 0" />
            {delegationResponse ? (
              <DelegationResultUI
                delegationResponse={delegationResponse}
                backLink="/"
                mode={mode}
              />
            ) : (
              <DelegationFormUI
                {...{
                  isBatch: true,
                  error,
                  success,
                  formik,
                  backLink,
                  mode
                }}
              />
            )}
          </>
        )}
        <RoleInfoPanel/>
      </Container>
    </main>
  )
}

export default BatchDelegation
