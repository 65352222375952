// @flow

import { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'

import {
  submitDeviceDelegation,
  removeDeviceDelegation
} from 'apis/devices'

import {
  CARTRIDGE_MONITORING
} from 'appConstants'
import { performIdentityCheck } from 'apis/groups'

export const IDENTITY_NOT_EXISTS = 'User is not registered or group does not exist.'

export function useAddDelegation (serialNumber: string) {
  const history = useHistory()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | number>('')
  const [lastInsertedName, setLastInsertedName] = useState('')
  const backLink = `/devices/${serialNumber}/manageroles`
  const formik = useFormik({
    initialValues: {
      name: '',
      role: CARTRIDGE_MONITORING,
      submitBtnId: ''
    },
    onSubmit: async (values, { resetForm }) => {
      const nameVal = values.name?.toLowerCase().trim()
      const completedWithSuccess = await makeDelegationAPICall(
        nameVal,
        values.role,
        'PUT'
      )
      if (completedWithSuccess) {
        setLastInsertedName(nameVal)
        if (values.submitBtnId === 'button-add') {
          resetForm()
        } else if (completedWithSuccess) {
          history.push(backLink)
        }
      }
    },
    validate: (values) => {
      const errors: { name?: string } = {}
      if (!values.name) {
        errors.name = 'Required!'
      }

      return errors
    },
    validateOnChange: false,
    validateOnBlur: false
  })
   //  Remove or Update delegation
  const makeDelegationAPICall = async (identity: string, role: string, type: 'PUT' | 'DELETE') => {
    try {
      setSuccess(false)
      setError('')
      //  Check if user already exists.
      const identityExists = await performIdentityCheck(identity)
      if (!identityExists) {
        setError(IDENTITY_NOT_EXISTS)
        return false
      }

      const newDelegation = type === 'DELETE' ? await removeDeviceDelegation(serialNumber, { identity, role }) : await submitDeviceDelegation(serialNumber, { identity, role })
      if (newDelegation.status >= 400) {
        throw newDelegation.status
      } else {
        setSuccess(true)
        return true
      }
    } catch (e: any) {
      setError(e)
      return false
    }
  }

  return { makeDelegationAPICall, error, success, formik, lastInsertedName, setLastInsertedName, backLink }
}
