import * as C from 'appConstants'

const initialState = {
  user: {
    address: '',
    company: '',
    country: '',
    email: '',
    family_name: '',
    given_name: '',
    phone: '',
    postcode: '',
    website: ''
  },
  updateUserAccountAction: {
    updateSuccess: false,
    updateErrorMessage: ''
  },
  updateUserAccountActionPassword: {
    updateSuccess: false
  },
  deleteUserAccountAction: {
    deletionSuccess: false,
    deletionErrorMessage: ''
  }
}

function populateDeleteUserAccount (state, payload) {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      deleteUserAccountAction: {
        deletionSuccess: false,
        deletionErrorMessage: C.SOMETHING_WENT_WRONG
      }
    }
  }

  return {
    ...state,
    deleteUserAccountAction: {
      deletionSuccess: true,
      deletionErrorMessage: ''
    }
  }
}

function populateGetUserAccount (state, payload) {
  const newState = Object.assign({}, state)

  newState.user = payload.response
  //  Set initially to false.
  newState.updateUserAccountAction.updateSuccess = false
  return newState
}

function populateUpdateUserAccount (state, payload) {
  const newState = Object.assign({}, state)

  newState.updateUserAccountAction.updateSuccess = true
  newState.updateUserAccountAction.updateErrorMessage = ''

  if (payload.response.name && payload.response.name.includes('Error')) {
    newState.updateUserAccountAction.updateSuccess = false
    newState.updateUserAccountAction.updateErrorMessage = C.SOMETHING_WENT_WRONG
  }

  return newState
}

function populateUpdateUserAccountPassword (state, payload) {
  const newState = Object.assign({}, state)

  newState.updateUserAccountActionPassword.updateSuccess = true
  newState.updateUserAccountAction.updateErrorMessage = ''

  if (payload.response.name && payload.response.name.includes('Error')) {
    newState.updateUserAccountActionPassword.updateSuccess = false
    newState.updateUserAccountAction.updateErrorMessage = C.SOMETHING_WENT_WRONG
  }

  return newState
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_GET_USER_ACCOUNT:
      return populateGetUserAccount(state, payload)
    case C.POPULATE_UPDATE_USER_ACCOUNT:
      return populateUpdateUserAccount(state, payload)
    case C.POPULATE_UPDATE_USER_ACCOUNT_PASSWORD:
      return populateUpdateUserAccountPassword(state, payload)
    case C.POPULATE_DELETE_USER_ACCOUNT:
      return populateDeleteUserAccount(state, payload)
    default:
      return state
  }
}
