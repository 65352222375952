import React from 'react'
import styled from 'styled-components'

import { ListInformationResponse } from 'apis/advancedInfomation'
import Loading from './loading'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import { getDateStringOrInputString } from 'utils/helpers'

const StyledContent = styled.div`
  width: 100%;
  margin-top: 7px;
`

const StyledTable = styled.div`
  display: grid;
  min-width: 100%;
`

const StyledTableHeaderRow = styled.div`
  display: grid;
  min-height: 42px;
  background: #8E8E93;
  color: white;
  font-family: DigitalSerialBold;

  @media (min-width: 991.98px) {
    font-size: 16px;
  }
`

const StyledTableRow = styled.div`
  display: grid;
  font-family: open-sans-regular;
  min-height: 42px;

  &:nth-child(2n) {
    background: #F4F4F4;
  }
`

const StyledTableCell = styled.div`
  font-size: 12px;
  &:not(:last-child) {
    border-right: 1px solid #ABABAB;
  }
  
  display: flex;
  align-items: center;
  padding: 0 4px;

  @media (min-width: 991.98px) {
    padding: 0 16px;
    font-size: 14px;
  }
`

const TableSection: React.FC<{ resp: ListInformationResponse | null; loading: boolean }> = ({ resp, loading }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const getGridColDefinition = (count: number) => {
    if (!matches) {
      return `repeat(${count}, ${96 / count}%)`
    } else {
      const colCount = resp?.header?.length

      if (colCount && colCount > 4) {
        return `repeat(${count - 1}, calc(100% / ${colCount})) 2fr`
      } else {
        return `repeat(${count - 1}, 180px) 2fr`
      }
    }
  }
  return (
    <StyledContent>
      {loading ? (
        <Loading />
      ) : !resp?.data ? (
        <></>
      ) : (
        <>
        <StyledTable data-cy="table-content">
          <StyledTableHeaderRow style={{ gridTemplateColumns: getGridColDefinition(resp?.header.length) }}>
            {resp?.header.map((h: string) => (
              <StyledTableCell key={h}>{h}</StyledTableCell>
            ))}
          </StyledTableHeaderRow>
          {resp?.data.map((row: string[], ind: number) => (
            <StyledTableRow key={`datarow-${ind}`} style={{ gridTemplateColumns: getGridColDefinition(row.length) }}>
              {row.map((h: string, index: number) => (
                <StyledTableCell key={`${h}-${index}`}>{getDateStringOrInputString(h)}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTable>
        </>
      )}
    </StyledContent>
  )
}

export default TableSection
