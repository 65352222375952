// @flow

import {
  backendEndpoint,
  executeRequestSWR
} from '.'
import { addDays, getUnixTime, startOfDay } from 'date-fns'

const endpoint = `${backendEndpoint || ''}`

export const getAudits = ({ eventType, pageNumber, startDate, endDate } : { eventType: string, pageNumber?: number, startDate?: number, endDate?: number }) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  const startDateVal = startDate ? getUnixTime(startDate) : ''
  const endDatePlusOne = endDate ? getUnixTime(startOfDay(addDays(endDate, 1))) : ''

  const sorting = { sortKey: 'timestamp', sortDirection: 'desc' }

  const startDateQuery = startDateVal ? `&start=${startDateVal}` : ''
  const endDateQuery = endDatePlusOne ? `&end=${endDatePlusOne}` : ''

  return executeRequestSWR(`${endpoint}/audits?size=25${startDateQuery}${endDateQuery}&page=${pageNumber || 1}${eventType ? '&typeIn=' + eventType : ''}`, undefined, sorting, options)
}
