// @flow

import styled from 'styled-components'

import {
  Row,
  Col,
  PageTitle,
  Text,
  Container
} from 'components/ReUsable'
import { useState } from 'react'

const SubTitle = styled.span`
  color: var(--color-primary);
  font-size: 14px;
`

const StyledLanguageContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const StyledLanguageButton = styled.button`
  background: transparent;
  border: none;
  color: ${(props: {active: boolean}) => props.active ? 'var(--color-primary)' : 'lightgray'}
`

const StyledSeparator = styled.span`
  position: relative;
  bottom: 1px;
`

export default function TermsOfUse () {
  const browserCulture = (window as any).navigator.userLanguage || (window as any).navigator.language
  const browserLanguage = browserCulture.split('-')[0] || 'EN'

  const [language, setLanguage] = useState(browserLanguage.toUpperCase())
  const EN_CONTENT = (
    <>
     <Row margin="0 0 37px 0">
      <Col>
        <PageTitle>Terms of Use</PageTitle>
      </Col>
     </Row>
      <Row>
        <Col>
          <Text>The EFOY Cloud is an online accessible remote monitoring system for EFOY fuel cells and accessories of SFC Energy AG. These Terms of Use shall apply to the use of the EFOY Cloud. The Terms of Use shall apply to the exclusion of any deviating terms and conditions of the user.</Text>
          <SubTitle>1. Definitions</SubTitle>
          <Text>&quot;Authorised users&quot; means the users authorised by the owner of a User Account for a specific device.</Text>
          <Text>&quot;device&quot; means the respective EFOY fuel cell including accessories.</Text>
          <Text>&quot;EFOY Cloud&quot; means the online accessible remote monitoring system for the control, monitoring and use of EFOY fuel cells and their accessories.</Text>
          <Text>&quot;SFC&quot;, &quot;we&quot;, &quot;us&quot; means SFC Energy AG.</Text>
          <Text>&quot;User Account&quot; means the online account created on the EFOY Cloud platform in order to use the services of the EFOY Cloud.</Text>
          <Text>&quot;user&quot;, &quot;you&quot;, &quot;your&quot; means the registered user of the EFOY Cloud and owner of an User Account.</Text>
          <SubTitle>2. Services, service exclusions</SubTitle>
          <Text>The EFOY Cloud enables remote monitoring of EFOY fuel cells, including control, monitoring and use of the relevant device and connected accessories. This includes accessing device data, displaying and downloading certain data and making settings for the purpose of remote maintenance. Certain services of the EFOY Cloud can only be used with a device if the user purchases and activates a paid license key for the device (see section 6 below).</Text>
          <Text>The EFOY Cloud does not replace the regular inspection and maintenance of the EFOY fuel cell and accessories and in particular the visual inspection of battery status and tank status on the device itself. SFC does not guarantee and assumes no liability that the transmission of data from the device to the EFOY Cloud or data provided via the EFOY Cloud is error-free, complete or up-to-date.</Text>
          <SubTitle>3. Registration and User Account</SubTitle>
          <Text>The use of the EFOY Cloud requires registration as an User. This applies to every user, regardless of whether they want to connect to their own devices or third-party devices. Registration takes place via the EFOY Cloud website: <a href="https://www.efoy-cloud.com" target="_blank" rel="noreferrer">https://www.efoy-cloud.com</a>. For registration you must enter your name (first and last name), an e-mail address and an authentication attribute (e.g. password). In addition, you may voluntarily enter further information that allows as to communicate with you more easily (e.g. company name, telephone, address, country). Required fields are marked with an asterisk (*) or otherwise; all other fields ask for voluntary information. The voluntary information can also be entered later. All information provided by the user must be true and accurate. The e-mail address provided during registration cannot be changed; the other contact information can be changed.</Text>
          <Text>We use the user&apos;s data to manage the User Account, to contact the user and to answer enquiries. The data protection policy of the EFOY Cloud applies to all data related to an User Account: <a href="https://www.efoy-cloud.com/gdpr/" target="_blank" rel="noreferrer">https://www.efoy-cloud.com/gdpr/</a>.</Text>
          <Text>The user is responsible for the lawful use of the e-mail-address and for keeping the password secret.</Text>
          <Text>Successful registration creates an User Account that can be connected to devices. You can find out whether your device can be used with the EFOY Cloud here: <a href="https://www.efoy-pro.com/en/service/cloud-license/" target="_blank" rel="noreferrer">https://www.efoy-pro.com/en/service/cloud-license/</a>. If no devices are connected to an User Account over a longer period of time, we reserve the right to temporarily or permanently block or delete such an unused account.</Text>
          <Text>The type and scope of possible use of the EFOY Cloud depend on the respective device and the EFOY Cloud licence purchased for the device, if applicable.</Text>
          <SubTitle>4. Adding users to a device</SubTitle>
          <Text>Depending on the licence you have purchased, you can add further users via your User Account to a device and assign certain rights to such users. These Terms of Use apply to all users of your device.</Text>
          <Text>In particular, you may connect a service partner to a device who will take care of the remote monitoring of your device. We are not responsible for the agreement between you and the service partner.</Text>
          <Text>The users authorised by you must first open an User Account (see section 3 above). They can then access the connected EFOY fuel cells in accordance with the rights granted. An authorised user can also connect further users to a device.</Text>
          <Text>Each user is responsible for assigning rights to the users authorised by him/her. Each user who has assigned rights can remove the users authorised by him/her from a device at any time or change or restrict the assigned authorisation. The owner of the User Account who has added a device can manage, change or remove the rights of all other users for this relevant device at any time.</Text>
          <Text>You, as the owner of the device, are responsible for ensuring that the users authorised by you agree to the use of their respective data in relation to your device and operate the connected device properly. Upon request of the user you have added to a device, you will immediately remove such user.</Text>
          <Text>You are solely responsible for all activities of users authorised by you for a device. SFC is not responsible or liable for the activities of users authorised by you.</Text>

          <SubTitle>5. Connecting EFOY fuel cells</SubTitle>
          <Text>If you would like to add one or more EFOY fuel cells to your User Account, you must enter the serial number of the relevant device in your User Account under &quot;Add EFOY&quot;. A one-time password (&quot;claiming key&quot;) will then be generated for the device. This one-time password is not identical to the password of your User Account! The one-time password is retrieved using the EFOY Operating Panel (OP3) or the EFOY app. The relevant end user device on which the EFOY App is used must be connected to the device via the EFOY Bluetooth® Adapter.</Text>
          <Text>You can assign an individual name to the respective device. Please note that the name you choose will be visible to every authorised user as the device name.</Text>
          <Text>You can remove the device from the User Account at any time.</Text>
          <Text>The EFOY Cloud receives and stores the device data only for the time period for which the device is connected to the Internet. The device does not have a memory that (temporarily) stores the accruing data and subsequently transmits it to the EFOY Cloud. The data transmitted by the device to the EFOY Cloud can be viewed via your User Account. For the storage period, see section 7 below.</Text>

          <SubTitle>6. Acquisition and use of paid licences (licence keys)</SubTitle>
          <Text>The services of the EFOY Cloud are offered in different licence versions, which enable a different usage.</Text>
          <Text>You can find the current conditions, licence terms and how to acquire a licence key for the respective EFOY fuel cell here: <a href="https://www.efoy-pro.com/en/service/cloud-license/" target="_blank" rel="noreferrer">https://www.efoy-pro.com/en/service/cloud-license/</a>.</Text>
          <Text>The purchased license key entitles the purchaser to use the EFOY Cloud in the scope of functions specified in the license for the respective device for the term of the license key.</Text>
          <Text>The term of the license key only begins with the activation of the license key for the relevant device in the EFOY Cloud (see section 5 above). Activation requires that you enter the license key in the EFOY Cloud.</Text>
          <Text>The activated license key and the related right to use the EFOY Cloud are device-specific (serial number) and cannot be transferred to other devices.</Text>
          <Text>The licence for the EFOY Cloud is not tied to a sales partner of SFC and only refers to the use of the respective device in the EFOY Cloud. It is independent of any accounts or user groups of sales partners of SFC in the EFOY Cloud.</Text>

          <SubTitle>7. Data collection and availability</SubTitle>
          <Text>The device data of the EFOY fuel cell is automatically transferred from the connected device to the EFOY Cloud and stored in the EFOY Cloud when an Internet connection is established. This applies regardless of the existence of an User Account. As long as the device is not actively connected to an User Account, we cannot assign the device data to any user. The data transmitted to us will only be made available in the EFOY Cloud in the User Account once the license key for the relevant device has been activated.</Text>
          <Text>The provision of data under the Licence shall automatically terminate upon expiry of the Licence (see section 6 above).</Text>
          <Text>The actions performed on the connected EFOY fuel cell by you or by authorised users are logged and can be viewed in the device view in the EFOY Cloud (so-called audit log). This data is stored for a period of up to 18 months. SFC accepts no liability for the completeness or accuracy of such data.</Text>
          <Text>The EFOY Cloud is not intended to be used as a storage, backup or archiving service. Storage of usage data is only intended for a rolling period of 18 months (at the earliest from the initial successful connection of a device with an User Account). SFC assumes no liability and does not provide any guarantee for the availability of this data in the event of data loss. Regular data backup is the responsibility of the user. The user has the option to export certain data by download and to back it up on his or her own devices. In the event of loss, recovery of the data by SFC is not possible.</Text>

          <SubTitle>8. Use of device data</SubTitle>
          <Text>SFC collects, stores and processes device data in order to provide the services and functionalities of the EFOY Cloud and to continuously improve them. SFC reserves the right to anonymise the device data for statistical purposes and for the purposes of research, development and improvement of the products and to analyse and process it in anonymised form (i.e. without reference to individuals). For this purpose, SFC is also entitled to pass on device data (without reference to individuals) to affiliated companies. We may use third parties as service providers (e.g. data centre service providers or authentication service providers) under a data processing agreement. We use your data for the purpose of advertising our products if you have given your consent for this purpose or if this is otherwise permitted by law. Beyond this, we only use your device data in connection with your User Account for the performance of the contract and answering your enquiries and do not pass them on to third parties without authorisation.</Text>
          <SubTitle>9. Responsibility of the user</SubTitle>
          <Text>Full use of the EFOY Cloud requires that the EFOY fuel cell is successfully and permanently connected to the EFOY Cloud via the Internet. The costs for setting up and maintaining the connection are borne by the user. The technical provision and availability of the connection between the EFOY fuel cell and the EFOY Cloud is the responsibility of the user (e.g. connection quality, firewall configuration, network configuration, power supply). The use of the EFOY Cloud also requires the use of an EFOY Operating Pannel (OP3) or other EFOY accessories (e.g. Bluetooth® Adapter) in order to connect the device to an User Account. When using the web application for the EFOY Cloud and/or a smartphone to use the EFOY app, connection fees for the connection to the Internet may be incurred and shall be borne by the user.</Text>
          <Text>The user shall notify SFC immediately if he or she learns of any unauthorised use of his User Account or any other form of breach of the security of the EFOY Cloud.</Text>
          <Text>The user is responsible for regular data backups (see section 7 above).</Text>
          <Text>In the event that an EFOY fuel cell is sold, the user is responsible for deleting the data on his/her device and removing the device from his/her User Account (see section 11 below).</Text>

          <SubTitle>10. Blocking and deleting the User Account</SubTitle>
          <Text>In the event of a breach of these Terms of Use or of applicable laws, we are entitled to block your access to your User Account and/or the access of users authorised by you to your devices. This includes, but is not limited to, misuse of the respective User Account that impairs or jeopardises the general availability and/or security of the EFOY Cloud, customer data and/or devices. In the event of a justified blocking or deletion of the User Account, any licence fees paid will not be refunded. SFC’s right to claim damages remains unaffected.</Text>
          <SubTitle>11. Sale of a device</SubTitle>
          <Text>If the user of the EFOY Cloud sells or otherwise disposes of an EFOY fuel cell connected to his or her User Account, such user undertakes to remove the relevant device from his/her User Account without undue delay. SFC is entitled, but not obligated, to remove the respective device from the User Account of the previous owner at the request of a third party who adequately proves the acquisition of ownership and possession of the EFOY fuel cell.</Text>
          <SubTitle>12. Changes to the EFOY Cloud and these Terms of Use</SubTitle>
          <Text>SFC may make changes to the EFOY Cloud and these Terms of Use at any time to improve the security of the application and data protection, to comply with legal and regulatory requirements, to make adjustments to legal changes or to improve or expand the functionality of the EFOY Cloud. For this purpose, SFC is entitled to make updates or improvements to the EFOY Cloud and the EFOY App as well as the firmware of the EFOY fuel cell and accessories via an existing Internet connection at any time.</Text>
          <SubTitle>13. Copyright</SubTitle>
          <Text>The EFOY Cloud is protected by copyright. SFC grants the respective user and the user authorised by him a non-exclusive right to use the EFOY Cloud and the associated web application for the operation of the EFOY fuel cells. The permitted scope of use depends on the licence version purchased. Use is limited to the duration of the respective licence purchased. For the EFOY app, the terms of use and data protection policy provided in the app shall apply.</Text>
          <SubTitle>14. Term and termination</SubTitle>
          <Text>These Terms of Use apply for an unlimited period of time to any use of the EFOY Cloud.</Text>
          <Text>The term of an acquired license begins from the day of activation of the respective acquired license for the corresponding EFOY fuel cell by entering the license key and remains in force for the duration provided for in the license.</Text>
          <Text>You can stop using the EFOY Cloud at any time and delete your User Account. In this case, there will be no refund of the purchase price for purchased or possibly already activated license keys.</Text>
          <Text>If you delete your User Account, the connection to the EFOY fuel cells in your User Account up to that point will be removed and the users authorised by you will lose their access to the EFOY fuel cells connected up to that point. Personal data held by us will generally be deleted unless there are legal obligations to retain such data. It is no longer possible to restore the User Account. However, you can create a new User Account at any time. You may then administer any new data with the new User Account.</Text>
          <Text>The right of each party to terminate for cause remains unaffected.</Text>
          <SubTitle>15. Warranties</SubTitle>
          <Text>We provide the EFOY Cloud with reasonable care. We give no guarantee that the data and the EFOY Cloud will be available at all times and without interruption.</Text>
          <Text>The warranty and liability for defects for the EFOY fuel cells and accessories are governed by the General Terms and Conditions of SFC and the applicable warranty conditions.</Text>
          <SubTitle>16. Liability</SubTitle>
          <Text>SFC shall be liable, irrespective of the legal grounds, in the event of damages caused by simple negligence only in the event of breach of material obligations by its legal representatives or vicarious agents and then only limited to compensation for the typical damage foreseeable for SFC at the time of conclusion of the contract. In all other cases, SFC shall not be liable for damage caused by simple negligence.</Text>
          <Text>The limitations of this section 16 do not apply to SFC&apos;s liability for intentional or grossly negligent conduct, for guaranteed characteristics, for injury to life, limb or health or under the Product Liability Act.</Text>
          <SubTitle>17. Place of performance, law, place of jurisdiction</SubTitle>
          <Text>The exclusive place of performance for delivery and payment obligations is the registered office of SFC.</Text>
          <Text>All legal relations between the client and SFC shall be governed by German law to the exclusion of the UN Convention on Contracts for the International Sale of Goods. The place of jurisdiction for all disputes is Munich. In addition, SFC is also entitled to bring an action at the customer&apos;s place of business.</Text>
          <Text>Should one or more of these provisions be or become invalid or unenforceable in whole or in part, this shall not affect the validity of the remaining provisions.</Text>
          <Text>Amendments and supplements as well as collateral agreements must be made in writing to be valid.</Text>
        </Col>
      </Row>
    </>
  )

  const DE_CONTENT = (
    <>
      <Row margin="0 0 37px 0">
        <Col>
          <PageTitle>Allgemeine Nutzungsbedingungen</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text>
            Die EFOY Cloud ist ein online zugängliches Fernüberwachungssystem
            für EFOY Brennstoffzellen und Zubehör der SFC Energy AG. Für die
            Nutzung der EFOY Cloud gelten ausschließlich diese Allgemeinen
            Nutzungsbedingungen. Abweichende Bedingungen des Nutzers finden
            keine Anwendung.
          </Text>
          <SubTitle>1. Begriffsbestimmungen</SubTitle>
          <Text>
            „EFOY Cloud“ bezeichnet das online zugängliche
            Fernüberwachungssystem zur Steuerung, Überwachung und Nutzung von
            EFOY Brennstoffzellen und ihrem Zubehör.
            </Text>
            <Text>
            „Gerät“ bezeichnet die jeweilige EFOY Brennstoffzelle einschließlich
            Zubehör.
            </Text>
            <Text>
            „Nutzer“, „Sie“, „Ihre“ bezeichnet den angemeldeten Nutzer der EFOY
            Cloud und Inhaber eines Nutzerkontos.
            </Text>
            <Text>
            „autorisierte Nutzer“ sind die vom Inhaber eines Nutzerkontos für
            ein bestimmtes Gerät autorisierten Nutzer.
            </Text>
            <Text>
            „Nutzerkonto“ ist das Online-Konto, das auf der Plattform EFOY Cloud
            erstellt wird, um die Dienste der EFOY Cloud nutzen zu können.
            </Text>
            <Text>
            „SFC“, „Wir“, „uns“ bezeichnet die SFC Energy AG.
            </Text>
          <SubTitle>2. Dienstleistungen, Ausschlüsse</SubTitle>
          <Text>
          Die EFOY Cloud ermöglicht die Fernüberwachung von EFOY Brennstoffzellen, einschließlich der Steuerung, Überwachung und Nutzung des jeweiligen Geräts und des angeschlossenen Zubehörs. Dies umfasst den Zugriff auf Gerätedaten, Anzeige und Download bestimmter Daten und die Vornahme von Einstellungen zum Zweck der Fernwartung. Bestimmte Dienste der EFOY Cloud können mit einem Gerät nur genutzt werden, wenn der Nutzer einen kostenpflichtigen Lizenzschlüssel für das Gerät erwirbt und aktiviert (dazu unten Ziffer 6).
          </Text>
          <Text>
          Die EFOY Cloud ersetzt nicht die regelmäßige Prüfung und Wartung der EFOY Brennstoffzelle und des Zubehörs und insbesondere die Sichtprüfung von Batteriestatus und Tankstatus am Gerät selbst. SFC garantiert nicht und übernimmt keine Haftung dafür, dass die Übermittelung von Daten vom Gerät an die EFOY Cloud oder über die EFOY Cloud bereitgestellte Daten fehlerfrei, vollständig oder aktuell sind.
          </Text>
          <SubTitle>3. Registrierung und Nutzerkonto</SubTitle>
          <Text>
          Die Nutzung der EFOY Cloud setzt eine Registrierung als Nutzer voraus. Dies gilt für jeden Nutzer, unabhängig davon, ob er sich mit eigenen Geräten oder fremden Geräte verbinden möchte. Die Registrierung erfolgt über die Webseite der EFOY Cloud: <a href="https://www.efoy-cloud.com" target="_blank" rel="noreferrer">https://www.efoy-cloud.com</a>. Für die Registrierung sind zwingend die Angabe Ihres Namens (Vor- und Nachname), einer E-Mail-Adresse und die Festlegung eines Authentifizierungsmerkmals (z.B. Passwort) erforderlich. Zusätzlich können Sie freiwillig weitere Informationen eingeben, damit wir einfacher mit Ihnen kommunizieren können (z.B. Firma, Telefon, Adresse, Land). Pflichtfelder sind durch ein Sternchen (*) oder auf andere Weise gekennzeichnet; alle übrigen Felder enthalten freiwillige Informationen. Die freiwilligen Informationen können auch noch nachträglich eingegeben werden. Alle Angaben des Nutzers sind wahrheitsgemäß zu machen. Die bei der Registrierung angegebene E-Mail-Adresse kann später nicht mehr geändert werden; die übrigen Kontaktinformationen können geändert werden.
          </Text>
          <Text>
          Wir verwenden die Daten des Nutzers, um das Nutzerkonto zu verwalten, mit dem Nutzer Kontakt aufzunehmen und Anfragen zu beantworten. Für alle Daten, die zu einem Nutzerkonto gehören, gelten die Datenschutzbedingungen der EFOY Cloud: <a href="https://www.efoy-cloud.com/gdpr/" target="_blank" rel="noreferrer">https://www.efoy-cloud.com/gdpr/</a>.
Der Nutzer ist für die berechtigte Nutzung der E-Mail-Adresse und die Geheimhaltung des Passwortes selbst verantwortlich.
          </Text>
          <Text>
          Der Nutzer ist für die berechtigte Nutzung der E-Mail-Adresse und die Geheimhaltung des Passwortes selbst verantwortlich.
          </Text>
          <Text>
          Mit der erfolgreichen Registrierung entsteht ein Nutzerkonto, das mit Geräten verbunden werden kann. Ob Ihr Gerät mit der EFOY Cloud verwendet werden kann, erfahren Sie hier: <a href="https://www.efoy-pro.com/service/cloud-license" target="_blank" rel="noreferrer">https://www.efoy-pro.com/service/cloud-license</a>. Sofern einem Nutzerkonto über längere Zeit kein Gerät hinzugefügt wurde, behalten wir uns vor, ein solches nicht genutztes Konto vorläufig oder dauerhaft zu sperren oder zu löschen.
Art und Umfang der möglichen Nutzung der EFOY Cloud hängen von dem jeweiligen Gerät und dem für das Gerät ggf. erworbenen EFOY Cloud-Lizenz ab.
          </Text>
          <SubTitle>4. Hinzufügen von Nutzern zu einem Gerät</SubTitle>
          <Text>Je nach Lizenz, die Sie erworben haben, können Sie über Ihr Nutzerkonto für ein Gerät weitere Nutzer hinzufügen und an diese bestimmte Rechte vergeben. Diese Nutzungsbedingungen gelten für alle Nutzer Ihres Geräts.</Text>
          <Text>Sie können insbesondere auch einen Servicepartner mit einem Gerät verbinden, der sich um die Fernüberwachung Ihres Geräts kümmert. Für die Vereinbarung zwischen Ihnen und dem Servicepartner sind wir nicht verantwortlich.</Text>
          <Text>Die von Ihnen autorisierten Nutzer müssen sich zuvor ein Nutzerkonto eröffnen (siehe oben Ziffer 3). Sie können dann entsprechend der eingeräumten Rechte Zugriff auf verbundene EFOY Brennstoffzellen nehmen. Ein autorisierter Nutzer kann auch selbst für ein Gerät weitere Nutzer hinzufügen.</Text>
          <Text>Jeder Nutzer ist für die Vergabe von Rechten an die von ihm autorisierten Nutzer selbst verantwortlich. Jeder Nutzer, der Rechte vergeben hat, kann die von ihm autorisierten Nutzer jederzeit wieder von einem Gerät trennen oder die vergebene Berechtigung ändern oder einschränken. Der Inhaber des Nutzerkontos, der ein Gerät hinzugefügt hat, kann jederzeit die Rechte aller weiteren Nutzern für dieses Gerät verwalten, ändern oder von dem Gerät entfernen.</Text>
          <Text>Sie sind als Inhaber des Geräts dafür verantwortlich, dass die von Ihnen autorisierten Nutzer mit der Verwendung ihrer jeweiligen Daten in Bezug auf das verbundene Gerät einverstanden sind und das verbundene Gerät ordnungsgemäß bedienen. Auf Verlangen des Nutzers, den Sie einem Gerät hinzugefügt haben, werden Sie den Nutzer unverzüglich entfernen.</Text>
          <Text>Sie sind für alle Aktivitäten der von Ihnen für ein Gerät autorisierten Nutzer allein verantwortlich. SFC ist nicht verantwortlich und haftet nicht für die Aktivitäten der von Ihnen autorisierten Nutzer.</Text>
          <SubTitle>5. Verbinden von EFOY Brennstoffzellen</SubTitle>
          <Text>Wenn Sie Ihrem Nutzerkonto eine EFOY Brennstoffzelle oder mehrere hinzufügen möchten, müssen Sie in Ihrem Nutzerkonto unter „EFOY Hinzufügen“ die Seriennummer des betreffenden Geräts eingeben. Dann wird ein Einmal-Passwort („claiming key“) für das Gerät generiert. Dieses Einmal-Passwort ist nicht identisch mit dem Passwort Ihres Nutzerkontos! Das Einmal-Passwort wird mit Hilfe des EFOY Bedienpanels (OP3) oder der EFOY App abgerufen. Voraussetzung ist, dass das Endgerät auf dem die EFOY App genutzt wird über den EFOY Bluetooth®-Adapter mit dem Gerät verbunden ist.</Text>
          <Text>Sie können dem jeweiligen Gerät einen individuellen Namen zuweisen. Bitte beachten Sie, dass der von Ihnen gewählte Name für jeden autorisierten Nutzer als Gerätename sichtbar sein wird.</Text>
          <Text>Sie können das Gerät jederzeit wieder aus dem Nutzerkonto entfernen.</Text>
          <Text>Die EFOY Cloud empfängt und speichert die Daten des Geräts nur für den Zeitraum, für den das Gerät mit dem Internet verbunden ist. Das Gerät hat keinen Speicher, der die anfallenden Daten (zwischen-)speichert und nachträglich an die EFOY Cloud übermittelt. Die vom Gerät an die EFOY Cloud übermittelten Daten können über Ihr Nutzerkonto eingesehen werden. Zur Speicherdauer siehe unten Ziffer 7.</Text>
          <SubTitle>6. Erwerb und Nutzung von kostenpflichtigen Lizenzen (Lizenzschlüssel)</SubTitle>
          <Text>Die Dienste der EFOY Cloud werden in verschiedenen Lizenzversionen angeboten, die einen unterschiedlichen Nutzungsumfang ermöglichen.</Text>
          <Text>Die aktuellen Bedingungen, Laufzeiten der Lizenzen und wie Sie einen Lizenzschlüssel für die jeweilige EFOY-Brennstoffzelle erwerben, erfahren Sie hier: <a href="https://www.efoy-pro.com/service/cloud-license" target="_blank" rel="noreferrer">https://www.efoy-pro.com/service/cloud-license</a>.</Text>
          <Text>Der erworbene Lizenzschlüssel berechtigt den Erwerber für die Laufzeit des Lizenzschlüssels zur Nutzung der EFOY Cloud in dem in der Lizenz festgelegte Funktionsumfang für das jeweilige Gerät.</Text>
          <Text>Die Laufzeit des Lizenzschlüssels beginnt erst mit der Aktivierung des Lizenzschlüssels für das betreffende Gerät in der EFOY Cloud (siehe oben Ziffer 5). Die Aktivierung setzt voraus, dass Sie den Lizenzschlüssel in der EFOY Cloud eingeben.</Text>
          <Text>Der aktivierte Lizenzschlüssel und das damit verbundene Recht zur Nutzung der EFOY Cloud sind gerätegebunden (Seriennummer) und nicht auf andere Geräte übertragbar.</Text>
          <Text>Die Lizenz für die EFOY Cloud ist nicht an einen Vertriebspartner der SFC gebunden und bezieht sich nur auf die Verwendung des jeweiligen Gerätes in der EFOY Cloud. Sie ist unabhängig von etwaigen Konten oder Nutzergruppen von Vertriebspartnern der SFC in der EFOY Cloud.</Text>
          <SubTitle>7. Erhebung und Verfügbarkeit von Daten</SubTitle>
          <Text>Die Gerätedaten der EFOY Brennstoffzelle werden bei einer bestehenden Internetverbindung automatisch vom angeschlossenen Gerät an die EFOY Cloud übertragen und in der EFOY Cloud gespeichert. Dies gilt unabhängig vom Bestehen eines Nutzerkontos. Solange das Gerät nicht aktiv mit einem Nutzerkonto verbunden ist, können wir die Daten des Geräts keinem Nutzer zuordnen. Die an uns übermittelten Daten werden erst ab Aktivierung des Lizenzschlüssels für das betreffende Gerät in der EFOY Cloud im Nutzerkonto zur Verfügung gestellt.</Text>
          <Text>Die Bereitstellung der Daten im Rahmen der Lizenz endet mit dem Ablauf der Lizenz automatisch (siehe oben Ziffer 6).</Text>
          <Text>Die Aktionen, die an der verbundenen EFOY Brennstoffzelle von Ihnen oder von autorisierten Nutzern vorgenommen werden, werden protokolliert und können über die Geräteansicht in der EFOY Cloud durch autorisierte Nutzer eingesehen werden (sog. Audit). Diese Daten werden für die Dauer von bis zu 18 Monaten gespeichert. SFC übernimmt keine Haftung für die Vollständigkeit oder Richtigkeit dieser Daten.</Text>
          <Text>Die EFOY Cloud ist nicht dazu bestimmt als Speicher,- Sicherungs-, oder Archivierungsdienst genutzt zu werden. Eine Speicherung der Nutzungsdaten ist jeweils nur für einen rollierenden Zeitraum von 18 Monaten (frühestens ab der ersten Verbindung und dem erfolgreichen Hinzufügen eines Geräts zu einem Nutzerkonto) vorgesehen. SFC übernimmt keine Haftung oder Garantie für die Verfügbarkeit dieser Daten im Fall eines Datenverlusts. Die regelmäßige Datensicherung obliegt dem Nutzer. Der Nutzer hat die Möglichkeit bestimmte Daten durch Download zu exportieren und auf eigenen Geräten zu sichern. Im Fall des Verlustes ist eine Wiederherstellung durch SFC nicht möglich.</Text>
          <SubTitle>8. Verwendung von Gerätedaten</SubTitle>
          <Text>SFC erhebt, speichert und verarbeitet Gerätedaten, um die Leistung und Funktionalität der EFOY Cloud zur Verfügung zu stellen und diese stetig zu verbessern. SFC behält sich vor, die Gerätedaten für statistische Zwecke und Zwecke der Forschung, Entwicklung und Verbesserung der Produkte zu anonymisieren und in anonymisierter Form (d.h. ohne Personenbezug) zu analysieren und zu verarbeiten. Zu diesem Zweck ist SFC berechtigt, Gerätedaten (ohne Personenbezug) auch an verbundene Unternehmen weiterzugeben. Wir können Dritte als Dienstleister (z.B. Rechenzentrumsdienstleister oder Identifikationsdienstleister) im Rahmen einer Auftragsverarbeitung nutzen. Für Zwecke der Werbung für unsere Produkte verwenden wir Ihre Daten, sofern Sie hierfür eine Einwilligung erteilt haben oder dies gesetzlich zulässig ist. Darüber hinaus verwenden wir Ihre Gerätedaten nur im Zusammenhang mit Ihrem Nutzerkonto zur Durchführung des Vertrags und Beantwortung Ihrer Anfragen und geben diese nicht unbefugt an Dritte weiter.</Text>
          <SubTitle>9. Verantwortlichkeit des Nutzers</SubTitle>
          <Text>Die vollständige Nutzung der EFOY Cloud setzt voraus, dass die EFOY Brennstoffzelle über das Internet erfolgreich und dauerhaft mit der EFOY Cloud verbunden ist. Die Kosten für die Einrichtung und Unterhaltung der Verbindung trägt der Nutzer. Die technische Bereitstellung und Verfügbarkeit der Verbindung zwischen der EFOY Brennstoffzelle und der EFOY Cloud liegt in der Verantwortung des Nutzers (z.B. Verbindungsqualität, Firewall-Konfiguration, Netzwerkkonfiguration, Energieversorgung). Die Nutzung der EFOY Cloud setzt im Übrigen die Nutzung eines EFOY Bedienpanels (OP3) oder von sonstigem EFOY-Zubehör (z.B. Bluetooth®-Adapter) voraus, um das Gerät mit einem Nutzerkonto verbinden zu können. Bei der Nutzung der Webanwendung für die EFOY Cloud und/oder eines Smartphones zur Nutzung der EFOY App fallen ggf. Verbindungsgebühren für die Verbindung mit dem Internet an, die der Nutzer zu tragen hat.</Text>
          <Text>Der Nutzer wird SFC unverzüglich unterrichten, sofern er von einer unbefugten Nutzung seines Kontos oder von einer anderen Form der Verletzung der Sicherheit der EFOY Cloud erfährt.</Text>
          <Text>Die regelmäßige Datensicherung obliegt dem Nutzer (siehe oben Ziffer 7).</Text>
          <Text>Der Nutzer ist selbst dafür verantwortlich, im Fall der Veräußerung einer EFOY Brennstoffzelle die Daten auf seinem Gerät zu löschen und das Gerät von seinem Nutzerkonto zu entfernen (siehe unten Ziffer 11).</Text>
          <SubTitle>10. Sperrung und Löschung des Kontos</SubTitle>
          <Text>Bei Verstoß gegen diese Nutzungsbedingungen oder gegen gesetzliche Vorschriften sind wir berechtigt, Ihren Zugang zu Ihrem Nutzerkonto und/oder den Zugang der von Ihnen autorisierten Nutzer zu Ihren Geräten zu sperren. Dazu zählt unter anderem eine missbräuchliche Verwendung des jeweiligen Nutzerkontos, welche die allgemeine Verfügbarkeit und/oder Sicherheit der EFOY Cloud, von Kundendaten und/oder Geräten beeinträchtigt oder gefährdet. Im Falle einer berechtigten Sperrung oder Löschung des Nutzerkontos werden gezahlte Lizenzgebühren nicht erstattet. Ersatzansprüche von SFC bleiben unberührt.</Text>
          <SubTitle>11. Veräußerung eines Geräts</SubTitle>
          <Text>Sofern der Nutzer der EFOY Cloud eine mit seinem Nutzerkonto verbundene EFOY Brennstoffzelle veräußert, verpflichtet er sich, das betreffende Gerät unverzüglich von seinem Nutzerkonto zu entfernen. SFC ist auf Verlangen eines Dritten, der den Erwerb und Besitz der EFOY Brennstoffzelle angemessen nachweist, berechtigt, aber nicht verpflichtet, das betreffende Gerät von dem Nutzerkonto des vorherigen Eigentümers zu entfernen.</Text>
          <SubTitle>12. Änderungen der EFOY Cloud und dieser Bedingungen</SubTitle>
          <Text>SFC kann jederzeit Änderungen der EFOY Cloud und dieser Bedingungen vornehmen, die der Sicherheit der Anwendung und dem Datenschutz dienen, um gesetzlichen und behördlichen Anforderungen zu genügen, Anpassungen an gesetzliche Änderungen vorzunehmen oder um die Funktionalität der EFOY Cloud zu verbessern oder zu erweitern. SFC ist berechtigt, hierfür jederzeit Aktualisierungen oder Verbesserungen der EFOY Cloud und der EFOY App sowie der Firmware der EFOY Brennstoffzelle und von Zubehörteilen über eine bestehende Internetverbindung vorzunehmen.</Text>
          <SubTitle>13. Urheberrechte</SubTitle>
          <Text>Die EFOY Cloud ist urheberrechtlich geschützt. SFC räumt dem jeweiligen Nutzer und dem von ihm autorisierten Nutzern ein nicht-ausschließliches Recht ein, die EFOY Cloud sowie die dazugehörige Webanwendung für den Betrieb der EFOY Brennstoffzellen zu nutzen. Der zulässige Nutzungsumfang richtet sich nach der erworbenen Lizenzversion. Die Nutzung ist auf die Dauer der jeweils erworbenen Lizenz begrenzt. Für die EFOY App gelten die hierfür in der App hinterlegten Nutzungsbedingungen und Datenschutzhinweise.</Text>
          <SubTitle>14. Laufzeit und Beendigung</SubTitle>
          <Text>Diese Nutzungsbedingungen gelten zeitlich unbeschränkt für jede Nutzung der EFOY Cloud.</Text>
          <Text>Die Laufzeit einer erworbenen Lizenz beginnt ab dem Tag der Aktivierung der jeweils erworbenen Lizenz für die entsprechende EFOY Brennstoffzelle durch Eingabe des Lizenzschlüssels und bleibt für die in der Lizenz vorgesehene Dauer in Kraft.</Text>
          <Text>Sie können die Nutzung der EFOY Cloud jederzeit beenden und Ihr Konto löschen. In diesem Fall findet eine Erstattung des Kaufpreises für erworbene oder ggf. schon aktivierte Lizenzschlüssel nicht statt.</Text>
          <Text>Wenn Sie Ihr Nutzerkonto löschen, wird die Verbindung zu den in Ihrem Konto bis dahin verbundenen EFOY Brennstoffzellen aufgehoben und die von Ihnen autorisieren Nutzer verlieren ihren Zugang zu den bis dahin verbundenen EFOY Brennstoffzellen. Die bei uns vorhandenen personenbezogenen Daten werden grundsätzlich gelöscht, sofern dem nicht gesetzliche Aufbewahrungspflichten entgegenstehen. Eine Wiederherstellung des Nutzerkontos ist nicht mehr möglich. Sie können jedoch jederzeit ein neues Nutzerkonto anlegen. Mit dem neuen Konto können ab Einrichtung dann neue Daten verwaltet werden.</Text>
          <Text>Das Recht jeder Partei zur Kündigung aus wichtigem Grund bleibt unberührt.</Text>
          <SubTitle>15. Mängelhaftung</SubTitle>
          <Text>Wir stellen die EFOY Cloud mit angemessener Sorgfalt zur Verfügung. Wir geben keine Garantie, dass die Daten und die EFOY Cloud jederzeit und unterbrechungsfrei verfügbar sind.</Text>
          <Text>Die Garantie und Mängelhaftung für die EFOY Brennstoffzellen und das Zubehör richtet sich nach den Allgemeinen Geschäftsbedingungen der SFC und den jeweils anwendbaren Garantiebedingungen.</Text>
          <SubTitle>16. Haftung</SubTitle>
          <Text>SFC haftet, gleich aus welchem Rechtsgrund, bei einfach fahrlässiger Schadensverursachung nur bei Verletzung wesentlicher Pflichten ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen und dann nur begrenzt auf den Ersatz des typischen und bei Vertragsschluss für SFC vorhersehbaren Schadens. Im Übrigen ist eine Haftung von SFC bei einfach fahrlässiger Schadensverursachung ausgeschlossen.</Text>
          <Text>Die Einschränkungen dieser Ziffer 16 gelten nicht für die Haftung von SFC wegen vorsätzlichen oder grob fahrlässigen Verhaltens, für garantierte Beschaffenheitsmerkmale, wegen Verletzung des Lebens, des Körpers oder der Gesundheit oder nach dem Produkthaftungsgesetz.</Text>
          <SubTitle>17. Erfüllungsort, Recht, Gerichtsstand</SubTitle>
          <Text>Ausschließlicher Erfüllungsort für Liefer- und Zahlungsverpflichtungen ist der Geschäftssitz von SFC.</Text>
          <Text>Für alle Rechtsbeziehungen zwischen dem Kunden und SFC kommt deutsches Recht unter Ausschluss des UN-Kaufrechts zur Anwendung. Gerichtsstand für alle Streitigkeiten ist München. SFC ist daneben auch berechtigt, am Sitz des Kunden zu klagen.</Text>
          <Text>Sollten eine oder mehrere dieser Bestimmungen ganz oder teilweise unwirksam oder undurchführbar sein oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.</Text>
          <Text>Änderungen und Ergänzungen sowie Nebenabreden bedürfen zu ihrer Gültigkeit der Schriftform.</Text>
        </Col>
      </Row>
    </>
  )

  return (
    <Container className="bg-white mt-5 p-5" style={{ position: 'relative' }}>
      <StyledLanguageContainer>
        <StyledLanguageButton onClick={() => setLanguage('EN')} active={language === 'DE'}>EN</StyledLanguageButton>
        <StyledSeparator>|</StyledSeparator>
        <StyledLanguageButton onClick={() => setLanguage('DE')} active={language === 'EN'}>DE</StyledLanguageButton>
      </StyledLanguageContainer>
      { language === 'DE' ? DE_CONTENT : EN_CONTENT }
    </Container>
  )
}
