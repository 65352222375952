import * as C from 'appConstants'

const initialState = {
  filterModalOpened: false
}

const openFilter = (state: any) => {
  return {
    ...state,
    filterModalOpened: true
  }
}

const closeFilter = (state: any) => {
  return {
    ...state,
    filterModalOpened: false
  }
}

export default (state: any = initialState, action: any) => {
  const { type } = action

  switch (type) {
    case C.OPEN_DEVICE_FILTER:
      return openFilter(state)
    case C.CLOSE_DEVICE_FILTER:
        return closeFilter(state)
    default:
      return state
  }
}
