import Highcharts from 'highcharts'
import { getEvents, renderTitleWithStyles, zoomCharts } from '../utils/utils'

/*
For this chart we need these these props:
chartComponentRef.current
type
events
series
*/

const createIosOptions = (chartComponentRef, title, devicetype, events, series) => {
  const options: Highcharts.Options = {
    title: {
      text: title,
      align: 'left',
      style: {
        color: '#000000',
        fontFamily: 'open-sans-regular',
        fontSize: '20px',
        paddingTop: '10px'
      },
      useHTML: true
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            lineWidth: 10
          },
          inactive: {
            opacity: 1,
            enabled: false
          }
        },
        label: {
          connectorAllowed: false
        },
        lineWidth: 10,
        marker: {
          enabled: false // symbols on the graph series
        },
        point: {
          events: {
            /* mouseOver: () => {
              // syncroTooltip(e, customTooltip)
            } */
          }
        }
      }
    },
    xAxis: {
      width: '90%',
      title: {
        text: ''
      },
      crosshair: true,
      labels: {
        distance: 10,
        style: {
          color: 'gray'
        },
        rotation: 0
      },
      type: 'datetime',
      plotLines: getEvents(events, devicetype),
      events: {
        afterSetExtremes: (e) => {
          zoomCharts(e, chartComponentRef.current)
        }
      }
    },
    yAxis: [
      {
        title: {
          style: {
            fontFamily: 'open-sans-regular',
            fontSize: '20px'
          },
          align: 'middle',
          rotation: 270,
          text: series && `${renderTitleWithStyles(series, 0)}`
        },
        labels: {
          distance: 10,
          format: 'ON',
          style: {
            color: 'gray',
            display: 'none'
          }
        }
      },
      {
        title: {
          text: series && `${renderTitleWithStyles(series, 1)}`,
          style: {
            fontWeight: 'bold',
            fontFamily: 'open-sans-regular',
            fontSize: '20px'
          },
          align: 'middle'
        },
        opposite: true,
        labels: {
          distance: 12,
          format: '{value}',
          style: {
            color: 'gray'
          }
        }
      }
    ],
    series: series,
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              useHTML: true,
              itemStyle: {
                fontSize: '12px',
                fontFamily: 'open-sans-regular'
              }
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: '12px'
                }
                // format: '{value:%H:%M}'
              }
            },
            yAxis: {
              labels: {}
            }
          }
        }
      ]
    },
    chart: {
      type: 'spline',
      height: 400,
      zooming: {
        type: 'xy',
        mouseWheel: true
      }
    },
    credits: {
      enabled: false
    },
    accessibility: {
      enabled: false
    },
    tooltip: {
      shared: true,
      distance: 30,
      padding: 5,
      useHTML: true,
      style: {
        fontSize: '14px',
        fontFamily: 'open-sans-regular'
      },
      headerFormat: '<div class="tooltip-component"><table><tr><th colspan="2">{point.key}</th></tr>',
      pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' + '<td style="text-align: right"><b>ON</b></td></tr>',
      footerFormat: '</table></div>'
    }
  }
  return options
}

export { createIosOptions }
