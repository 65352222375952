// @flow

import { EmptyElement } from 'components/ReUsable'

type Props = {
  data?: any,
  errorCode?: any,
}

export default function ShowErrorLink (props: Props) {
  const {
    errorCode,
    data
  } = props

  if (typeof errorCode === 'string' && data && data.deviceType) {
    const type = data.deviceType.replace(/ /g, '-').toLowerCase()
    const link = `https://www.efoy-pro.com/en/service/servicetool/?product=${type}&errorcode=${errorCode}`
    const style = {
      color: 'red'
    }

    return (
      <a href={link} style={style} target="_blank" rel="noopener noreferrer">
        {errorCode}
      </a>
    )
  }

  return <EmptyElement>none</EmptyElement>
}
