import { useEffect, useState } from 'react'
import { checkScopePermissionsFromAuth0 } from 'utils/helpers'
import { useAuth0 } from 'utils/react_auth0_spa'

export default function useAdminRoute ({ rights, path }:{ rights: string[], path: string }):{ pageAvailable: boolean, routeLoading: boolean } {
  const { isAuthenticated, getTokenSilently } = useAuth0()
  const [pageAvailable, setPageAvailable] = useState<boolean>(false)
  const [routeLoading, setRouteLoading] = useState<boolean>(true)
  const show = async () => {
    const token = await getTokenSilently()
    const data = await checkScopePermissionsFromAuth0(rights, token)
    setPageAvailable(data)
    setRouteLoading(false)
  }

  useEffect(() => {
    show()
  }, [isAuthenticated, path])

  return {
    pageAvailable, routeLoading
  }
}
