import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'

import CommonHeadingCell from 'components/Application/CommonTable/commonHeadingCell'

export const defineColumns = function () {
    const columnHelper = createColumnHelper()

    return [
      columnHelper.accessor((row: any) => row.timestamp, {
        id: 'timestamp',
        header: (info: any) => <CommonHeadingCell
            value='Date'
            id={info.id}
        />,
        cell: (info: any) => <td>{moment(info.renderValue()).format('YYYY-MM-DD HH:mm')}</td>
    }),
    columnHelper.accessor((row: any) => row.createdBy, {
      id: 'createdBy',
      header: (info: any) => <CommonHeadingCell
          value='User'
          id={info.id}
      />,
      cell: (info: any) => <td>{info.renderValue()}</td>
    }),
      columnHelper.accessor((row: any) => row.eventType, {
        id: 'eventType',
        header: (info: any) => <CommonHeadingCell
            value='Event type'
            id={info.id}
        />,
        cell: (info: any) => <td>{info.renderValue()}</td>
    }),
      columnHelper.accessor((row: any) => row.description, {
        id: 'description',
        header: (info: any) => <CommonHeadingCell
            value='Description'
            id={info.id}
        />,
        cell: (info: any) => <td>{info.renderValue()}</td>
    })
    ]
}
