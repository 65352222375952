// @flow

import { useState, useEffect } from 'react'

import { addDays, getUnixTime, startOfDay } from 'date-fns'
import { getDeviceAudits } from 'apis/devices'
import { useCurrentDevice } from 'components/Application/ManageDelegations/hooks/useCurrentDevice'

type AuditType = {
  content: {
    eventType: string;
    description: string;
    timestamp: string;
    device: string;
    createdBy: string;
    groupSlug: string | null;
    hidden?: boolean;
  }[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { empty: boolean; sorted: boolean; unsorted: boolean };
    unpaged: false;
  };
  size: number;
  sort: { empty: boolean; sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
};

export function useAudit (serialNumber: string) {
  const { currentDevice, deviceLoadingOrErrorComponent, responseCode } = useCurrentDevice(serialNumber)
  const [audits, setAudits] = useState<AuditType | null>(null)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  //  Device Audits API call.
  const getDeviceAuditsCall = async (pageNumber?: number) => {
    if (pageNumber) {
      setCurrentPage(pageNumber)
    }
    try {
      const endDatePlusOne = endDate ? startOfDay(addDays(endDate, 1)) : ''

      const auditsArr = await getDeviceAudits(
        serialNumber,
        pageNumber,
        startDate ? getUnixTime(startDate) : undefined,
        endDatePlusOne ? getUnixTime(endDatePlusOne) : undefined
      )
      if (auditsArr.status) {
        throw new Error(auditsArr.status)
      }
      setAudits(auditsArr)
      return true
    } catch (e) {
      setAudits(null)
      setError(true)
      return false
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDeviceAuditsCall(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {
    if (+responseCode > 200) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCode])

  return {
    getDeviceAuditsCall,
    audits,
    loading,
    currentDevice,
    currentPage,
    error,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    deviceLoadingOrErrorComponent
  }
}
