// @flow

import StatusBanner from './StatusBanner'
import { useStatusBar } from './useStatusBar'

export default function ApplicationStatusBar () {
  const { banner, loading, error } = useStatusBar()
  if (loading || error.length > 0) {
    return null
  }

  if (banner.type === 'NONE') {
    return null
  }

  return (
    <StatusBanner id="application-status-bar" banner={banner} />
  )
}
