// @flow

import styled from 'styled-components'
import { Tag } from '../context/tagContext'

const StyledTagList = styled.div`
  margin-bottom: 6px;
  margin-top: 8px;
`

const StyledTag = styled.span<{ compact: boolean | undefined }>`
  display: inline-block;
  min-height: 20px;
  border-radius: 0.375rem;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 2px;
  background: transparent;
  padding: ${(props: { compact: boolean | undefined }) => (props.compact ? '0 10px;' : '4px 10px;')};
`

const DeviceTags = ({ tags, compact }: { tags: Tag[]; compact?: boolean }) => {
  if (tags.length === 0) return null
  return (
    <StyledTagList>
      {tags.map((t: Tag) => {
        const styleOfButton = { border: `2px solid ${t.color}`, backgroundColor: t.color, color: '#fff' }
        return (
          <StyledTag key={t.id} style={styleOfButton} compact={compact}>{t.name}</StyledTag>
        )
      })}
    </StyledTagList>
  )
}
export default DeviceTags
