// @flow

import styled from 'styled-components'
import IconButton from '../IconButton'

export const StyledBackLinkContainer = styled.div`
  cursor: pointer;
  font-size: 13px;

  &:hover,
  &:focus {
    filter: brightness(0.8);
  }
`

type HeaderBackButtonProps = {
  backlinkText: string;
  handleButtonClick: () => void;
}

export const HeaderBackButton = function ({
  backlinkText,
  handleButtonClick
}: HeaderBackButtonProps) {
  const iconStyles = {
    width: '20px',
    height: 'auto',
    marginRight: '6px',
    marginLeft: '-10px',
    transform: 'rotate(180deg)',
    bottom: '2px'
  }
  return (
    <StyledBackLinkContainer onClick={handleButtonClick}>
      <IconButton onClick={() => null} src="arrow-right-dark" width="20px" style={iconStyles}/>
      {backlinkText}
    </StyledBackLinkContainer>
  )
}

export default HeaderBackButton
