import React, { useEffect, useState } from 'react'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import styled from 'styled-components'
import FilterIcon from './filterIcon'
import { Button, ButtonCancel, Row } from 'components/ReUsable'
import { NEW_API_ACTIVE } from 'featureToggles'

const StyledFilterButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  position: relative;
  top: 1px;
`

const StyledPopOverContent = styled.div`
  padding: 10px;
  padding-top: 16px;
  min-width: 360px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`

const StyledSaveButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
`

const StyledInput = styled.input`
  font-family: open-sans-regular;
  border: 1px solid #CFCFCF;
  border-radius: 3px;
  padding: 3px;
  min-width: 100%;
  height: 2.5rem;

  &:focus {
    border: 2px solid #CFCFCF;
    outline: none;
  }
`

type FilterProps = { id: string, filterActive: boolean, currentFilterValue:string, onFilterSave: (filter: string) => void, onFilterOpen: () => void }

const FilterPopOver = React.memo(function FilterPopOverFunc ({ id, filterActive, onFilterSave, currentFilterValue, onFilterOpen } : FilterProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [filterText, setFilterText] = useState<string>(currentFilterValue || '')

  if (!NEW_API_ACTIVE) {
    return null
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  useEffect(() => {
    if (anchorEl) {
      onFilterOpen()
    }
  }, [anchorEl])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const idValue = open ? id : undefined

  return (
    <>
      <StyledFilterButton onClick={handleOpen} data-cy={`filterBy-${id}`}>
        <FilterIcon filterName='name' active={filterActive} />
      </StyledFilterButton>
      <Popover
        id={idValue}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
      <ClickAwayListener onClickAway={() => onFilterSave(filterText)}>
        <StyledPopOverContent>
          <Row>
            <StyledInput type="text" data-cy={`filterInput-${id}`} value={filterText} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event?.target.value)}/>
          </Row>
          <StyledButtonContainer>
            <ButtonCancel onClick={() => onFilterSave('')} data-cy="cancelFilter">Cancel</ButtonCancel>
            <StyledSaveButton onClick={() => onFilterSave(filterText)} data-cy="saveFilter">
              Save
            </StyledSaveButton>
          </StyledButtonContainer>
        </StyledPopOverContent>
        </ClickAwayListener>
    </Popover>
    </>
  )
})
export default FilterPopOver
