export const setEnabledKeys = (obj: any, value: boolean) => {
  // Base case: if the obj is not an object, return it unmodified
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // Iterate over each property in the object
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // If the property is named "enabled", update its value
      if (key === 'enabled') {
        obj[key] = value
      }

      // If the property is an object, recurse into it
      if (typeof obj[key] === 'object') {
        setEnabledKeys(obj[key], value)
      }
    }
  }

  return obj
}
