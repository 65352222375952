// @flow

import { backendEndpoint, executeRequest, executeRequestSWR } from '.'

const endpoint = `${backendEndpoint || ''}`

export const getTags = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  return executeRequestSWR(`${endpoint}/tags`, undefined, undefined, options)
}

export const getDeviceTags = (url: string, serialNumber: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  return executeRequestSWR(`${endpoint}/devices/${serialNumber}/tags`, undefined, undefined, options)
}

export const updateDeviceTags = (serialNumber: string, payload: { id: string; selected: boolean }[]) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(payload),
    'Content-Type': 'application/json'
  }

  return executeRequest(`${endpoint}/devices/${serialNumber}/tags`, undefined, undefined, options)
}

export const updateTag = (id: string, payload: { name: string; color: string }) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(payload),
    'Content-Type': 'application/json'
  }

  return executeRequest(`${endpoint}/tags/${id}`, undefined, undefined, options)
}

export const addTag = (payload: { name: string; color: string; group: string | null }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    'Content-Type': 'application/json'
  }

  return executeRequest(`${endpoint}/tags`, undefined, undefined, options)
}

export const removeTag = (id: string) => {
  const options = {
    method: 'DELETE',
    'Content-Type': 'application/json'
  }

  return executeRequest(`${endpoint}/tags/${id}`, undefined, undefined, options)
}
