import * as C from 'appConstants'

const initialState = {
    group: {
        add: {
            success: false
        },
        update: {
            success: false
        },
        delete: {
            success: false
        },
        leave: {
            success: false
        }
    }
}

function populateGroupAddSuccess (state) {
    return {
        ...state,
        group: {
            ...state.group,
            add: {
                success: true
            }
        }
    }
}

function populateGroupDeleteSuccess (state) {
    return {
        ...state,
        group: {
            ...state.group,
            delete: {
                success: true
            }
        }
    }
}

function populateGroupUpdateSuccess (state) {
    return {
        ...state,
        group: {
            ...state.group,
            update: {
                success: true
            }
        }
    }
}

function populateGroupLeaveSuccess (state) {
    return {
        ...state,
        group: {
            ...state.group,
            leave: {
                success: true
            }
        }
    }
}

function resetGroupSuccess (state) {
    return {
        ...state,
        ...initialState
    }
}

export default function (state = initialState, action) {
    const { type } = action

    switch (type) {
        case C.POPULATE_GROUP_ADD_SUCCESS:
            return populateGroupAddSuccess(state)
        case C.POPULATE_GROUP_DELETE_SUCCESS:
            return populateGroupDeleteSuccess(state)
        case C.POPULATE_GROUP_UPDATE_SUCCESS:
            return populateGroupUpdateSuccess(state)
        case C.POPULATE_GROUP_LEAVE_SUCCESS:
            return populateGroupLeaveSuccess(state)
        case C.RESET_GROUP_SUCCESS:
            return resetGroupSuccess(state)
        default:
            return state
    }
}
