export const GRANTED = 'granted'
export const DENIED = 'denied'
export const DENIED_MISSING_LICENSE = 'denied_missing_license'
export const HIDDEN = 'hidden'
export const INACTIVE = 'inactive'
export const NOT_FOUND = 'not_found'

// Device Permissions Constants
export const DEVICE_DETAILS_PERMISSIONS_LIST = {
    GENERAL_INFO_READ: 'device:general_info:read',
    DETAIL_READ: 'device:detail:read',
    STATE_READ: 'device:state:read',
    OWNER_READ: 'device:owner:read',
    TELEMETRY_SUMMARY_READ: 'device:telemetry_summary:read',
    RESET: 'device:reset',
    OPERATION_MODE_WRITE: 'device:operation_mode:write',
    BATTERY_WRITE: 'device:battery:write',
    RESET_CYCLES: 'device:reset_cycles',
    FUEL_LEVEL_WRITE: 'device:fuel_level:write',
    FUEL_PORT_WRITE: 'device:fuel_port:write',
    TELEMETRY_HISTORY_READ: 'device:telemetry_history:read',
    RENAME: 'device:rename',
    TELEMETRY_EXPORT: 'device:telemetry:export',
    REPORT: 'device:report',
    ALERT_ADMIN: 'device:alert:admin',
    ALERT_WRITE: 'device:alert:write',
    UNCLAIM: 'device:unclaim',
    LICENSE_ADD: 'device:license:add',
    LICENSE_WRITE: 'device:license:write',
    DELEGATION_WRITE: 'device:delegation:write',
    DIRECT_COMMAND: 'device:direct_command',
    TLP_UNLOCK: 'device:unlock_tlp',
    AUDIT_READ: 'device:audit:read',
    MULTI_SENSE_WRITE: 'device:multi_sense:write',
    TAGS_WRITE: 'device:tags:write',
    ADVANCED_INFORMATION_READ: 'device:advanced_info:read',
    SERVICE_MODE_WRITE: 'device:service_mode:write'
}

export const GROUPS_PERMISSIONS_LIST = {
    GROUP_DELETE: 'group:delete',
    GROUP_LEAVE: 'group:leave',
    GROUP_MODIFY: 'group:modify'
}
