// @flow

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import {
  togglePageDialog,
  clearDevicesPageDialog,
  verifyPasswordSubmit
} from 'actions'

import { Button, OutlineButton, Input, Form } from 'components/ReUsable'

import { PASSWORD_SCREEN_STEP } from 'appConstants'

type StepTwoStateProps = {
  devices: {
    pageDialog: {
      currentStep: number,
      currentUserStep: number,
      lastStep: number,
      requestErrorMessage: string
    },
    startClaimingForm: {
      serialNumber: string
    },
    verifyPasswordForm: {
      password: string
    }
  }
}

const selectDeviceState = (state: any): StepTwoStateProps => ({
  devices: state.devices
})

export function AddEfoyStepTwo () {
  const dispatch = useDispatch()
  const _verifyPasswordSubmit = (payload: {
    serialNumber: string;
    password: string;
}) => dispatch(verifyPasswordSubmit(payload))
  const _togglePageDialog = () => dispatch(togglePageDialog())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())
  const { devices } = useSelector(selectDeviceState)
  const {
    startClaimingForm, verifyPasswordForm
  } = devices

  const {
    currentUserStep, currentStep, lastStep,
    requestErrorMessage
  } = devices.pageDialog

  const formik = useFormik(
    {
      initialValues: verifyPasswordForm,
      onSubmit: (values) => {
        const updatedValues = Object.assign(startClaimingForm, values)
        _verifyPasswordSubmit(updatedValues)
      }
    }
  )

  return currentStep === PASSWORD_SCREEN_STEP ? (
    <>
      <p className='add-efoy-message'>
        Please enter the claiming key generated by your EFOY.
        The key will be displayed on the operating panel or on the myEFOY App.
      </p>
      <Form id='add-efoy-password-form' handleSubmit={ formik.handleSubmit }>
        <Input
          type='text' id='password' name='password' placeholder='Enter EFOY claiming key'
          className={ `form-control serial-number ${requestErrorMessage ? 'input-errored' : ''}` }
          onChange={ formik.handleChange } value={ formik.values.password }
        />
        <p className='field-error'>{ requestErrorMessage }</p>
        {/* this section can be a isolated component */}
        <div className='d-flex justify-content-between buttons-section'>
          <OutlineButton
            type='button' id='cancel'
            onClick={ () => {
              _togglePageDialog()
              _clearDevicesPageDialog()
            } }
          >
            CANCEL
          </OutlineButton>
          <Button
            type='submit' id='button-next'
            disabled={ !formik.values.password }
          >
            NEXT
          </Button>
        </div>
      </Form>
      {/* this section can be a isolated component */}
      <p className='modal-step'>Step { currentUserStep } of { lastStep }</p>
    </>
  ) : null
}

export default AddEfoyStepTwo
