import { useEffect, useState } from 'react'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import styled from 'styled-components'
import { FilterByTagIcon } from './filterIcon'
import { Button, ButtonCancel } from 'components/ReUsable'
import { TagContextProvider, useTagContext } from '../Tags/context/tagContext'
import TagSelectorContent from '../Tags/tagSelection/tagSelectorContent'

const StyledFilterButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  position: relative;
  top: 1px;
`

const StyledPopOverContent = styled.div`
  padding: 10px;
  padding-top: 16px;
  min-width: 360px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`

const StyledSaveButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
`

type FilterProps = { id: string; filterActive: boolean; currentFilterValue: string[]; onFilterSave: (filter: string[]) => void; onFilterOpen: () => void }

export default function FilterTagsPopOver ({ id, filterActive, onFilterSave, currentFilterValue, onFilterOpen }: FilterProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [filterValues, setFilterValues] = useState<string[]>(currentFilterValue || [])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (anchorEl) {
      onFilterOpen()
    }
  }, [anchorEl])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const idValue = open ? id : undefined

  return (
    <>
      <StyledFilterButton onClick={handleOpen} data-cy={`filterBy-${id}`} title="Filter by tags">
        <FilterByTagIcon filterName="tags" active={filterActive as boolean} />
      </StyledFilterButton>
      <Popover
        id={idValue}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <ClickAwayListener onClickAway={() => onFilterSave(filterValues)}>
          <StyledPopOverContent>
            <TagContextProvider>
              <TagSelectorContent filterValues={filterValues} onFilterChange={(filters: string[]) => setFilterValues(filters)} />
              <TagsPopOverButtons filterValues={filterValues} onFilterSave={onFilterSave} />
            </TagContextProvider>
          </StyledPopOverContent>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

function TagsPopOverButtons ({ filterValues, onFilterSave }: { filterValues: string[], onFilterSave: (filter: string[]) => void }) {
  const { tagsEmpty } = useTagContext()
  return (
    <>
      {!tagsEmpty ? (
        <StyledButtonContainer>
          <ButtonCancel onClick={() => onFilterSave([])} data-cy="cancelFilter">
            Cancel
          </ButtonCancel>
          <StyledSaveButton onClick={() => onFilterSave(filterValues)} data-cy="saveFilter">
            Save
          </StyledSaveButton>
        </StyledButtonContainer>
      ) : null}
    </>
  )
}
