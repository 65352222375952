import * as C from 'appConstants'

const initialState = {
  error: '',
  sent: false
}

function populateSendContactSupportMessage (state: any, payload: any) {
  const newState = Object.assign({}, state)

  newState.sent = true
  newState.error = ''
  if (payload.response.status && payload.response.status >= 400) {
    newState.sent = false
    newState.error = 'Something went wrong.'
  }

  return newState
}

export default function (state: any = initialState, action: any) {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_SEND_CONTACT_SUPPORT_MESSAGE:
      return populateSendContactSupportMessage(state, payload)
    default:
      return state
  }
}
