// @flow

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled as muiStyled } from '@mui/styles'
import styled from 'styled-components'
import { CurrentDeviceType } from 'types/devices'

import { Col, PermissionCheck, ButtonWithIcon, ButtonOption } from 'components/ReUsable'
import { DeviceProps } from '../Types/DeviceTypes'
import DownloadReportButton from './downloadReportButton'

import { DENIED_MISSING_LICENSE, DEVICE_DETAILS_PERMISSIONS_LIST, GRANTED } from 'appConstants'
import { useSelector } from 'react-redux'

type ButtonTypes = { serialNumber: string; name?: string; onDialogToggle?: () => void }

export const SupportButton = ({ name, serialNumber }: ButtonTypes) => {
  const history = useHistory()
  return (
    <ButtonOption
      onClick={() => {
        history.push(`/contact?deviceName=${name}&serialNumber=${serialNumber}`)
      }}
      label="Support"
      iconName="support-grey"
      secondary={true}
      dark={false}
    ></ButtonOption>
  )
}

export const ManageAccessButton = ({ serialNumber, onDialogToggle }: ButtonTypes) => {
  const permissionValue = useSelector((state: any) => (state?.devices?.current as CurrentDeviceType).permissions[DEVICE_DETAILS_PERMISSIONS_LIST.DELEGATION_WRITE])
  const history = useHistory()

  const handleButtonClick = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (permissionValue === GRANTED) {
      history.push(`/devices/${serialNumber}/manageroles`)
    }
    if (permissionValue === DENIED_MISSING_LICENSE && onDialogToggle) {
      onDialogToggle()
    }
  }

  if (permissionValue === GRANTED || permissionValue === DENIED_MISSING_LICENSE) {
    return <ButtonOption onClick={handleButtonClick} label="Manage Access" iconName="manage-green" data-cy="manage-access-button"></ButtonOption>
  }

  return <></>
}

export const AuditLogsButton = ({ serialNumber, onDialogToggle }: ButtonTypes) => {
  const permissionValue = useSelector((state: any) => (state?.devices?.current as CurrentDeviceType).permissions[DEVICE_DETAILS_PERMISSIONS_LIST.AUDIT_READ])
  const history = useHistory()

  const handleButtonClick = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (permissionValue === GRANTED) {
      history.push(`/devices/${serialNumber}/auditlog`)
    }
    if (permissionValue === DENIED_MISSING_LICENSE && onDialogToggle) {
      onDialogToggle()
    }
  }

  if (permissionValue === GRANTED || permissionValue === DENIED_MISSING_LICENSE) {
    return <ButtonOption onClick={handleButtonClick} label="Audit" iconName="audit-log" data-cy="audit-logs-button"></ButtonOption>
  }

  return <></>
}

export const AdvancedInformationButton = ({ serialNumber }: ButtonTypes) => {
  const history = useHistory()

  const handleButtonClick = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    history.push(`/devices/${serialNumber}/advancedinfo`)
  }

  return <ButtonOption onClick={handleButtonClick} secondary={true} dark={true} label="Advanced Information" data-cy="advanced-information-button"></ButtonOption>
}

export const ServiceModeButton = ({ togglePageDialog }: { togglePageDialog: (obj: any) => void }) => {
  const permissionValue = useSelector((state: any) => (state?.devices?.current as CurrentDeviceType).permissions[DEVICE_DETAILS_PERMISSIONS_LIST.AUDIT_READ])
  //  TODO activate this permission
  //  const permissionValue = useSelector((state: any) => (state?.devices?.current as CurrentDeviceType).permissions[DEVICE_DETAILS_PERMISSIONS_LIST.SERVICE_MODE_WRITE])

  const handleButtonClick = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (permissionValue === GRANTED) {
      togglePageDialog({ dialogId: 'set-service-mode-dialog' })
    }
  }

  if (permissionValue === GRANTED) {
    return <ButtonOption onClick={handleButtonClick} secondary={true} dark={true} label="Set in Service" data-cy="set-in-service-button"></ButtonOption>
  }

  return <></>
}

//  Styled Container Col.
const StyledCol = styled(Col)`
  position: relative;
`

//  Styled Col for dropdown list.
const StyledColInner = styled(Col)`
  background-color: #fff;
  border-bottom-left-radius: var(--dim-border-radius);
  border-bottom-right-radius: var(--dim-border-radius);
  
  & > button {
    width: calc(100%);
  }
`

const CustomCollapse = muiStyled(Collapse)(() => ({
  '@media (min-width: 991.98px)': {
    position: 'absolute',
    zIndex: 10,
    width: '100%'
  }
}))

type Props = {
  togglePageDialog: (obj: any) => void
  permissions: any
  device: DeviceProps
}

export const DeviceOptions = (props: Props) => {
  const history = useHistory()
  const { togglePageDialog, permissions, device } = props
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    open && setOpen(false)
  }

  if (!device) {
    return <></>
  }

  return (
    <StyledCol alignItems="flex-end" data-cy="device-options">
      <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE}>
        <ButtonWithIcon
          label="set alerts"
          iconName="alarm-white"
          onClick={() => {
            history.push(`/devices/${device.serialNumber}/alerts`)
          }}
          disabled={device.inServiceMode && permissions[DEVICE_DETAILS_PERMISSIONS_LIST.SERVICE_MODE_WRITE] !== GRANTED}
        />
      </PermissionCheck>

      <div style={{ position: 'relative' }}>
        <ButtonWithIcon label="more" iconName="chevron-down" onClick={handleClick} optionList={true} isOpen={open} data-cy="device-more-options-button" />
        <CustomCollapse in={open} timeout="auto" unmountOnExit>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledColInner alignItems="flex-end" data-cy="device-more-options">
              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.LICENSE_ADD]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.LICENSE_ADD}>
                <ButtonOption onClick={() => togglePageDialog({ dialogId: 'add-license-dialog' })} iconName="license-green" label="Apply License" />
              </PermissionCheck>
              <ManageAccessButton serialNumber={device.serialNumber} onDialogToggle={() => togglePageDialog({ dialogId: 'license-missing-dialog' })} />
              <AuditLogsButton name={device.name} serialNumber={device.serialNumber} onDialogToggle={() => togglePageDialog({ dialogId: 'license-missing-dialog' })} />
              <SupportButton name={device.name} serialNumber={device.serialNumber} />
              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.UNCLAIM]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.UNCLAIM}>
                <ButtonOption onClick={() => togglePageDialog({ dialogId: 'remove-device-dialog' })} iconName="remove-grey" label="remove device" secondary={true} />
              </PermissionCheck>

              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.REPORT]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.REPORT}>
                <DownloadReportButton togglePageDialog={togglePageDialog} />
              </PermissionCheck>
              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.LICENSE_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.LICENSE_WRITE}>
                <ButtonOption
                  onClick={() =>
                    togglePageDialog({
                      dialogId: 'manage-license-dialog',
                      dialogTitle: 'Licenses'
                    })
                  }
                  secondary={true}
                  dark={true}
                  label="Modify Licenses"
                />
              </PermissionCheck>
              <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.ADVANCED_INFORMATION_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.ADVANCED_INFORMATION_READ}>
                <AdvancedInformationButton serialNumber={device.serialNumber} />
              </PermissionCheck>
              <ServiceModeButton togglePageDialog={togglePageDialog} />
            </StyledColInner>
          </ClickAwayListener>
        </CustomCollapse>
      </div>
    </StyledCol>
  )
}
