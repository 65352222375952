// @flow

import styled from 'styled-components'

import { OutlineButton } from 'components/ReUsable'

type Props = {
  label: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  secondary?: boolean,
  dark?: boolean,
  iconName?: string,
}

const StyledButton = styled(OutlineButton)`
  border: 1px solid #bebdbd;
  border-radius: 0;
  margin: 0;
  color: var(--color-option-text);
  transition: scale(1);
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: ${(props: any) => props.dark ? '#e9eaea' : 'transparent'};
  &:focus {
    box-shadow: none;
  }
  @media (min-width: 991.98px) {
    padding: 0 .85rem;
  }  

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid #bebdbd;
    border-bottom-left-radius: var(--dim-border-radius);
    border-bottom-right-radius: var(--dim-border-radius);
  }

  &:enabled:hover, &:enabled:focus {
    border-color: #bebdbd;
    transition: scale(1) !important;
    background: var(--color-background);
    transform: scale(1) !important;
  }
`
const StyledText = styled.span`
flexGrow: 1;
color: ${(props: any) => props.secondary ? '#4b4b4b' : 'var(--color-option-text)'};
`

const StyledImg = styled.img`
  width: 1rem;
  height: auto;
`

export default function ButtonOption (props: Props) {
  const { onClick, label, iconName, ...rest } = props

  return (
    <StyledButton onClick={onClick} {...rest}>
      <StyledText {...rest}>{label}</StyledText>
      {iconName &&
        <StyledImg style={iconName === 'audit-log' ? { width: '1.4rem', position: 'relative', left: '0.4rem' } : {}} alt={label} src={`/imgs/${iconName}.svg`} />
      }
    </StyledButton>
  )
}
