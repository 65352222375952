// @flow

export const OWNER = 'owner'
export const FULL_SUPPORT = 'full_support'
export const EFOY_MONITORING = 'efoy_monitoring'
export const CARTRIDGE_MONITORING = 'cartridge_monitoring'
export const EDITABLE_ROLES_ARRAY = [
  CARTRIDGE_MONITORING,
  EFOY_MONITORING,
  FULL_SUPPORT
]

export const LICENSE_MISSING = 'LICENSE_MISSING'
export const FORBIDDEN = 'FORBIDDEN'
export const DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND'

export const delegationScreenName = (d: string) => {
  let delegationVal = d

  if (delegationVal.indexOf('efoy') === 0) {
    delegationVal = delegationVal.replace('efoy', 'EFOY')
  }

  return (
    delegationVal.charAt(0).toUpperCase() +
    delegationVal.replace('_', ' ').slice(1)
  )
}
