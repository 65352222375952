// @flow

import React, { useState, useEffect } from 'react'
import {
    Form,
    Row,
    Col
} from 'react-bootstrap'
import styled from 'styled-components'
import { useFormik } from 'formik'
import moment from 'moment'
import { ALERT_TYPE, Button, Input, Alert } from 'components/ReUsable'
import { submitLicenseKey } from 'apis/devices'
import { getLicenses } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import ActivateInclusiveLicense, { INCLUSIVE_LICENSE } from './activateInclusiveLicense'
import { LicenseType } from 'types/devices'

const ContentDiv = styled.div`
    padding: 25px 50px 15px;
`
const ButtonRight = styled(Button)`
    margin: 1rem 0 1rem 0;
`
const SendButton = styled(Form.Group)`
    padding: 15px;
    padding-top: 30px;
    flex-direction: row-reverse;
`
const StyledRow = styled(Row)`
    padding-bottom: 15px;
`
const StyledFormLabel = styled(Form.Label)`
    text-transform: capitalize
`
const StyledLi = styled.li`
    white-space: nowrap;
`
const StyledUL = styled.ul`
    padding-left: 1rem;
`
const StyledInput = styled(Input)`
    height: 44px;
`
const StyledCenteredRow = styled(Row)`
   align-items: center;
`
type ActivateLicenseKeyProps = {
    serialNumber: string
}

export const validateLicenseKey = (key: string) => {
    const charsWithoutDashes = key.split('-').join('').split('')
    let integerChars = charsWithoutDashes.map(c => (c.charCodeAt(0) >= 65 && c.charCodeAt(0) < 97) ? (c.charCodeAt(0) - 55) : c).join('')
    let lastModValue = 0
    while (true) {
        const slicedChars = integerChars.substring(0, 9)
        const modValue = +slicedChars % 97
        integerChars = integerChars.slice(9)
        if (integerChars.length === 0) {
            lastModValue = modValue
            break
        }
        if (modValue === 0) continue
        integerChars = modValue + integerChars
    }
    return lastModValue
}

export const ActivateLicenseKey = ({ serialNumber }: ActivateLicenseKeyProps) => {
    const [loading, setLoading] = useState(false)
    const [activatedLicense, setActivatedLicense] = useState<LicenseType | null>(null)
    const [error, setError] = useState<{value?: boolean, message?: string, disable?: boolean}>({
        value: false,
        message: '',
        disable: true
    })
    const dispatch = useDispatch()
    const licenses:LicenseType[] = useSelector((state: any) => state.devices.licenses)

    useEffect(() => {
        dispatch(getLicenses({ serialNumber: serialNumber, state: { active: true, followup: true, consumed: false, inclusive: true } }))
    }, [])

    const handleLicenseApply = async (license: string) => {
        try {
            const res = await submitLicenseKey(serialNumber, license)
            setLoading(false)
            //  Successful response
            if (Object.prototype.hasOwnProperty.call(res, 'tier')) {
                setActivatedLicense(res)
            } else {
                throw new Error()
            }
        } catch {
            setError({ value: true, message: 'The key entered is incorrect or has already been activated.' })
        }
    }

    const formik = useFormik(
        {
            initialValues: {
                license: ''
            },
            onSubmit: values => {
                setLoading(true)
                setError({ value: false, message: '' })
                handleLicenseApply(values.license)
            }
        }
    )

    const isLicenseKeyValid = (key: string) => validateLicenseKey(key) === 1

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = event.target.value
        const validFormat = /^(?:[\dA-Z]{4}-){3}[\dA-Z]{2}\d{2}$/i.test(val)
        if (val.length === 19 && validFormat) {
            isLicenseKeyValid(val.toUpperCase()) ? setError({ value: false, message: '', disable: false }) : setError({ value: true, message: 'Invalid license key.', disable: true })
        } else setError({ disable: true })
        formik.setFieldValue(event.target.name, val)
    }

    //  Find inclusive license
    const inclusiveLicense = licenses ? licenses.filter((l:LicenseType) => l.state === INCLUSIVE_LICENSE) : []
    return <>
        <ContentDiv>
            {error.value ? <Alert id="license-error-alert" message={error.message} type={ALERT_TYPE.danger} /> : null }
            {
                activatedLicense ? <>
                    <StyledRow>
                        <Col>
                            License succesfully activated
                        </Col>
                    </StyledRow>
                    <StyledRow>
                        <Col>
                            License:
                        </Col>
                        <Col>
                            {activatedLicense.tier}
                        </Col>
                    </StyledRow>
                    <StyledRow>
                        <Col>
                            Duration:
                        </Col>
                        <Col>
                            {activatedLicense.duration}
                        </Col>
                    </StyledRow>
                    <StyledRow>
                        <Col>
                            Start date:
                        </Col>
                        <Col>
                            {activatedLicense.start ? moment(activatedLicense.start).format('DD.MM.yyyy') : ''}
                        </Col>
                    </StyledRow>
                    <StyledRow>
                        <Col>
                            End date:
                        </Col>
                        <Col>
                            {activatedLicense.end ? moment(activatedLicense.end).format('DD.MM.yyyy') : 'never'}
                        </Col>
                    </StyledRow>
                </>
                    : <Form onSubmit={formik.handleSubmit} id="activate-license-form">
                        {inclusiveLicense.length > 0 && <ActivateInclusiveLicense onLicenseAdd={handleLicenseApply} inclusiveLicense={inclusiveLicense[0]}/>}
                        <Form.Group as={Row} style={{ marginBottom: '0' }}>
                            <Form.Label column sm="4">
                                Active license:
                            </Form.Label>
                            <StyledFormLabel column sm="8">
                                <StyledUL>
                                {
                                    licenses && licenses.length > 0 ? licenses.map((license: any, key: number) => license.state === 'active' && <StyledLi key={key}>{`${license.tier} license (Expires: ${license.end ? moment(license.end).format('DD.MM.yyyy') : 'Never'})`}</StyledLi>) : 'None'
                                }
                                </StyledUL>
                            </StyledFormLabel>
                            <Form.Label column sm="4">
                                Follow-up license:
                            </Form.Label>
                            <StyledFormLabel column sm="8">
                                <StyledUL>
                                {
                                    licenses && licenses.length > 0 ? licenses.map((license: any, key: number) => license.state === 'followup' && <StyledLi key={key}>{`${license.tier} license (${moment(license.start).format('DD.MM.yyyy')} - ${license.end ? moment(license.end).format('DD.MM.yyyy') : 'Never'})`}</StyledLi>) : 'None'
                                }
                                </StyledUL>
                            </StyledFormLabel>
                        </Form.Group>
                        <StyledCenteredRow>
                            <Form.Label column sm="4">
                                License key:
                            </Form.Label>
                            <Col>
                                <StyledInput
                                    name="license"
                                    value={formik.values.license}
                                    placeholder='ABCD-ABCD-ABCD-ABCD'
                                    onChange={handleChange}
                                    required
                                    type="input"
                                    className="form-control"
                                />
                            </Col>
                        </StyledCenteredRow>
                        <SendButton as={Row}>
                            <ButtonRight data-cy="apply-license-button" type="submit" form="activate-license-form" loading={loading} disabled={error.disable}>Apply</ButtonRight>
                        </SendButton>
                    </Form>
            }
        </ContentDiv>
    </>
}

export default ActivateLicenseKey
