
import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 68px;
  justify-content: center;
`

const Loading: React.FC = () => {
  return <SpinnerContainer><Spinner animation="border" variant="secondary" /></SpinnerContainer>
}

export default Loading
