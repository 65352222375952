// @flow
import { Container, PageTitle, Button, Alert, ALERT_TYPE, Dialog, Text, Form, DatePicker } from 'components/ReUsable'
import styled from 'styled-components'
import { AccessTokenType, useAccessToken } from './hooks/useAccessToken'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { togglePageDialog } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { copyToClipboard } from 'utils/helpers'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'

const ContentDiv = styled.div`
  margin: 2rem auto 0 auto;
  padding: 1.8rem;
  background-color: #ffffff;
  width: 945px;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledPageTitle = styled(PageTitle)`
  text-align: left;
  margin-bottom: 1rem;
`

const now = new Date()
const twoMonthsLater = new Date()
twoMonthsLater.setMonth((new Date()).getMonth() + 2) // This sets the date 2 months from now
twoMonthsLater.setHours(0, 0, 0, 0) // This sets the time to 00:00:00.000
const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1))
//  const oneHourLater = new Date(now.setHours(now.getHours() + 1))
const oneHourLater = new Date((new Date()).getTime() + (60 * 60 * 1000))
export function AccessToken () {
  const { loading, error, createToken, deleteToken, tokens, saving } = useAccessToken()
  //  const errorMsg = error ? error.title : 'Something went wrong.'
  const errorMsg = error ? 'Something went wrong.' : ''
  if (loading) {
    return <></>
  }
  return (
    <Container>
      <ContentDiv>
        <StyledPageTitle data-cy="access-token-header" className="mr-auto mb-3">Personal Access Token</StyledPageTitle>
        { error && <Alert type={ ALERT_TYPE.danger } message={ errorMsg } /> }
        <TokenList tokens={tokens} saving={saving} createToken={ createToken } deleteToken={ deleteToken } />
      </ContentDiv>
    </Container>
  )
}

const TokenListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-text);
  line-height: 1.5rem;

  & > p {
    min-width: 42%;
    width: 42%;
    font-family: DigitalSerialBold;
    margin-bottom: .7rem;
    font-size: 13px;
  }
`

const TokenFormHeader = styled(TokenListHeader)`
  margin-bottom: -1rem;

  & > p {
    min-width: 42%;
    width: 42%;
    font-family: DigitalSerialBold;
    margin-bottom: .7rem;
    margin-top: 1rem;
    font-size: 13px;
  }
`

const TokenListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-text);
  line-height: 1.8rem;
  margin-bottom: .5rem;

  & > p {
    min-width: 42%;
    width: 42%;
    margin-bottom: 0;
    font-size: 13px;
    font-family: var(--bs-body-font-family);
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    padding-right: .5rem;
  }

  & > div {
    min-width: 42%;
    width: 42%;
    margin-top: 1rem;

    input#token-name {
      width: 90%;
      height: 2.5rem;
      font-family: var(--bs-body-font-family);
      @media (min-width: 991.98px) {
        width: 50%;
      }
    }

    input#token-expire-date {
      height: 2.5rem;
    }
  }
`

const StyledExpireDate = styled.p`
  @media (max-width: 991px) {
    min-width: 32% !important;
    width: 32% !important; 
  }
`

const StyledDialogContent = styled.div`
  text-align: center;
  padding: 1.6rem;
  & > p {
    text-align: center;
  }
`

const StyledCheckmarkImg = styled.img`
  width: 1.6rem;
  height: auto;
  margin-left: 1rem;
`
const StyledDeleteButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #CFCFCF;
  border-radius: 4px;
  background: transparent;
`

const StyledDeleteIcon = styled.img`
  width: 1rem;
  height: auto;
`

const StyledAddButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
  margin: 0;
  margin-top: 1rem;
  height: 2.5rem;
`

const StyledError = styled.span`
  display: block;
  color: var(--color-alert-error);
  font-size: 1rem;
`

export const StyledRemoveMenu = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0 !important;

  & > p {
    margin-bottom: 0;
    font-family: var(--bs-body-font-family);
  }
`

export const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: var(--color-primary);
    cursor: pointer;
    font-size: 18px;
`

export const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: var(--color-alert-error);
    cursor: pointer;
    font-size: 18px;
`

type TokenListType = {
  tokens: AccessTokenType[] | null,
  saving: boolean,
  createToken: (name: string, expiresAt: string) => Promise<string>,
  deleteToken: (uuid: string) => Promise<boolean>
}

function TokenList ({ tokens, saving, createToken, deleteToken } : TokenListType) {
  const dispatch = useDispatch()
  const [uuidToRemove, setUuidToRemove] = useState<string>('')
  const [newAccessToken, setNewAccessToken] = useState<string>('')
  const [newAccessTokenCopied, setNewAccessTokenCopied] = useState<boolean>(false)
  const dialogId = useSelector((state:any) => state?.dialog?.dialogId)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (newAccessToken && newAccessToken.length > 0) {
      dispatch(togglePageDialog({ dialogId: 'access-token-dialog' }))
    }
  }, [newAccessToken])

  const formik = useFormik({
    initialValues: {
      name: '',
      tokenStartDate: twoMonthsLater.toISOString()
    },
    onSubmit: async (values) => {
      const token = await createToken(values.name, values.tokenStartDate)
      setNewAccessToken(token)
      formik.resetForm()
    },
    validate: (values) => {
      const required = 'Required!'
      const errors: { name?: string, tokenStartDate?: string | null } = {}
      if (!values.name) {
        errors.name = required
      }
      if (!values.tokenStartDate) {
        errors.tokenStartDate = required
      }
      return errors
    },
    validateOnChange: true,
    validateOnBlur: true
  })
  return (
    <>
    {tokens && tokens.length > 0
    ? <>
        <TokenListHeader data-cy="token-list-header">
            <p>Name</p>
            <p>Expires at</p>
          </TokenListHeader>
          <div>
            { tokens.map((token: AccessTokenType) => {
              return (
                <TokenListRow data-cy="token-list-row" key={token.uuid}>
                  <p>{ token.name }</p>
                  <StyledExpireDate>{ format(new Date(token.expiresAt), 'dd.MM.yyyy') }</StyledExpireDate>
                  { uuidToRemove.length > 0 && token.uuid === uuidToRemove
                  ? <StyledRemoveMenu><p>Delete?</p>&nbsp;&nbsp;
                      <StyledPrimaryFontAwesomeIcon icon={['far', 'circle-check'] as any} onClick={() => deleteToken(token.uuid)} />&nbsp;&nbsp;
                      <StyledAlertFontAwesomeIcon icon={['far', 'circle-xmark'] as any} onClick={() => setUuidToRemove('')} />
                    </StyledRemoveMenu>
                  : <StyledDeleteButton onClick={() => setUuidToRemove(token.uuid)}>
                      <StyledDeleteIcon alt="Remove access token" src="/imgs/remove-grey.svg" />
                    </StyledDeleteButton>
                  }
                </TokenListRow>
              )
            })
            }
          </div>
          <br/>
        </>
      : <></>
      }
      <TokenFormHeader data-cy="token-form-header">
        <p>Add a new token</p>
      </TokenFormHeader>
      <Form id="add-efoy-role-form" handleSubmit={formik.handleSubmit}>
        <TokenListRow style={{ flexWrap: 'wrap', alignItems: 'flex-start' }}>
          <div>
            <input type="text" className="form-control form-input"
            data-cy="token-name"
            onChange={formik.handleChange}
            value={ formik.values.name }
            placeholder='Name'
            id="token-name"
            name="name"
            disabled={saving}/>
            {formik.errors.name && (
              <StyledError>{formik.errors.name}</StyledError>
            )}
          </div>
          <div>
            <DatePicker
              name="tokenStartDate"
              selected={formik.values.tokenStartDate ? new Date(formik.values.tokenStartDate) : undefined}
              onChange={(date: Date | null) => formik.setFieldValue('tokenStartDate', date ? date.toISOString() : '')}
              timeInputLabel="Time:"
              maxDate={oneYearLater}
              minDate={oneHourLater}
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect={true}
              timeIntervals={60}
              timeFormat='HH:mm'
              className='form-control form-input'
              disabled={saving}
              id="token-expire-date"
              placeholderText='Expire'
              data-cy="token-expires-at"
              withPortal={!matches}
            />
            {formik.errors.tokenStartDate && (
              <StyledError data-cy="token-start-date-error">{formik.errors.tokenStartDate}</StyledError>
            )}
          </div>
          <StyledAddButton type="submit" disabled={saving} data-cy="btn-add-token">
            Add
          </StyledAddButton>
        </TokenListRow>
      </Form>
      <Dialog
        id='access-token-dialog'
        title="Personal Access Token"
        show={ dialogId === 'access-token-dialog' }
        onClose={ () => {
          setNewAccessToken('')
          setNewAccessTokenCopied(false)
          dispatch(togglePageDialog())
        }}
      >
        <StyledDialogContent>
          <Text>
            Please make sure to copy your personal access token now. <br/>
            You won&apos;t be able to see it again.
          </Text>
          <Text className="form-group col-md-12" style={{ wordWrap: 'break-word' }}>
            { newAccessToken }
          </Text>
          <Button data-cy="copy-to-clipboard" onClick={async () => {
            const copySuccess: boolean = await copyToClipboard(newAccessToken)
            setNewAccessTokenCopied(copySuccess)
          }} style={{ marginLeft: '2.5rem' }}>
            Copy to clipboard
          </Button>
          <StyledCheckmarkImg src={'imgs/checkmark.svg'} alt="Done" style={{ visibility: newAccessTokenCopied && newAccessToken ? 'visible' : 'hidden' }} />
        </StyledDialogContent>
      </Dialog>
    </>
  )
}
