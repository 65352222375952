
import { createColumnHelper } from '@tanstack/react-table'

import DeviceRowCell from 'components/Application/DevicesTable/deviceRowCell'
import StateRowCell from 'components/Application/DevicesTable/stateRowCell'
import FuelLevelRowCell from 'components/Application/DevicesTable/fuelLevelRowCell'
import FirmwareVersionRowCell from 'components/Application/DevicesTable/firmwareVersionRowCell'
import CommonRowCell, { CommonSimpleRowCell, LicenseRowCell } from 'components/Application/CommonTable/commonRowCell'
import CommonHeadingCell from 'components/Application/CommonTable/commonHeadingCell'
import CommonHeadingDeviceCell from 'components/Application/CommonTable/commonHeadingDeviceCell'
import AccessTypeRowCell from '../DevicesTable/accessTypeRowCell'

export type FilterPropName = 'name' | 'serialNumber' | 'compoundState' | 'firmwareVersion'

export const defineColumns = function (devices, updateSorting, selectedColumns) {
    const columnHelper = createColumnHelper()
    const columnList = [
        columnHelper.accessor((row: any) => row.device, {
            header: (info: any) => <CommonHeadingDeviceCell
                value='Device / License'
                sortable
                sortKey='name'
                sorting={devices.sorting}
                sortByColumn={sorting => {
                    updateSorting({
                        sorting
                    })
                } }
                id={info.id}
                devices={devices?.items || []}
                filterable={true}
                filterPropName="name"
            />,
            cell: info => <DeviceRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'name'
        }),
        columnHelper.accessor((row: any) => ({ ...row.state, inServiceMode: row.inServiceMode }), {
            header: (info: any) => <CommonHeadingCell
                value='Operating state'
                id={info.id}
                sortable
                sortKey='compoundState'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
                filterable={true}
                filterPropName="compoundState"
            />,
            cell: info => <StateRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'compoundState'
        }),
        columnHelper.accessor((row: any) => ({ ...row.fuel_level, inServiceMode: row.inServiceMode }), {
            header: (info: any) => <CommonHeadingCell
                value='Fuel level'
                id={info.id}
                sortable
                sortKey='fuelLevel'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <FuelLevelRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'fuelLevel'
        }),
        columnHelper.accessor((row: any) => ({ ...row.firmware_version, inServiceMode: row.inServiceMode }), {
            header: (info: any) => <CommonHeadingCell
                value='Firmware'
                hideable
                id={info.id}
                sortable
                sortKey='firmwareVersion'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
                filterable={true}
                filterPropName="firmwareVersion"
            />,
            cell: info => <FirmwareVersionRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'firmwareVersion'
        }),
        columnHelper.accessor((row: any) => ({ ...row.battery_soc_or_voltage, inServiceMode: row.inServiceMode }), {
            header: (info: any) => <CommonHeadingCell
                value='Voltage / SOC'
                hideable
                id={info.id}
                sortable
                sortKey='batteryVoltage'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <CommonRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'batteryVoltage'
        }),
        columnHelper.accessor((row: any) => ({ ...row.operating_hours, inServiceMode: row.inServiceMode }), {
            header: (info: any) => <CommonHeadingCell
                value='Operating hours'
                hideable
                id={info.id}
                sortable
                sortKey='stackOperationTime'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <CommonRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'stackOperationTime'
        }),
        columnHelper.accessor((row: any) => row.serialNumber, {
            header: (info: any) => <CommonHeadingCell
                value='Serial number'
                sortable
                hideable
                sorting={devices.sorting}
                sortKey='serialNumber'
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
                id={info.id}
                filterable={true}
                filterPropName="serialNumber"
            />,
            cell: info => <CommonSimpleRowCell values={
                    { ...info.getValue() }
                } />,
            id: 'serialNumber'
        }),
        columnHelper.accessor((row: any) => row.license_type, {
            header: (info: any) => <CommonHeadingCell
                value='License Type'
                id={info.id}
                sortable
                sortKey='licenseType'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <LicenseRowCell values={
                { ...info.getValue() }
            } />,
            id: 'licenseType'
        }),
        columnHelper.accessor((row: any) => row.license_expire, {
            header: (info: any) => <CommonHeadingCell
                value='License Expire'
                id={info.id}
                sortable
                sortKey='licenseExpire'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <LicenseRowCell values={
                { ...info.getValue() }
            } />,
            id: 'licenseExpire'
        }),
        columnHelper.accessor((row: any) => row.access_type, {
            header: (info: any) => <CommonHeadingCell
                value='Access Type'
                id={info.id}
                sortable
                sortKey='ownershipStatus'
                sorting={devices.sorting}
                sortByColumn={sorting => updateSorting({
                    sorting
                })}
            />,
            cell: info => <AccessTypeRowCell values={
                { ...info.getValue() }
            } />,
            id: 'accessType'
        })
    ]

    return columnList.filter((c: any) => selectedColumns.indexOf(c.id) > -1).sort((a, b) => {
        return selectedColumns.indexOf(a.id) - selectedColumns.indexOf(b.id)
    })
}
