import { createStore, compose, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import mainSaga from './sagas'
import history from './utils/history'

export default function configureStore (preloadedState) {
  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )
  )

  sagaMiddleware.run(mainSaga)

  return store
}
