// @flow
/** Types: https://instatus.com/help/api */
/** Summary Endpoint: https://instatus.com/help/api/public-data */
export type StatusSummary = {
  page: StatusPage;
  activeIncidents?: IncidentSummary[];
  activeMaintenances?: MaintenanceStatus[];
};

type StatusPage = {
  name: string;
  url: string;
  status: PageStatus;
};

export type IncidentSummary = {
  name: string;
  started: string;
  status: IncidentStatus;
  impact: IncidentImpact;
  url: string;
};

type IncidentStatus =
  | 'INVESTIGATING'
  | 'IDENTIFIED'
  | 'MONITORING'
  | 'RESOLVED';

type IncidentImpact =
  | 'OPERATIONAL'
  | 'UNDERMAINTENANCE'
  | 'DEGRADEDPERFORMANCE'
  | 'PARTIALOUTAGE'
  | 'MAJOROUTAGE';

export type MaintenanceStatus = {
  name: string;
  start: string;
  status: MaintenanceStatusTypes;
  duration: string;
  url: string;
};

type MaintenanceStatusTypes = 'NOTSTARTEDYET' | 'INPROGRESS' | 'COMPLETED';

type PageStatus =
  | 'UP'
  | 'HASISSUES'
  | 'ALLUNDERMAINTENANCE'
  | 'ALLDEGRADEDPERFORMANCE'
  | 'ALLPARTIALOUTAGE'
  | 'ALLMINOROUTAGE'
  | 'ALLMAJOROUTAGE'
  | 'SOMEUNDERMAINTENANCE'
  | 'SOMEDEGRADEDPERFORMANCE'
  | 'SOMEPARTIALOUTAGE'
  | 'SOMEMINOROUTAGE'
  | 'SOMEMAJOROUTAGE'
  | 'ONEUNDERMAINTENANCE'
  | 'ONEDEGRADEDPERFORMANCE'
  | 'ONEPARTIALOUTAGE'
  | 'ONEMINOROUTAGE'
  | 'ONEMAJOROUTAGE'

const STATUS_ENDPOINT_URL = (window as any).REACT_APP_STATUS_URL
  ? (window as any).REACT_APP_STATUS_URL
  : process.env.REACT_APP_STATUS_URL
  ? process.env.REACT_APP_STATUS_URL
  : 'https://efoy-cloud.instatus.com/'
const statusEndpoint = `${STATUS_ENDPOINT_URL}summary.json`
export const getApplicationStatusRequest = async () => {
  let response: Response
  try {
    response = await fetch(statusEndpoint)
    if (response.ok) {
      return await response.json()
    } else {
      const text = await response.text()
      throw new Error(text)
    }
  } catch (error) {
    throw new Error()
  }
}
