// @flow

import styled from 'styled-components'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { addDays, startOfDay } from 'date-fns'
import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'

import {
  Container,
  PaginateIt,
  CommonTable,
  Text,
  Col,
  Row,
  Button,
  DatePicker,
  PageTitle,
  SelectElement,
  PageNotReachable
} from 'components/ReUsable'
import { getRowStyles } from './rowStyles'
import { useAdminAudit } from './hooks/useAdminAudit'
import { defineColumns } from './defineColumns'
import { StyledAdminCard } from 'components/ReUsable/Card'

const maxEndDate = startOfDay(addDays(new Date(), 1))

export const StyledEmptyText = styled(Text)`
  margin: 2rem 0;
  text-align: center;
`

const StyledDatePickerRow = styled.div`
  width: 32rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center
`

const StyledShowAllButton = styled(Button)<{ show?: boolean }>`
  min-height: 31.5px;
  min-width: 8rem;
  visibility: ${(props: any) => (props.show ? 'visible' : 'hidden')};
`

const AuditLogs = () => {
  const {
    audits,
    pageNumber,
    customError,
    endDate,
    startDate,
    eventType,
    allowRead,
    handlePageNumberChange,
    handleRangeDateChange,
    handleEventTypeChange
  } = useAdminAudit()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const table = useReactTable({
    data: audits?.content || [],
    columns: defineColumns(),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      getRowStyles
    }
  })

  //  Page only visible for users with user read rights.
  if (!allowRead) return <PageNotReachable/>

  return (
    <Container data-cy='admin-page-audit-logs'>
      <Row margin='1rem 0'>
        <Col>
          <PageTitle>Audit Log</PageTitle>
        </Col>
      </Row>
      <Row>
        <StyledAdminCard>
          <StyledDatePickerRow>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxEndDate}
              onChange={handleRangeDateChange}
              monthsShown={!matches ? 1 : 2}
              fixedHeight={true}
              showMonthDropdown={true}
              showYearDropdown={true}
              withPortal={!matches}
              data-cy="audit-log-date-picker"
            />
            <SelectElement
              value={eventType}
              id="event-type-dropdown"
              className='form-control'
              data-cy="audit-log-select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleEventTypeChange(e.target.value)}>
              <option value="">Filter by Event Type</option>
              <option value="start-efoy">start-efoy</option>
              <option value="reset-efoy">reset-efoy</option>
              <option value="change-battery-parameter">change-battery-parameter</option>
              <option value="reset-fuel-cartridge">reset-fuel-cartridge</option>
              <option value="direct-command">direct-command</option>
              <option value="alert-management">alert-management</option>
              <option value="device-delegation">device-delegation</option>
              <option value="configure-port">configure-port</option>
              <option value="reset-charging-cycle">reset-charging-cycle</option>
              <option value="user-group-management">user-group-management</option>
              <option value="license-management">license-management</option>
              <option value="device-claim">device-claim</option>
              <option value="device-management">device-management</option>
              <option value="board-assignment">board-assignment</option>
              <option value="notice">notice</option>
            </SelectElement>
            <StyledShowAllButton
              onClick={() => {
                handleRangeDateChange([undefined, undefined])
                handleEventTypeChange('')
                handlePageNumberChange(1)
              }}
              data-cy="audit-filter-clear-button"
              show={startDate || endDate || eventType !== ''}
            >
              Clear filters
            </StyledShowAllButton>
          </StyledDatePickerRow>
          { audits && audits?.content?.length ? (
              <PaginateIt
                page={pageNumber}
                totalPages={audits.totalPages}
                firstPage={audits.first}
                lastPage={audits.last}
                jumpToPage={handlePageNumberChange}
                showLegend={false}
              >
                <CommonTable
                  tableClass={'table table-bordered'}
                  theadLineClass={'table-dark-grey'}
                  table={table}
                />
              </PaginateIt>
            ) : (
              <StyledEmptyText data-cy='empty-audit-logs'>
                {customError || 'No audit entries yet.'}
              </StyledEmptyText>
            )
          }
        </StyledAdminCard>
      </Row>
    </Container>
  )
}

export default AuditLogs
