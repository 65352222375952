// @flow

import {
  backendEndpoint,
  executeRequest
} from '.'

const licenseEndpoint = `${backendEndpoint || ''}/licenses`

export type GenerateLicenseFormType = {
  amount: string;
  tier: 'basic' | 'platinum';
  duration: 'half_year' | 'one_year';
}

export const generateNewLicenses = (payload: GenerateLicenseFormType) => {
  const options = {
    accept: 'text/csv',
    'Content-Type': 'application/json',
    method: 'POST',
    body: JSON.stringify({ ...payload })
  }
  return executeRequest(`${licenseEndpoint}`, undefined, undefined, options)
}
