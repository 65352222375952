// @flow

import { ALERT_TYPE, Alert } from '.'

type Props = {
    pageDialog: {
        requestErrorMessage: string,
        alertConfigSavedSuccess?: boolean,
        alertProfilesConfigSavedSuccess?: boolean,
        batteryConfigSavedSuccess?: boolean,
        fuelCellConfigSavedSuccess?: boolean,
        loadingRequest?: boolean,
        deviceOfflineErrorMessage?: string
    }
}

export default function AlertConfigMessages (props: Props) {
    const {
        requestErrorMessage, alertConfigSavedSuccess, alertProfilesConfigSavedSuccess, batteryConfigSavedSuccess, fuelCellConfigSavedSuccess
    } = props.pageDialog

    if (alertProfilesConfigSavedSuccess) return (<Alert message={'Alert profiles configurations saved.'} type={ALERT_TYPE.success} />)

    if (alertConfigSavedSuccess) return (<Alert message={'New alerts profiles saved.'} type={ALERT_TYPE.success} />)

    if (batteryConfigSavedSuccess) return (<Alert message={'Battery settings saved.'} type={ALERT_TYPE.success} />)

    if (fuelCellConfigSavedSuccess) return (<Alert message={'Operating mode successfully changed'} type={ALERT_TYPE.success} />)

    if (requestErrorMessage.length === 0) return null

    return (<Alert message={requestErrorMessage} type={ALERT_TYPE.danger} />)
}
