import {
  REQUEST_TIMEOUT_STATUS_CODE,
  GATEWAY_TIMEOUT_STATUS_CODE,
  NOT_FOUND_STATUS_CODE,
  SOMETHING_WENT_WRONG,
  DEVICE_NOT_REACHABLE_ERROR,
  DEVICE_NOT_FOUND_ERROR,
  DEVICE_ALREADY_CLAIMED_ERROR,
  CLAIMING_PASSWORD_ERROR,
  SERIAL_NUMBER_SCREEN_STEP,
  RETRY_CONNECTION_SCREEN_STEP,
  CURRENT_USER_STEP_ONE,
  CURRENT_USER_STEP_TWO,
  CONFLICT_STATUS_CODE,
  BAD_REQUEST_STATUS_CODE,
  DEVICE_CLAIM_EXPIRED_ERROR
} from 'appConstants'

export const getErrorMessageForAddSerialNumber = (responseStatus: number) => {
  switch (responseStatus) {
    case NOT_FOUND_STATUS_CODE:
      return DEVICE_NOT_FOUND_ERROR
    case CONFLICT_STATUS_CODE:
      return DEVICE_ALREADY_CLAIMED_ERROR
    case GATEWAY_TIMEOUT_STATUS_CODE:
      return DEVICE_NOT_REACHABLE_ERROR
    default:
      return SOMETHING_WENT_WRONG
  }
}

export const getErrorMessageForAddPassword = (responseStatus: number, response: any) => {
  if (responseStatus === NOT_FOUND_STATUS_CODE) {
    if (response?.type && response?.type.indexOf('expired') > -1) {
      return DEVICE_CLAIM_EXPIRED_ERROR
    } else if (response?.type && response?.type.indexOf('not-found') > -1) {
      return DEVICE_NOT_FOUND_ERROR
    }
  }

  if (responseStatus === BAD_REQUEST_STATUS_CODE) {
    return CLAIMING_PASSWORD_ERROR
  }

  return SOMETHING_WENT_WRONG
}

export const getNewStateBasedOnError = (state, error) => {
  if ([REQUEST_TIMEOUT_STATUS_CODE, GATEWAY_TIMEOUT_STATUS_CODE].includes(error)) {
    state.pageDialog.currentStep = RETRY_CONNECTION_SCREEN_STEP
    state.pageDialog.currentUserStep = CURRENT_USER_STEP_TWO

    return state
  }

  state.pageDialog.currentStep = SERIAL_NUMBER_SCREEN_STEP
  state.pageDialog.currentUserStep = CURRENT_USER_STEP_ONE

  return state
}
