import * as C from 'appConstants'
import { NEW_API_ACTIVE } from 'featureToggles'

import {
  getErrorMessageForAddSerialNumber,
  getErrorMessageForAddPassword,
  getNewStateBasedOnError
} from 'helpers/claimDevices'
import { LicenseType } from 'types/devices'

import { productImage, getProperty } from 'utils/helpers'

export const allDeviceColumnsList = [
  'name',
  'compoundState',
  'fuelLevel',
  'firmwareVersion',
  'batteryVoltage',
  'stackOperationTime',
  'serialNumber',
  'licenseType',
  'licenseExpire',
  'accessType'
]

export const defaultDeviceColumns = ['name', 'compoundState', 'fuelLevel', 'firmwareVersion', 'batteryVoltage', 'stackOperationTime', 'serialNumber']

const devicesPageDialogInitialState = {
  pageDialog: {
    title: 'Add EFOY',
    currentStep: C.SERIAL_NUMBER_SCREEN_STEP,
    currentUserStep: C.CURRENT_USER_STEP_ONE,
    lastStep: C.CURRENT_USER_STEP_THREE,
    requestErrorMessage: '',
    chargeFuelCellSuccess: false,
    resetStatus: null
  }
}

const xlsxReportInitialState = {
  xlsxReport: {
    exportId: '',
    fileLimitExceeded: false, //  only if status is done
    status: 'processing',
    expiresAt: null //  only if status is done
  }
}

const initialState = {
  page: 1,
  firstPage: true,
  lastPage: false,
  hasMoreItems: false,
  items: [],
  loaded: false,
  totalItems: 0,
  search: {
    searchValue: ''
  },
  selectedDevices: [],
  selectionActive: false,
  selectedColumns: defaultDeviceColumns,
  current: null,
  ...devicesPageDialogInitialState,
  startClaimingForm: {
    serialNumber: '',
    defaultName: ''
  },
  verifyPasswordForm: {
    password: ''
  },
  setDeviceNameForm: {
    name: ''
  },
  sorting: {
    sortKey: 'name',
    sortDirection: 'asc'
  },
  efoyAddedSuccess: false,
  updateCartridge: {
    loading: false
  },
  report: {
    checkUrl: null,
    exportId: null,
    finished: ''
  },
  ...xlsxReportInitialState,
  licenses: null,
  filter: null
}

function populateDevice (state: any, payload: any) {
  if (payload.device instanceof Error) {
    return {
      ...state,
      current: {
        error: {
          message: payload.device.message
        }
      }
    }
  }
  //  API error received
  if (payload?.device?.status >= 400) {
    return {
      ...state,
      current: {
        error: {
          ...payload?.device
        }
      }
    }
  }

  const device = {
    ...payload.device,
    img_url: productImage(payload.device.deviceType || '')
  }

  return {
    ...state,
    current:
      payload.device && Object.keys(payload.device).length !== 0
        ? device
        : null
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function clearDevice (state: any) {
  return {
    ...state,
    current: null
  }
}

function populateDevices (state: any, payload: any) {
  const newState = {
    ...state,
    totalItems: payload.totalItems ? payload.totalItems : 0,
    page: payload.pageNumber,
    totalPages: payload.totalPages,
    firstPage: payload.firstPage,
    lastPage: payload.lastPage,
    items: payload.content,
    search: {
      searchValue: payload.searchValue
    },
    efoyAddedSuccess: false,
    loaded: true,
    selectedColumns: NEW_API_ACTIVE ? payload.selectedColumns || defaultDeviceColumns : defaultDeviceColumns
  }

  if (payload.sorting) {
    newState.sorting = payload.sorting
  }

  return newState
}

function updateDeviceSorting (state: any, payload: {sorting: {
  sortKey: string;
  sortDirection: string;
}}) {
  const newState = {
    ...state,
    sorting: payload.sorting
  }

  return newState
}

function updateDevicePageIndex (state: any, payload: number) {
  const newState = {
    ...state,
    page: payload
  }

  return newState
}

function populateTelemetryHistory (state: any, payload: any) {
  const newState = {
    ...state,
    telemetryHistory: {
      ...payload
    }
  }

  return newState
}

function populateTelemetryEvents (state: any, payload: any) {
  const newState = {
    ...state,
    telemetryEvents: {
      ...payload
    }
  }

  return newState
}

const populateStartClaiming = (state: any, payload: any) => {
  // If there is a serial number error, go to serial number screen displaying error
  // If not, go to password screen

  const newState = Object.assign({}, state)
  const responseContent = payload?.response?.status
  if (responseContent >= 400) {
    newState.pageDialog.requestErrorMessage =
      getErrorMessageForAddSerialNumber(responseContent)

    return getNewStateBasedOnError(
      newState,
      newState.pageDialog.requestErrorMessage
    )
  }

  newState.startClaimingForm.serialNumber = payload.serialNumber
  newState.startClaimingForm.defaultName = payload.response.defaultName

  newState.pageDialog = {
    ...state.pageDialog,
    title: 'Claiming key',
    currentStep: C.PASSWORD_SCREEN_STEP,
    currentUserStep: C.CURRENT_USER_STEP_TWO,
    requestErrorMessage: ''
  }

  return newState
}

const populateVerifyPassword = (state: any, payload: { response: any, password: string }) => {
  // If there is a password error, go to password screen displaying error
  // If not, go to final screen and add device name

  const newState = Object.assign({}, state)
  const responseContent = payload?.response?.status
  if (responseContent >= 400) {
    newState.pageDialog = {
      ...state.pageDialog,
      requestErrorMessage: getErrorMessageForAddPassword(responseContent, payload?.response),
      title: 'Claiming key',
      currentStep: C.PASSWORD_SCREEN_STEP,
      currentUserStep: C.CURRENT_USER_STEP_TWO
    }
  } else {
    newState.pageDialog = {
      ...state.pageDialog,
      title: 'Connection successful',
      currentStep: C.DEVICE_NAME_SCREEN_STEP,
      currentUserStep: C.CURRENT_USER_STEP_THREE,
      requestErrorMessage: ''
    }
  }

  newState.verifyPasswordForm.password = payload.password

  return newState
}

const connectTrySubmit = (state: any) => {
  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      title: 'Add EFOY',
      currentStep: C.TRY_CONNECTION_SCREEN_STEP
    }
  }
}

const populateAddedEfoyFormSubmit = (state: any, payload: { inclusiveLicense?: LicenseType | null }) => {
  //  Setting name is the last step in the claiming process,
  //  only if there is no inclusive license assigned to the about to claim device.
  if (!payload.inclusiveLicense) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        title: 'Add EFOY'
      },
      efoyAddedSuccess: true
    }
  }
  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      title: 'Add EFOY',
      currentStep: C.INCLUSIVE_LICENSE_SCREEN_STEP,
      inclusiveLicense: payload.inclusiveLicense
    }
  }
}

const populateAddedInclusiveLicenseSubmit = (state: any, payload) => {
  const newState = Object.assign({}, state)
  const responseStatus = payload?.status || 200
  newState.pageDialog = {
    ...state.pageDialog,
    title: 'Add EFOY',
    currentStep: C.INCLUSIVE_LICENSE_SCREEN_STEP,
    requestErrorMessage: responseStatus >= 400 ? payload?.detail : ''
  }

  return {
    ...newState,
    efoyAddedSuccess: responseStatus < 400
  }
}

const completeClaimDevice = (state: any) => {
  return {
    ...state,
    efoyAddedSuccess: true
  }
}

// TODO: Maybe consider to add fuelcell handlers to a new fuelCellReducer file.
const populateChargeFuelCell = (state: any, payload: any) => {
  // TODO: waiting for the backend to provide a success response so, we can check the edge cases.
  // As of now we do not receive any success responses.
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        requestErrorMessage: C.SOMETHING_WENT_WRONG,
        chargeFuelCellSuccess: false
      }
    }
  }

  // TODO: can we have this scaped already in the backend?
  if (payload.status === 'Ok\r') {
    return {
      ...state,
      current: {
        ...state.current,
        details: {
          ...state.current.details,
          content: {
            ...state.current.details.content,
            fuelCell: {
              ...state.current.details.content.fuelCell,
              operatingMode: payload.operatingMode
            }
          }
        }
      }
    }
  }

  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      requestErrorMessage: '',
      chargeFuelCellSuccess: true
    }
  }
}

const populateResetFuelCell = (state: any, payload: any) => {
  // TODO: waiting for the backend to provide a success response so, we can check the edge cases.
  // As of now we do not receive any success responses.
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        requestErrorMessage: C.SOMETHING_WENT_WRONG
      }
    }
  }

  const resetStatusForDevice = state.pageDialog?.resetStatus?.[payload.serialNumber] === C.WAITING_COMPLETE && payload.resetStatus === 'complete' ? C.RESET_COMPLETE : payload.resetStatus
  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      requestErrorMessage: '',
      resetStatus: { ...state.pageDialog?.resetStatus, [payload.serialNumber]: resetStatusForDevice }
    }
  }
}

const populateResetFuelCellStatus = (state: any, payload: any) => {
  if (payload?.response?.status >= 400) {
    return state
  }

  const resetStatusForDevice = state.pageDialog?.resetStatus?.[payload.serialNumber] === C.WAITING_COMPLETE && payload.resetStatus === 'complete' ? C.RESET_COMPLETE : payload.resetStatus

  return {
    ...state,
    pageDialog: {
      ...state.pageDialog,
      resetStatus: { ...state.pageDialog?.resetStatus, [payload.serialNumber]: resetStatusForDevice }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateCartridge = (state: any, payload: any) => {
  return {
    ...state,
    updateCartridge: {
      loading: true
    }
  }
}

const populateUpdateCartridge = (state: any, payload: any) => {
  return {
    ...state,
    updateCartridge: {
      loading: false,
      status: payload.response.status,
      message:
        payload.response.status >= 400
          ? C.SOMETHING_WENT_WRONG
          : C.UPDATE_SUCCESSFULL
    }
  }
}

const populateDeviceExport = (state: any, payload: any) => {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        requestErrorMessage: C.SOMETHING_WENT_WRONG
      }
    }
  }

  return {
    ...state,
    report: payload.response,
    pageDialog: {
      ...state.pageDialog,
      requestErrorMessage: ''
    }
  }
}

const populateXLSXExport = (state: any, payload: any) => {
  if (payload?.response?.status >= 400) {
    return {
      ...state,
      pageDialog: {
        ...state.pageDialog,
        requestErrorMessage: C.SOMETHING_WENT_WRONG
      }
    }
  }

  return {
    ...state,
    xlsxReport: {
      ...state.xlsxReport,
      ...payload.response
    },
    pageDialog: {
      ...state.pageDialog,
      requestErrorMessage: ''
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearXLSXExport = (state: any, payload?: any) => ({
  ...state,
  ...xlsxReportInitialState,
  ...clearDevicesPageDialog(state)
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearDevicesPageDialog = (state: any, payload?: any) => ({
  ...state,
  ...devicesPageDialogInitialState
})

const resetCatridgeMessage = (state: any) => ({
  ...state,
  updateCartridge: {
    loading: false
  }
})

const resetDeviceExport = (state: any) => ({
  ...state
})

const clearClaimForm = (state) => ({
  ...state,
  startClaimingForm: {
    serialNumber: '',
    defaultName: ''
  },
  verifyPasswordForm: {
    password: ''
  }
})

const populateLicenses = (state: any, payload: any) => {
  return {
    ...state,
    licenses: getProperty(payload)
  }
}

const toggleSelectionMode = (state, { selectionActive }) => {
  return {
    ...state,
    selectionActive: selectionActive,
    selectedDevices: []
  }
}

const updateSelectedDevices = (state: any, payload: any) => {
  let selectedDevices = state.selectedDevices
  //  Reset
  if (!payload) {
    selectedDevices = []
  } else {
    const { serialNumber, name } = payload
    //  Already added. Remove
    if (selectedDevices.find((device: any) => device.serialNumber === serialNumber)) {
      selectedDevices = selectedDevices.filter((device: any) => device.serialNumber !== serialNumber)
    } else {
      selectedDevices.push({ serialNumber, name })
    }
  }
  return {
    ...state,
    selectedDevices: selectedDevices
  }
}

//  serialnumbers: string[]
//  mode: string --> 'ADD' | 'REMOVE'
const batchUpdateSelectedDevices = (state: any, { serialNumbers, mode }: {serialNumbers:{serianNumber:string; name: string}[], mode: string}) => {
  let selectedDevices: {serianNumber:string; name: string}[] = state?.selectedDevices
  const newDevices = serialNumbers.map((d: any) => selectedDevices.find((sd: any) => sd.serialNumber === d.serialNumber) ? null : d).filter((d: any) => d !== null)
    if (mode === 'ADD') {
      selectedDevices = selectedDevices.concat(newDevices)
    } else {
      //  Remove
      selectedDevices = selectedDevices.filter((d: any) => serialNumbers.map((sd: any) => sd.serialNumber).indexOf(d.serialNumber) === -1)
    }
  return {
    ...state,
    selectedDevices
  }
}

const updateSelectedDeviceColumns = (state: any, payload: { selectedList: string[] }) => {
  const { selectedList } = payload
  let selectedColumns = state.selectedColumns
  //  Selected Columns
  if (selectedList.length === 0) {
    selectedColumns = [...defaultDeviceColumns]
  } else {
    selectedColumns = [...selectedList]
  }

  return {
    ...state,
    selectedColumns
  }
}

const applyFilter = (state: any, payload: { name: string, value: string | string[] | {inactive?: string[], active?: string[]} | null } | null) => {
  if (!payload) {
    return {
      ...state,
      selectionActive: false, //  Disable device selection mode
      filter: null
    }
  }
  const { name, value } = payload
  const filterVal = { ...state.filter, [name]: value }
  if (!value) {
    delete filterVal[name]
  }

  if (Object.keys(filterVal).length === 0) {
    return {
      ...state,
      selectionActive: false, //  Disable device selection mode
      filter: null
    }
  }

  return {
    ...state,
    selectionActive: false, //  Disable device selection mode
    filter: { ...filterVal }
  }
}

export default (state: any = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_DEVICES:
      return populateDevices(state, payload)
    case C.POPULATE_DEVICE:
      return populateDevice(state, payload)
    case C.CLEAR_DEVICE:
      return clearDevice(state)
    case C.POPULATE_START_CLAIMING:
      return populateStartClaiming(state, payload)
    case C.POPULATE_VERIFY_PASSWORD:
      return populateVerifyPassword(state, payload)
    case C.CONNECT_TO_DEVICE_SUBMIT:
      return connectTrySubmit(state)
    case C.POPULATE_SET_DEVICE_NAME:
      return populateAddedEfoyFormSubmit(state, payload)
    case C.POPULATE_SET_INCLUSIVE_LICENSE:
      return populateAddedInclusiveLicenseSubmit(state, payload)
    case C.COMPLETE_CLAIM_DEVICE:
      return completeClaimDevice(state)
    case C.POPULATE_CHARGE_FUEL_CELL:
      return populateChargeFuelCell(state, payload)
    case C.POPULATE_RESET_FUEL_CELL:
      return populateResetFuelCell(state, payload)
    case C.POPULATE_RESET_FUEL_CELL_STATUS:
      return populateResetFuelCellStatus(state, payload)
    case C.POPULATE_TELEMETRY_HISTORY:
      return populateTelemetryHistory(state, payload)
    case C.POPULATE_TELEMETRY_EVENTS:
      return populateTelemetryEvents(state, payload)
    case C.UPDATE_CARTRIDGE:
      return updateCartridge(state, payload)
    case C.POPULATE_UPDATE_CARTRIDGE:
      return populateUpdateCartridge(state, payload)
    case C.POPULATE_DEVICE_EXPORT:
      return populateDeviceExport(state, payload)
    case C.POPULATE_XLSX_EXPORT:
      return populateXLSXExport(state, payload)
    case C.CLEAR_DEVICES_PAGE_DIALOG:
      return clearDevicesPageDialog(state, payload)
    case C.CLEAR_XLSX_EXPORT:
      return clearXLSXExport(state, payload)
    case C.RESET_CATRIDGE_MESSAGE:
      return resetCatridgeMessage(state)
    case C.RESET_DEVICE_EXPORT:
      return resetDeviceExport(state)
    case C.CLEAR_CLAIM:
      return clearClaimForm(state)
    case C.POPULATE_LICENSES:
      return populateLicenses(state, payload)
    case C.TOGGLE_SELECTION_MODE:
      return toggleSelectionMode(state, payload)
    case C.UPDATE_SELECTED_DEVICES:
      return updateSelectedDevices(state, payload)
    case C.BATCH_UPDATE_SELECTED_DEVICES:
      return batchUpdateSelectedDevices(state, payload)
    case C.APPLY_DEVICE_FILTER:
      return applyFilter(state, payload)
    case C.UPDATE_DEVICE_SORTING:
      return updateDeviceSorting(state, payload)
    case C.UPDATE_DEVICE_PAGE_NUMBER:
      return updateDevicePageIndex(state, payload)
    case C.UPDATE_SELECTED_DEVICE_COLUMNS:
      return updateSelectedDeviceColumns(state, payload)
    default:
      return state
  }
}
