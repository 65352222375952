// @flow

import { useState } from 'react'
import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled as muiStyled } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RadioButton } from 'components/ReUsable'
import {
  EDITABLE_ROLES_ARRAY,
  delegationScreenName,
  OWNER
} from 'appConstants'
import { Delegation } from './ManageDelegationList'

import { useAddDelegation } from '../hooks/useAddDelegation'
import { useParams } from 'react-router-dom'

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledExpandButton = styled.button<{isOpen: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  background: ${(props: any) => (props.isOpen ? '#fff' : 'transparent')};

  &:hover {
    background: #fff;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 20px;
  path {
    fill: #bebdbd;
  }
`

export const StyledText = styled.span<{isOpen?: boolean, title?: string, 'data-cy'?: string}>`
{
  font-size: 17px;
  margin-bottom: 0;
  color: ${(props: any) => (props.isOpen ? 'var(--color-option-text)' : '#4b4b4b')};
  padding: 0 4px 0 20px;

  @media (max-width: 991px) {
    padding-left: 7px;
    font-size: 14px;
    text-align: initial;
  }
`

//  Mui Collapse style overrides.
const CustomCollapse = muiStyled(Collapse)(() => ({
  position: 'absolute',
  top: 63,
  left: -1,
  right: 0,
  zIndex: 2,
  border: '1px solid #cfcfcf',
  background: '#fff',
  borderTop: 'none',
  width: 'calc(100% + 2px)',
  '@media (max-width: 991px)': {
    top: '36px',
    left: 'initial',
    right: '20px',
    width: '206px',
    borderTop: '1px solid #cfcfcf'
  }
}))

const StyledSelectorButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent; 
  width: 100%;
  height: 63px;
  text-align: left;
  padding: 0 16px 0 20px !important;

  &:hover {
    background: rgba(228,228,228,0.5);
  }

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf
  }
  
  & > span {
    font-size: 17px;
    margin-bottom: 0;
    color: #4b4b4b};
    padding: 0 4px 0 20px;

    
  }

  @media (max-width: 991px) {
    height: 48px;
    padding: 0 14px 0 14px !important;
  
    & > span {
      padding-left: 7px;
      font-size: 14px;
    }
  }
`

export const DelegationSelector = function ({
  delegation
}: {
  delegation?: Delegation,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    delegation ? delegation.role : ''
  )
  if (!delegation) {
    return <></>
  }

  const screenName = delegationScreenName(selectedOption)

  if (EDITABLE_ROLES_ARRAY.indexOf(delegation.role) > -1) {
    return (
      <StyledContainer>
        <ExpandButton {...{ isOpen, screenName, setIsOpen }} />
        <ExpandedMenu
          {...{
            delegation,
            isOpen,
            setIsOpen,
            selectedOption,
            setSelectedOption
          }}
        />
      </StyledContainer>
    )
  } else {
    return (
      <StyledText data-cy="owner-row-selector" title={OWNER}>
        {delegationScreenName(OWNER)}
      </StyledText>
    )
  }
}

export const ExpandButton = function ({
  screenName,
  isOpen,
  setIsOpen
}: {
  screenName: string,
  isOpen: boolean,
  setIsOpen: (boolean) => void,
}) {
  return (
    <StyledExpandButton
      onClick={() => setIsOpen(!isOpen)}
      title={screenName}
      isOpen={isOpen}
    >
      <StyledText isOpen={isOpen}>
        {isOpen ? 'Select Role' : screenName}
      </StyledText>
      <StyledIcon
        style={
          isOpen
            ? {
                transform: 'rotate(-180deg)',
                transition: 'all .15s ease-in-out'
              }
            : {
                transform: 'rotate(0deg)',
                transition: 'all .15s ease-in-out'
              }
        }
        icon={['fas', 'chevron-down']}
      />
    </StyledExpandButton>
  )
}

type ExpandedMenuProps = {
  delegation: Delegation,
  isOpen: boolean,
  setIsOpen: (boolean) => void,
  selectedOption: string,
  setSelectedOption: (string) => void,
};

export const ExpandedMenu = function ({
  delegation,
  isOpen,
  setIsOpen,
  selectedOption,
  setSelectedOption
}: ExpandedMenuProps) {
  const { serialNumber } = useParams<{serialNumber: string}>()
  const { makeDelegationAPICall } = useAddDelegation(serialNumber)

  const handleMenuSelection = async (selectedRole: string) => {
    const completedWithSuccess = await makeDelegationAPICall(
      delegation.identity?.trim(),
      selectedRole,
      'PUT'
    )
    if (completedWithSuccess) {
      setSelectedOption(selectedRole)
      setIsOpen(false)
    }
  }

  return (
    <CustomCollapse in={isOpen} timeout="auto" unmountOnExit data-cy="expand-delegation-roles">
      <ClickAwayListener onClickAway={() => isOpen && setIsOpen(false)}>
        <div>
          {EDITABLE_ROLES_ARRAY.map((role: string, idx: number) => {
            return (
              <StyledSelectorButton
                data-cy={`select-role-button-${role}`}
                key={`styledSelectorButton-${idx}`}
                onClick={(e) => handleMenuSelection(role)}// eslint-disable-line @typescript-eslint/no-unused-vars
              >
                <span>{delegationScreenName(role)}</span>
                <RadioButton selected={role === selectedOption} />
              </StyledSelectorButton>
            )
          })}
        </div>
      </ClickAwayListener>
    </CustomCollapse>
  )
}

export default DelegationSelector
