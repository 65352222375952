import * as C from 'appConstants'

const initialState = {
  isRequesting: undefined
}

function populateRequestIsStarted (state, payload) { // eslint-disable-line @typescript-eslint/no-unused-vars
  return {
    ...state,
    isRequesting: true
  }
}

function populateRequestIsFinished (state, payload) { // eslint-disable-line @typescript-eslint/no-unused-vars
  return {
    ...state,
    isRequesting: false
  }
}

function resetRequest (state) {
  return {
    ...state,
    ...initialState
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case C.POPULATE_REQUEST_IS_STARTED:
      return populateRequestIsStarted(state, payload)
    case C.POPULATE_REQUEST_IS_FINISHED:
      return populateRequestIsFinished(state, payload)
    case C.RESET_REQUEST:
      return resetRequest(state)
    default:
      return state
  }
}
