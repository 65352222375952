// @flow
import { EmptyElement } from 'components/ReUsable'

type Props = {
  data: any,
  property?: string,
  suffix?: string,
  places?: number,
  showZero?: boolean,
  decimals?: number
}

export default function ShowDataValueRounded (props: Props) {
  const {
    data,
    property,
    suffix,
    places,
    showZero
  } = props

  if (data && property && (data[property] || (data[property] === 0 && showZero))) {
    if (places) {
      return <>{`${parseFloat(data[property]).toFixed(places)} ${suffix}`}</>
    }

    return <>{`${Math.round(data[property])} ${suffix}`}</>
  }

  return <EmptyElement>none</EmptyElement>
}
