import moment from 'moment'

type DifferenceType = {
  length: number,
  suffixSingle: string,
  suffix: string
}

export function getLastUpdatedValue (lastUpdate) {
  const now = moment(new Date())
  const end = moment(lastUpdate)
  const differences: DifferenceType[] = []
  differences.push({
    length: moment.duration(now.diff(end, 'weeks'), 'weeks').asWeeks(),
    suffixSingle: 'week ago',
    suffix: 'weeks ago'
  })
  differences.push({
    length: moment.duration(now.diff(end, 'days'), 'days').asDays(),
    suffixSingle: 'day ago',
    suffix: 'days ago'
  })
  differences.push({
    length: moment.duration(now.diff(end, 'hours'), 'hours').asHours(),
    suffixSingle: 'hour ago',
    suffix: 'hours ago'
  })
  differences.push({
    length: moment.duration(now.diff(end, 'minutes'), 'minutes').asMinutes(),
    suffixSingle: 'minute ago',
    suffix: 'minutes ago'
  })
  differences.push({
    length: moment.duration(now.diff(end, 'seconds'), 'seconds').asSeconds(),
    suffixSingle: 'second ago',
    suffix: 'seconds ago'
  })
  const difference = differences.find(d => d.length > 0)

  return difference ? `${difference.length} ${difference.length === 1 ? difference.suffixSingle : difference.suffix}` : 'never'
}

export function formatTimestamp (timestamp) {
  return moment(timestamp).format('DD.MM.yyyy HH:mm')
}

export function isDifferenceMoreThanXMinutes (timeStamp: string, minutes: number): boolean {
  const timestampDate = new Date(timeStamp)
  const now = new Date(Date.now())
  // Get the absolute difference in milliseconds
  const difference = Math.abs(timestampDate.getTime() - now.getTime())
  //  Provided minutes in milliseconds
  const minutesInMilliseconds = minutes * 60 * 1000
  return difference > minutesInMilliseconds
}
