import { getTime } from 'date-fns'
import { MyChartProps, InputObject, OutputObject } from '../types/types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { green, blue } from '@mui/material/colors'

// custom colors for the charts
const customColor = ['#d53808', '#b7837b', '#3db7a9', '#d8e35c', '#dc952f', '#c662ed', '#1480d3', '#ebae4f', '#79f1d9', '#b52247']
// Get a color and pop it.
export const getColor = (unit) => {
  if (unit === 'celsius') {
    // get a random green or blue color
    const color = [green[500], green[700], green[900], blue[500], blue[700], blue[900]][Math.floor(Math.random() * 2)]
    return color
  }
  /* if (!unit) {
    // get a random yellow or orange color
    const color = [yellow[500], orange[500], yellow[700], orange[700], yellow[900], orange[900], yellow[900]][Math.floor(Math.random() * 2)]
    return color
  } */
  if (customColor.length > 0) {
    return customColor.pop()
  }
}

const LocalToUtc = (localTimestamp) => {
  const localDate = new Date(localTimestamp) // Convert seconds to milliseconds
  const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000) // Add offset in milliseconds
  return utcDate.getTime() // Convert back to seconds
}

/*
  @func processHistoryData
  @desc Process the data for the history chart
  @param {any} obj
  @return {MyChartProps[]}
*/
export const processHistoryData = (obj: any) => {
  const result: MyChartProps[] = []
  const avgOutputPowers: MyChartProps = {
    id: 'avgOutputPower',
    name: 'Power Output (W)',
    type: 'spline',
    data: obj.avgOutputPowers.map((ele, idx) => [obj.timestamps[idx], twoDecimalPlaces(ele)]),
    yAxis: 0,
    color: '#98d051',
    visible: true
  }
  const avgOutputCurrents: MyChartProps = {
    id: 'avgOutputCurrent',
    name: 'Charging Current (A)',
    type: 'spline',
    data: obj.avgOutputCurrents.map((ele, idx) => [obj.timestamps[idx], twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: '#598236',
    visible: true
  }
  const avgFillLevels: MyChartProps = {
    id: 'avgFillLevel',
    name: 'Remaining Total Fuel (%)',
    type: 'spline',
    data: obj.avgFillLevels.map((ele, idx) => [obj.timestamps[idx], twoDecimalPlaces(ele)]),
    yAxis: 0,
    color: '#9634fe',
    visible: true
  }
  const avgBatteryVoltages: MyChartProps = {
    id: 'avgBatteryVoltage',
    name: 'Battery Voltage (V)',
    type: 'spline',
    data: obj.avgBatteryVoltages.map((ele, idx) => [obj.timestamps[idx], twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: '#a6a6a6',
    visible: true
  }
  const avgAmbientTemperatures: MyChartProps = {
    id: 'avgAmbientTemperature',
    name: 'Ambient Temperature (C)',
    type: 'spline',
    data: obj.avgAmbientTemperatures.map((ele, idx) => [obj.timestamps[idx], twoDecimalPlaces(ele)]),
    yAxis: 1,
    color: '#4d73c3',
    visible: true
  }

  result.push(avgOutputPowers, avgOutputCurrents, avgFillLevels, avgBatteryVoltages, avgAmbientTemperatures)

  // turn the timestamps into local time
  result.forEach((element) => {
    element.data.forEach((item) => {
      item[0] = LocalToUtc(item[0])
    })
  })

  // Sort data array by timestamp
  result.forEach((element) => {
    element.data = element.data.sort((a, b) => a[0] - b[0])
  })

  return result
}

/*
  @func getRandomColor
  @desc Get a random color
*/
export const getRandomColor: () => string = () => {
  // get a random yellow color shade
  const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`
  return color
}

/*
@func processMultiSenseArray
@desc Process the data for the multi sense chart
@param {InputObject} inputObject
@return {OutputObject}
*/
export const processMultiSenseArray = (inputObject: InputObject, variant: string): OutputObject => {
  // const mock = mockedResponse
  if (!inputObject) {
    // Handle null or undefined inputObject
    return [[], []]
  }

  const processedMultisenseArray: any = []
  const closedPortsArray: any = []
  let allTimestamps: number[] = []

  // should be inputObject, but you can use mock.ports for testing
  Object.entries(inputObject).forEach(([name, items]) => {
    // pust the IOs only if the variant is not equal to "none" and "multi_sense_4"
    const isIO = name.includes('io')
    const variantExcludingIos = ['none', 'multi_sense_4'].includes(variant)
    if (!items || items.length === 0) {
      // if variant is equal to none || multise_sense_4 then get rid of the IOs in the unconfigured ports
      if (!isIO && variantExcludingIos) {
        closedPortsArray.push({
          name: name,
          unconfigured: true
        })
      }

      if (!variantExcludingIos) {
        closedPortsArray.push({
          name: name,
          unconfigured: true
        })
      }

      return
    }

    items.forEach((item) => {
      const { data, config } = item
      const seperateData = <T>(commonValues: T[][]): [T[], T[]] => {
        const timestamps = commonValues.reduce((acc, curr) => {
          return [...acc, curr[0]]
        }, [])
        const values = commonValues.reduce((acc, curr) => {
          return [...acc, curr[1]]
        }, [])
        return [timestamps, values]
      }
      // data now is of this format [[timestamp, value],...]
      const [timestamps, values]: number[][] = seperateData(data)

      if (!data.length) {
        return
      }

      allTimestamps.push(...timestamps)
      processedMultisenseArray.push({
        data: values.map((val, idx) => {
          return [timestamps[idx], val === 0 || val === null ? null : twoDecimalPlaces(val)]
        }),
        id: `${Math.random().toString(26).slice(2)}-${name}`,
        name: `${config.function.at(0)?.toUpperCase()}${config.function.slice(1)} ${name.toLocaleUpperCase()} (${config.unit ?? '100'})`,
        yAxis: config.unit !== undefined ? 0 : 1,
        color: getColor(config.unit),
        visible: true,
        timestamps: timestamps,
        alignedData: values.map((val, idx) => {
          return [timestamps[idx], val === 0 || val === null ? null : twoDecimalPlaces(val)]
        })
      })
    })
  })

  // Sort timestamps and try to remove duplicates
  allTimestamps = allTimestamps.sort((a, b) => getTime(new Date(a)) - getTime(new Date(b)))
  // timestamps = timestamps.filter((item, index) => timestamps.indexOf(item) === index)
  const minTimestamp = Math.min(...allTimestamps.map((item) => getTime(new Date(item))))
  const maxTimestamp = Math.max(...allTimestamps.map((item) => getTime(new Date(item))))
  const unifiedTimestamps: number[] = []
  for (let timestamp = minTimestamp; timestamp <= maxTimestamp; timestamp += 3600000 /* 1 day in milliseconds */) {
    unifiedTimestamps.push(timestamp)
  }

  // Renaming the series if there is two series coming from the same sensor (for example a1 and a1)
  const processNames = (processedArr: { id: string; name: string; data: number[] }[]) => {
    // merge two series with the same name and add the values of the two series
    const result = processedArr.reduce((acc: { id: string; name: string; data: number[] }[], curr) => {
      const { name } = curr
      const existing: any = acc.find((item) => item.name === name)
      if (existing) {
        // get the timestamp of the second after the last timestamp of the existing series
        // to discuss with Jakob
        const lastTimestamp = existing.data[existing.data.length - 1][0]
        // add one second from the the last timestamp of the existing series
        const newTimestamp = lastTimestamp + 1000
        // add the new timestamp to the existing series
        existing.data.push([newTimestamp, null])
        curr.data.forEach((item) => {
          existing.data.push(item)
        })
      } else {
        acc.push(curr)
      }
      return acc
    }, [])

    return result
  }

  const doorsTableSeries = (processedArr: { id: string; name: string; data: number[]; alignedData?: any[] }[]) => {
    let temp: any[] = []

    // check if all values of the series is either 100 or null and the name is ending with (100) and form an array with these which will be used in the doors table
    const filteredArray = processedArr.filter((element) => {
      const allValues = element.data.every((item) => item[1] === 100 || item[1] === null)
      const name = element.name.endsWith('(100)')
      return allValues && name
    })

    // do get the filtered series that are ending with (100) and push them to the temp array
    temp = filteredArray.map((ele, idx) => {
      return {
        ...ele,
        yAxis: 0,
        linecap: 'square',
        data:
          ele.data.length > 0
            ? ele.data.map((data) => {
                // if data[1] is 100 then give it the value of id
                if (data[1] === 100) {
                  return [data[0], idx + 1]
                } else {
                  return [data[0], null]
                }
              })
            : []
      }
    })

    // regroup the series that has the same IO number
    temp = temp.reduce((acc, curr) => {
      const IO = curr.name.split(' ')[1]
      const Func = curr.name.split(' ')[0]
      const found = acc.findIndex((item) => item.name.split(' ')[1] === IO)
      const foundValues = found > -1 ? acc[found].data.find((item) => item[1] > 0) : []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [foundYAxisTmstm, foundYAxisValue] = foundValues?.length ? foundValues : [null, null] // if foundValues is null then assign null to both foundYAxisTmstm and foundYAxisValue
      if (found > -1) {
        const newData = curr.data.map((item) => (item[1] ? [item[0], foundYAxisValue] : [item[0], null]))
        return [...acc, { ...curr, name: `${Func} ${IO}`, data: newData }]
      }

      return [...acc, { ...curr, name: `${Func} ${IO}` }]
    }, [])
    return temp
  }

  const series = processNames(processedMultisenseArray)
  const periods = doorsTableSeries(series)

  // Getting the names of the periods
  let periodNames = periods.reduce((acc, curr) => {
    if (!acc.includes(curr.name)) {
      return [...acc, curr.name]
    }
    return acc
  }, [])

  // filter out the series that are not periods
  const seriesWithoutIOs = series.filter((element) => !periodNames.includes(`${element.name.split(' ')[0]} ${element.name.split(' ')[1]}`))
  // clean up of names in the periodNames array and turning it into a set, just to Get an example of [IO1, IO2, IO3]
  periodNames = [...new Set(periodNames.map((period: any) => period.slice(0, -5).split(' ')[1]))]
  return [seriesWithoutIOs, periods, closedPortsArray]
}

export const twoDecimalPlaces = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const refactorTimestampGraph = (array: string[]) => {
  const result = array.map((timeStamp) => new Intl.DateTimeFormat('de-DE', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).format(new Date(timeStamp)))
  return result
}

export const refactorTimestampGraphinMilliSeconds = (array: string[]) => {
  // Return minutes and seconds and millisecuonds as well
  const result = array.map((timeStamp) =>
    new Intl.DateTimeFormat('de-DE', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(new Date(timeStamp))
  )
  return result
}

export const getTimeStamp = (timestamps: any) => {
  return timestamps.sort((a: string, b: string) => getTime(new Date(a)) - getTime(new Date(b)))
}

export const findErrorValueBasedOnTimeStamp = (array: string[], timeStamp: string) => {
  let index = 0
  if (array.length === 0) return null
  for (let i = 0; i < array.length; i++) {
    if (timeStamp > array[i]) {
      index = i
    }
  }
  const timeStampMinutes = new Date(timeStamp).getMinutes()
  // find the percentage of minutes in the hour
  const percentage = timeStampMinutes / 60
  // return the error value of the timestamp
  return index + percentage
}

export const renderTitleWithStyles = (arrayOfSeries: any[], Axis: number) => {
  const rightSideYAxis = arrayOfSeries.filter((element) => element.yAxis === 1)
  const leftSideYAxis = arrayOfSeries.filter((element) => element.yAxis === 0)

  let leftSideTitle = ''
  let rightSideTitle = ''

  leftSideYAxis.forEach((element) => {
    leftSideTitle += `<span style="color: ${element.color}; font-weight: bold; font-family: open-sans-regular; font-size: 13px">${element.name}</span>   `
  })

  rightSideYAxis.forEach((element) => {
    rightSideTitle += `<span style="color: ${element.color}; font-weight: bold; font-family: open-sans-regular; font-size: 13px">${element.name}</span>  `
  })

  // Based on Axis return the title
  if (Axis === 0) {
    return leftSideTitle
  } else {
    return rightSideTitle
  }
}

export const zoomCharts = (e, current: HighchartsReact.RefObject | null) => {
  const AllLineCharts = Highcharts.charts.filter((c) => c?.options?.chart?.type === 'spline')
  if (current) {
    // Get type by chart?.options.chart?.type
    const chart = current.chart
    const index = AllLineCharts.findIndex((c) => c === chart)
    if (AllLineCharts.length > 1) {
      const firstChartExtremes = chart?.xAxis[0].getExtremes() // min and max for zoom
      // Accessing the extremes
      const min = firstChartExtremes.min
      const max = firstChartExtremes.max

      // Calculating the difference between min and max, this might be used later
      // const difference = new Date(max).getTime() - new Date(min).getTime()
      // to rethink about the 24 hour condition later // Code to be added

      // All charts are synchronized except the first one
      if (firstChartExtremes?.min) {
        AllLineCharts.forEach((c, i) => {
          if (i !== index) {
            c?.xAxis[0].setExtremes(min, max)
          }
        })
      }
    }
  }
}

/*
  @func getEvent
  @desc Return an event to the chart based on its type
  @returns ArrayOfEvents
  @param {event} any
*/

export const getEvents = (events: any, devicetype: string) => {
  // based on event.eventClass return the color of the line
  // event can be "error", "warning" or newly added "fm_active_port"
  const colorEvent = (eventType) => {
    return eventType === 'error' ? 'rgb(250, 88, 98)' : eventType === 'warning' ? 'rgb(255, 175, 79)' : 'rgb(255,0,255)'
  }

  if (events?.lenght <= 0) {
    return []
  }
  const newEvents = events.map((event) => {
    return {
      color: colorEvent(event.eventClass), // line color
      value: new Date(event.timestamp).getTime(), // x-axis value where the line will appear
      width: 2, // line width,
      zIndex: 1000,
      label: {
        useHTML: true, // allows HTML tags in the label text
        style: {
          color: '#fff', // label text color,
          fontSize: '13px',
          cursor: 'pointer',
          background: colorEvent(event.eventClass),
          padding: '1px',
          fontFamily: 'open-sans-regular',
          border: '1px solid white',
          borderRadius: '4px',
          textDecoration: 'none',
          rotation: 45
        },
        x: -12, // Adjust the horizontal position if needed
        formatter: () => {
          if (event?.eventClass === 'fm_active_port') {
            return `<span class="highcharts-tooltip-event">Port ${event.activePort}</span>`
          }
          const majorEventCodeStr = event?.major?.toString()
          const minorEventCodeStr = event?.minor?.toString()
          const formulatedError = () => {
            const maxLength = 3
            const majorEventCode = majorEventCodeStr.padStart(maxLength, '0')
            const minorEventCode = minorEventCodeStr.padStart(maxLength, '0')
            return `${majorEventCode}.${minorEventCode}`
          }
          // Use HTML and wrap the label text in an anchor tag with the desired hyperlink
          return `<a target="_blank" class="highcharts-tooltip-event" href="https://www.efoy-pro.com/en/service/servicetool/?product=${devicetype}&errorcode=${formulatedError()}">${formulatedError()}</a>`
        }
      }
    }
  })
  return newEvents
}
