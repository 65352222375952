// @flow

import styled from 'styled-components'

const StyledLegendText = styled.div`
  color: var(--color-text);
  font-size: 14px;
  font-family: open-sans-regular;
  line-height: 18px;
  padding-top: 14px;
  margin-bottom: 10px;
`

const StyledLegendTextTitle = styled.p`
  margin-bottom: 4px;
  font-weight: bold;
`

const StyledLegendTextContainer = styled.div`
  margin-top: 2rem;
  @media (min-width: 991.97px) {
    margin-top: 3rem;
  }
`

const StyledLegendMainHeader = styled.p`
  color: var(--color-text);
  font-size: 16px;
  font-family: open-sans-regular;
  line-height: 20px;
  margin-bottom: 8px;
  font-weight: bold;
`

const RoleInfoPanel = function () {
  return (
    <>
      <StyledLegendTextContainer>
        <StyledLegendMainHeader>Roles:</StyledLegendMainHeader>
        <StyledLegendText>
          <StyledLegendTextTitle>Full support:</StyledLegendTextTitle> With this role you have the same rights as the owner, except for deleting the device.
        </StyledLegendText>
        <StyledLegendText>
          <StyledLegendTextTitle>EFOY monitoring:</StyledLegendTextTitle> The rights of this role are limited: No battery configuration, no data export and no alert setting.
          <br />
          Reset and changing mode of the fuell cell is possible.
        </StyledLegendText>
        <StyledLegendText>
          <StyledLegendTextTitle>Cartridge monitoring:</StyledLegendTextTitle> User with this role can only change fuel cartridges and see information of the device.
        </StyledLegendText>
      </StyledLegendTextContainer>
    </>
  )
}

export default RoleInfoPanel
