import { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useNotificationContext } from './context/NotificationContext'
import {
  StyledRemoveMenu,
  StyledPrimaryFontAwesomeIcon,
  StyledAlertFontAwesomeIcon
} from 'components/Application/UserAccount/accessToken'

type NotificationInfoType = {
  publishDate: string
  userConfirmations: number
  id: string
}

const SyledNotificationInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: open-sans-regular;
  font-size: 13px;
  margin-top: 10px;
  max-width: 384px;
  @media (min-width: 1200px) {
    min-width: min(75vw, 500px);
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  background: transparent;
  border: 1px solid #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  & > img {
    width: auto;
    height: 70%;
  }

  &:hover {
    filter: brightness(70%);
  }
`

export default function NotificationInfo ({
  publishDate,
  userConfirmations,
  id
}: NotificationInfoType) {
  const { setMode, setSelectedNotification, deleteNotification, allowWrite } =
    useNotificationContext()
  const [startRemove, setStartRemove] = useState<boolean>(false)
  return (
    <SyledNotificationInfo data-cy="notice-info">
      <div>
        {`Publish at: ${moment(publishDate).format('DD.MM.YYYY HH:mm')}`}
        <br />
        {`(id: ${id})`}
        <br />
        {`Confirmed by ${userConfirmations} user${
          userConfirmations > 1 ? 's' : ''
        }`}
      </div>
      {allowWrite ? (
        <ButtonsContainer>
          <StyledButton
            onClick={() => {
              setMode('EDIT')
              setSelectedNotification(id)
            }}
            data-cy="edit-notice-btn"
          >
            <img alt='Edit' src='/imgs/Stift_grey.svg' />
          </StyledButton>
          {startRemove ? (
            <StyledRemoveMenu>
              <p>Delete?</p>&nbsp;
              <StyledPrimaryFontAwesomeIcon
              data-cy="delete-notice-approve"
                icon={['far', 'circle-check'] as any}
                onClick={() => deleteNotification(id)}
              />
              &nbsp;
              <StyledAlertFontAwesomeIcon
                data-cy="delete-notice-cancel"
                icon={['far', 'circle-xmark'] as any}
                onClick={() => setStartRemove(false)}
              />
              &nbsp;
            </StyledRemoveMenu>
            ) : (
              <StyledButton data-cy="delete-notice-btn" onClick={() => setStartRemove(true)}>
                <img alt='Remove' src='/imgs/Trash_grey.svg' />
              </StyledButton>
            )
          }
        </ButtonsContainer>
      ) : null}
    </SyledNotificationInfo>
  )
}
