import * as C from 'appConstants'
export const getStatusMessage = (status) => {
    switch (status) {
        case 'off':
            return C.OFF
        case 'standby':
            return C.STANDBY
        case 'in_operation':
            return C.CHARGING
        case 'shut_down':
            return C.SHUTDOWN
        case 'frost_protection':
            return C.FROST_PROTECTION
        case 'deep_discharge_protection':
            return C.BATTERY_PROTECTION
        case 'transport_lock_procedure':
            return C.TRANSPORT_LOCK_PROCEDURE
        case 'transport_lock':
            return C.TRANSPORT_LOCK
        case 'reset':
            return C.RESET
        case 'factory_defaults':
            return C.FACTORY_DEFAULTS
        case 'error':
            return C.ERROR
        case 'emergency_frost_protection':
            return C.ERROR_AND_FROST_PROTECTION
        case 'maintenance_circulation_pump':
            return C.PENDING
        case 'maintenance_dosing_and_feeding_pump':
            return C.PENDING
        case 'pending':
            return C.PENDING
        case 'can_update':
            return C.UPDATE_EFOY_ACCESSORIES
        case 'warning':
            return C.WARNING
        case 'start_phase':
            return C.START_PHASE
        default:
            return C.UNKNOWN
    }
}

export const getStatusGroupMessage = (groupName) => {
    switch (groupName) {
        case 'status_ideal':
            return C.STATUS_IDEAL
        case 'attention_required':
            return C.ATTENTION_REQUIRED
        case 'action_required':
            return C.ACTION_REQUIRED
        default:
            return 'Inactive'
    }
}

export const getErrorStatusMessage = (status: number, message?: string) => {
    switch (status) {
        case 409: return message || 'Conflict'
        default:
            return C.SOMETHING_WENT_WRONG
    }
}
