// @flow

import styled from 'styled-components'

import {
  StatusIndicator,
  Row,
  Col,
  Gap
} from 'components/ReUsable'

import { getLastUpdatedValue } from 'utils/timeCalculations'
import { isDeviceOffline } from 'helpers/devices'
import { getStatusMessage } from 'helpers/status_message'
import { isLicenseExpired } from 'utils/helpers'
import { DEVICE_DETAILS_PERMISSIONS_LIST, DENIED, UNSUPPORTED_LICENSE_ERROR } from 'appConstants'
import { NEW_API_ACTIVE } from 'featureToggles'
import { StyledColumn, StyledFontAwesomeIcon, StyledImage } from './styleDefinitions'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'

type Props = {
  values: {
    state: string,
    lastUpdated: string,
    isConnected: boolean,
    permissions: any,
    inServiceMode: boolean
  }
}

const StateText = styled.div`
  font-weight: bold;
`
const GeneralWarningText = styled.td`
  text-align: center;
`

export const getStatus = (stateText: string, isConnected: boolean) => (
  <Row noWrap={true}>
    <StatusIndicator status={stateText} isConnected={isConnected}/>
    <Gap width='0.2rem'></Gap>
    <Col justifyContent='center'>
      <StateText>{getStatusMessage(stateText)}</StateText>
    </Col>
  </Row>
)

export default function stateRowCell (props: Props) {
  const { state, lastUpdated, isConnected, permissions, inServiceMode } = props.values

  if (isLicenseExpired(permissions) && !NEW_API_ACTIVE) {
    return <GeneralWarningText colSpan={5}>License expired</GeneralWarningText>
  }

  if (isLicenseExpired(permissions) && NEW_API_ACTIVE) {
    return <StyledColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title="License expired" /></StyledColumn>
  }

  if (isLicenseExpired(permissions) && !NEW_API_ACTIVE) {
    return <GeneralWarningText colSpan={5}>License expired</GeneralWarningText>
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ] === DENIED && !NEW_API_ACTIVE) { return <td></td> }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.STATE_READ] === DENIED && NEW_API_ACTIVE) {
    return <StyledColumn><StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title={UNSUPPORTED_LICENSE_ERROR} /></StyledColumn>
  }

  if (inServiceMode && !NEW_API_ACTIVE) {
    return <GeneralWarningText colSpan={5}>In service</GeneralWarningText>
  }

  if (inServiceMode && NEW_API_ACTIVE) {
    return <StyledColumn>
      <StyledFontAwesomeIcon icon={faScrewdriverWrench as any} title="In Service"/>
    </StyledColumn>
  }

  return (
    <td>
      { getStatus(state, isConnected) }
      <span>{ lastUpdated && isDeviceOffline(isConnected) && `Online: ${getLastUpdatedValue(lastUpdated)}` }</span>
    </td>
  )
}
