import * as C from 'appConstants'
import { ErrorResponseBody } from 'declarations/@types/alltypes'

type ErrorState = {
    [C.ADD_DEVICE_LICENSE]: any;
    [C.DELETE_DEVICE_LICENSE]: any;
    [C.UPDATE_DEVICE_LICENSE]: any;
    group: {
        [operation: string]: ErrorResponseBody
    }
}

const errorBody = {
    message: '',
    code: null
}

const initialState = {
    [C.ADD_DEVICE_LICENSE]: { ...errorBody },
    [C.DELETE_DEVICE_LICENSE]: { ...errorBody },
    [C.UPDATE_DEVICE_LICENSE]: { ...errorBody },
    group: {}
}

const clearErrors = (state: any, payload: any): ErrorState => ({ ...initialState }) // eslint-disable-line @typescript-eslint/no-unused-vars

const populateError = (state: any, payload: any): ErrorState => {
    return {
        ...state,
        [payload.operation]: {
            code: payload.code,
            message: payload.message
        }
    }
}

const populateErrors = (state: any, payload: any): ErrorState => {
    return {
        ...state,
        [payload.operation]: payload
    }
}

export default (state = initialState, action: any): ErrorState => {
    const { type, payload } = action

    switch (type) {
        case C.POPULATE_ERROR:
            return populateError(state, payload)
        case C.CLEAR_ERRORS:
            return clearErrors(state, payload)
        case C.SERVER_NOT_AVAILABLE_ERROR:
            return populateError(state, { operation: C.SERVER_NOT_AVAILABLE_ERROR, code: 503, message: C.SERVER_NOT_AVAILABLE_MESSAGE })
        case C.POPULATE_API_ERRORS:
            return populateErrors(state, payload)
        default:
            return state
    }
}
