// @flow
import styled from 'styled-components'

type Props = {
  grow?: string,
  shrink?: string,
  base?: string,
  justifyContent?: string,
  alignItems?: string,
  alignAlways?: boolean,
  direction?: string
}

export default styled.div`
  flex-grow: ${(props: Props) => props.grow || '1'};
  flex-shrink: ${(props: Props) => props.shrink || '1'};
  flex-basis: ${(props: Props) => props.base || '50%'};
  display: flex;
  flex-direction: column;
  justify-content: ${(props: Props) => props.justifyContent || 'flex-start'};
  align-items: ${(props: Props) => props.alignAlways ? props.alignItems : 'stretch'};

  @media (min-width: 991.98px) {
    align-items: ${(props: Props) => props.alignItems || 'flex-start'};
  }
`
