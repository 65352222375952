// @flow

import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import AlertConfigMessages from 'components/ReUsable/Alert/alertConfigMessages'

import * as C from 'appConstants'
import BatterySettingsForm from './batterySettingsForm'
import { useDispatch } from 'react-redux'
import { getBatteryTypeLimits } from 'actions'
import { getStatusMessage } from 'helpers/status_message'

const CenterContentDiv = styled.div`
  display: flex;
  justify-content: center;
`

type Props = {
  serialNumber: string
  battery: any
  pageDialog: {
    requestErrorMessage: string
    batteryConfigSavedSuccess: boolean
    loadingRequest: boolean
  }
  deviceState: {
    isConnected: boolean
    lastUpdated: string
    state: string
  }
}

const STATES_TO_DISABLE_BATTERY_TYPE_CHANGE = [C.CHARGING, C.START_PHASE, C.SHUTDOWN, C.FROST_PROTECTION, C.BATTERY_PROTECTION, C.TRANSPORT_LOCK_PROCEDURE, C.ERROR_AND_FROST_PROTECTION]

const BatterySettingsEditContent = (props: Props) => {
  const { serialNumber, battery, pageDialog, deviceState } = props
  const [isDeviceInChargingState, setIsDeviceInChargingState] = useState(false)

  const checkDeviceStateForChargingMode = useMemo(() => (deviceState: any) => STATES_TO_DISABLE_BATTERY_TYPE_CHANGE.includes(getStatusMessage(deviceState)), [])

  useEffect(() => {
    setIsDeviceInChargingState(checkDeviceStateForChargingMode(deviceState?.state))
  }, [deviceState.state, checkDeviceStateForChargingMode])

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isDeviceInChargingState && serialNumber) dispatch(getBatteryTypeLimits({ serialNumber: serialNumber }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber])

  if (pageDialog.loadingRequest) {
    return (
      <CenterContentDiv>
        <Spinner animation="border" variant="secondary" />
      </CenterContentDiv>
    )
  }

  return (
    <div>
      {!isDeviceInChargingState && <AlertConfigMessages pageDialog={pageDialog} />}
      <BatterySettingsForm
        type={battery.type}
        types={battery.types}
        loadingRequest={pageDialog.loadingRequest}
        serialNumber={serialNumber}
        deviceState={deviceState}
        isDeviceInChargingState={isDeviceInChargingState}
      />
    </div>
  )
}

export default BatterySettingsEditContent
