import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { min, differenceInHours, differenceInDays, isAfter, getUnixTime, addDays, startOfDay, startOfMinute, addHours, isToday, isSameDay } from 'date-fns'
import { Button, Col, Gap, LargeCardSection, PermissionCheck, Row, DatePicker } from 'components/ReUsable'
import { getTelemetryEvents, getTelemetryHistory } from 'actions'
import { DEVICE_DETAILS_PERMISSIONS_LIST, GRANTED } from 'appConstants'
import HighchartsReact from 'highcharts-react-official'

// Local Imports
import { processHistoryData, processMultiSenseArray } from 'components/ReUsable/Charts/utils/utils'
import Highcharts from 'components/ReUsable/Charts/modulesFunc'
// Enhanced Version of Highcharts
import { createHistoryOptions } from 'components/ReUsable/Charts/config/history'
import { createMultisenseOptions } from 'components/ReUsable/Charts/config/multisense'
import { createIosOptions } from 'components/ReUsable/Charts/config/ios'

const StyledDatePickerDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`

const StyledSpan = styled.span`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  margin-top: 1rem;
`

const StyledDiv = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: left;
  color: #000000;
  margin-top: 1rem;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px;
`

const StyledDivTitle = styled.div`
  font-family: 'open-sans-regular';
  font-size: 1.3rem;
  top: 0;
  left: 0;
  color: #000000;
  font-weight: bold;
`
const StyledDivText = styled.div`
  font-family: 'open-sans-regular';
  font-size: 12px;
  color: #000000;
  font-weight: 400;
`

type Props = {
  serialNumber: string
  telemetryHistory: any
  telemetryEvents: any
  getTelemetryHistory: (obj: any) => void
  getTelemetryEvents: (obj: any) => void
  togglePageDialog: () => void
  permissions: any
  deviceType: string
  getMultisenseData: (serialNumber: string, timeScale: string, startTimestamp: number, endTimestamp: number) => Promise<any>
}

type TimeScale = 'HOURS' | 'MINUTES'

type ScaleSettings = {
  timeScale: TimeScale
  maxDays: number
}

const defaultStartDate = startOfDay(addDays(new Date(), -29))
// current data with the current hour for maxEndDate
const maxEndDate = startOfMinute(new Date())
const currentMinute = startOfMinute(new Date())

const HOURS_SCALE: TimeScale = 'HOURS'
const HOURS_SETTINGS: ScaleSettings = {
  timeScale: HOURS_SCALE,
  maxDays: 31
}

const MINUTES_SCALE: TimeScale = 'MINUTES'
const MINUTES_SETTINGS: ScaleSettings = {
  timeScale: MINUTES_SCALE,
  maxDays: 1
}

const History = (props: Props) => {
  const { serialNumber, telemetryHistory, telemetryEvents, getTelemetryHistory, getTelemetryEvents, permissions, deviceType, getMultisenseData } = props
  // startDate should be 2 days before the maxEndDate and it is fixed at midnight
  const [startDate, setStartDate] = useState(startOfDay(addDays(maxEndDate, -3)))
  const [endDate, setEndDate] = useState(maxEndDate)
  const [currentScaleSettings, setCurrentScaleSettings] = useState(HOURS_SETTINGS)
  const [variant, setVariant] = useState<string | null>(null)

  const [historyData, setHistoryData] = useState<any>([])
  const [graphEvents, setGraphEvents] = useState<any>([])

  const [multisenseGraphData, setMultisenseGraphData] = useState<any>(null)
  const [periodSeries, setPeriodSeries] = useState<any>([])
  const [lineChartKey, setLineChartKey] = useState<number>(0)
  const [closedPortsArray, setClosedPortsArray] = useState<any>([])

  // Refs
  const containerRef = useRef(null)
  const HistoryChartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const multisenseChartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const IoChartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const getTelemetryHistory_ = (startDate: Date, endDate: Date, scaleSettings: any) => {
    const maxDays = scaleSettings.maxDays
    const size = scaleSettings.timeScale === MINUTES_SCALE ? maxDays * 24 * 60 : maxDays * 24
    getTelemetryHistory({
      serialNumber: serialNumber,
      startTimestamp: getUnixTime(startDate) * 1000,
      endTimestamp: getUnixTime(endDate) * 1000,
      timeScale: scaleSettings.timeScale,
      size: size
    })
  }

  const getTelemetryEvents_ = (startDate: Date, endDate: Date) => {
    getTelemetryEvents({
      serialNumber: serialNumber,
      startTimestamp: getUnixTime(startDate),
      endTimestamp: getUnixTime(endDate)
    })
  }

  const multiSenseData = async () => {
    const result = await getMultisenseData(serialNumber, currentScaleSettings.timeScale, getUnixTime(startDate) * 1000, getUnixTime(endDate) * 1000) // 1000 is to convert to milliseconds
    const { variant } = result
    if (variant) setVariant(variant)
    const [processedMultisenseArray, periods, closedPortsArray] = processMultiSenseArray(result.ports, variant)
    setMultisenseGraphData(processedMultisenseArray)
    setPeriodSeries(periods)
    setClosedPortsArray(closedPortsArray)
  }

  const triggerApiCalls = (startDate: Date, endDate: Date, scaleSettings: ScaleSettings) => {
    const days = Math.abs(differenceInDays(startDate, endDate))
    const gatedEndDate = min([endDate, currentMinute])

    if (isAfter(gatedEndDate, startDate) && days <= scaleSettings.maxDays) {
      getTelemetryHistory_(startDate, gatedEndDate, scaleSettings)
      getTelemetryEvents_(startDate, gatedEndDate)
    }
  }

  const checkDateDiffToChangeScale = (startDate: Date, endDate: Date) => {
    const diff = differenceInHours(endDate, startDate)
    if (diff <= 24) {
      setCurrentScaleSettings(MINUTES_SETTINGS)
    } else {
      setCurrentScaleSettings(HOURS_SETTINGS)
    }
  }

  const handleStartDateChange = (date) => {
    setLineChartKey((prevKey) => prevKey + 1)
    // Start date cannot be after end date
    if (isAfter(date, endDate)) {
      if (isToday(date)) {
        setStartDate(date)
        setEndDate(addHours(date, 3))
      } else {
        setStartDate(date)
        setEndDate(addDays(date, 1))
      }
    } else if (isSameDay(date, endDate) && differenceInHours(date, endDate) <= 3) {
      setStartDate(date)
      setEndDate(addHours(date, 3))
    } else {
      setStartDate(date)
    }
    checkDateDiffToChangeScale(date, endDate)
  }

  const handleEndDateChange = (date) => {
    setLineChartKey((prevKey) => prevKey + 1)
    // Check if end date is less than start date and if so then set the start date to end date
    if (isAfter(startDate, date)) {
      if (isToday(date)) {
        setStartDate(addHours(date, -3))
        setEndDate(date)
      } else {
        setStartDate(addDays(date, -1))
        setEndDate(date)
      }
    } else if (isSameDay(date, endDate) && differenceInHours(date, endDate) <= 3) {
      setStartDate(addHours(date, -3))
      setEndDate(date)
    } else {
      setEndDate(date)
    }
    // check if dates are equal and if so then add 1 day to end date
    // setEndDate(date)
    checkDateDiffToChangeScale(startDate, date)
  }

  useEffect(() => {
    triggerApiCalls(startDate, endDate, currentScaleSettings)
    multiSenseData()
  }, [startDate, endDate, currentScaleSettings]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (telemetryHistory && telemetryEvents && telemetryEvents.response.content && telemetryHistory.response) {
      const history = telemetryHistory.response
      if (history[Object.keys(history)[0]].length > 0) {
        const historySeries = processHistoryData(history)
        setHistoryData(historySeries)
        setGraphEvents(telemetryEvents.response.content)
      }
    }
  }, [telemetryHistory, telemetryEvents])

  // Charts UseEffect
  useEffect(() => {
    const container: any = containerRef.current
    const eventArr = ['mousemove', 'touchmove', 'touchstart']
    const findPointByCategory = (chart, categoryValue) => {
      const firstVisibleSeries = chart?.series.findIndex((serie) => serie.visible === true)
      const series = chart?.series[firstVisibleSeries] ?? undefined // Assuming working with the first series
      if (series !== undefined) {
        for (const point of series.data) {
          // Check if the category value matches
          if (point.category === categoryValue) {
            return point // Return the point if the category matches
          }
        }
      }
      return null // No point found
    }
    if (container) {
      eventArr.forEach((eventType) => {
        container.addEventListener(
          eventType,
          (e) => {
            const charts = Highcharts.charts
            // which chart is under the cursor
            const className = e?.target.className.baseVal
            const target = e?.target
            let indexOfTarget
            if (className === 'highcharts-background') {
              const getAllChartsByClassName = container.querySelectorAll('.highcharts-background')
              indexOfTarget = Array.from(getAllChartsByClassName).indexOf(target)
            } else {
              const closest = target.closest('.highcharts-series-group')
              indexOfTarget = Array.from(container.querySelectorAll('.highcharts-series-group')).indexOf(closest)
            }
            const event: any = charts[indexOfTarget]?.pointer.normalize(e)
            const point: any = charts[indexOfTarget]?.series[0]?.searchPoint(event, true) ?? undefined
            if (!point) return
            charts.forEach((chart, idx) => {
              if (chart && chart?.series?.length > 0 && idx !== indexOfTarget) {
                const points: any = charts.map((chart: any) => findPointByCategory(chart, point?.category))
                // check if chart has at least a visible serie
                const visibleSeries = chart.series.some((serie) => serie.visible === true)
                if (visibleSeries) {
                  if (points.length > 0) {
                    points.forEach((element) => {
                      if (element !== null && element.formatPrefix === 'point') {
                        element.highlight()
                      }
                    })
                  }
                }
              }
            })
          },
          { passive: true }
        )
      })

      return () => {
        // Cleanup: remove event listeners when the component unmounts
        eventArr.forEach((eventType) => {
          container.removeEventListener(
            eventType,
            () => {
              // do nothing
            },
            { passive: true }
          )
        })
      }
    }
  }, [])

  return (
    <LargeCardSection>
      <Row margin="1rem 0">
        <Col base="1px"></Col>
        <Gap></Gap>
        <Col justifyContent="center">
          <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ}>
            <StyledDatePickerDiv>
              <>
                <Row alignItems="center">
                  <Col>
                    <span>Select Date: </span>
                  </Col>
                </Row>
                <DatePicker
                  name="startDate"
                  selected={new Date(startDate)}
                  timeInputLabel="Time:"
                  timeFormat="HH:mm"
                  showTimeSelect={true}
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeIntervals={60}
                  onChange={handleStartDateChange}
                  minDate={defaultStartDate}
                  maxDate={new Date(maxEndDate)}
                  placeholderText="Select Start Date"
                  disabled={props.permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ] !== GRANTED}
                  dataCy="start-date-picker"
                >
                  <span>Maximum selectable range is {HOURS_SETTINGS.maxDays} days.</span>
                </DatePicker>
                <DatePicker
                  name="endDate"
                  selected={new Date(endDate)}
                  timeInputLabel="Time:"
                  timeFormat="HH:mm"
                  showTimeSelect={true}
                  endDate={endDate}
                  minDate={addDays(startDate, 1)}
                  maxDate={new Date(maxEndDate)}
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeIntervals={60}
                  onChange={handleEndDateChange}
                  placeholderText="Select End Date"
                  disabled={props.permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ] !== GRANTED}
                  dataCy="end-date-picker"
                >
                  <span>Maximum selectable range is {HOURS_SETTINGS.maxDays} days.</span>
                </DatePicker>
              </>
            </StyledDatePickerDiv>
          </PermissionCheck>
        </Col>
        <Gap></Gap>
        <Col base="1px" alignItems="flex-end">
          <PermissionCheck value={props.permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_EXPORT]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_EXPORT}>
            <>
              <input type="hidden" value={startDate?.toISOString()} id="from-date-history" />
              <input type="hidden" value={endDate?.toISOString()} id="to-date-history" />
              <Button onClick={() => props.togglePageDialog()} className="align-self-end" id="export-xlsx-button">
                export xlsx
              </Button>
            </>
          </PermissionCheck>
        </Col>
      </Row>
      <Row margin="10px">
        <Gap></Gap>
        <Gap></Gap>
      </Row>
      {/* ID-1 Here it ends */}
      <div id="container" ref={containerRef}>
        {historyData && historyData.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...createHistoryOptions(HistoryChartComponentRef, 'History', deviceType, graphEvents, historyData)
            }}
            ref={HistoryChartComponentRef}
            key={lineChartKey}
          />
        )}
        {multisenseGraphData && multisenseGraphData.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...createMultisenseOptions(multisenseChartComponentRef, 'Multisense', deviceType, graphEvents, multisenseGraphData)
            }}
            ref={multisenseChartComponentRef}
          />
        ) : (
          variant !== 'none' && (
            <StyledDiv data-cy="no-multisense-configured">
              <StyledDivTitle>MultiSense Analog/Temperature</StyledDivTitle>
              <StyledDivText>There is no data available for the MultiSense analog/temperature ports. Please check the MultiSense configuration or choose a different time frame.</StyledDivText>
            </StyledDiv>
          )
        )}
        {periodSeries && periodSeries.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...createIosOptions(IoChartComponentRef, 'IOs', '', graphEvents, periodSeries)
            }}
            ref={IoChartComponentRef}
          />
        ) : (
          variant === 'multi_sense_8' && (
            <StyledDiv>
              <StyledDivTitle>MultiSense IOs</StyledDivTitle>
              <StyledDivText>There is no data available for the MultiSense IO ports. Please check the MultiSense configuration or choose a different time frame.</StyledDivText>
            </StyledDiv>
          )
        )}
        <Col base="1px" alignItems="flex">
          {closedPortsArray && closedPortsArray.length >= 1 && (
            <StyledSpan>
              Ports currently not configured: &nbsp;
              {closedPortsArray?.map((ele, idx) => {
                if (ele.unconfigured) {
                  return (
                    <span key={Math.random().toString(26).slice(2)}>
                      &nbsp;{`${ele.name.toString().toUpperCase().trim()}`}
                      {idx === closedPortsArray.length - 1 ? '' : ','}
                    </span>
                  )
                }
              })}
            </StyledSpan>
          )}
        </Col>
      </div>
    </LargeCardSection>
  )
}

const mapState = (state) => ({
  telemetryHistory: state.devices.telemetryHistory,
  telemetryEvents: state.devices.telemetryEvents
})

const mapDispatch = {
  getTelemetryHistory,
  getTelemetryEvents
}

export default connect(mapState, mapDispatch)(History)
