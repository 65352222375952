// @flow

import { ALERT_TYPE, Alert } from 'components/ReUsable'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import DeviceTagSelectionList from './deviceTagSelectionList'
import { SelectedTagObj } from '../hooks/useDeviceTags'

const StyledNoTags = styled.p`
  font-family: open-sans-regular;
  color: var(--color-table-text);
  font-weight: bold;
  font-size: 1rem;
}`

const DeviceTagSelectorContent = ({ allTags, readError, handleSelection, userSelection }: { allTags: any, readError: boolean, handleSelection: (s: SelectedTagObj) => void, userSelection: SelectedTagObj[] }) => {
  if (!allTags) return null
  const groups = allTags?.groups || []
  const personal = allTags?.personal || []

  if (allTags.length === 0 || (groups.length === 0 && personal.length === 0)) {
    return <><StyledNoTags>No tags defined</StyledNoTags>
    <StyledNoTags>You can create tags <Link to="/tags"> here</Link>.</StyledNoTags></>
  }

  return (
    <>
      {readError ? (
        <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} data- />
      ) : (
        <>
        <DeviceTagSelectionList tags={personal} onSelectTag={handleSelection} userSelection={userSelection} />
          {Object.keys(groups).map((k: string) => {
            return <DeviceTagSelectionList key={k} tags={groups[k].tags || []} groupName={groups[k].name} onSelectTag={handleSelection} userSelection={userSelection} />
          })}
        </>
      )}
    </>
  )
}

export default DeviceTagSelectorContent
