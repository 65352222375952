// @flow

import { Route, Switch } from 'react-router-dom'

import PrivateRoute from 'components/Application/PrivateRoute'

import Main from 'components/Application/Main'
import Home from 'components/Application/Home'
import Device from 'components/Application/Device'
import ManageLicenses from 'components/Application/ManageLicenses'
import ManageDelegations from 'components/Application/ManageDelegations'
import AddDelegation from '../ManageDelegations/AddDelegation'
import AuditLogs from '../AuditLog'
import AdvancedInfo from '../AdvancedInfo'
import UserAccount from 'components/Application/UserAccount'
import Groups from 'components/Application/Groups'
import LegalNotice from 'components/Application/LegalNotice'
import Gdpr from 'components/Application/Gdpr'
import Contact from 'components/Application/Contact'
import { PageNotReachable } from 'components/ReUsable'
import BatchDelegation from '../ManageDelegations/BatchDelegation'
import UnsubscribeAlertConfig from '../UnsubscribeAlertConfig'
import TermsOfUse from 'components/Application/TermsOfUse'
import Alerts from 'components/Application/Alerts'
import Admin from '../Admin'
import Tags from '../Tags'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Router (props: any) {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Main(Home)} />
      <PrivateRoute path="/devices/delegations/:mode" component={Main(BatchDelegation)} />
      <PrivateRoute path="/devices/licenses" component={Main(ManageLicenses)} />
      <PrivateRoute path="/devices/:serialNumber/auditlog" component={Main(AuditLogs)} />
      <PrivateRoute path="/devices/:serialNumber/alerts" component={Main(Alerts)} />
      <PrivateRoute path="/devices/:serialNumber/addrole" component={Main(AddDelegation)} />
      <PrivateRoute path="/devices/:serialNumber/manageroles" component={Main(ManageDelegations)} />
      <PrivateRoute path="/devices/:serialNumber/advancedinfo" component={Main(AdvancedInfo)} />
      <PrivateRoute path="/devices/:serialNumber" component={Main(Device)} />
      <PrivateRoute path="/user_account" component={Main(UserAccount)} />
      <PrivateRoute path="/groups" component={Main(Groups)} />
      <PrivateRoute path="/admin" component={Main(Admin)} />
      <PrivateRoute path='/tags' component={Main(Tags)} />
      <Route path={'/legalnotice'} component={Main(LegalNotice)} />
      <Route path={'/gdpr'} component={Main(Gdpr)} />
      <Route path={'/termsofuse'} component={Main(TermsOfUse)} />
      <Route path="/contact" component={Main(Contact)} />
      <Route path="/unsubscribe/alertconfigs" component={Main(UnsubscribeAlertConfig)} />
      <Route path="*" component={Main(PageNotReachable)} />
    </Switch>
  )
}
