// @flow

import React from 'react'
import { connect } from 'react-redux'
import { GRANTED, DENIED, DENIED_MISSING_LICENSE, UNSUPPORTED_LICENSE_ERROR, DEVICE_DETAILS_PERMISSIONS_LIST, GROUPS_PERMISSIONS_LIST, SOMETHING_WENT_WRONG } from 'appConstants'
import { LicenseMissing, Tooltip } from 'components/ReUsable'

type PermissionCheckProps = {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  value: 'granted' | 'denied' | 'denied_missing_license' | string
  permission: string
  isConnected?: boolean
  deniedTemplate?: React.ReactElement
}

const newProps = {
  className: 'action-btn disable',
  onClick: () => undefined,
  onChange: () => undefined
}

const applyPropsToNestedComponent = (component: any, props?: any) => React.cloneElement(component, props ? { ...props } : { ...newProps })

export const PermissionCheck = ({ children, value, permission, isConnected, deniedTemplate }: PermissionCheckProps) => {
  const nestedComponent = children && children.props && children.props.children

  const cloneSingleLevelComponentWithTooltip = () => {
    const clonedElement = React.cloneElement(children, {
      ...newProps
    })
    return isConnected ? <Tooltip message={UNSUPPORTED_LICENSE_ERROR}>{clonedElement}</Tooltip> : <>{clonedElement}</>
  }

  const checkAndApplyTooltip = () => {
    switch (permission) {
      case DEVICE_DETAILS_PERMISSIONS_LIST.BATTERY_WRITE:
      case DEVICE_DETAILS_PERMISSIONS_LIST.RESET_CYCLES:
      case DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_LEVEL_WRITE:
      case DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_PORT_WRITE:
        return cloneSingleLevelComponentWithTooltip()
      case DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_EXPORT:
      case DEVICE_DETAILS_PERMISSIONS_LIST.ALERT_WRITE:
      case DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE:
        if (Array.isArray(nestedComponent)) {
          const clonedElements = React.cloneElement(children, {
            ...newProps,
            children: nestedComponent.map((component) => applyPropsToNestedComponent(component))
          })
          return isConnected ? <Tooltip message={UNSUPPORTED_LICENSE_ERROR}>{clonedElements}</Tooltip> : <>{clonedElements}</>
        }
        return cloneSingleLevelComponentWithTooltip()
      case DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_HISTORY_READ:
        return (
          <Tooltip message={UNSUPPORTED_LICENSE_ERROR}>
            {React.cloneElement(children, {
              children: applyPropsToNestedComponent(nestedComponent, {
                className: '',
                disabled: true,
                onChange: () => undefined,
                onClick: () => undefined
              })
            })}
          </Tooltip>
        )

      default:
        return <LicenseMissing />
    }
  }

  if (!value) {
    return deniedTemplate || null
  }

  switch (value) {
    case GRANTED:
      return <>{children}</>

    // DENIED case checks if element should be HIDDEN or become INACTIVE.
    case DENIED:
      switch (permission) {
        case DEVICE_DETAILS_PERMISSIONS_LIST.BATTERY_WRITE:
        case DEVICE_DETAILS_PERMISSIONS_LIST.RESET_CYCLES:
        case DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_LEVEL_WRITE:
        case DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_PORT_WRITE:
        case DEVICE_DETAILS_PERMISSIONS_LIST.OPERATION_MODE_WRITE: // Special Case
        case DEVICE_DETAILS_PERMISSIONS_LIST.RESET:
        case GROUPS_PERMISSIONS_LIST.GROUP_DELETE:
        case GROUPS_PERMISSIONS_LIST.GROUP_LEAVE:
        case GROUPS_PERMISSIONS_LIST.GROUP_MODIFY:
          if (Array.isArray(nestedComponent)) {
            return (
              <>
                {React.cloneElement(children, {
                  children: nestedComponent.map((component) => applyPropsToNestedComponent(component)),
                  ...newProps
                })}
              </>
            )
          }
          return <>{React.cloneElement(children, { ...newProps, className: `${children.props.className} ${newProps.className}` })}</>
        default:
          // HIDDEN return null
          return deniedTemplate || null
      }

    case DENIED_MISSING_LICENSE:
      return checkAndApplyTooltip()

    default:
      return <>{SOMETHING_WENT_WRONG}</>
  }
}

const mapState = (state) => ({
  isConnected: state.devices?.current?.state?.content?.isConnected
})

export default connect(mapState, null)(PermissionCheck)
