import React from 'react'
import styled from 'styled-components'
import Loading from './loading'
import { TextInformationResponse } from 'apis/advancedInfomation'

const StyledContent = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 7px;
  background: var(--color-background);
  font-family: open-sans-regular;
  min-height: 90px;
`

const TextSection: React.FC<{resp: TextInformationResponse | null, loading: boolean}> = ({ resp, loading }) => {
  return <StyledContent>{loading ? <Loading/> : resp?.data}</StyledContent>
}

export default TextSection
