import React from 'react'
import styled from 'styled-components'
import Loading from './loading'
import { AttributeInformationResponse, EnabledResponse } from 'apis/advancedInfomation'

const StyledContent = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 7px;
  background: var(--color-background);
`

const StyledAttributes = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  gap: 20px;
`

const StyledAttribute = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.span`
  color: #ababab;
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const StyledValue = styled.span`
  color: var(--color-table-text);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  font-family: digital-serial;
  margin-top: -2px;
`

const renderVal = (val: string | boolean): string => {
  if (typeof val === 'boolean') {
    return val ? 'ON' : 'OFF'
  }

  return val
}

const renderLabel = (key: string): string => {
  switch (key) {
    case 'dhcp':
      return 'DHCP client'
    case 'ip':
      return 'IP'
    case 'mask':
      return 'Mask'
    case 'gateway':
      return 'Gateway'
    case 'dns1':
      return 'DNS 1'
    case 'dns2':
      return 'DNS 2'
    case 'ntp':
      return 'NTP'
    case 'hardwareAddress':
      return 'Hardware Address'
    case 'currentTime':
      return 'Current Time'
    case 'modbus':
        return 'Modbus'
    default:
      return key
  }
}

const InfoSection: React.FC<{ resp: AttributeInformationResponse | null; loading: boolean }> = ({ resp, loading }) => {
  return (
    <StyledContent>
      {loading ? (
        <Loading />
      ) : !resp?.data ? (
        <></>
      ) : (
        <>
          <StyledAttributes>
            {Object.keys(resp?.data)?.map((k: string) => {
              const val = resp?.data

              if (typeof val[k] === 'string' || typeof val[k] === 'boolean') {
                return <StyledAttribute key={k}>
                  <StyledLabel>{renderLabel(k)}</StyledLabel>
                  <StyledValue className="value">{renderVal(val[k] as string)}</StyledValue>
                </StyledAttribute>
              }
              //  Modbus implementation
              const currentVal = val[k] as EnabledResponse
              if (currentVal?.enabled && currentVal?.port) {
                return <StyledAttribute key={k}>
                  <StyledLabel>{renderLabel(k)}</StyledLabel>
                  <StyledValue className="value">{`enabled on port ${currentVal?.port}`}</StyledValue>
                </StyledAttribute>
              }
              return null
            })}
          </StyledAttributes>
        </>
      )}
    </StyledContent>
  )
}

export default InfoSection
