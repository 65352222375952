// @flow

import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import { getTags, updateTag, addTag, removeTag } from 'apis/tags'
import { TagContextType, Tag, TagPost, PERSONAL_TAG_PLACEHOLDER } from '../context/tagContext'

function sortTagsByName (tags: Tag[]): Tag[] {
  if (!tags) return []
  return tags.sort((a, b) => a?.name?.localeCompare(b.name))
}

function sortObjectKeys<T extends Record<string, any>> (obj: T): T {
  const sortedKeys = obj ? Object.keys(obj).sort() : []
  const sortedObj = {} as T

  sortedKeys.forEach((key) => {
    sortedObj[key as keyof T] = obj[key as keyof T]
  })

  return sortedObj
}

export function useTags (): TagContextType {
  const { data: tags, error: readError, isLoading: loading } = useSWR('getTagsAPI', getTags)
  const [apiError, setApiError] = useState<string[]>([])
  const handleTagAddEdit = async (tag: TagPost | Tag, groupSlug: string | undefined): Promise<boolean> => {
    const currentSlug = groupSlug || PERSONAL_TAG_PLACEHOLDER
    const { name, color, id } = tag
    if (tag) {
      try {
        let resp
        if (id) {
          resp = await updateTag(`${id}`, { name: name?.trim(), color: color })
        } else {
          resp = await addTag({ ...tag, name: name?.trim() } as TagPost)
        }
        if (resp && resp.status && resp.status > 200) {
          throw resp
        }
        const errObj = apiError.filter((err: string) => err !== currentSlug)
        setApiError(errObj)
        mutate('getTagsAPI')
        return true
      } catch (e: any) {
        const errArr = [...new Set([...apiError, currentSlug])]
        setApiError(errArr)
        return false
      }
    }

    return false
  }

  const personalTags = !tags ? [] : (sortTagsByName(tags?.personal) as Tag[])
  const groupTags = sortObjectKeys(tags?.groups)

  const tagsEmpty = Object.keys(groupTags).filter((k: string) => groupTags[k].tags.length > 0).length === 0 && personalTags.length === 0

  const isUniqueInGroup = (tag: TagPost) => {
    if (!tag.group) {
      return personalTags?.filter((pt: Tag) => pt.name === tag.name && tag.id !== pt.id).length === 0
    } else {
      return groupTags[tag.group]?.tags?.filter((pt: Tag) => pt.name === tag.name && tag.id !== pt.id).length === 0
    }
  }

  const handleTagDelete = async (id: string, groupSlug: string | undefined) => {
    const currentSlug = groupSlug || PERSONAL_TAG_PLACEHOLDER
    try {
      const resp = await removeTag(id)
      if (resp && resp.status && resp.status > 204) {
        throw resp
      }
      const errObj = apiError.filter((err: string) => err !== currentSlug)
      setApiError(errObj)
      mutate('getTagsAPI')
      return true
    } catch (e: any) {
      const errArr = [...new Set([...apiError, currentSlug])]
      setApiError(errArr)
      return false
    }
  }
  return {
    personal: personalTags,
    groups: groupTags,
    loading,
    handleTagAddEdit,
    handleTagDelete,
    isUniqueInGroup,
    readError,
    apiError,
    tagsEmpty
  }
}
