import * as C from 'appConstants'
import { ClaimType } from 'types/claim'
import { LicenseType } from 'types/devices'

export const deleteClaim = (payload: {serialNumber: string}) => ({ type: C.DELETE_CLAIM, payload: { ...payload } })

export const startClaimingSubmit = (payload: { serialNumber: string }) => ({ type: C.START_CLAIMING_SUBMIT, payload: { ...payload } })

export const clearClaimingSubmit = () => ({ type: C.CLEAR_CLAIM })

export const populateStartClaiming = (payload: { response: Partial<ClaimType>, serialNumber: string }) => ({ type: C.POPULATE_START_CLAIMING, payload: { ...payload } })

export const verifyPasswordSubmit = (payload: { serialNumber: string, password: string }) => ({ type: C.VERIFY_PASSWORD_SUBMIT, payload: { ...payload } })

export const populateVerifyPassword = (payload: { response: any, password: string }) => ({ type: C.POPULATE_VERIFY_PASSWORD, payload: { ...payload } })

export const connectToDeviceSubmit = () => ({ type: C.CONNECT_TO_DEVICE_SUBMIT })

export const setDeviceNameSubmit = (payload: { serialNumber: string, name: string }) => ({ type: C.SET_DEVICE_NAME_SUBMIT, payload: { ...payload } })

export const setInclusiveLicenseSubmit = (payload: { serialNumber: string }) => ({ type: C.SET_INCLUSIVE_LICENSE_SUBMIT, payload: { ...payload } })

export const populateSetDeviceName = (payload: { inclusiveLicense?: LicenseType | null }) => ({ type: C.POPULATE_SET_DEVICE_NAME, payload: { ...payload } })

export const populateInclusiveLicense = (payload: any) => ({ type: C.POPULATE_SET_INCLUSIVE_LICENSE, payload: { ...payload } })

export const completeClaimDevice = () => ({ type: C.COMPLETE_CLAIM_DEVICE })
