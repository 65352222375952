// @flow

import MuiTooltip from '@mui/material/Tooltip'
import styled from 'styled-components'

const StyledReactTooltip = styled(MuiTooltip)`
    font-family: open-sans-regular;
`
type Props = {
    message: string,
    children?: any
}

export default function Tooltip ({ message, children }: Props) {
    return (
        <StyledReactTooltip
            title={message}
            arrow
            enterTouchDelay={10}
        >
            <span>
                {children}
            </span>
        </StyledReactTooltip>
    )
}
