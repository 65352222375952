import * as C from 'appConstants'
import { INotification } from 'reducers/notificationReducer'

export const getNotifications = () => ({ type: C.GET_NOTIFICATIONS })

export const populateNotifications = (payload: INotification[]) => ({ type: C.POPULATE_NOTIFICATIONS, payload })

export const confirmNotification = (payload: {uuid: string}) => ({ type: C.CONFIRM_NOTIFICATION, payload })

export const saveConfirmNotification = (payload: {uuid: string}) => ({ type: C.SAVE_CONFIRM_NOTIFICATION, payload })
