import {
  backendEndpoint,
  executeRequest
} from '.'

const tokensEndpoint = `${backendEndpoint || ''}/account/tokens`

export const deleteAccessTokenRequest = (uuid: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'DELETE'
  }

  return executeRequest(`${tokensEndpoint}/${uuid}`, undefined, undefined, options)
}

export const createAccessTokenRequest = ({ name, expiresAt }: {name: string, expiresAt: string}) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'POST',
      body: JSON.stringify({ name, expiresAt })
  }

  return executeRequest(`${tokensEndpoint}`, undefined, undefined, options)
}

export const getAccessTokensRequest = (page?: string) => {
  const options = {
      'Content-Type': 'application/json',
      method: 'GET'
  }

  return executeRequest(`${tokensEndpoint}?page=${page || 1}&size=25`, undefined, undefined, options)
}
