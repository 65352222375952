import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import styled from 'styled-components'
import { ALERT_TYPE, Button, ButtonCancel, PermissionCheck, Alert } from 'components/ReUsable'
import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'
import { useDeviceTags } from '../hooks/useDeviceTags'
import DeviceTagSelectorContent from '../deviceTagSelection/deviceTagSelectorContent'

const StyledAddButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  width: 19px;
  height: 19px;
  margin-left: -8px;
  position: relative;
  top: -1px;

  img {
    width: 100%;
    height: 100%;
  }
`

const StyledPopOverContent = styled.div`
  padding: 10px;
  padding-top: 16px;
  min-width: 360px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`

const StyledSaveButton = styled(Button)`
  min-height: 2.5rem;
  min-width: 7rem;
`

type DeviceTagsSelectorProps = { id: string; serialNumber: string; onSave: () => void; permissions: any }

export default function DeviceTagsSelectorPopOver ({ id, serialNumber, permissions, onSave }: DeviceTagsSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const idValue = open ? id : undefined

  return (
    <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TAGS_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.TAGS_WRITE}>
      <>
          <StyledAddButton onClick={handleOpen} data-cy={`addDeviceTagButton-${id}`} title="Add tags to this device">
            <img src="/imgs/add-tag-button.svg"/>
          </StyledAddButton>
          <Popover
            id={idValue}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <StyledPopOverContent>
                <DeviceTags handleClose={handleClose} serialNumber={serialNumber} onSaveComplete={onSave} />
              </StyledPopOverContent>
            </ClickAwayListener>
          </Popover>
        </>
    </PermissionCheck>
  )
}

export function DeviceTags ({ serialNumber, handleClose, onSaveComplete } : { serialNumber: string, handleClose: () => void, onSaveComplete: () => void }) {
  const { tagsEmpty, allTags, loading, readError, userSelection, handleUserSelection, handleTagSave } = useDeviceTags(serialNumber)
  const [saveError, setSaveError] = useState(false)

  const handleSave = async () => {
    const success = await handleTagSave()
    if (success) {
      handleClose()
      onSaveComplete()
    } else {
      setSaveError(true)
    }
  }

  if (loading) {
    return <Spinner animation="border" variant="secondary" />
  }

  return (
    <>
      { saveError ? <Alert messages={['An error occured. Please try again later.']} type={ALERT_TYPE.danger} /> : null }
      <DeviceTagSelectorContent allTags={allTags} readError={readError} userSelection={userSelection} handleSelection={handleUserSelection} />
      {!tagsEmpty ? (
        <StyledButtonContainer>
          <ButtonCancel onClick={handleClose} data-cy="cancelTagSelection">
            Cancel
          </ButtonCancel>
          <StyledSaveButton onClick={handleSave} data-cy="saveTagSelection">
            Save
          </StyledSaveButton>
        </StyledButtonContainer>
      ) : null}
    </>
  )
}
