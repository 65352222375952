import {
    productImage,
    getNumberWithDecimals,
    getProperty
} from 'utils/helpers'
import { secondsToHours } from 'date-fns'
import moment from 'moment'

export const getOperatingHours = (operatingTime:number) => (secondsToHours(operatingTime))

export const parseDeviceTelemetryData = deviceData => {
    const getSocOrVoltage = (data) => {
        if (data.stateOfCharge && Number(data.stateOfCharge) >= 0) {
            return `${data.stateOfCharge} %${data.voltage ? ` (${getNumberWithDecimals(data.voltage, 1)} V)` : ''}`
        }
        return data.voltage ? `${getNumberWithDecimals(data.voltage, 1)} V` : ''
    }

    const operatingData = getProperty(deviceData.operatingState) ? getProperty(deviceData.operatingState) : {}
    const telemetryData = getProperty(deviceData.telemetryOverview) ? getProperty(deviceData.telemetryOverview) : {}

    return {
        device: {
            id: deviceData.id,
            name: deviceData.name,
            img_url: productImage(deviceData.deviceType, '_front'),
            serialNumber: deviceData.serialNumber,
            serviceLevel: deviceData.serviceLevel,
            type: deviceData.deviceType,
            permissions: deviceData.permissions,
            license: deviceData.license,
            clusterRole: deviceData.clusterRole,
            tags: deviceData.tags || []
        },
        state: {
            state: operatingData.state,
            isConnected: operatingData.isConnected,
            lastUpdated: operatingData.lastUpdated,
            permissions: deviceData.permissions
        },
        fuel_level: { default: telemetryData.fuelLevel, permissions: deviceData.permissions },
        firmware_version: {
            default: telemetryData.firmwareVersion ? `${telemetryData.firmwareVersion.major}.${telemetryData.firmwareVersion.minor}.${telemetryData.firmwareVersion.patch}` : '',
            permissions: deviceData.permissions
        },
        battery_soc_or_voltage: {
            default: getSocOrVoltage(telemetryData),
            hideable: true,
            permissions: deviceData.permissions
        },
        operating_hours: {
            default: telemetryData.operatingTime ? getOperatingHours(telemetryData.operatingTime) : '',
            hideable: true,
            permissions: deviceData.permissions
        },
        serialNumber: {
            default: deviceData.serialNumber,
            hideable: true,
            permissions: {}
        },
        license_type: {
            default: deviceData.license ? `${deviceData.license.tier} license` : 'no license',
            permissions: deviceData.permissions
        },
        license_expire: {
            default: deviceData.license ? `${deviceData.license.end ? moment(deviceData.license.end).format('DD.MM.yyyy') : 'never'}` : '',
            permissions: deviceData.permissions
        },
        access_type: {
            default: deviceData.ownershipStatus ? deviceData.ownershipStatus : 'unrelated',
            permissions: deviceData.permissions
        },
        inServiceMode: deviceData.inServiceMode
    }
}
