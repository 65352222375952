import styled from 'styled-components'
import { Button } from 'components/ReUsable'
import { INotification } from 'reducers/notificationReducer'

const StyledButton = styled(Button)`
  display: block;
  margin: 2rem auto 1rem auto;
  min-width: calc(100% - 2rem)
`

const StyledContentContainer = styled.div`
  display: flex;
  min-height: 240px;
  flex-direction: column;
`

const StyledLink = styled.a`
  display: inline-block;
  margin: 0;
  padding: 1rem;
  white-space: nowrap;
  font-family: digital-serial;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.8rem;
  color: var(--color-table-text);
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: .5px;
`

const StyledBody = styled.div`
  font-family: open-sans-regular;
  font-size: 16px;
  font-weight: 400;
  padding: 1rem;
  img, video {
    max-width: 100%;
    height: auto;
  }
`

type NotificationContentType = {
  activeNotification: INotification | null,
  handleConfirmNotification?: (uuid: string) => void
}

export default function NotificationContent ({ activeNotification, handleConfirmNotification } : NotificationContentType) {
  if (!activeNotification) return null

  const { body, button, link } = activeNotification

  return (
    <>
    <StyledContentContainer>
      <StyledBody dangerouslySetInnerHTML={{ __html: body }} />
      { link
      ? <StyledLink href={link.url} target="_blank" data-cy="notification-link">
        {link.label}
      </StyledLink>
      : null
      }
    </StyledContentContainer>
    <StyledButton onClick={() => handleConfirmNotification?.(activeNotification.id)} data-cy="notification-button">
      {button}
    </StyledButton>
  </>
  )
}
