import {
  TOGGLE_DIALOG,
  TOGGLE_PAGE_DIALOG
} from 'appConstants'

const initialState = {
  showConsent: true,
  isDialogOpen: false,
  isPageDialogOpen: false,
  dialogTitle: '',
  dialogContent: '',
  dialogContentValue: '',
  dialogId: null
}

function toggleDialogOpen (state) {
  const newState = Object.assign({}, state)

  newState.isDialogOpen = !state.isDialogOpen

  return newState
}

function togglePageDialog (state, payload: {dialogId?: string, dialogTitle?: string, dialogContent?: string, dialogContentValue?: string}) {
  const newState = Object.assign({}, state)
  if (payload.dialogId) {
    newState.isPageDialogOpen = true
  } else {
    newState.isPageDialogOpen = !state.isPageDialogOpen
  }

  newState.dialogTitle = payload.dialogTitle
  newState.dialogContent = payload.dialogContent
  newState.dialogContentValue = payload.dialogContentValue
  newState.dialogId = payload.dialogId
  return newState
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case TOGGLE_DIALOG:
      return toggleDialogOpen(state)
    case TOGGLE_PAGE_DIALOG:
      return togglePageDialog(state, payload)
    default:
      return state
  }
}
